<template>
  <v-dialog v-model="dialog" transition="slide-x-transition" max-width="400">
    <v-card>
      <v-toolbar flat dense dark>
        <div>Je připravena aktualizace panelu</div>
        <v-spacer></v-spacer>
        <v-toolbar-items class="pl-3">
        <v-btn fab x-small color="red" class="elevation-0">
          <v-icon @click="dialog=false">mdi-close</v-icon>
        </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-3">
        <p>Prosím proveďte při nejbližší příležitosti aktualizaci panelu.</p>
        <p>POZOR - aktualizaci neprovádějte pokud máte otevřeny záznamy příchozích hovorů.<br />
          (Aktualizace bude trvat pouze několik vteřin)</p>
        <v-btn block color="error" @click="reloadApp">Aktualizovat panel</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import axios from "axios";
import moment from "moment/moment";
import {mapGetters, mapState} from "vuex";

export default {
  mounted() {
    this.initVersionCheck('/version.json');
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapState({
      currentVersionHash: state => state.app.currentVersionHash,
      newVersionHash: state => state.app.newVersionHash,
    }),
    ...mapGetters('app', {
      hasVersionChanged: 'hasVersionChanged',
    }),
  },
  watch: {
    hasVersionChanged(value) {
      if (value) {
        this.dialog = true;
      }
    }
  },
  methods: {
    initVersionCheck(url, frequency = 1000 * 60) {

      this.checkVersion(url);

      setInterval(() => {
        this.checkVersion(url);
      }, frequency);
    },
    async checkVersion(url) {
      try {
        const fileResponse = await axios.create({
          // baseURL: `/version.json`,
          headers: {
            'Content-type': 'application/json'
          }
        }).get(url + '?t=' + new Date().getTime());
        this.$store.commit('app/setNewVersionHash', fileResponse.data.hash)

      } catch (error) {
        this.loading = false;
        if (!error.response) {
          this.errorStatus = 'Error: Network Error'
        } else {
          this.errorStatus = error.response.data.message;
        }
      }
    },
    reloadApp() {
      window.location.href = "/?" + moment();
    }
  }
}
</script>