<template>
    <v-dialog v-if="dialog" v-model="dialog" max-width="1024">
        <v-card :loading="loading" :disabled="loading">
            <v-list color="secondary" dark>
                <v-list-item>
                    <v-list-item-content>
                        Příkaz k nasazení techniky
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn icon @click="close()">
                            <v-icon>cancel</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <div v-if="showResources">
                <resources @onSelect="onResourceSelect" @onClose="onResourcesClose"></resources>
            </div>
            <div v-else>
                <v-form ref="form" class="pa-3">
                    <resource :resource="form.resource" :current-event="event" :job="form.job"
                              :resourceEvent="resourceEvent"></resource>
                </v-form>
                <v-card-actions>
                    <v-btn text @click="onResourceList">
                        <v-icon left>fa-caret-left</v-icon>
                        Přehled techniky
                    </v-btn>
                    <div class="flex-grow-1"></div>
                    <v-btn color="success" v-if="canUpdate" @click="save">Uložit změny</v-btn>
                    <v-btn color="success" v-if="canInsert" :disabled="disableInsert" @click="insert">{{
                            title
                        }}
                    </v-btn>
                    <confirm-button
                            color="success"
                            v-if="showMove"
                            v-bind:options="moveConfirmOptions"
                            @click="insert">
                        {{ moveConfirmOptions.label }}
                    </confirm-button>
                    <confirm-button
                            color="error"
                            v-if="showDelete"
                            v-bind:options="endConfirmOptions"
                            @click="end">
                        {{ endConfirmOptions.label }}
                    </confirm-button>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>

import Resources from "./Resources";
import Resource from "./Resource";
import {mapGetters} from "vuex";
import ConfirmButton from "@/components/ui/ConfirmButton";

export default {
    name: 'sidebar-event-contact-form',
    components: {
        ConfirmButton,
        Resources,
        Resource,
    },
    data() {
        return {
            resourcesDialog: false,
            dialog: false,
            loading: false,
            form: {
                resource: {},
            },
            message: false,
            hints: true,
            endConfirmOptions: {
                label: 'Ukončit nasazení techniky',
                tooltip: 'Ukončení nasazení techniky',
                confirm: 'Chcete opravdu ukončit nasazení této techniky?',
                icon: null,
                class: null,
            },
            moveConfirmOptions: {
                label: 'Přesunout techniku',
                tooltip: 'Přesun techniky mezi událostmi',
                confirm: 'Technika zasahuje u jiné události! Chcete ji přesunout?',
                icon: null,
                class: null,
            }
        }
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('resources', {
            resourceById: 'byId',
        }),
        ...mapGetters('events', {
            tooltip: 'targetTooltip',
        }),
        eventCategory() {
            return id => this.getEventCategoryById(id);
        },
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
        }),
        title() {
            return this.canInsert ? "Nasadit techniku" : "Upravit výjezd";
        },
        resourceEvent() {
            if (this.form.resource.event)
                return this.form.resource.event;

            if (this.form.job && this.event.id === this.form.job.eventId)
                return this.event;

            return null;
        },
        showResources() {
            return this.resourcesDialog || this.form.vehicle === null;
        },
        canInsert() {
            return this.form.id === null;
        },
        showMove() {
            if (this.event.id === this.form.job?.eventId)
                return false;

            return this.canDelete;
        },
        disableInsert() {
            return this.form.job?.id > 0;
        },
        canUpdate() {
            return false;
        },
        canChange() {
            return this.form.id > 0 && this.form.job?.eventJobCategoryId > 0;
        },
        showDelete() {
            return this.form.job?.eventId && this.canDelete && this.isSameEvent;
        },
        canDelete() {
            if (this.canChange) {
                if (this.form.resource.baseId === this.$store.state.auth.user.baseId)
                    return true;

                if (this.$store.state.auth.user.baseId === this.$store.state.bases.centralBaseId)
                    return true;
            }
            return false;
        },
        isSameEvent() {
            return this.event.id === this.form.job?.eventId;
        }
    },
    methods: {
        new() {
            this.form = {
                eventId: this.event.id,
                resource: {},
                vehicle: null,
            }
            this.loading = false;
            this.dialog = true;
        },
        open(job) {
            this.resourcesDialog = false;
            this.loading = false;
            this.dialog = true;
            this.form = {
                id: job.id,
                job: job,
                resource: this.$store.state.resources.items[job.vehicle],
                vehicle: job.vehicle,
            }

            if (job.baseId)
                this.form.baseId = job.baseId
        },
        onResourcesClose() {
            this.resourcesDialog = false;
        },
        onResourceList() {
            this.resourcesDialog = true;
            this.form.vehicle = null;
            this.form.resource = {};
        },
        onResourceSelect(resource) {
            this.resourcesDialog = false;
            this.form.id = resource?.eventJob?.id ? resource.eventJob.id : null;
            this.form.resource = resource;
            this.form.job = resource?.eventJob;
            this.form.vehicle = resource.id;

            if (resource.baseId)
                this.form.baseId = resource.baseId
        },
        validate() {
            this.$v.form.$touch();
            if (this.$v.$invalid) {
                this.submitting = false;
                return false;
            }
            return true;
        },
        close() {
            this.dialog = false;
        },
        insert() {
            this.loading = true;
            const data = {
                id: this.form.id,
                baseId: this.form.baseId,
                eventId: this.form.eventId,
                vehicle: this.form.vehicle,
                resource: {
                    id: this.form.resource.id
                },
            };
            this.$store.dispatch('eventJobs/insert', data).then(() => {
                this.loading = false;
                this.close();
            });
        },
        save() {
            this.loading = true;
            this.$store.dispatch('eventJobs/update', this.form).then(() => {
                this.close();
                this.loading = false;
            });
        },
        end() {
            this.loading = true;
            this.$store.dispatch('eventJobs/end', this.form.id).then(() => {
                this.close();
                this.loading = false;
            });
        },
    }
}
</script>