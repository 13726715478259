import axios from 'axios';
import store from '../store/index'

let apiUrl = process.env.VUE_APP_TEREZA_API_URL;
let apiCancelSource = null;

export default {
    getHeaders() {
        return {
            cancelToken: apiCancelSource ? apiCancelSource.token : null,
            headers: {
                'Authorization': store.getters['auth/token'],
                'panel': true,
            }
        };
    },
    save(endpoint, data) {
        return new Promise((resolve) => {
            let result;
            if (data.id) {
                result = this.update(endpoint + '/' + data.id, data);
            } else {
                result = this.create(endpoint, data);
            }
            resolve(result);
        })
    },
    get(endpoint) {
        this.cancel();
        apiCancelSource = axios.CancelToken.source();

        return axios.get(apiUrl + endpoint, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    async delete(endpoint) {
        return axios.delete(apiUrl + endpoint, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    async create(endpoint, data) {
        return axios.post(apiUrl + endpoint, data, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    update(endpoint, data) {
        return axios.put(apiUrl + endpoint, data, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    async post(endpoint, data) {
        return await this.create(endpoint, data);
    },
    put(endpoint, data) {
        return this.update(endpoint, data);
    },
    cancel() {
        if (apiCancelSource)
            apiCancelSource.cancel('Cancel previous request');
    }
}
