export const UPDATE_APP  = 'UPDATE_APP';
export const ON_TERRAIN_DIALOG = 'ON_TERRAIN_DIALOG';
export const ON_EVENT_INSERT = 'ON_EVENT_INSERT';
export const ON_EVENT_ALERT_INSERT = 'ON_EVENT_ALERT_INSERT';
export const ON_ACTIVE_EVENT_BLOCK = 'ON_ACTIVE_EVENT_BLOCK';
export const ON_ACTIVE_EVENT_UNBLOCK = 'ON_ACTIVE_EVENT_UNBLOCK';
export const ON_RESIZE = 'ON_RESIZE';
export const ON_SIDEBAR_SHOW = 'ON_SIDEBAR_SHOW';
export const ON_SIDEBAR_HIDE = 'ON_SIDEBAR_HIDE';
export const ON_MAP_SCREEN_SHOT = 'ON_MAP_SCREEN_SHOT';
export const ON_JPO_AREAS_LAYER_VISIBILITY = 'ON_JPO_AREAS_LAYER_VISIBILITY';
export const SHOW_EVENT_VIEW = 'SHOW_EVENT_VIEW';
export const UPDATE_CHILD_EVENTS  = 'UPDATE_CHILD_EVENTS';
export const SELECT_EVENT_TAB = 'SELECT_EVENT_TAB';
export const HIDE_TOOLTIP = 'HIDE_TOOLTIP';
export const HIDE_EVENT_VIEW = 'HIDE_EVENT_VIEW';
export const SHOW_EVENT_JOURNAL = 'SHOW_EVENT_JOURNAL';
export const HIDE_EVENT_JOURNAL = 'HIDE_EVENT_JOURNAL';
export const ON_MICROPHONE_TEST = 'ON_MICROPHONE_TEST';
export const ON_APP_LOG = 'ON_APP_LOG';
export const ON_APP_LOG_SEND = 'ON_APP_LOG_SEND';
export const ADD_CONTACT_TO_EVENT = 'ADD_CONTACT_TO_EVENT';
export const ON_CONTEXT_OPEN = 'ON_CONTEXT_OPEN';
export const ON_CONTEXT_CLOSE = 'ON_CONTEXT_CLOSE';
export const ON_FORWARD_MESSAGE = 'ON_FORWARD_MESSAGE';
export const ON_DEBUG_DIALOG = 'ON_DEBUG_DIALOG';
export const ON_SHOW_EVENT_LINK_DIALOG = 'ON_SHOW_EVENT_LINK_DIALOG';
export const ON_NETWORK_STATUS_CHANGE = 'ON_NETWORK_STATUS_CHANGE';
export const ON_MEDIA_SHOW = 'ON_MEDIA_SHOW';
export const ON_OPEN_IFRAME = 'ON_OPEN_IFRAME';