<template>
    <vl-layer-group ref="layer" v-if="nearestEvents">
        <layer-event-target v-bind:event="event" v-bind:z-index="110" v-for="(event) in nearestEvents" :key="event.id"
                            v-bind:feature-type="'eventTargetLink'"
                            v-bind:context-menu="'linkEventContextMenu'"></layer-event-target>
        <vl-layer-vector :z-index="100">
            <vl-source-vector>
                <vl-feature>
                    <vl-geom-circle v-if="radius > 0" :coordinates="targetCoordinates" :radius="radius"/>
                    <vl-style-func :function="circleStyleFunc()"/>
                </vl-feature>
                <vl-feature v-for="(relativeEvent) in nearestEvents" :key="relativeEvent.id" :properties="{
                    type: 'eventTargetLinkArrow', 
                    contextMenu: 'linkEventContextMenu',
                    tooltip:targetTooltip(relativeEvent)
                }" :id="relativeEvent.uuid">
                    <vl-geom-line-string :coordinates="arrowCoordinates(relativeEvent)"/>
                    <vl-style-func :function="arrowStyleFunc()"/>
                </vl-feature>
            </vl-source-vector>
        </vl-layer-vector>
    </vl-layer-group>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import LayerEventTarget from "@/components/map/layers/event/Target.vue";
import LineString from 'ol/geom/LineString';
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Icon from 'ol/style/Icon'
import Point from 'ol/geom/Point'
import Fill from 'ol/style/Fill';
import {boundingExtent} from 'ol/extent'
import {transformExtent} from 'ol/proj';
import {formatLineLength} from "@/helpers/format";
import {createPointGeom, createStyle} from "vuelayers/dist/ol-ext";
import {forEach, isEmpty, maxBy, minBy, toArray} from "lodash";
import {appBus} from "@/main";
import {HIDE_TOOLTIP, ON_SHOW_EVENT_LINK_DIALOG} from "@/events";

export default {
    name: 'event-link-event-map-layer',
    components: {LayerEventTarget},
    props: {
        event: Object,
        targetCoordinates: Array,
    },
    data() {
        return {
            visible: 1,
        }
    },
    created() {
        this.$nextTick(() => {
            this.zoomToTargets();
            this.showLinkDialog();
        });
    },
    computed: {
        ...mapState({
            map: state => state.map.map,
        }),
        ...mapGetters('events', {
            targetTooltip: 'targetTooltip',
            getNearestEvents: 'getNearestEvents',
            getNearestEvent: 'getNearestEvent',
            getEventTargetCoordinates: 'getEventTargetCoordinates',
        }),
        ...mapGetters('eventCategories', {
            eventCategoryById: 'getEventCategoryById',
        }),
        radius() {
            return this.maxDistance * 0.000006884;
        },
        nearestEvents() {
            const nearestEvents = this.getNearestEvents(this.event);
            return isEmpty(nearestEvents) ? null : nearestEvents;
        },
        maxDistance() {
            return maxBy(toArray(this.nearestEvents), function (o) {
                return o.distance;
            }).distance;
        },
        minDistance() {
            return minBy(toArray(this.nearestEvents), function (o) {
                return o.distance;
            }).distance;
        },
        eventsTargetExtents() {
            let extents = [];
            extents.push([this.event.targetLongitude, this.event.targetLatitude]);
            forEach(this.nearestEvents, (event) => {
                extents.push([event.targetLongitude, event.targetLatitude]);
            });
            return extents;
        }
    },
    methods: {
        ...mapActions('map', {
            extendToArray: 'extendToArray',
        }),
        arrowCoordinates(event) {
            return [this.targetCoordinates, this.getEventTargetCoordinates(event)];
        },
        ...mapActions('eventDestination', {
            save: 'save',
            end: 'end',
            remove: 'remove',
        }),
        zoomToTargets() {
            if (this.maxDistance > 150)
                return;

            this.$store.commit('map/setAutoFocus', false);
            this.$nextTick(() => {
                let extent = boundingExtent(this.eventsTargetExtents);
                extent = transformExtent(extent, this.$store.state.map.dataProjection, this.$store.state.map.viewProjection);

                this.$store.state.map.map.$view.fit(extent, {
                    size: this.$store.state.map.map.$map.getSize(),
                    duration: 500,
                    maxZoom: 30,
                    padding: [5, 5, 5, 5],
                })
            })
        },
        showLinkDialog() {
            if (this.minDistance <= 15) {
                const event = this.getNearestEvent(this.event);
                appBus.$emit(HIDE_TOOLTIP);
                appBus.$emit(ON_SHOW_EVENT_LINK_DIALOG, event.uuid);
            }
        },
        circleStyleFunc() {
            const fillColor = [255, 82, 82, 0.2];

            return () => {
                return [
                    new Style({
                        fill: new Fill({
                            color: fillColor,
                            opacity: 1.0
                        }),
                        stroke: new Stroke({
                            width: 2,
                            color: "grey",
                            lineCap: 'square',
                            lineDash: [6, 6],
                        }),
                    }),
                ];
            }
        },
        arrowStyleFunc() {

            let whiteColor = [255, 255, 255, 1];
            let redColor = [255, 82, 82, 1];

            return (feature) => {

                let geometry = feature.getGeometry();

                let styles = [
                    new Style({
                        stroke: new Stroke({
                            width: 6,
                            color: "white",
                        }),
                    }),
                    new Style({
                        stroke: new Stroke({
                            width: 3,
                            color: redColor,
                            lineCap: 'square',
                            lineDash: [8, 6],
                        }),
                    }),
                ];

                geometry.forEachSegment((start, end) => {
                    const dx = end[0] - start[0];
                    const dy = end[1] - start[1];
                    const rotation = Math.atan2(dy, dx);
                    const line = new LineString([start, end]);
                    const center = line.getCoordinateAt(0.5)


                    const startPx = this.map.$map.getPixelFromCoordinate(start);
                    const endPx = this.map.$map.getPixelFromCoordinate(end);
                    const distance = Math.sqrt((startPx[0] - endPx[0]) ** 2 + (startPx[1] - endPx[1]) ** 2);
                    const numberOfArrows = Math.ceil(distance / 50);

                    styles.push(createStyle({
                        zIndex: 10,
                        geom: createPointGeom(center),
                        text: formatLineLength(line),
                        textFont: 'normal 11px Roboto',
                        textFillColor: whiteColor,
                        textBackgroundFillColor: redColor,
                        textBaseline: 'middle',
                        textAlign: 'center',
                        textPadding: [20, 3, 0, 5],
                        textBackgroundStrokeWidth: 2,
                        textBackgroundStrokeColor: 'white',
                        textOffsetY: 9,
                    }))

                    styles.push(createStyle({
                        zIndex: 11,
                        geom: createPointGeom(center),
                        text: '"',
                        textFont: 'normal 20px tereza',
                        textFillColor: whiteColor,
                        textAlign: 'center',
                        textOffsetY: -6,
                    }))

                    let arrowDistance = 1 / numberOfArrows;
                    for (let i = 1; i <= numberOfArrows; i++) {
                        let arrowPos = line.getCoordinateAt(arrowDistance * i)
                        styles.push(
                            new Style({
                                zIndex: 1,
                                geometry: new Point(arrowPos),
                                image: new Icon({
                                    src: './assets/icons/arrowWhiteOutline.png',
                                    anchor: [0.75, 0.5],
                                    rotateWithView: true,
                                    rotation: -rotation,
                                }),
                            })
                        );
                    }
                });
                return styles;
            }
        },
    },
    watch: {
        minDistance: function () {
            this.showLinkDialog();
        },
        maxDistance: function () {
            this.zoomToTargets();
        },
        eventsTargetExtents: function (extents) {
            this.$store.commit('map/setExtraExtents', {key: 'linkEventEvents', values: extents});
        },
    },
}
</script>
