<template>
  <v-card flat style="max-height: 60vh; overflow: auto">
      <pre style="white-space: pre-wrap">{{ messages }}</pre>
    </v-card>
</template>

<script>

    import {mapState} from "vuex";

    export default {
        name: 'dev-messages',
        created() {
        },

        computed: {
          ...mapState({
            messages: state => state.messages.items,
          })
        },
    }
</script>

<style lang="scss">
.dev-panel {
  position: absolute;
  z-index: 100000;
  bottom: 0;

  .input-group {
    &.period {
      width: 50px;
    }

    &.hash {
      width: 100px;
    }
  }

  .tab-content {
    max-width: 600px;
    max-height: 80vh;
    overflow: auto;
  }
}
</style>

