import axios from 'axios';
import store from '../store/index'


let apiCancelSource = null;

export default {
    getHeaders() {
        return {
            responseType: 'json',
            cancelToken: apiCancelSource ? apiCancelSource.token : null,
        };
    },
    getUrl() {
        return store.state.setup.endpoints.railImageUrl;
    },
    getData(coordinates) {
        this.cancel();
        apiCancelSource = axios.CancelToken.source();
        
        let url = this.getUrl() + '?longitude=' + coordinates[1] + '&latitude=' + coordinates[0];
        return axios.get(url, this.getHeaders()).then((response) => {
            return response.data;
        }).catch(function (error) {
            throw new Error(error);
        });
    },
    cancel() {
        if (apiCancelSource)
            apiCancelSource.cancel('Cancel previous request');
    }
}
