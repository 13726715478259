<template>
    <v-col align-items-center class="panel-title">
        <div>
            <div class="text-h6 text-truncate lh-1-3">{{ getTitle }}</div>
            <div class="text-caption text-truncate lh-1-0"><v-chip x-small v-if="env!=='production'" color="warning" class="mr-1">{{ env }}</v-chip>{{ getDescription }}</div>
        </div>
    </v-col>
</template>

<script>

import {mapGetters, mapState} from 'vuex'
    import {shift} from "@/helpers/helper";

    export default {
        name: 'header-main',
        data() {
            return {
                env: process.env.VUE_APP_ENV,
            }
        },
        computed: {
            ...mapState({
                minuteDate: store => store.app.minuteDate,
            }),
            ...mapGetters('bases', {
                getUserBase: 'getUserBase',
            }),
            ...mapGetters('events', {
                isActiveEvent: 'isActiveEvent',
                activeEvent: 'getActiveEvent',
            }),
            ...mapGetters('eventTypes', {
                getEventType: 'get',
            }),
            shift() {
              return shift(this.minuteDate);
            },
            getDescription() {
                if (this.isActiveEvent) {
                    return this.activeEvent.header && this.activeEvent.header.title.value !== undefined ? this.activeEvent.header.title.value : null;
                } else {
                    return this.getUserBase ? this.getUserBase.name : null;
                }
            },
            getTitle() {
                let title = '';
                if (this.isActiveEvent) {
                    if (this.activeEvent.id === undefined) {
                        title += 'Situace nové události';
                    } else {
                        title += 'Situace události';
                    }
                } else {
                    title += 'Operační situace';
                }
                if (this.getUserBase)
                  title += ' / směna ' + this.shift
                
                return title
            },
        },
    }
</script>
