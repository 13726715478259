<template>
    <v-card flat style="max-height: 60vh; overflow: auto">
      <v-row class="mx-2">
        <v-col cols="12">
          <v-btn @click="showAlert" small color="primary">Show Alert</v-btn>
          <v-btn @click="setActiveEvent(false)" small color="primary">Unselect Event</v-btn>
        </v-col>
        <v-col cols="2">
          <v-text-field dense label="Číslo vlaku" outlined type="number" v-model="trainNumber"></v-text-field>
        </v-col>

      </v-row>
      <hr />
        <v-row no-gutters class="text-caption mt-2">
            <v-col cols="4">
                Local:<br/>
                <pre style="white-space: pre-wrap">{{ event }}</pre>
            </v-col>
            <v-col cols="4">
                remote:<br/>
                <pre style="white-space: pre-wrap">{{ eventRemove }}</pre>
            </v-col>
            <v-col cols="4">
                Jobs
                <div v-for="job in eventJobs(event.uuid)" :key="job.id">
                    <pre style="white-space: pre-wrap">{{ job }}</pre>
                  <div>
                    Job resource
                    <pre style="white-space: pre-wrap">{{ resourceById(job.vehicle) }}</pre>
                  </div>
                    Job devices
                    <div v-for="device in mapJobDevices(job.id)" :key="device.id">
                        <pre style="white-space: pre-wrap">{{ device }}</pre>
                    </div>
                </div>
              Geo
              {{ geoByEventUuid(event.uuid) }}
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import {appBus} from "@/main";
    import {ON_EVENT_INSERT} from "@/events";

    export default {
        name: 'dev-event',
        created() {
        },
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters('events', {
                event: 'getActiveEvent',
            }),
            ...mapGetters('eventJobs', {
                eventJobs: 'jobs',
            }),
            ...mapGetters('resources', {
              resourceById: 'byId',
            }),
          ...mapGetters('geo', {
            geoByEventUuid: 'byEvent',
          }),
            ...mapGetters('devices', {
                mapJobDevices: 'byEventJobIdForMap',
            }),
          trainNumber: {
            get() {
              return this.event.data === null || this.event.data === undefined || this.event.data.trainNumber === undefined ? null  : this.event.data.trainNumber;
            },
            set(value) {
              let payload = {key: this.event.uuid, item: { data: {
                    trainNumber: parseInt(value)
                  }}};
              return this.$store.dispatch('events/updateLocalAttributes', payload)
            }
          },
            eventRemove() {
                return this.$store.state.events.remote[this.event.uuid];
            }
        },
        methods: {
            showAlert() {
                appBus.$emit(ON_EVENT_INSERT, this.event.uuid);
            },
            setTrainNumber() {

            },
            ...mapActions('events', {
                setActiveEvent: 'setActiveEvent',
            }),
        },
    }
</script>

<style lang="scss">
    .dev-panel {
        position: absolute;
        z-index: 100000;
        bottom: 0;

        .input-group {
            &.period {
                width: 50px;
            }

            &.hash {
                width: 100px;
            }
        }

        .tab-content {
            max-width: 600px;
            max-height: 80vh;
            overflow: auto;
        }
    }
</style>
