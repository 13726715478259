<template>
    <v-card flat class="text-caption" style="max-height: 60vh; overflow: auto">
        <pre style="white-space: pre-wrap">{{ devices }}</pre>
    </v-card>
</template>

<script>

    export default {
        name: 'dev-events',
        computed: {
            devices() {
                return this.$store.state.devices.items;
            }
        },
    }
</script>
