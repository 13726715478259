<template>
    <div class="map-cards-wrapper" id="cards">
        <event-pois></event-pois>
        <cadastre></cadastre>
        <rail-images></rail-images>
        <sap-card></sap-card>
        <explore-card></explore-card>
        <event-destination-card></event-destination-card>
        <hunting-regions></hunting-regions>
        <train-location></train-location>
    </div>
</template>

<script>

    import Cadastre from "./Cadastre";
    import RailImages from "./RailImages";
    import EventPois from "./EventPois";
    import HuntingRegions from "../huntingRegions/Card";
    import TrainLocation from "../trainLocation/Card";

    export default {
        name: 'map-cards',
        components: {
            Cadastre,
            EventPois,
            RailImages,
            HuntingRegions,
            TrainLocation,
        },
    }
</script>
