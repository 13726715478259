<template>
    <v-row no-gutters class="panel-header grey white--text darken-1 elevation-2">
        <v-col class="panel-header-main flex-grow-1 flex-shrink-0">
            <v-row no-gutters align="center" justify="space-between" class="fill-height">
                <header-alert v-if="syncAlert" :updatedAt="updatedAt"/>
                <header-main v-if="!isMobile" class="pl-2"/>
                <header-date-time v-if="!isMobile"/>
                <sidebar-nav v-if="isMobile"></sidebar-nav>
                <header-menu/>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
    import HeaderAlert from './Alert'
    import HeaderMain from './Main'
    import HeaderMenu from './Menu'
    import HeaderDateTime from './DateTime'
    import {mapState} from "vuex";
    import SidebarNav from "@/components/panel/header/Nav";

    export default {
        name: 'panel-header',
        components: {
            SidebarNav,
            HeaderAlert,
            HeaderMenu,
            HeaderMain,
            HeaderDateTime,
        },
        computed: {
            ...mapState({
                isDarkMode: state => state.setup.darkMode,
                updatedAt: store => store.app.updatedAt,
                secondDate: store => store.app.secondDate,
                autoRefreshPeriod: store => store.setup.autoRefreshPeriod,
                isMobile: state => state.isMobile,
            }),
            syncAlert() {
                return this.secondDate > this.updatedAt + (this.autoRefreshPeriod * 5);
            },
            // classObject() {
            //     return this.isDarkMode ? 'darken-4' : 'darken-2';
            // }
        },
    }
</script>
