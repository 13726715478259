<template>
    <v-dialog v-model="dialog" transition="slide-x-transition" max-width="400">
        <v-card :loading="sending" :disabled="sending">
            <v-toolbar flat dense dark>
                <div>Odeslání aplikačního logu</div>
                <v-spacer></v-spacer>
                <v-toolbar-items class="pl-3">
                    <v-btn fab x-small color="red" class="elevation-0">
                        <v-icon @click="dialog=false">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="mt-3">
                <v-alert color="success" v-show="result">{{ result }}</v-alert>
                <v-btn block color="error" v-show="result === ''" @click="send">Odeslat log</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>

import {mapGetters, mapState} from "vuex";
import {appBus} from "@/main";
import {ON_APP_LOG, ON_APP_LOG_SEND} from "@/events";
import {stateMixin} from "@/mixins/stateMixin";
import app from "@/api/app";
import moment from "moment";

export default {
    mixins: [stateMixin],
    created() {
        appBus.$on(ON_APP_LOG, () => {
            this.result  = '';
            this.dialog = true;
            this.sending = false;
        });
        appBus.$on(ON_APP_LOG_SEND, () => {
           this.send();
        });
    },
    data() {
        return {
          dialog: false,
          sending: false,
          result: '',
        }
    },
    computed: {
        ...mapState({
            currentVersionHash: state => state.app.currentVersionHash,
            errors: state => state.errors,
            newVersionHash: state => state.app.newVersionHash,
        }),
        ...mapGetters('app', {
            hasVersionChanged: 'hasVersionChanged',
        }),
        errorCount() {
            return this.errors.length;
        }
    },
    methods: {
        send() {
          this.sending = true;
          const id = this.$store.state.auth.device.id;
          const dir = moment().format('YYYYMMDDHHmmss');
          app.upload('/v2/logs/log/' + id + '/'+dir+'/state.json', this.getStateBlob()).then(() => {
            this.$store.commit('clearErrors');
            this.result = "Log byl odeslán";
          }).finally(() => {
            this.sending = false;
          });
        }
    },
    watch: {
      errorCount(num) {
        if (num > 100)
          this.send();
      },
    },
    beforeDestroy() {
        appBus.$off(ON_APP_LOG);
        appBus.$off(ON_APP_LOG_SEND);
    }
}
</script>