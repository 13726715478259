var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"d-flex map-icons ma-0"},[_c('v-btn-toggle',{staticClass:"mr-1",attrs:{"background-color":"grey darken-2","dense":"","dark":"","rounded":"","multiple":""},model:{value:(_vm.buttons_state),callback:function ($$v) {_vm.buttons_state=$$v},expression:"buttons_state"}},[_c('v-btn',{attrs:{"color":"grey darken-2"},on:{"click":_vm.toggleMapSearch}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20"}},on),[_vm._v("$vuetify.icons.searchLocation")])]}}])},[_vm._v(" Vyhledávání místa ")])],1),_c('v-btn',{attrs:{"color":"grey darken-2"},on:{"click":_vm.toggleAutoFocus}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20"}},on),[_vm._v(_vm._s(_vm.autoFocusIcon))])]}}])},[_vm._v(" Vystřeďovat ")])],1),_c('v-btn',{attrs:{"fab":"","small":"","dark":"","color":_vm.muvLayer ? 'blue darken-2' : 'grey darken-2'},on:{"click":_vm.toggleMuvLayer}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("ter-muv")])]}}])},[_vm._v(" Speciální drážní vozidla ")])],1),_c('v-btn',{attrs:{"fab":"","small":"","dark":"","color":_vm.jpoAreasLayer ? 'blue darken-2' : 'grey darken-2'},on:{"click":_vm.toggleJpoAreas}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("ter-regions")])]}}])},[_vm._v(" Zásahové obvody ")])],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","max-height":"80vh"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"rounded":"","color":"grey darken-2","dark":"","depressed":""}},on),[_vm._v(" "+_vm._s(_vm.activeBaseLayerMenuItemName)+" ")])]}}]),model:{value:(_vm.baseLayerMenu),callback:function ($$v) {_vm.baseLayerMenu=$$v},expression:"baseLayerMenu"}},[_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',[_vm._v("Volba podkladove mapy")]),_c('v-list-item-group',_vm._l((_vm.baseLayerMenuItems),function(menuItem){return _c('v-list-item',{key:menuItem.layerId,attrs:{"disabled":menuItem.disabled},on:{"click":function($event){return _vm.setBaseLayer(menuItem.layerId)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(menuItem.title)+" ")])],1)}),1)],1),_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',[_vm._v("Volba vrstvy")]),_c('v-list-item-group',{attrs:{"multiple":""}},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleDroneLayer.apply(null, arguments)}}},[_c('v-list-item-action',{staticClass:"ml-0 mr-2"},[_c('v-checkbox',{on:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.droneLayer),callback:function ($$v) {_vm.droneLayer=$$v},expression:"droneLayer"}})],1),_c('v-list-item-title',[_vm._v("Letecká mapa (DRON)")])],1)],1),(_vm.droneLayer)?_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.droneLayerActiveLayer),callback:function ($$v) {_vm.droneLayerActiveLayer=$$v},expression:"droneLayerActiveLayer"}},_vm._l((_vm.droneLayerLayers),function(layer){return _c('v-list-item',{key:layer,attrs:{"value":layer}},[_c('v-list-item-action',{staticClass:"pl-0 ml-0 mr-2"},[_c('v-checkbox',{attrs:{"on-icon":"radio_button_checked","off-icon":"radio_button_unchecked","value":layer},on:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.droneLayerActiveLayer),callback:function ($$v) {_vm.droneLayerActiveLayer=$$v},expression:"droneLayerActiveLayer"}})],1),_c('v-list-item-title',[_vm._v(_vm._s(layer))])],1)}),1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }