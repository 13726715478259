<template>
    <v-btn
            v-bind="$attrs"
            v-on="$listeners"
            @mousedown="startHold"
            @mouseup="endHold"
            @mouseleave="endHold"
            @touchstart="startHold"
            @touchend="endHold"
            @touchcancel="endHold"
            @click="onClick"
    >
        <slot></slot>
        <v-overlay :value="showOverlay">
            <v-progress-circular
                    class="hold-button"
                    :rotate="-90"
                    :size="100"
                    :width="15"
                    :value="progress"
                    color="white"
            >
                <v-icon x-large v-if="progressIcon">{{ progressIcon }}</v-icon>
            </v-progress-circular>
            <div class="mt-3 text-uppercase">{{ overlayTitle }}</div>
        </v-overlay>
    </v-btn>
</template>

<script>
export default {
    name: 'VBtnHold',
    inheritAttrs: false,
    props: {
        progressIcon: {
            type: String,
            default: null,
        },
        holdTime: {
            type: Number,
            default: 1
        },
        overlayTitle: {
            type: String,
            default: 'Holding...'
        }
    },
    data() {
        return {
            showOverlay: false,
            holdTimer: null,
            progress: 0,
            overlayTimer: null,
            holdStarted: false,
            showOverlayDelay: 250,
        };
    },
    methods: {
        onClick() {
            console.log('click');
            if (!this.holdStarted) {
                this.$emit('onClick');
            }
        },
        startHold() {
            this.progress = 0;
            this.overlayTimer = setTimeout(() => {
                this.showOverlay = true;
                this.holdStarted = true;
                const startProgress = (100 / (this.holdTime * 1000)) * this.showOverlayDelay;
                this.$nextTick(() => {
                    this.startProgress(startProgress);
                });
            }, this.showOverlayDelay);
        },
        startProgress(startProgress) {
            this.progress = startProgress;
            const interval = 10;
            const increment = (100 / (this.holdTime * 1000)) * interval;
            this.holdTimer = setInterval(() => {
                if (this.progress < 100) {
                    this.progress += increment;
                } else {
                    this.holdFinished();
                }
            }, interval);
        },
        holdFinished() {
            this.clear();
            this.$emit('onHold');
        },
        endHold(triggerHoldAction = false) {
            this.$emit('onRelease');
            this.clear();
            if (triggerHoldAction && this.progress >= 100) {
                this.$emit('onHold');
            }
        },
        clear() {
            clearTimeout(this.overlayTimer);
            clearInterval(this.holdTimer);
            this.showOverlay = false;
            this.holdStarted = false;
            this.progress = 0;
        }
    },
};
</script>
<style>
.hold-button .v-progress-circular__overlay {
    transition: none !important;
}
</style>