<template>
    <v-container class="primary" fluid fill-height>
        <v-layout flex align-center justify-center>
            <v-flex xs12 sm4 elevation-6>
                <v-card>
                    <v-card-text>
                        <v-layout column flex align-center justify-center>
                            <h3 class="pb-4">Načítám vstupní data</h3>
                            <pulse-loader color="#2196f3"></pulse-loader>
                            <v-btn class="mt-5" small @click="reset">Restart</v-btn>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import PulseLoader from 'vue-spinner/src/PacmanLoader.vue'
    import {mapActions} from "vuex";

    export default {
        name: 'panel-loader',
        components: {
            PulseLoader
        },
        data() {
            return {
            }
        },
        methods: {
            ...mapActions('auth', {
                reset: 'logout',
            }),
        }
    }
</script>
