<template>
    <div class="dev-panel bg-dark" v-if="visible">
        <v-card>
            <v-tabs show-arrows v-model="activeTab" style="max-width: calc(100vw - 480px);">

                <v-tab key="app" ripple>App</v-tab>
                <v-tab key="events" ripple>Events</v-tab>
                <v-tab key="event" ripple>Event</v-tab>
                <v-tab key="messages" ripple>Msgs</v-tab>
                <v-tab key="devices" ripple>Dvcs</v-tab>
                <v-tab key="map" ripple>Map</v-tab>
                <v-tab key="sap" ripple>SapP</v-tab>
                <v-tab key="notifications" ripple>Notifications</v-tab>
                <v-tab key="errors" ripple>Errors</v-tab>

                <v-tab-item key="app">
                    <app-tab></app-tab>
                </v-tab-item>
                <v-tab-item key="events">
                    <events-tab></events-tab>
                </v-tab-item>
                <v-tab-item key="event">
                    <event-tab flat v-if="isActiveEvent"></event-tab>
                </v-tab-item>
                <v-tab-item key="messages">
                  <messages-tab></messages-tab>
                </v-tab-item>
                <v-tab-item key="devices">
                    <devices-tab></devices-tab>
                </v-tab-item>
                <v-tab-item key="map">
                    <v-btn @click="hideBaseLayers">Hide base layer</v-btn>
                    <v-card flat>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-title>Base Map:</v-list-item-title>
                                <v-list-item-title>{{ activeBaseLayer }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>Opacity:</v-list-item-title>
                                <v-list-item-title>{{ mapOpacity }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>Zoom:</v-list-item-title>
                                <v-list-item-title>{{ mapZoom }}</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-title>Center:</v-list-item-title>
                                <v-list-item-title>lon: {{ mapCenter[0] }}</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-title></v-list-item-title>
                                <v-list-item-title>lat: {{ mapCenter[1] }}</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                        </v-list>
                    </v-card>
                    <pre>{{ points }}</pre>
                </v-tab-item>
                <v-tab-item key="sap">
                    <sap-dev-tab></sap-dev-tab>
                </v-tab-item>
                <v-tab-item key="notifications">
                    <notifications-tab flat></notifications-tab>
                </v-tab-item>
              <v-tab-item key="errors">
                <errors-tab flat></errors-tab>
              </v-tab-item>
            </v-tabs>
        </v-card>
        <v-card dark>
            <v-btn icon small @click="toggleDarkMode" title="Theme mode" class="ma-1">
                <v-icon>invert_colors</v-icon>
            </v-btn>
            <v-btn icon small @click="toggleAutoRefresh" :color="autoRefreshVariant" title="Toggle auto refresh"
                   class="ma-1">
                <v-icon small>$vuetify.icons.refresh</v-icon>
            </v-btn>
            <v-btn icon small @click="load" title="Reload data" class="ma-1">
                <v-icon small>$vuetify.icons.reload</v-icon>
            </v-btn>
            <!--<v-btn icon small @click="resetData" title="Reset data" class="ma-1">-->
                <!--<v-icon small>$vuetify.icons.clear</v-icon>-->
            <!--</v-btn>-->
            <v-btn icon small @click="toggleDev" title="Close this panel" class="ma-1">
                <v-icon small>$vuetify.icons.close</v-icon>
            </v-btn>
        </v-card>
    </div>
</template>

<script>

    import {mapActions, mapGetters, mapState} from 'vuex'
    import {
        faSyncAlt as refresh,
        faRedoAlt as reload,
        faTools as dev,
        faEraser as clear,
        faTimes as close,
    } from '@fortawesome/free-solid-svg-icons'

    import appTab from "./tabs/App";
    import eventTab from "./tabs/Event";
    import eventsTab from "./tabs/Events";
    import messagesTab from "./tabs/Messages";
    import DevicesTab from "./tabs/Devices";
    import notificationsTab from "./tabs/Notifications";
    import errorsTab from "./tabs/Errors";

    export default {
        name: 'dev',
        components: {
            appTab,
            eventTab,
            eventsTab,
            messagesTab,
            DevicesTab,
            notificationsTab,
            errorsTab,
        },
        data() {
            return {
                suggestions: [],
                visibleTabs: true,
                icons: {
                    refresh,
                    reload,
                    dev,
                    clear,
                    close,
                },
            }
        },
        computed: {
            ...mapGetters('events', {
                isActiveEvent: 'isActiveEvent',
                event: 'getActiveEvent',
            }),
            autoRefreshVariant() {
                return this.autoRefresh ? 'success' : ''
            },
            hash: {
                get() {
                    return this.$store.state.app.hash;
                },
                set(value) {
                    this.$store.commit('app/setHash', value)
                }
            },
            activeTab: {
                get() {
                    return this.$store.state.setup.devPanel.activeTab;
                },
                set(value) {
                    this.$store.commit('setup/setDevPanelActiveTab', value)
                }
            },
            mode: {
                get() {
                    return this.$store.state.setup.darkMode;
                },
                set(value) {
                    this.$store.commit('setup/setDarkMode', value);
                }
            },
            autoRefreshPeriod: {
                get() {
                    return this.$store.state.setup.autoRefreshPeriod;
                },
                set(value) {
                    if (value >= 500) {
                        this.$store.commit('setup/setAutoRefreshPeriod', value)
                    }
                }
            },
            ...mapState({
                visible: state => state.setup.devPanel.visible,
                mapZoom: state => state.map.zoom,
                mapCenter: state => state.map.center,
                mapOpacity: state => state.map.opacity,
                setup: state => state.setup.setup,
                points: state => state.map.points,
                autoRefresh: state => state.setup.autoRefresh,
            }),
            ...mapGetters('map', {
                activeBaseLayer: 'activeBaseLayer',
            }),
        },
        methods: {
            load() {
                this.$store.dispatch('app/getData');
            },
            hideBaseLayers() {
                if (this.activeBaseLayer)
                    this.$store.commit("map/hideBaseLayers", this.activeBaseLayer.layers);
            },
            ...mapActions('app', {
                'resetData': 'resetState'
            }),
            ...mapActions('setup', {
                'toggleAutoRefresh': 'toggleAutoRefresh',
                'toggleDev': 'toggleDevPanel',
                'toggleDarkMode': 'toggleDarkMode',
            }),
        },
    }
</script>

<style lang="scss">
    .dev-panel {
        position: absolute;
        z-index: 2;
        bottom: 0;

        .input-group {
            &.period {
                width: 50px;
            }

            &.hash {
                width: 100px;
            }
        }

        .tab-content {
            max-width: 600px;
            max-height: 80vh;
            overflow: auto;
        }
    }
</style>
