<template>
    <div v-if="showPanoramaObject && points !== undefined && points.length > 0">
        <vl-layer-vector :zIndex="105">
            <vl-source-vector :features="points" ident="panoramaPoint">
                <vl-style-func :function="styleFunc()"></vl-style-func>
            </vl-source-vector>
        </vl-layer-vector>
        <vl-interaction-translate
                source="panoramaPoint"
                @translatestart="beginTranslate"
                @translateend="endTranslate"
        >
        </vl-interaction-translate>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import Icon from 'ol/style/Icon'
    import Style from 'ol/style/Style'

    export default {
        name: 'layer-panorama',
        data() {
            return {
                offsetLat: 0,
                offsetLon: 0,
                angle: undefined,
            }
        },
        methods: {
            beginTranslate(event) {
                this.offsetLon = this.points[0].geometry.coordinates[0] - event.coordinate[0];
                this.offsetLat = this.points[0].geometry.coordinates[1] - event.coordinate[1];
            },
            endTranslate(event) {
                const coordinates = [event.coordinate[0] + this.offsetLon, event.coordinate[1] + this.offsetLat];
                this.$store.dispatch('panorama/setUserCoordinates', coordinates);
                this.$store.commit('panorama/setCoordinates', coordinates);
            },
        },
        computed: {
            ...mapState({
                panoramaObject: state => state.panorama,
                coordinates: state => state.panorama.coordinates,
                points(state) {
                  let features = [];
                  if (state.panorama.coordinates) {
                    features.push({
                      type: 'Feature',
                      geometry: {
                        type: 'Point',
                        coordinates: state.panorama.coordinates,
                      },
                      properties: {
                        type: 'panorama',
                      },
                    });
                  }
                  return features;
                },
            }),
            ...mapGetters('panorama', {
                showPanoramaObject: 'showPoint',
            }),
            styleFunc() {
                let angle = this.panoramaObject.angle;
                return () => {
                    return () => {
                        return [
                            new Style({
                                image: new Icon({
                                    src: this.panoramaObject.icon,
                                    crossOrigin: 'anonymous',
                                    anchorXUnits: 'fraction',
                                    anchorYUnits: 'fraction',
                                    anchor: [0.5, 0.9],
                                    rotation: (angle) * Math.PI / 180,
                                }),
                            })
                        ];
                    }
                }
            },
        },
    }
</script>
