<template>
    <div>
        <v-card v-if="showState">
            <v-autocomplete :items="stateOptions" v-model="stateFilter"></v-autocomplete>
            <div class="overflow-y-auto overflow-x-auto caption" style="max-height: 70vh">
                <pre>{{ filteredState }}</pre>
            </div>
        </v-card>
        <v-card flat>
            <v-card-text>
                <v-btn @click="importDialog = true" small color="error">Import State</v-btn>
                <v-btn @click="downloadState" small color="warning">Export State</v-btn>
                <v-btn @click="toggleState" small color="warning">Show/hide state</v-btn>
            </v-card-text>
        </v-card>
        <v-dialog v-model="importDialog" transition="slide-x-transition" persistent max-width="400">
            <v-card>
                <v-card-title class="text-h5">Import APP state</v-card-title>
                <v-card-text>
                    <v-alert color="warning">Warning</v-alert>
                    <ul>
                        <li>Lost all current data</li>
                        <li>Change current user</li>
                        <li>Disconnect from PubSub</li>
                        <li>Stop auto refresh from TereZa</li>
                        <li>Can explode</li>
                    </ul>
                    <v-file-input v-model="jsonFile" accept="application/json" show-size
                                  label="json state file"></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="importState">Import</v-btn>
                    <v-btn color="green darken-1" text @click="importDialog = false">Zrušit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {stateMixin} from "@/mixins/stateMixin";
import {includes, keys, pickBy} from "lodash";

export default {
    name: 'dev-app',
    mixins: [stateMixin],
    created() {
    },
    data() {
        return {
            importDialog: false,
            exportDialog: false,
            showState: false,
            jsonFile: null,
            stateFilter: null,
        }
    },
    computed: {
        state() {
            return localStorage.getItem('panel');
        },
        filteredState() {
            if (this.stateFilter === null) {
                return this.state;
            } else {
                return pickBy(this.state, (item, key) => {
                    return includes([this.stateFilter], key)
                })
            }
        },
        stateOptions() {
            return keys(this.$store.state);
        }
    },
    methods: {
        toggleState() {
            this.showState = !this.showState;
        }
    }
}
</script>

<style lang="scss">
.dev-panel {
    position: absolute;
    z-index: 100000;
    bottom: 0;

    .input-group {
        &.period {
            width: 50px;
        }

        &.hash {
            width: 100px;
        }
    }

    .tab-content {
        max-width: 600px;
        max-height: 80vh;
        overflow: auto;
    }
}
</style>
