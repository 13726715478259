<template>
    <vl-layer-vector :zIndex="160">
        <vl-source-vector>
            <vl-feature v-for="(device, id) in devices(job.id)" :key="id"
                        :properties="{type: 'device', id: device.id,  tooltip: tooltip(device) }">
                <vl-geom-point :coordinates="[device.locationLongitude, device.locationLatitude]"></vl-geom-point>
            </vl-feature>
            <vl-style-func :function="styleFunc()"/>
        </vl-source-vector>
    </vl-layer-vector>
</template>

<script>
    import Style from 'ol/style/Style'
    import Icon from 'ol/style/Icon'
    import {mapGetters} from "vuex";

    export default {
        props: {
            event: Object,
            job: Object,
        },
        computed: {
            ...mapGetters('devices', {
                devices: 'byEventJobIdForMap',
                tooltip: 'tooltip',
            }),
        },
        methods: {
            styleFunc() {
                return () => {
                    return [
                        new Style({
                            image: new Icon({
                                src: './assets/icons/map/device-person.svg',
                                crossOrigin: 'anonymous',
                                anchorXUnits: 'fraction',
                                anchorYUnits: 'fraction',
                                anchor: [0.5, 1],
                                scale: 0.3
                            }),
                        }),
                    ];
                }
            },
        },
    }
</script>
