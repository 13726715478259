import {forEach, includes} from "vuelayers/dist/utils";

const getDefaultState = () => {
    return {
        visible: false,
        filter: ['call'],
        config: {}
    }
};

const state = getDefaultState();

const getters = {
    isVisible: state => {
        return state.visible;
    },
    getFilteredCategories: (state, getters, rootState) => {
        let result = [];
        let categories = rootState.eventCategories.items;
        forEach(categories, function (category) {
            if (includes(state.filter, category.type))
                result.push(category.id);
        });
        return result;
    }
};

const actions = {
    setFilter: ({commit}, value) => {
        commit("SET_FILTER", value);
    },
};

const mutations = {
    SET_FILTER: (state, value) => {
        state.filter = value;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
