<template>
    <v-flex d-flex class="map-mouse-position" v-show="mouseCoordinate || clickCoordinate">
        <v-card flat>
            <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-icon @click="openPositionDialog" v-on="on" small class="mr-1">near_me</v-icon>
                    {{ mousePosition }}
                </template>
                <v-card>
                    <v-card-text>
                        <v-text-field
                                v-model="inputCoordinates"
                                label="Souřadnice"
                                placeholder="x, y"
                        ></v-text-field>
                    </v-card-text>
                </v-card>

            </v-menu>
        </v-card>
        <v-card flat v-show="clickCoordinate">
            <v-btn icon small
                   v-clipboard:copy="clickPosition"
                   v-clipboard:success="onCopy"
                   v-clipboard:error="onError">
                <v-icon small>mouse</v-icon>
            </v-btn>
            {{ clickPosition }}
        </v-card>

    </v-flex>
</template>
<script>

import {format} from 'ol/coordinate';

export default {
    name: 'map-position-component',
    props: {
        mouseCoordinate: [Array, Boolean],
        clickCoordinate: [Array, Boolean],
    },
    data() {
        return {
            positionMenu: false,
            inputCoordinates: null,
            template: '{y}, {x}'
        }
    },
    computed: {
        mousePosition() {
            return format(this.mouseCoordinate, this.template, 6);
        },
        clickPosition() {
            return format(this.clickCoordinate, this.template, 6);
        },
    },
    watch: {
        inputCoordinates(newCoordinates) {
            newCoordinates = newCoordinates.replace(/[^0-9.,]/g, '');
            if (newCoordinates !== this.inputCoordinates)
                this.inputCoordinates = newCoordinates;

            const coordinates = newCoordinates.split(',')
            const latitude = parseFloat(coordinates[0]);
            const longitude = parseFloat(coordinates[1]);
            if (this.checkCoordinates(longitude, latitude)) {
                this.$store.dispatch('map/extendTo', [longitude, latitude]);
            }
        }
    },
    methods: {
        onCopy() {
            this.$noty.success("Souřadnice zkopírovány do paměti");
        },
        onError() {
            this.$noty.warning("Chyba pri kopírování");
        },
        openPositionDialog() {
            this.positionDialog = true;
        },
        checkCoordinates(longitude, latitude) {
            if (!longitude || !latitude) {
                return false;
            }
            if (latitude < 48.5518083 || latitude > 52.90609 ||
                longitude < 12.090590 || longitude > 22.558014) {
                return false;
            }
            return true;
        },
    },

}
</script>
