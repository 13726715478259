<template>
  <div class="calendar fill-sidebar-height">
    <!--Calendar-->
    <div class="d-flex flex-column fill-sidebar">
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="2">
            <v-btn x-large block dark color="grey darken-3" tile depressed @click="prev">
              <v-icon>fa-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" x-large block dark color="grey darken-3" tile depressed @click="menu = true">
                  <span>{{ formatDate }}</span>
                </v-btn>
              </template>
              <v-date-picker v-model="date" no-title scrollable>
                <v-btn @click="today">Dnes</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-btn x-large block dark color="grey darken-3" tile depressed @click="next">
              <v-icon>fa-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- Filter -->
      <div class="grey darken-3 d-flex justify-center">
        <v-chip-group
            v-model="filter"
            column
            color="white"
            multiple
            class="px-2 grey darken-3"
        >
          <v-chip filter dark outlined v-for="(item, key) in filterItems" :value="key" :key="key">{{ item.name }}
          </v-chip>
        </v-chip-group>
      </div>
      <!--Events -->
      <v-card tile class="event-list white elevation-0 tab-overflow" :loading="loading">
        <v-list>
          <template v-for="(event) in events">
            <event-head :key="event.uuid" @onSelect="showDetail(event)" v-bind:event="event"></event-head>
          </template>
        </v-list>
        <v-skeleton-loader v-show="loading" class="ma-3"
                           type="list-item-avatar-three-line, divider, list-item-avatar-three-line"></v-skeleton-loader>
      </v-card>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import {mapActions, mapGetters, mapState} from "vuex";
import eventHead from "./event/HeadInactive";
import {SHOW_EVENT_VIEW} from "@/events";
import {appBus} from "@/main";
import eventsApi from "@/api/events";
import {includes, pickBy, orderBy} from "lodash";

export default {
  name: 'calendar',
  components: {
    eventHead,
  },
  data() {
    return {
      items: {},
      loading: false,
      menu: false,
      dialog: false,
      eventDialogKey: null,
    }
  },
  created() {
    this.getItems();
  },
  computed: {
    ...mapState({
      date: state => state.panel.date,
    }),
    events() {
      return orderBy(pickBy(this.items, (item) => {
        return includes(this.filterIds, item.eventCategoryId)
      }), ['start', 'id'], ['desc', 'desc']);
    },
    formatDate() {
      return moment(this.date).format('dddd D.M.YYYY');
    },
    ...mapGetters('calendar', {
      filterIds: 'getFilteredCategories',
    }),
    filter: {
      get() {
        return this.$store.state.calendar.filter;
      },
      set(value) {
        this.$store.dispatch('calendar/setFilter', value)
      }
    },
    date: {
      get() {
        return this.$store.state.panel.date;
      },
      set(value) {
        this.$store.dispatch('panel/setDate', value)
      }
    },
    filterItems() {
      return {
        'call': {
          name: 'Volání'
        },
        'event': {
          name: 'Události'
        },
        'data': {
          name: 'D. věty'
        },
        'support': {
          name: 'Podpora'
        },
        'chat': {
          name: 'Chat'
        }
      };
    }
  },
  methods: {
    ...mapActions('events', [
      'setActiveEvent',
    ]),
    getItems() {
      this.loading = true;
      this.items = {};
      eventsApi.getEventList({date: this.date}).then((response) => {
        this.loading = false;
        this.items = response;
      });
    },
    showDetail(event) {
      appBus.$emit(SHOW_EVENT_VIEW, event);
    },
    today() {
      this.date = moment().format(moment.HTML5_FMT.DATE);
    },
    next() {
      this.date = moment(this.date).add(1, 'd').format(moment.HTML5_FMT.DATE);
    },
    prev() {
      this.date = moment(this.date).subtract(1, 'd').format(moment.HTML5_FMT.DATE);
    },
  },
  watch: {
    date() {
      this.getItems();
    },
  },
}
</script>
