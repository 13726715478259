import {mapActions} from "vuex";

export default {
    install(Vue) {
        Vue.mixin({
            methods: {
                ...mapActions('notifications', {
                    'addNotification':'add',
                }),
                addSuccessNotification(message) {
                    this.addNotification({
                        type: 'success',
                        message: message,
                    });
                },
                addErrorNotification(message) {
                    this.addNotification({
                        type: 'error',
                        message: message,
                    });
                }
            }
        });
    }
}
