<template>
  <v-dialog transition="slide-x-transition" fullscreen dark hide-overlay v-model="dialog" style='z-index:40001;'>
    <v-card color="rgb(0, 0, 0, 0.7)" loading="capturing">
      <v-container grid-list-md text-center>
        <span v-show="capturing">Načítám...</span>
        <v-layout row wrap v-show="!capturing">
          <v-flex xs12>
            <v-img max-height="85vh" contain v-if="clientImageDataUrl" :src="clientImageDataUrl"></v-img>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions class="justify-center">
        <v-btn large text :disabled="!canCancel" @click="close">
          <v-icon left>$vuetify.icons.cancel</v-icon>
          Zrušit
        </v-btn>
        <v-btn large color="success" :disabled="!canSend" @click="send">
          Odeslat
          <v-icon right>$vuetify.icons.sendMessage</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <file-upload v-show="0"
                 class=""
                 :post-action="upload.postAction"
                 :extensions="upload.extensions"
                 :accept="upload.accept"
                 :multiple="upload.multiple"
                 :headers="upload.headers"
                 :size="upload.size"
                 :drop="upload.drop"
                 v-model="files"
                 @input-filter="inputFilter"
                 @input-file="inputFile"
                 ref="upload">
    </file-upload>
  </v-dialog>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import FileUpload from 'vue-upload-component'
import {isEmpty, forEach} from "lodash";
import moment from "moment/moment";

export default {
  name: 'map-screenshot-dialog',
  components: {
    FileUpload,
  },
    props: {
        el: {},
        messageType: {
            type: String,
        },
    },
  data() {
    return {
      dialog: false,
      sending: false,
      capturing: true,
      clientImageDataUrl: null,
      files: [],
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      device: state => state.auth.device,
      apiUrl: state => state.tereza.apiUrl,
    }),
    ...mapGetters('events', {
      isActiveEvent: 'isActiveEvent',
      event: 'getActiveEvent',
    }),
    ...mapGetters('eventTypes', {
      getEventType: 'get',
    }),
    canCancel() {
      return this.capturing === false && this.sending === false;
    },
    canSend() {
      return this.capturing === false && this.sending === false;
    },
    upload() {
      return {
        accept: 'image/jpeg',
        extensions: 'jpg',
        headers: {
          'Authorization': this.$store.getters['auth/token'],
        },
      }
    },
  },
  methods: {
    inputFilter(newFile, oldFile) {
      if (newFile && !oldFile) {
        let extension = newFile.name.split('.').pop();
        newFile.filename = this.event.id + '-' + moment().format('YYYYMMDD_HHmmss') + '-' + this.device.code + '.' + extension;
        newFile.headers['X-File-Size-Total'] = newFile.size;
        newFile.postAction = this.apiUrl + '/v2/media/event/' + this.event.id + '/' + newFile.filename;
      }
    },
    inputFile() {
      if (!this.$refs.upload.active) {
        this.$refs.upload.active = true
      }
    },
      start() {
          this.clientImageDataUrl = null;
            this.dialog = true;
            this.capturing = true;
            this.sending = false;
            this.inputTimeout = setTimeout(() => {
                  this.capture(this.el)
                },
                200
            )
            this.files = [];
      },
    async capture(el) {

      this.capturing = true;
      const options = {
        type: 'dataURL'
      }
      this.clientImageDataUrl = await this.$html2canvas(el, options);
      this.capturing = false;
    },
    send() {

      this.sending = true;

      this.$watch(
          () => {
            return this.$refs.upload.uploaded
          },
          (val) => {
            if (val === true)
              this.sendMessage();
          }
      )

      this.uploadImage();
    },
    isFiles() {
      return !isEmpty(this.files);
    },
    getFiles() {
      if (this.isFiles()) {
        let files = [];
        forEach(this.files, function (file) {
          files.push(file.filename);
        });
        return files;
      } else {
        return null
      }
    },
    uploadImage() {
      this.sending = true;

      fetch(this.clientImageDataUrl)
          .then(res => res.blob())
          .then(blob => {
            let file = new window.File([blob], 'screenshot.jpg', {
              type: blob.type,
              headers: {
                'X-File-Size-Total': blob.size
              }
            });
            this.$refs.upload.add(file);
          })
    },
    sendMessage() {
      this.sending = true;
      let message = {
        content: '',
        type: this.messageType,
        media: this.getFiles(),
      };
      this.$store.dispatch('messages/send', message).then(() => {
        this.sending = false;
        this.$emit('onSend', message);
        this.close();
      });
    },
    close() {
      this.dialog = false;
    },
  },
}

</script>