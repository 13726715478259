import axios from 'axios';
import store from '../store/index'

let apiUrl = process.env.VUE_APP_TEREZA_API_URL;

export default {
    getHeaders(timeout = null) {
        return {
            timeout: timeout,
            headers: {
                'Authorization': store.getters['auth/token'],
                'panel': true,
            }
        };
    },
    getInputData(payload) {
        let inputData = {
            uid: store.state.app.hash,
        };

        if (payload !== undefined && payload.reset === true)
            inputData.uid = null;

        return inputData;
    },
    getData(payload, timeout = null) {
        return axios.post(apiUrl + '/v2/app', this.getInputData(payload), this.getHeaders(timeout))
            .then(
                response => {
                    return response.data;
                }
            ).catch(function (error) {
                window.console.error('error', error);
                return Promise.reject(error);
            });
    },
    getMediaUrl(key, media, size = 'normal') {
        return apiUrl + '/v2/media/event/' + key + '/' + media + '?size=' + size;
    },
    getMedia(key, media, size = 'normal') {
        let config = this.getHeaders();
        config.responseType = 'blob';
        return axios.get(this.getMediaUrl(key, media, size), config)
            .then((response) => {
                    return response;
                }
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    create(endpoint, data) {
        return axios.post(apiUrl + endpoint, data, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    update(endpoint, data) {
        return axios.put(apiUrl + endpoint + data.id, data, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    upload(endpoint, blob) {
        let options = this.getHeaders();
        // options.headers['Access-Control-Allow-Origin'] = "tereza-panel";
        options.headers['content-type'] = blob.type;

        return axios.post(apiUrl + endpoint, blob, options)
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
}
