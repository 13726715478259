/* eslint-disable */
import axios from 'axios';
import store from '../store/index'
import {appBus} from "../main";

export default {
    getUrl() {
        return store.state.setup.endpoints.apiReverseGeoUrl;
    },
    async reverse(coordinates, event = null, updateEvent = false, point = null) {
        let data = {};

        if (event) {
            data.eventTerrainId = event.eventTerrainId;
            data.eventUuid = event.uuid;
            data.updateEvent = updateEvent;
            data.addressData = point;
        }

        return axios.post(this.getUrl() + '/' + coordinates[0] + '/' + coordinates[1], data, {
            responseType: 'json',
        }).then((response) => {
            appBus.$emit('onReverseResult', response.data);
            return response.data;
        });
    },
}
