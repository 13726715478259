import mapapi from "@/api/mapapi";

const getDefaultState = () => {
    return {
        status: false,
        coordinates: [],
        features: [],
        apiEndpoint: '/hunting-region',
    }
};

const state = getDefaultState();

const getters = {
    isEnabled: state => {
        return state.status;
    },
    getFeatures: state => {
        return state.features;
    },
};

const actions = {
    setCoordinates: ({state, commit}, coords) => {
        commit('setCoordinates', coords);
        if (state.status === false) {
            commit('setStatus', true);
        }
        mapapi.post(state.apiEndpoint, {lat: coords[1], lon: coords[0]})
            .then(res => {
                commit('setFeatures', res.features);
            }).catch(err => {
            window.console.log(err);
        });
    },
    update: ({state, commit}) => {
        commit('setFeatures', []);
        mapapi.post(state.apiEndpoint, {lat: state.coordinates[1], lon: state.coordinates[0]})
            .then(res => {
                commit('setFeatures', res.features);
            }).catch(err => {
            window.console.log(err);
        });
    },
    close: ({commit}) => {
        commit('setCoordinates', []);
        commit('setFeatures', []);
        commit('setStatus', false);
    },
    resetState({commit}) {
        commit('resetState');
    }
};

const mutations = {
    setCoordinates: (state, coordinates) => {
        state.coordinates = coordinates;
    },
    setFeatures: (state, features) => {
        state.features = features;
    },
    setStatus: (state, status) => {
        state.status = status;
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
