import Vue from 'vue';
import {uuid} from "vue-uuid";
import api from "../../../api/api";
import {filter, forEach} from "lodash";
import {stateMerge} from "../../object-merge";
import {isNumeric} from "vuelayers/dist/utils";

const getDefaultState = () => {
    return {
        items: {},
        active: null,
        status: 0,
    }
};
const state = getDefaultState();

const getters = {
    isActive: (state) => {
        return state.status > 0;
    },
    activePoi: (state) => {
        if (state.active)
            return state.items[state.active];
        return false;
    },
    eventPoises: state => (key) => {
        return filter(state.items, (o) => {
            return o && o.eventUuid === key;
        });
    },
    extent: (state, getters) => (key) => {
        let objects = getters.eventPoises(key);
        let extent = [];
        forEach(objects, function (object) {
            extent.push([object.longitude, object.latitude])
        });
        return extent;
    },
};

const actions = {
    set({commit}, items) {
        commit("MERGE_STATE", items)
    },
    create({commit, rootState}, payload) {

        let timestamp = ~~(Date.now() / 1000);
        let uuid5Name = rootState.auth.user.baseId.toString() + timestamp.toString();
        let key = uuid.v5(uuid5Name, rootState.setup.setup.uuid);

        commit('setStatus', 1);
        let item = {
            key: key,
            item: {
                id: null,
                uuid: key,
                eventId: payload.eventId,
                eventUuid: payload.eventUuid,
                sequence: null,
                longitude: payload.coordinates[0],
                latitude: payload.coordinates[1],
                content: '',
                edit: true,
                // createdAt: ,
            }
        };
        commit('add', item);
        commit('setActive', key);
    },
    save({state, commit, dispatch}, payload) {
        return new Promise((resolve) => {
            api.save('/v2/event-pois', payload).then((result) => {

                if (!isNumeric(state.active))
                    commit('remove', state.active);

                let data = {};
                data[result.id] = result;
                dispatch('set', data);
                dispatch('notifications/add', {type: 'success', message: 'Objekt byl přidán do mapy'}, {root: true});
                commit('setActive', null);
                commit('setStatus', 0);
                resolve();
            }).catch(function (error) {
                dispatch('notifications/add', {
                    type: 'error',
                    message: 'Chyba při odebrání objektu.' + error
                }, {root: true});
            });
        })
    },
    edit({commit}, key) {
        commit('setActive', key);
        commit('setStatus', 1);
    },
    remove({state, commit, dispatch}) {
        return new Promise((resolve) => {
            let item = state.items[state.active];
            if (item.id) {
                api.delete('/v2/event-pois/' + item.id).then(() => {
                    dispatch('notifications/add', {
                        type: 'success',
                        message: 'Objekt z mapy byl odebrán'
                    }, {root: true});
                    commit('remove', state.active);
                    commit('setActive', null);
                    commit('setStatus', 0);
                    resolve();
                }).catch(function (error) {
                    dispatch('notifications/add', {
                        type: 'error',
                        message: 'Chyba při odebrání objektu.' + error
                    }, {root: true});
                });
            } else {
                commit('remove', state.active);
                commit('setActive', null);
                commit('setStatus', 0);
                resolve();
            }
        })
    },
    close({state, commit, getters}) {
        if (getters.activePoi.id === null)
            commit('remove', state.active);

        commit('setActive', null);
        commit('setStatus', 0);
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    setStatus(state, status) {
        state.status = status;
    },
    setActive(state, key) {
        state.active = key;
    },
    updateItem(state, payload) {
        state.activeItem = payload;
    },
    add(state, payload) {
        Vue.set(state.items, payload.key, payload.item);
    },
    remove(state, key) {
        Vue.delete(state.items, key);
    },
    update(state, payload) {
        forEach(payload.item, (value, key) => {
            state.items[payload.key][key] = value
        });
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
