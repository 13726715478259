<template>
    <vue-context class="context-menu" ref="contextMenu" @close="onClose" @open="onOpen" id="context-menu">
        <ol v-if="opened && data" slot-scope="child">
            <div class="context-menu-title" v-html="title"></div>
            <div v-show="header" class="context-menu-info" v-html="header"></div>
            <li class="context-menu__event-context-group">
                <ol>
                    <event v-bind:data="child.data" v-if="$can('manage', 'Event')"></event>
                    <event-destination-context-menu-item v-bind:data="data"
                                                         v-if="$can('manage', 'Event')"></event-destination-context-menu-item>
                    <event-pois v-bind:data="child.data"></event-pois>
                    <event-geo v-bind:data="child.data"></event-geo>
                    <send-map-screen-shot v-bind:data="data"></send-map-screen-shot>
                </ol>
            </li>
            <li class="context-menu__map-context-group">
                <ol>
                    <explore-context-menu-item v-bind:data="data"></explore-context-menu-item>
                    <hunting-regions v-bind:data="data"></hunting-regions>
                </ol>
            </li>
            <li class="context-menu__map-context-group">
                <ol>
                    <sap-context-menu-item v-bind:data="data"></sap-context-menu-item>
                    <panorama v-bind:data="data"></panorama>
                    <rail-images v-bind:data="data"></rail-images>
                    <cadastre v-bind:data="data"></cadastre>
                    <ispd v-bind:data="data"></ispd>
                    <draw v-bind:data="data"></draw>
                </ol>
            </li>
            <li class="context-menu__points-context-group">
                <ol>
                    <point v-bind:data="data"></point>
                </ol>
            </li>
        </ol>
    </vue-context>
</template>

<script>

import VueContext from 'vue-context';
import Event from './items/Event'
import SendMapScreenShot from './items/SendMapScreenshot'
import Point from './items/Point'
import Draw from './items/Draw'
import EventPois from './items/EventPois'
import EventGeo from '../geo/ContextMenuItem'
import Panorama from './items/Panorama'
import Cadastre from './items/Cadastre'
import Ispd from './items/Ispd'
import RailImages from './items/RailImages'
import {mapGetters} from "vuex";
import HuntingRegions from "../map/huntingRegions/ContextMenuItem";

export default {
    name: 'map-context-menu',
    data() {
        return {
            data: null,
            opened: false,
            title: null,
            header: null,
            overview: null,
        }
    },
    components: {
        Event,
        SendMapScreenShot,
        Point,
        Draw,
        EventPois,
        EventGeo,
        Panorama,
        Cadastre,
        Ispd,
        RailImages,
        VueContext,
        HuntingRegions,
    },
    computed: {
        ...mapGetters('events', [
            'isActiveEvent',
            'targetTooltip',
            'getActiveEvent',
        ]),
    },
    methods: {
        setHeaderAndTitle() {
            this.title = null;
            this.header = null;
            this.overview = null;

            if (this.data.feature !== null) {
                const feature = this.data.feature;
                let tooltip = feature.getProperties().tooltip;
                if (tooltip !== undefined) {
                    this.title = tooltip.title;
                    this.header = tooltip.content;
                } else if (feature.getProperties().tooltipAction) {
                    const tooltipAction =  feature.get('tooltipAction');
                    if (tooltipAction) {
                        tooltip = this.$store.getters[tooltipAction](feature.get('id'));
                        this.title = tooltip.title;
                        this.header = tooltip.content;
                    }
                }

            } else if (this.isActiveEvent) {
                let tooltip = this.targetTooltip(this.getActiveEvent);
                this.title = tooltip.title;
                this.header = tooltip.content;
            }
        },
        onClose() {
            this.opened = false;
            this.$store.commit('panel/SET_CONTEXT_MENU', 0);
        },
        onOpen() { // event, data, top, left
            this.$store.commit('panel/SET_CONTEXT_MENU', 1);

            this.opened = true;
            this.setHeaderAndTitle();
        },
        open(event, data) {
            this.opened = false;
            this.data = false;
            if (data) {
                this.$nextTick(() => {
                    this.data = data;
                    this.opened = true;
                    this.$refs.contextMenu.open(event, data);
                });
            }
        }
    },
}

</script>
