import api from "@/api/api";
import {some, isEmpty} from "lodash";

const getDefaultState = () => {
    return {
        status: 0,
        items: [],
        coordinates: [],
    }
};

const state = getDefaultState();

const getters = {
    isEnabled: state => {
        return state.status > 0;
    },
    visible: state => {
        return state.status > 0;
    },
};

const actions = {
    start: ({commit, dispatch}, payload) => {
        commit('resetState');
        commit('setCoordinates', payload.coordinates);
        commit('setStatus', 1);
        dispatch('getInitialData');
    },
    end: ({commit}) => {
        commit('resetState');
        commit('setStatus', false);
    },
    getInitialData: ({state, commit}) => {
        api.get('/v2/explore/' + state.coordinates[0] + '/' + state.coordinates[1]).then((response) => {
            if (state.status > 0) {
                if (some(response, isEmpty)) {
                    commit("setStatus", 6);
                } else {
                    commit("setData", response);
                    commit("setStatus", 5);
                }
            }
            return response;
        }).catch(function () {
            
        })
    },
    resetState({commit}) {
        commit('resetState');
    },
};

const mutations = {
    setCoordinates: (state, coordinates) => {
        state.coordinates = coordinates;
    },
    setData: (state, payload) => {
        state.items = payload;
    },
    setStatus: (state, status) => {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
