import {findKey, forEach} from "lodash";
import {stateMerge} from "../object-merge";

const getDefaultState = () => {
    return {
        filters: {
            predefined: {},
        }
    }
};

const state = getDefaultState();

const getters = {
    getFilters: (state) => {
        return state.filters.predefined;
    },
    getDefaultFilter: (state, getters) => {
        return state.filters.predefined[getters.getDefaultFilterKey];
    },
    getFilterByKey: (state) => (key) => {
        return state.filters.predefined[key];
    },
    getDefaultFilterKey: (state) => {
        return findKey(state.filters.predefined, {default: true});
    }
};

const actions = {
    async set({commit}, settings) {
        commit("MERGE_STATE", settings)
    },
};

const mutations = {
    MERGE_STATE(state, settings) {
        forEach(settings, (data, key) => {
            stateMerge(state, data, key, false, true)
        });
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
