const getDefaultState = () => {
    return {
        status: false,
        coordinates: [],
        icon: "http://api.mapy.cz/img/api/marker/eye.png",
        anchor: [0.46, 0.62],
    }
};

const state = getDefaultState();

const getters = {
    isEnabled: state => {
        return state.status;
    },
    visible: state => {
        return state.status;
    },
};

const actions = {
    setCoordinates: ({state, commit}, coords) => {
        commit('setCoordinates', coords);
        if (!state.status)
            commit('setStatus', true);
    },
    close: ({commit}) => {
        commit('setCoordinates', []);
        commit('setStatus', false);
    },
    resetState({commit}) {
        commit('resetState');
    },
};

const mutations = {
    setCoordinates: (state, coordinates) => {
        state.coordinates = coordinates;
    },
    setStatus: (state, status) => {
        state.status = status;
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
