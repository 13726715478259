import axios from 'axios';
// import {appBus} from "../main";

let apiUrl = process.env.VUE_APP_TEREZA_API_URL;

export default {
    async getRouting(origin, destination, options) {

        let data = {
            origin: origin,
            destination: destination,
            options
        };

        return axios.get(apiUrl + '/v2/pnt/routing', {
            params: data,
            responseType: 'json',
        }).then((response) => {
            // appBus.$emit('onReverseResult', response.data);
            return response.data;
        });

    },
}
