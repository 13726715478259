<template>
    <section class="card" style="position: absolute; width: 300px">
        <div class="card-content">
            <div class="content">
                <div class="feature-form p-2">
                    <b-form @submit="onSubmit" @reset="onReset">
                        <b-form-group class="mb-0">
                            <b-form-textarea
                                    v-model="form.content"
                                    ref="content"
                                    class="mb-2"
                                    :rows="2"
                                    :max-rows="5"
                                    :disabled="submitting"
                                    placeholder="popis..."
                            ></b-form-textarea>
                        </b-form-group>
                        <b-button type="submit" variant="success" size="sm" :disabled="submitting">Uložit</b-button>
                        <b-button type="button" variant="default" size="sm" @click="onClose" :disabled="submitting" class="float-right">Zavřít</b-button>
                    </b-form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

    export default {
        name: 'layer-draw-form',
        props: {
            drawKey: String,
            title: String,
        },
        mounted: function () {
            this.form.content = this.title;
        },
        data() {
            return {
                submitting: false,
                draw: false,
                drawType: undefined,
                drawnFeatures: [],
                form: {
                    content: '',
                    media: {},
                    data: {},
                },
            }
        },
        methods: {
            onSubmit(evt) {
                evt.preventDefault();
                this.submitting = true;
                this.$store.dispatch('map/updateDrawnFeature', {
                    key: this.drawKey,
                    item: {
                        title: this.form.content
                    }
                });
                this.submitting = false;
            },
            onClose() {
                this.$store.dispatch('map/updateDrawnFeature', {
                    key: this.drawKey,
                    item: {}
                });
            },
            onReset(evt) {
                evt.preventDefault();
                this.reset();
            },
            resetForm() {
                this.form.content = '';
            },
        }
    }
</script>
