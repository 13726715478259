import axios from 'axios';
import store from '../store/index'

let apiUrl = process.env.VUE_APP_TEREZA_API_URL;
let cancelEventLisSource = null;

export default {
    getHeaders() {
        return {
            headers: {
                'Authorization': store.getters['auth/token'],
                'panel': true,
            }
        };
    },
    getEvents(cb) {
        setTimeout(this.loadEvents()
                .then(
                    data => {
                        cb(data);
                    }
                ).catch(function (error) {
                    throw new Error(error);
                })
            , 100);
    },
    getEventList(data) {

        if (cancelEventLisSource)
            cancelEventLisSource.cancel('Cancel previous request');

        cancelEventLisSource = axios.CancelToken.source();

        let options = this.getHeaders();
        options.params = data;
        options.cancelToken = cancelEventLisSource ? cancelEventLisSource.token : null;

        return axios.get(apiUrl + '/v2/events/list', options)
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    createEvent(data) {
        return axios.post(apiUrl + '/v2/events', data, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    updateEvent(id, data) {
        return axios.put(apiUrl + '/v2/events/' + id, data, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    createFrom(uuid, event, sendToQueue = false) {
        return axios.post(apiUrl + '/v2/events/create-from/' + uuid, {
            event: event,
            sendToQueue: sendToQueue
        }, this.getHeaders())
            .then(
                response => response.data
            ).catch((error) => {
                throw new Error(error.response.data.message);
            });
    },
    createEventJob(data) {
        return axios.post(apiUrl + '/v2/event-jobs', data, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    updateEventJob(id, data) {
        return axios.put(apiUrl + '/v2/event-jobs/' + id, data, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    endEventJob(id) {
        return axios.delete(apiUrl + '/v2/event-jobs/' + id, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    async getMessage(id) {
        return axios.get(apiUrl + '/v2/messages/' + id, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    sendMessage(data) {
        return axios.post(apiUrl + '/v2/messages', data, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    readMessage(id) {
        return axios.post(apiUrl + '/v2/messages/' + id + '/read', {}, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    readAllEventMessages(id, messageCategoryId = null) {
        return axios.post(apiUrl + '/v2/messages/event/' + id + '/read', { messageCategoryId: messageCategoryId }, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    readUser() {
        return axios.post(apiUrl + '/v2/messages/user/read', {}, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    loadEvents() {
        let data = {
            uid: '',
        };
        return axios.post(apiUrl + '/v2/app', data, this.getHeaders())
            .then(
                response => {
                    return response.data;
                }
            ).catch(function (error) {
                throw new Error(error);
            });
    },
}
