<template>
    <div>
        <vl-layer-vector v-if="isVisible" :z-index="zIndex">
            <vl-source-vector :features.sync="areaFeatures" :ident="layerIdentArea">
                <vl-style-func :function="areaStyleFunc()"/>
            </vl-source-vector>
        </vl-layer-vector>
        <vl-layer-vector v-if="isVisible" :z-index="zIndex+1">
            <vl-source-vector :features.sync="pointFeatures" :ident="layerIdentPoint">
                <vl-style-func :function="pointStyleFunc()"/>
            </vl-source-vector>
        </vl-layer-vector>
    </div>
</template>

<script>

import Vue from 'vue';
import mapapi from "@/api/mapapi";
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import {SelectInteraction} from 'vuelayers';
import {mapState} from "vuex";

Vue.use(SelectInteraction);

export default {
    name: "jpo-layer",
    data() {
        return {
            areaEndpoint: '/jpo-area',
            pointEndpoint: '/jpo-point',
            loaded: false,
            areaFeatures: [],
            pointFeatures: [],
            layerIdentArea: 'jpoAreaLayer',
            layerIdentPoint: 'jpoPointLayer',
            zIndex: 1,
            colorMap: {
                blue: '0,0,255',
                green: '0,255,0',
                grey: '128,128,128',
                red: '255,0,0',
                violet: '238,130,238',
                white: '255,255,255',
                yellow: '255,255,0',
            }
        }
    },
    computed: mapState({
        isVisible: state => state.map.jpoAreasLayer
    }),
    methods: {
        async loadFeatures() {
            if (this.loaded)
                return;

            if (this.areaFeatures.length === 0) {
                const areas = await mapapi.get(this.areaEndpoint);
                this.areaFeatures = areas.features;
            }
            if (this.pointFeatures.length === 0) {
                let points = await mapapi.get(this.pointEndpoint);
                this.pointFeatures = points.features;
            }
            this.loaded = true;
        },
        pointStyleFunc() {
            return () => {
                const marker = new Icon({
                    src: './assets/icons/map/border-icon.png',
                    scale: 1,
                    opacity: 1,
                });
                return new Style({
                    zIndex: 1,
                    image: marker,
                });
            }
        },
        areaStyleFunc() {
            return (feature) => {
                let color = this.colorMap[feature.get('color')];
                let fill = new Fill({
                    color: 'rgba(' + color + ',0.2)',
                });
                const stroke = new Stroke({
                    color: 'black',
                    width: 2,
                });

                return new Style({
                    zIndex: 1,
                    fill: fill,
                    stroke: stroke,
                });
            }
        }
    },
    mounted() {
        if (this.isVisible) {
            this.loadFeatures();
        }

    },
    watch: {
        isVisible(val) {
            if (val)
                this.loadFeatures();
        },
    },
}
</script>