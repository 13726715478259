<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card v-bind="$attrs">
    <v-card-text>
      <v-btn absolute fab small right @click="close" class="close">
        <v-icon v-html="closeIcon"></v-icon>
      </v-btn>

      <div class="p-2">
        <div class="text-center">
          <v-btn class="mx-1" v-for="group in groups"
                 :color="group.active ? 'success' : ''"
                 v-on:click.prevent.stop="toggleGroup(group.id)"
                 v-bind:key="group.id">
            {{ group.label }}
          </v-btn>
        </div>
        <v-text-field
            ref="input"
            @keyup.up.prevent.stop="onInputArrowUp"
            @keyup.down.prevent.stop="onInputArrowDown"
            @keyup.enter.prevent.stop="onInputEnter"
            :type="activeGroup.inputType"
            @click:clear="onInputClear"
            :clear-icon="clearIcon"
            clearable
            outline
            class="mx-6 mt-5 hide-spin-buttons"
            v-model="query"
        >
          <template v-slot:label>
            Hledání v <strong>{{ activeGroup.placeholder }}</strong> objektech...
          </template>
        </v-text-field>

        <div class="points" v-if="points.length > 0">
          <v-chip
              class="text-caption"
              close
              close-icon="close"
              @click="pointFocus(point)"
              @click:close="removePoint(point.uuid)"
              v-for="(point) in points" :key="point.uuid"
          >
            <v-avatar>
              <v-icon small>{{ getIcon(point.type_code) }}</v-icon>
            </v-avatar>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="name text-truncate">{{ point.title }}</span>
              </template>
              <span class="text-wrap-pre-line">{{ point.title }}</span>
            </v-tooltip>
            <span v-if="point.base_id" class="base">{{ point.base_title }}</span>
          </v-chip>
        </div>
        <div class="d-flex py-2">
          <v-btn v-if="origin" text rounded small @click="clearOriginObject">
            Odebrat vyhledávací pozici
            <v-icon small right color="error">close</v-icon>
          </v-btn>
          <v-btn v-if="points.length > 0" text rounded small @click="removeAllPoints">
            Odebrat body
            <v-icon small right color="error">close</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="v-suggestion-list">
        <div v-if="loading" class="loading d-flex justify-center align-center">
          <div>
            <pulse-loader color="#2196f3"></pulse-loader>
          </div>
        </div>
        <v-layout column v-if="suggestions.length > 0">
          <v-layout v-for="(suggestion, index) in suggestions" :class="{selected: isSelected(index)}"
                    :key="suggestion.id" class="v-suggestion-item"
                    @mouseover="suggestHover(suggestion)"
                    @click="suggestionSelect(suggestion)">
            <v-flex shrink class="icon">
              <v-icon small>{{ getIcon(suggestion.type_code) }}</v-icon>
            </v-flex>
            <v-flex>
              <v-layout column class="content">
                <div><span class="font-weight-bold">{{ suggestion.name }}</span> <span
                    class="pl-2 text-caption text-lowercase">({{ searchTypeName(suggestion.type_code) }})</span>
                </div>
                <v-flex class="description text-caption">
                  <v-layout justify-space-between>
                    <v-flex v-html="getDescription(suggestion)"></v-flex>
                    <v-flex grow class="text-right text-muted"
                            v-html="suggestion.jpo_name"></v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex shrink v-if="suggestion.distance"
                    class="distance">{{ formatDistance(suggestion.distance) }}
            </v-flex>
            <v-flex shrink class="actions pl-1">
              <v-btn icon small color="primary" @click.stop="suggestionFocus(suggestion)">
                <v-icon small>remove_red_eye</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-layout>
      </div>

      <v-layout justify-space-between class="pt-2">
        <v-btn v-show="page > 0" @click="onPrevPage">
          <v-icon>navigate_before</v-icon>
          Předchozí
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-show="suggestions.length >= limit" @click="onNextPage">
          Další
          <v-icon>navigate_next</v-icon>
        </v-btn>
      </v-layout>

      <div class="mt-4 text-muted">
        posledni dotaz: <strong>{{ currentSearch }}</strong>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import {join, find, forEach, remove, unionWith, isRegExp} from "lodash";
import PulseLoader from 'vue-spinner/src/GridLoader.vue'
import {mapActions} from "vuex";
import {formatDistance} from "@/helpers/format";

export default {
  components: {
    PulseLoader
  },
  mounted() {
    this.inputElement = this.$refs.input;
    window.addEventListener('keyup', this.eventHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.eventHandler);
  },
  watch: {
    enabled(val) {
      if (val)
        this.focusInput();
    },
    query() {
      clearTimeout(this.inputTimeout);
      this.inputTimeout = setTimeout(() => {
            this.search();
          },
          300
      )
      ;
    },
    suggestions() {
      this.$emit('onSearchPoints', this.suggestions);
    },
    origin() {
      if (this.enabled) {
        this.search();
      }
    },
  },
  props: {
    enabled: Boolean,
    groups: Array,
    types: Object,
    origin: {
      type: [Object, Boolean],
      default: null
    },
    urls: Object,
    points: Array,
    closeIcon: {
      type: String,
      default: 'cancel'
    },
    clearIcon: {
      type: String,
      default: 'cancel'
    }
  },
  data() {
    return {
      query: '',
      selected: '',
      limit: 10,
      page: 0,
      mode: 'input',
      loading: false,
      suggestions: [],
      selectedIndex: null,
      cancelSource: null,
      inputTimeout: null,
      currentSearch: '',
      inputElement: null,
      controls: {
        selectionUp: 38,
        selectionDown: 40,
        escape: 27,
        select: 13,
      }
    }
  },
  computed: {
    ...mapActions('panel', {
      toggleSidebar: 'toggleSidebar',
      toggleMapSearch: 'toggleMapSearch',
      showMapSearch: 'showMapSearch',
    }),
    suggestionCount() {
      return this.suggestions.length;
    },
    searchTypeName() {
      return code => {
        if (this.types[code] !== undefined)
          return this.types[code].title;
        return code;
      }
    },
    addressContent() {
      return this.event.address ? this.event.address : 'Vyhledat místo události'
    },
    activeGroup() {
      return find(this.groups, function (o) {
        return o.active === 1;
      });
    },
    url() {
      return this.urls[this.activeGroup.id];
    },
  },
  methods: {
      formatDistance(value) {
          return formatDistance(value);
      },
    clearOriginObject() {
      this.$emit('onClearOrigin');
    },
    removePoint(item) {
      this.$emit('onRemovePoint', item);
    },
    removeAllPoints() {
      this.$emit('onRemoveAllPoints');
    },
    start() {
      this.focusInput();
    },
    close() {
      this.$emit('onClose');
    },
    focusInput() {
      if (this.inputElement)
        this.inputElement.focus();
    },
    getDescription(suggestion) {
      let values;
      if (suggestion.source === 'isor') {
        values = [
          suggestion.begin ? 'Z: ' + suggestion.begin : null,
          suggestion.end ? 'Do: ' + suggestion.end : null,
          suggestion.last_seen ? '<br>Naposledy viděn: ' + suggestion.last_seen : null,
        ];
      } else {
        values = [
          suggestion.note,
          suggestion.borough,
          suggestion.town,
          suggestion.district ? 'okr.' + suggestion.district : null,
          suggestion.region,
        ];
      }
      return join(remove(values, function (o) {
        return o !== null;
      }), ', ');
    },
    getIcon(code) {
      if (code === 'custom')
        return 'help_outline';

      if (!this.types[code].icon)
        return 'fa-question';

      return this.types[code].icon;
    },
    toggleGroup(id) {
      let activeId = this.activeGroup.id;
      if (activeId !== id) {
        let newGroup = this.groups.find(o => o.id === id);
        if (newGroup.inputType === 'number') {
          if (this.query !== null) {
            let query = this.query.match(/\d+/);
            if (query) {
              this.query = query[0];
            }
          }
        }
        this.$nextTick(() => {
          this.$emit('onGroupChange', id);
          this.search();
        });
      }
      this.focusInput();
    },
    search() {
      this.page = 0;
      this.getList();
    },
    suggestHover(item) {
      this.$emit('onSuggestHover', item);
    },
    suggestionSelect(suggest) {
      if (suggest !== null) {
        this.$emit('onSuggestionSelect', suggest);
      }
    },
    suggestionFocus(item) {
      this.$emit('onSuggestionFocus', item);
    },
    pointFocus(item) {
      this.$emit('onPointFocus', item);
    },
    getOrigin() {
      if (this.origin)
        return this.origin.coordinates;
      return null;
    },
    clearAddressInput() {
      this.query = '';
      this.suggestions = [];
    },
    clearAddressSearch() {
      this.clearAddressInput();
      this.clearOriginObject();
      this.removeAllPoints();
    },
    getList() {
      if (this.query === undefined || this.query === null)
        return false;

      let inputValue = this.query;

      if (inputValue.length < 2)
        return false;

      if (!inputValue) {
        this.clearAddressInput();
        return false;
      }

      this.cancelSearch();
      this.cancelSource = axios.CancelToken.source();

      let url = this.url;
      let json = this.getPostData(inputValue);

      this.currentSearch = inputValue;
      this.loading = true;

      const accessControlHostname = (new URL(this.url)).hostname;
      return axios.post(url, json, {
        cancelToken: this.cancelSource.token,
        headers: {
          'Access-Control-Allow-Origin': accessControlHostname,
        },
        responseType: 'json',
      }).then((response) => {
        this.loading = false;
        this.selectedIndex = null;
        this.suggestions = response.data.result.data;
      }).catch((err) => {
        window.console.debug(err);
      })
    },
    /**
     * Get jsonrpc payload
     * @param inputValue
     * @returns {{jsonrpc: string, method: string, params: {latitude: null, longitude: null, searchTerm: *, typeCodes: Array}}}
     */
    getPostData(inputValue) {

      let origin = this.getOrigin();
      let typeCodes = [];

      this.groups.forEach((item) => {
        if (item.active === 1
        ) {
          if (item.typeRules) {
            forEach(item.typeRules, (rule) => {
              if (rule.regex === null
              ) {
                typeCodes = unionWith(typeCodes, rule.type);
                return false;
              } else if (isRegExp(new RegExp(rule.regex)) && new RegExp(rule.regex).exec(inputValue) !== null) {
                typeCodes = unionWith(typeCodes, rule.type);
                return false;
              }
            })
            ;
          } else if (item.type) {
            typeCodes = unionWith(typeCodes, item.type);
          }
        }
      })
      ;

      return {
        jsonrpc: "2.0",
        method: "fire.search",
        params: {
          latitude: origin ? origin[1] : null,
          longitude: origin ? origin[0] : null,
          searchTerm: inputValue,
          typeCodes: typeCodes,
          limit: this.limit,
          offset: this.page * this.limit,
        }
      };
    },
    cancelSearch() {
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, cancel previous request');
      }
    },
    isSelected(index) {
      return index === this.selectedIndex;
    },
    onInputArrowUp() {
      this.listMove(-1);
    },
    onInputArrowDown() {
      this.listMove(1);
    },
    onInputClear() {
      this.clearAddressInput();
    },
    onInputEnter() {
      if (this.selectedIndex !== null)
        this.suggestSelect(this.suggestions[this.selectedIndex])
    },
    onPrevPage() {
      this.page--;
      this.getList();
    },
    onNextPage() {
      this.page++;
      this.getList();
    },
    eventHandler(event) {
      if (this.enabled) {
        if (event.keyCode === this.controls.escape) {
          this.close();
        } else if (event.keyCode === this.controls.select) {
          this.onInputEnter();
        } else if (event.keyCode === this.controls.selectionUp) {
          this.listMove(-1);
        } else if (event.keyCode === this.controls.selectionDown) {
          this.listMove(1);
        }
      }
    },
    listMove(direction) {
      if (this.suggestionCount === 0)
        return;

      let currentPosition = this.selectedIndex === null ? 0 : this.selectedIndex + 1;
      let newPosition = currentPosition === null ? direction : currentPosition + direction;

      if (newPosition < 1) {
        newPosition = this.suggestionCount;
      } else if (newPosition > this.suggestionCount) {
        newPosition = 1;
      }

      this.selectedIndex = newPosition - 1;
    }
  }
}
</script>
