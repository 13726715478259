<template>
    <v-card flat>
        <v-data-table
                dense
                :headers="headers"
                :items="eventsArray"
                class="elevation-1"
        >
            <template v-slot:[`item.eventCategoryId`]="{ item }">
                <!--<v-icon small :color="eventCategory(item.eventCategoryId).color">{{ eventCategory(item.eventCategoryId).icon }}</v-icon>-->
                <!--{{ eventCategory(item.eventCategoryId).name }}-->
                {{ item.eventCategoryId }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <v-icon
                        small
                        class="mr-2"
                        @click="setActiveEvent(item.uuid)"
                >
                    remove_red_eye
                </v-icon>
                <v-icon
                        small
                        @click="addNewEventDialog(item)"
                >
                    add_alert
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import {values} from "lodash";
    import {appBus} from "../../../main";
    import {ON_EVENT_INSERT} from "../../../events";

    export default {
        name: 'dev-events',
        created() {
        },
        data() {
            return {
                headers: [
                    {text: 'Id', align: 'left', sortable: false, value: 'id'},
                    {text: 'UUID', align: 'left', sortable: false, value: 'uuid'},
                    {text: 'State', align: 'left', sortable: false, value: 'eventStateId'},
                    {text: 'Cat.', align: 'left', sortable: false, value: 'eventCategoryId'},
                    {text: 'Title', align: 'left', sortable: false, value: 'header.title.value'},
                    {text: 'Start', align: 'left', sortable: false, value: 'startAt'},
                    {text: 'End', align: 'left', sortable: false, value: 'endAt'},
                    {text: 'Started', align: 'left', sortable: false, value: 'startedAt'},
                    {text: 'Eneded', align: 'left', sortable: false, value: 'endedAt'},
                    {text: 'Actions', align: 'right', sortable: false, value: 'action'},
                ],
            }
        },
        computed: {
            eventCategory() {
                return id => this.getEventCategoryById(id);
            },
            eventsArray() {
                return values(this.events);
            },
            ...mapGetters('eventCategories', {
                getEventCategoryById: 'getEventCategoryById',
            }),
            ...mapGetters('events', {
                events: 'getEvents',
            }),
        },
        methods: {
            addNewEventDialog(item) {
                appBus.$emit(ON_EVENT_INSERT, item.uuid);
            },
            ...mapActions('events', {
                setActiveEvent: 'setActiveEvent',
            }),
        },
    }
</script>

<style lang="scss">
    .dev-panel {
        position: absolute;
        z-index: 100000;
        bottom: 0;

        .input-group {
            &.period {
                width: 50px;
            }

            &.hash {
                width: 100px;
            }
        }

        .tab-content {
            max-width: 600px;
            max-height: 80vh;
            overflow: auto;
        }
    }
</style>
