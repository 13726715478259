<template>
    <div class="panorama-wrapper" style="position: relative;">
        <v-btn small fab color="info" absolute @click="close" style="right: 10px; top: 10px;  z-index: 100">
            <v-icon>$vuetify.icons.close</v-icon>
        </v-btn>
        <v-chip v-if="date" dense rounded color="info" class="absolute" style="position:absolute; left: 10px; top: 10px; z-index: 100">pořízeno: {{ date | moment('DD.MM.YYYY') }}</v-chip>
        <div ref="panoramaContainer" class="panorama" style="height:100%; width: 100%; overflow: hidden"></div>
    </div>
</template>

<script>

/* eslint-disable */
import {mapState} from "vuex";
import moment from "moment/moment";
import {appBus} from "@/main";
import {ON_RESIZE} from "@/events";

export default {
    name: 'map-panorama',
    data() {
        return {
            panoramaData: null,
            yaw: null,
            date: null,
        }
    },
    mounted() {
        this.createPanorama(this.userCoordinates);
    },
    computed: {
        ...mapState({
            userCoordinates: state => state.panorama.userCoordinates,
        }),
        angle: {
            get() {
                return this.$store.state.panorama.angle;
            },
            set(value) {
                this.$store.commit('panorama/setAngle', value)
            }
        },
        coordinates: {
            get() {
                return this.$store.state.panorama.coordinates;
            },
            set(value) {
                this.$store.commit('panorama/setCoordinates', value)
            }
        },
    },
    methods: {
        async createPanorama(coordinates) {
            let container = this.$refs.panoramaContainer;

            if (this.panoramaData)
                this.panoramaData.destroy();

            this.panoramaData = await Panorama.panoramaFromPosition({
                parent: container,
                lon: coordinates[0],
                lat: coordinates[1],
                apiKey: process.env.VUE_APP_MAPYCZ_API_KEY,
                yaw: this.yaw ? this.yaw : null,
                pitch: Math.PI,
                fov: Math.PI,
                showNavigation: true,
            });
            appBus.$emit(ON_RESIZE);
            if (this.panoramaData.errorCode === 'NONE') {
                const camera = this.panoramaData.getCamera();
                this.onPanoramaPlaceChange(this.panoramaData);
                this.onPanoramaViewChange(camera);
                this.panoramaData.addListener("pano-view", viewData => this.onPanoramaViewChange(viewData));
                this.panoramaData.addListener("pano-place", placeData => this.onPanoramaPlaceChange(placeData));
            } else {
                this.$store.dispatch('map/extendTo', coordinates);
            }
        },
        moveTo(userCoordinates) {
            this.createPanorama(userCoordinates);
        },
        close() {
            this.$store.dispatch('panorama/close')
        },
        onPanoramaViewChange(viewData) {
            this.yaw = viewData.yaw;
            let angle = Math.round(viewData.yaw * 180.0 / Math.PI);
            if (this.angle !== angle)
                this.angle = angle;
        },
        onPanoramaPlaceChange(placeData) {
            this.date = placeData.info.date;
            if (this.coordinates[0] !== placeData.info.lon || this.coordinates[1] !== placeData.info.lat) {
                this.coordinates = [placeData.info.lon, placeData.info.lat];
                this.$store.dispatch('map/extendTo', this.coordinates);
            }
        },
    },
    watch: {
        userCoordinates: function (userCoordinates) {
            this.moveTo(userCoordinates);
        },
    },
    beforeDestroy() {
        this.panoramaData.removeListener("pano-view");
        this.panoramaData.removeListener("pano-place");
        this.panoramaData.destroy();
        appBus.$emit(ON_RESIZE);
    }
}
</script>