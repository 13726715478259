<template>
    <v-tooltip top v-if="$can('view', 'Debug')">
      <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="debugDialog" small icon>
              <v-icon>science</v-icon>
          </v-btn>
      </template>
      Debug dialog
    </v-tooltip>
</template>
<script>

import {appBus} from "@/main";
import {ON_DEBUG_DIALOG} from "@/events";

export default {
  name: "DebugButton",
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  methods: {
      debugDialog() {
          appBus.$emit(ON_DEBUG_DIALOG, this.data);
      },
  },
};
</script>
