import Vue from 'vue'
import moment from "moment/moment";
import {
    forEach,
    includes,
    orderBy,
    filter,
    map,
    indexOf,
    pickBy,
    unionBy,
    intersection,
    isEmpty,
    findKey,
    some,
    has,
    size
} from "lodash";
import eventApi from "../../api/events";
import api from "../../api/api";
import {confirmTagCode} from "@/store/modules/tags";
import {stateMerge} from "../object-merge";
import * as Sentry from "@sentry/vue";
import {sortBy} from "lodash/collection";

const invalidateNotificationInSeconds = 60;

const getDefaultState = () => {
    return {
        items: {},
        newMessages: false,
        categories: {
            2: {
                id: 2,
                color: 'grey lighten-2',
                unreadClass: 'red lighten-1',
                name: 'Operační kanál',
                options: {
                    tooltip: 'Operační komunikační kanál HZS',
                    placeholder: 'operační zpráva...',
                }
            },
            3: {
                id: 3,
                color: 'blue lighten-5',
                unreadClass: 'blue lighten-2',
                name: 'Dispečerský kanál',
                options: {
                    tooltip: 'Kanál pro komunikaci s dispečinky SŽ',
                    placeholder: 'zpráva pro dispečery SŽ...',
                }
            },
            5: {
                id: 5,
                color: 'yellow lighten-5',
                unreadClass: 'grey lighten-1',
                name: 'Obecný kanál',
                options: {
                    tooltip: 'Chat',
                    placeholder: 'zpráva...',
                }
            },
        },
        mapMessageTypes: [
            'X04',
            'X30',
            'T00',
            'X50',
            'X55',
        ],
        uploadQueue: [],
        visited: [],
    }
};

const state = getDefaultState();

const getters = {

    /**
     *
     * @param state
     * @returns {function(*): *}
     */
    byId: (state) => (id) => {
        return state.items[id];
    },

    /**
     *
     * @param state
     * @param getters
     * @param rootState
     * @returns {function(*)}
     */
    isMy: (state, getters, rootState) => (id) => {
        const message = getters.byId(id);
        return rootState.auth.device.id === message.deviceId && rootState.auth.user.id === message.userId;
    },
    /**
     * Get all unvisited messages by current user
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @returns {number}
     */
    unvisited: (state, getters, rootState, rootGetters) => {
        let eventKeys = map(rootGetters['events/getFilteredEvents'], 'uuid');
        let messages = filter(state.items, (o) => {
            if (o)
                return o.userId !== rootState.auth.user.id && o.read !== 1 && indexOf(eventKeys, o.eventUuid) >= 0;
        });
        return messages.length;
    },

    unvisitedExceptActiveEvent: (state, getters, rootState, rootGetters) => {
        let eventUuid = rootGetters['events/getActiveEventId'];
        if (!eventUuid)
            return 0;

        let unvisited = 0;
        forEach(state.items, (o) => {
            if (o !== undefined && o !== null && o.eventUuid !== eventUuid && o.read !== 1 && o.userId !== rootState.auth.user.id)
                unvisited++;
        });
        return unvisited;
    },
    unvisitedByEventGroupByMessageCategory: (state, getters) => (event, messageCategories) => {
        let unvisitedMessagesByCategories = [];
        forEach(messageCategories, (messageCategory) => {
            let count = getters.unvisitedByEvent(event, messageCategory.id).length;
            if (count > 0) {
                unvisitedMessagesByCategories.push({
                    messageCategory: messageCategory,
                    unvisited: count
                })
            }
        });
        return unvisitedMessagesByCategories;
    },
    unvisitedByEvent: (state, getters, rootState) => {
        return (event, messageCategoryId = null) => {
            let messages = {};

            if (messageCategoryId) {
                messages = pickBy(state.items, (o) => {
                    return o !== undefined && o !== null && o.eventUuid === event.uuid && o.messageCategoryId === messageCategoryId && o.read !== 1 && o.userId !== rootState.auth.user.id;
                });
            } else {
                messages = pickBy(state.items, (o) => {
                    return o !== undefined && o !== null && o.eventUuid === event.uuid && o.read !== 1 && o.userId !== rootState.auth.user.id;
                });
            }
            return map(messages, 'id');
        }
    },
    eventMessages: (state) => (payload) => {
        return orderBy(filter(state.items, {
            eventUuid: payload.eventUuid,
            messageCategoryId: payload.messageCategoryId
        }), ['sequence'], ['desc']);
    },
    getIdsByEvent: (state) => {
        return eventKey => {
            return map(filter(state.items, {eventUuid: eventKey}), 'id')
        }
    },
    byEventKeyForMap: (state) => {
        return (eventKey) => {
            return filter(state.items, function (o) {
                return o !== undefined && o.eventUuid !== undefined && o.eventUuid === eventKey && o.longitude > 0 && o.latitude > 0 && includes(state.mapMessageTypes, o.type)
            });
        }
    },
    mediaByEventIdAndMessageCategoryId: (state) => {
        return (eventId, messageCategoryId) => {
            return orderBy(filter(state.items, function (o) {
                return o !== undefined &&
                    o.eventId !== undefined &&
                    o.eventId === eventId &&
                    o.messageCategoryId === messageCategoryId &&
                    !isEmpty(o.media) &&
                    some(o.media, media => media.includes('.jpg'))
            }), ['sequence'], ['desc']);
        }
    },
    extend: (state, getters) => {
        return (eventKey) => {
            let extent = [];
            forEach(getters['byEventKeyForMap'](eventKey), function (object) {
                extent.push([object.longitude, object.latitude])
            });
            return extent;
        }
    },
    tooltip: (state) => (message) => {

        let content = message.content;
        let comp = null;

        if (includes(state.mapMessageTypes, message.type) && message.media !== null && message.media[0] !== undefined) {
            comp = {
                is: 'MessageMedia',
                data: {
                    'id': message.id,
                    'eventId': message.eventId,
                    'media': message.media[0],
                },
            };
        }

        return {
            title: 'Odesláno: ' + moment(message.createdAt).format('HH:mm DD.MM.YYYY'),
            content: content,
            overview: 'Odeslal: ' + message.deviceCode + ' ' + message.senderName + (message.accuracy ? ', Poloha: +-' + message.accuracy + 'm' : ''),
            comp: comp,
        };
    },
    /**
     * Merged tags - applied and available by user
     */
    tags: (state, getters) => (message) => {
        let appliedTags = getters['appliedTags'](message);
        // console.log('appliedTags', appliedTags);
        let availableTags = getters['availableTags'](message, appliedTags);
        // console.log('availableTags', availableTags);
        return sortBy(unionBy(appliedTags, availableTags, 'id'), ['sort']);
    },
    /**
     * Available tags for user to use
     */
    availableTags: (state, getters, rootState, rootGetters) => (message, appliedTags) => {
        const tags = rootGetters['tags/byCategory'](process.env.VUE_APP_MESSAGE_CATEGORY_ID);
        const event = rootGetters['events/byId'](message.eventId);
        const type = rootGetters['messageTypes/getByType'](message.type);

        let availableTags = [];

        // console.log(tags);
        forEach(tags, (item) => {

            // Todo: add to rights - rbac
            if (item.code.slice(0, 2) === 'C_' && rootState.auth.user.baseId !== rootState.bases.centralBaseId)
                return true;

            if (!includes(item.options?.messageCategories, message.messageCategoryId))
                return true;

            if (!isEmpty(item.options?.eventCategories) && !includes(item.options?.eventCategories, event.eventCategoryId))
                return true;

            if (!isEmpty(item.options?.messageTypes) && !includes(item.options?.messageTypes, type.id))
                return true;

            if (!isEmpty(item.options?.excludeMessageTypes) && includes(item.options?.excludeMessageTypes, type.id))
                return true;

            if (item.options?.isMy !== null) {
                let isMy = rootGetters['messages/isMy'](message.id);
                if (item.options.isMy === 1 && !isMy)
                    return true;

                if (item.options.isMy === 0 && isMy)
                    return true;
            }

            if (intersection(map(appliedTags, 'id'), item.options?.hiddenOn).length < 1) {
                let tag = JSON.parse(JSON.stringify(item));
                tag.state = 0;
                tag.show = 1;
                availableTags.push(tag);
            }
        });
        return availableTags;
    },
    /**
     * Applied tag on message
     */
    appliedTags: (state, getters, rootState, rootGetters) => (message) => {
        return rootGetters['tags/byMessage'](message);
    }
};

const actions = {
    async set({state, commit, dispatch}, payload) {
        let exists = false;
        let error = false;

        if (!payload)
            return;

        let messages = {};

        forEach(payload, async function (value, key) {
            exists = state.items[key] !== undefined;
            error = false;

            if (!exists && size(value) === 1 && has(value, 'tags')) {
                error = true;
            } else if (!exists && value.eventUuid !== undefined) {
                let oldInSeconds = moment().diff(moment.unix(value.updatedAt), 'seconds');
                if (oldInSeconds <= invalidateNotificationInSeconds)
                    dispatch('notifications/onNewMessage', value, {root: true});
            } else if (exists && state.items[key].eventUuid === undefined && value.eventUuid === undefined) {
                error = true;
            }

            if (!error) {
                messages[key] = value;
            }
        });

        if (isEmpty(messages))
            return;

        commit("MERGE_STATE", messages);
        commit("CLEAR");
    },
    read({getters, rootState, commit}, key) {

        if (!key)
            return;

        let message = getters.byId(key);

        // check if message is not my own or is already marked as read
        if (rootState.auth.device.id === message.deviceId || message.read === 1)
            return;

        commit('read', key);
        return eventApi.readMessage(key).then(() => {
            commit('read', key);
            return true;
        }).catch(function () {
            commit('unread', key);
            return false;
        });
    },
    readAll({commit, dispatch}) {

        return eventApi.readUser().then(() => {
            dispatch('notifications/add', {
                type: 'success',
                message: 'Všechny zprávy byly označeny jako přečtené.'
            }, {root: true});

            commit('readAll');

        }).catch(function () {
            return false;
        });
    },
    readEvent({state, commit, dispatch, getters, rootGetters}, payload) {

        let key = payload.key
        let messageCategoryId = payload.messageCategoryId

        let event = rootGetters['events/getByKey'](key);
        const messageCategory = state.categories[messageCategoryId];
        let messageIds = getters.unvisitedByEvent(event, messageCategoryId);

        forEach(messageIds, function (key) {
            commit('read', key);
        });

        if (event.id > 0) {
            return eventApi.readAllEventMessages(event.id, messageCategoryId).then(() => {
                dispatch('notifications/add', {
                    type: 'success',
                    message: 'Zprávy v: `' + messageCategory.name + '` byly označeny jako přečtené.'
                }, {root: true});
                return true;
            }).catch(function () {
                return false;
            });
        }
    },
    async send({dispatch, rootState, rootGetters}, data) {
        data.device_id = rootState.auth.device.id;
        data.event_id = rootGetters['events/getEventId'](rootState.events.active);
        data.created_at = moment().format('YYYY-MM-DD HH:mm:ss');

        return eventApi.sendMessage(data).then(() => {
            dispatch('notifications/add', {
                type: 'success',
                message: 'Zpráva odeslána\n'
            }, {root: true});
            return true;
        }).catch(function (error) {
            dispatch('notifications/add', {
                type: 'error',
                message: "Chyba při odesílání zprávy: " + error
            }, {root: true});
            return false;
        });
    },
    async applyTag({dispatch}, payload) {
        await api.post('/v2/messages/' + payload.message.id + '/tag/' + payload.tag.id)
            .then((response) => {
                dispatch("notifications/add", {type: 'success', message: 'Požadavek odeslán'}, {root: true});
                dispatch('messages/set', response, {root: true});
            })
            .catch((error) => {
                dispatch("notifications/add", {type: 'error', message: error}, {root: true});
            })
    },
    async confirm({getters, dispatch}, messageId) {

        const message = getters.byId(messageId)
        const appliedTags = getters.appliedTags(message);

        let exists = findKey(appliedTags, (o) => {
            return o.code === confirmTagCode;
        });

        if (exists)
            return;

        if (message.type === 'X05')
            return;

        if (getters.isMy(messageId))
            return;

        let confirmMessage = {
            content: 'Potvrzuji příjem zprávy ' + message.sequence,
            type: 'X05',
            data: JSON.stringify({messageId: message.id}),
            messageCategoryId: message.messageCategoryId,
        };

        await dispatch('send', confirmMessage).then(() => {
            dispatch("notifications/add", {type: 'success', message: 'Potvrzení odesláno'}, {root: true});
            eventApi.getMessage(message.id).then((response) => {
                let data = {};
                data[response.id] = response;
                dispatch('set', data);
            });
        });
    },
    onRegister() {
        // console.log("START ON_NETWORK_STATUS_CHANGE");
        // appBus.$on(ON_NETWORK_STATUS_CHANGE, (status) => {
        //     console.log("ON_NETWORK_STATUS_CHANGE", status)
        // });
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    CLEAR(state) {
        for (let propName in state.items) {
            if (state.items[propName] === null || state.items[propName] === undefined) {
                Vue.delete(state.items, propName);
                Sentry.captureMessage("Removing null object from messages.items");
            }
        }
    },
    add(state, payload) {
        Vue.set(state.items, payload.key, payload.item);
    },
    read(state, key) {
        if (state.items[key] !== undefined)
            Vue.set(state.items[key], 'read', 1);
    },
    readAll(state) {
        forEach(state.items, (value, key) => {
            Vue.set(state.items[key], 'read', 1);
        });
    },
    unread(state, key) {
        if (state.items[key] !== undefined)
            Vue.set(state.items[key], 'read', 0);
    },
    update(state, payload) {
        forEach(payload.item, (value, key) => {
            state.items[payload.key][key] = value
        });
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
    setNew(state, value) {
        state.newMessages = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export function registerModule(store) {
    store.dispatch('messages/onRegister');
}
