<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
<v-card>
<v-list>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-subtitle>Přihlášený uživatel</v-list-item-subtitle>
            <v-list-item-title>{{ userFullName }}</v-list-item-title>
            <v-list-item-title>
                <a class="caption" href="https://stopa.hzs.spravazeleznic.cz/" target="_blank">změna hesla</a>
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
            <v-btn text @click="logout">
                <v-icon left>eject</v-icon>
                Odhlásit se
            </v-btn>
        </v-list-item-action>
    </v-list-item>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-subtitle>Zařízení</v-list-item-subtitle>
            <v-list-item-title>{{ auth.device.name }} ({{ auth.device.code }})</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="userBase">
        <v-list-item-content>
            <v-list-item-subtitle>Jednotka</v-list-item-subtitle>
            <v-list-item-title>{{ userBase.name }}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</v-list>
</v-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import moment from "moment/moment";

export default {
    name: 'header-user-card',
    data() {
        return {}
    },
    computed: {
        ...mapGetters('auth', {
            userFullName: 'fullName',
        }),
        ...mapGetters('bases', {
            userBase: 'getUserBase',
        }),
        ...mapState({
            auth: state => state.auth,
            pubNubStatus: state => state.pubNubStatus,
        }),
    },
    methods: {
        restart() {
            window.location.href = "/?" + moment();
        },
        ...mapActions('auth', {
            logout: 'logout',
        }),
    },
}
</script>
