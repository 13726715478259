<template>
    <div>
    </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {appBus} from "@/main";
import {ON_MEDIA_SHOW} from "@/events";
import {api as viewerApi} from 'v-viewer'
import {forEach} from "lodash";
import app from "@/api/app";
import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls.js";

const SPHERICAL_IMAGE_MESSAGE_TYPE = 'X55'

export default {
    name: "ImageViewer",
    props: {
        viewerOptions: {
            type: Object,
            required: false,
            default: () => ({})
        },
    },
    created() {
        appBus.$on(ON_MEDIA_SHOW, (media, eventId, messageCategoryId) => {
            this.media = media;
            this.eventId = eventId;
            this.messageCategoryId = messageCategoryId;
            this.openImageViewer();
        });
    },
    data() {
        return {
            viewer: null,
            scene: null,
            camera: null,
            render: null,
            media: null,
            eventId: null,
            messageCategoryId: null,
            options: {
                url: 'data-src',
                container: '.v-application',
                title: true,
                show: () => {
                    if (this.viewer) {
                        const footer = this.viewer.footer;
                        const buttonElement = footer.querySelector('.viewer-coords');
                        if (buttonElement) {
                            buttonElement.textContent = '...';
                        }
                    }
                },
                hide: () => {
                    console.log('hide');
                    const threeContainer = this.viewer.canvas.querySelector('canvas');
                    if (threeContainer) {
                        threeContainer.remove();
                    }
                    this.viewer.destroy();
                },
                stop: () => {
                    console.log('stop');
                },
                view: (e) => {
                    console.log('view', this.viewer);
                    const footer = this.viewer.footer;
                    const image = e.detail.originalImage;
                    const messageId = image.getAttribute('data-message-id');
                    const message = this.$store.getters['messages/byId'](messageId);
                    const buttonElement = footer.querySelector('.viewer-coords');

                    if (buttonElement) {
                        if (message.latitude && message.latitude) {
                            buttonElement.textContent = message.latitude + ', ' + message.longitude;
                        } else {
                            buttonElement.textContent = 'bez souřadnic';
                        }
                    }

                    const threeContainer = this.viewer.canvas.querySelector('canvas');
                    if (threeContainer) {
                        threeContainer.remove();
                    }

                    const zoomInButton = this.viewer.toolbar.querySelector('.viewer-zoom-in');
                    const zoomOutButton = this.viewer.toolbar.querySelector('.viewer-zoom-out');
                    const rotateLeftButton = this.viewer.toolbar.querySelector('.viewer-rotate-left');
                    const rotateRightButton = this.viewer.toolbar.querySelector('.viewer-rotate-right');
                    const resetButton = this.viewer.toolbar.querySelector('.viewer-reset');
                    const oneToOneButton = this.viewer.toolbar.querySelector('.viewer-one-to-one');
                    const flipHorizontalButton = this.viewer.toolbar.querySelector('.viewer-flip-horizontal');
                    const flipVerticalButton = this.viewer.toolbar.querySelector('.viewer-flip-vertical');

                    this.viewer.canvas.parentElement.dataset.messageType = message.type;

                    if (message.type === SPHERICAL_IMAGE_MESSAGE_TYPE) {
                        e.detail.image.style.display = "none";
                        zoomInButton.style.display = 'none';
                        zoomOutButton.style.display = 'none';
                        rotateLeftButton.style.display = 'none';
                        rotateRightButton.style.display = 'none';
                        resetButton.style.display = 'none';
                        oneToOneButton.style.display = 'none';
                        flipHorizontalButton.style.display = 'none';
                        flipVerticalButton.style.display = 'none';
                    } else {
                        zoomInButton.style.display = '';
                        zoomOutButton.style.display = '';
                        rotateLeftButton.style.display = '';
                        rotateRightButton.style.display = '';
                        resetButton.style.display = '';
                        oneToOneButton.style.display = '';
                        flipHorizontalButton.style.display = '';
                        flipVerticalButton.style.display = '';
                    }

                },
                viewed: (e) => {
                    document.removeEventListener( 'wheel', this.onDocumentMouseWheel );
                    document.removeEventListener( 'resize', this.onWindowResize );

                    const threeContainer = this.viewer.canvas.querySelector('canvas');
                    if (threeContainer) {
                        threeContainer.remove();  // remove three container if exists
                    }

                    const image = e.detail.originalImage;
                    const messageId = image.getAttribute('data-message-id');
                    this.readMessage(messageId);
                    const message = this.$store.getters['messages/byId'](messageId);

                    if (message.type === SPHERICAL_IMAGE_MESSAGE_TYPE) {
                        e.detail.image.style.display = "none";
                        const container = this.viewer.canvas;
                        this.renderer = new THREE.WebGLRenderer();
                        this.renderer.setPixelRatio(window.devicePixelRatio);
                        this.renderer.setSize(window.innerWidth, window.innerHeight);

                        container.appendChild(this.renderer.domElement);

                        const scene = new THREE.Scene();
                        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
                        this.camera.position.z = 0.01;

                        const controls = new OrbitControls(this.camera, this.renderer.domElement);

                        controls.enableZoom = true;
                        controls.enablePan = false;
                        controls.enableDamping = true;
                        controls.rotateSpeed = -0.25;

                        const geometry = new THREE.SphereGeometry(5, 32, 32);
                        geometry.scale(-1, 1, 1);

                        const texture = new THREE.TextureLoader().load(image.getAttribute('data-src'));
                        const material = new THREE.MeshBasicMaterial({map: texture});
                        const sphere = new THREE.Mesh(geometry, material);

                        scene.add(sphere);

                        const animate = () => {
                            controls.update();
                            requestAnimationFrame(animate);
                            this.renderer.render(scene, this.camera);
                        };
                        animate();
                        document.addEventListener( 'wheel', this.onDocumentMouseWheel );
                        window.addEventListener( 'resize', this.onWindowResize );
                    }
                },
                toolbar: {
                    zoomIn: true,
                    zoomOut: true,
                    reset: true,
                    oneToOne: true,
                    play: false,
                    prev: true,
                    next: true,
                    rotateLeft: true,
                    rotateRight: true,
                    flipHorizontal: true,
                    flipVertical: true,
                    download: {
                        click: async () => {
                            const viewer = this.viewer
                            const response = await axios({
                                method: 'GET',
                                url: viewer.image.src,
                                responseType: 'blob', // Important
                            });
                            let blob = new Blob([response.data], {type: response.headers['content-type']});
                            let url = window.URL.createObjectURL(blob);
                            let link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', viewer.image.alt);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    },
                    coords: {
                        click: () => {
                            const footer = this.viewer.footer;
                            const buttonElement = footer.querySelector('.viewer-coords');
                            if (buttonElement) {
                                let text = buttonElement.textContent;
                                navigator.clipboard.writeText(text).then(() => {
                                    this.$noty.success("Souřadnice zkopírovány do paměti");
                                }, (err) => {
                                    this.$noty.error("Chyba kopírování do paměti:" + err);
                                });
                            }
                        }
                    },
                }
            },
        }
    },
    computed: {
        mergedOptions() {
            return Object.assign({}, this.options, this.viewerOptions);
        },
        ...mapGetters('messages', {
            mediaByEventIdAndMessageCategoryId: 'mediaByEventIdAndMessageCategoryId',
        }),
        imageObjects() {
            const imageObjects = [];
            forEach(this.mediaByEventIdAndMessageCategoryId(this.eventId, this.messageCategoryId), (message) => {
                forEach(message.media, (media) => {
                    imageObjects.push({
                        'data-src': this.getMediaUrl(media, ''),
                        src: this.getMediaUrl(media, 'small'),
                        alt: 'image',
                        'data-media': media,
                        'data-event': this.eventId,
                        'data-message-id': message.id,
                    })
                });
            });
            return imageObjects;
        }
    },
    methods: {
        onDocumentMouseWheel(event) {
            event.preventDefault();
            const fov = this.camera.fov + event.deltaY * 0.05;
            this.camera.fov = THREE.MathUtils.clamp(fov, 10, 75);
            this.camera.updateProjectionMatrix();

        },
        onWindowResize() {
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(window.innerWidth, window.innerHeight);
        },
        getMediaUrl(media, size) {
            return app.getMediaUrl(this.eventId, media, size)
        },
        openImageViewer() {
            let options = {
                options: this.mergedOptions,
                images: this.imageObjects,
            };

            const index = this.imageObjects.findIndex(image => image['data-media'] === this.media);
            if (index !== -1) {
                options.options.initialViewIndex = index;
            }
            this.viewer = viewerApi(options);
        },
        ...mapActions('messages',
            {
                readMessage: 'read',
            }
        ),
    },
    beforeDestroy() {
        appBus.$off(ON_MEDIA_SHOW);
        document.removeEventListener( 'wheel', this.onDocumentMouseWheel );
        document.removeEventListener( 'resize', this.onWindowResize );
    }
}
</script>
