<template>
    <fragment>
        <li v-if="showBaseRouting" @click="routingBase">
            <v-icon left>fa-stopwatch</v-icon>
            Odhad trasy ze základny
        </li>
        <li v-if="showWaypoints" @click="waypoints">
            <v-icon left>fa-route</v-icon>
            Podrobná trasa vozidlové navigace
        </li>
        <li v-if="showJobRouting" @click="routing">
            <v-icon left>fa-stopwatch</v-icon>
            Odhad trasy a doby dojezdu
        </li>
        <li v-if="showCreateNote" @click="create">
            <v-icon color="red" left>circle</v-icon>
            Vložit poznámku do mapy
        </li>
        <li v-if="showEditNote" @click="edit">
            <v-icon left>circle</v-icon>
            Smazat poznámku
        </li>
    </fragment>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {includes} from "lodash";

    export default {
        name: 'map-notice-context-menu',
        props: [
            'data',
        ],
        computed: {
            ...mapGetters('eventCategories', {
                getEventCategoryById: 'getEventCategoryById',
            }),
            showWaypoints() {
                if (this.isActive && this.data.type === 'eventJob') {
                    let jobId = this.data.feature.getProperties().id;
                    let job = this.$store.state.eventJobs.items[jobId];
                    let vehicle = this.$store.state.resources.items[job.vehicle];
                    return vehicle !== undefined && vehicle.pnt !== undefined && vehicle.pnt && vehicle.pnt.waypoints;
                }
                return false;
            },
            showJobRouting() {
                return this.isActive && this.data.type === 'eventJob';
            },
            showBaseRouting() {
                return this.isActive && this.data.type === 'eventTarget';
            },
            showCreateNote() {
                return this.isActive 
                    && this.data.area === 'map' 
                    && this.event.id > 0
                    && this.data.type !== 'eventObject'
                    && this.$can('manage', 'Message2')
                    && !includes(['call', 'chat', 'monitor', 'support'], this.getEventCategoryById(this.event.eventCategoryId).type);
            },
            showEditNote() {
                return this.isActive && this.data.type === 'eventObject' && this.$can('manage', 'Message2');
            },
            isActive() {
                return !this.isObjectActive && this.isActiveEvent && this.event.id > 0 && this.data;
            },
            ...mapGetters('events', {
                isActiveEvent: 'isActiveEvent',
                event: 'getActiveEvent',
            }),
        },
        methods: {
            getUuid() {
                return this.data.feature.getProperties().uuid
            },
            edit() {
                this.$store.dispatch('eventPoises/edit', this.getUuid());
            },
            create() {
                this.$store.dispatch('eventPoises/create', {
                    eventId: this.event.id,
                    eventUuid: this.event.uuid,
                    coordinates: this.data.coordinates,
                });
            },
            routing() {
                let payload = {
                    coordinates: this.data.coordinates,
                    event: this.event,
                };
                payload.jobId = this.data.feature.getProperties().id;
                this.$store.dispatch('routes/eventJob', payload);
            },
            routingBase() {
                let payload = {
                    coordinates: this.data.coordinates,
                    event: this.event,
                };
                this.$store.dispatch('routes/base', payload);
            },
            waypoints() {
                let payload = {
                    jobId: this.data.feature.getProperties().id,
                };
                this.$store.dispatch('eventJobs/showWaypoints', payload);
            }
        },
    }
</script>
