<template>
    <fullscreen :fullscreen.sync="fullscreen">
        <v-app :class="'app-body env-'+env + (isMobile ? ' mobile' : '')">
            <dev/>
            <auth-user v-if="!isUser"/>
            <auth-device v-if="!isDevice && isUser"></auth-device>
            <loading v-if="isDevice && !isPanelLoaded"></loading>
            <panel v-if="isPanelLoaded && isDevice" ref="panel"/>
        </v-app>
        <v-dialog v-if="debug.dialog" v-model="debug.dialog" max-width="760" scrollable>
            <v-card>
                <v-toolbar color="default" dark dense>
                    <v-toolbar-title>Debug data</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items class="pl-3">
                        <v-btn fab x-small color="red" @click="debug.dialog = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <pre>{{ debug.data }}</pre>
                </v-card-text>
            </v-card>
        </v-dialog>
    </fullscreen>
</template>

<script>

import AuthUser from './components/auth/AuthUser.vue'
import AuthDevice from './components/auth/AuthDevice.vue'
import Panel from './components/Panel.vue'
import Loading from './components/Loading.vue'
import Dev from "./components/dev/Dev"
import {mapState, mapGetters} from 'vuex'
import {appBus} from "@/main";
import {ON_DEBUG_DIALOG} from "@/events";

export default {
    name: 'app',
    created() {

        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);

        appBus.$on(ON_DEBUG_DIALOG, (payload) => {
            this.debug.dialog = true;
            this.debug.data = payload;
        });
        this.minuteInterval();
        this.secondInterval();
    },
    destroy() {
        clearInterval(this.minuteInterval)
        clearInterval(this.secondInterval)
    },
    mounted() {

        document.addEventListener('touchmove', this.disableReload);
        window.addEventListener('popstate', this.disableBackToExit);

        if (this.isUser) {
            this.$store.dispatch('auth/setIdentity');
        }
        this.$store.commit('auth/setDevice', null);
    },
    data() {
        return {
            env: process.env.VUE_APP_ENV,
            debug: {
                dialog: false,
                data: null,
            },
        }
    },
    components: {
        AuthUser,
        AuthDevice,
        Dev,
        Panel,
        Loading,
    },
    computed: {
        ...mapGetters('auth', [
            'isUser',
            'isDevice',
        ]),
        ...mapGetters('app', [
            'isPanelLoaded',
        ]),
        ...mapState({
            auth: state => state.auth,
            fullscreen: state => state.panel.fullscreen,
            isMobile: state => state.isMobile,
        }),
    },
    methods: {
        disableReload(event) {
            event.preventDefault();
        },
        disableBackToExit(event) {
            event.preventDefault();
            window.history.pushState(null, null, window.location.href);
        },
        loadInitData(reset) {
            this.$store.dispatch('app/getData', {reset: reset}).then(() => {
            });
        },
        minuteInterval() {
            this.minuteInterval = setInterval(() => {
                this.$store.commit('app/setMinuteDate');
            }, 1000 * 60)
        },
        secondInterval() {
            this.secondInterval = setInterval(() => {
                this.$store.commit('app/setSecondDate');
            }, 1000)
        },
        updateOnlineStatus() {
            const isOnline = navigator.onLine;
            this.$store.commit('app/setOnlineStatus', isOnline);
        },
    },
    watch: {
        isDevice(state) {
            if (state)
                this.loadInitData(true);
        },
    },
    beforeDestroy() {
        clearInterval(this.minuteInterval);
        document.removeEventListener('touchmove', this.disableReload);
        window.removeEventListener('popstate', this.disableBackToExit);
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    },
}
</script>
<style lang="scss">
@import '~vuejs-noty/dist/vuejs-noty.css';

#app {
  height: 100vh;
}

.btn-xs {
  padding: 0.15rem 0.30rem !important;
  font-size: 0.80rem !important;
}

</style>
