<template>
    <v-card flat>

        <v-card-text>
            <v-row>
                <v-col sm="2">Stopped: {{ isPlaying }}<br/>Paused: {{ isPaused }}</v-col>
                <v-col sm="3">Current sound: {{ soundCurrent ? soundCurrent.key : '-' }}</v-col>
                <v-col>Sound library: {{ soundLibrary }}</v-col>
            </v-row>
        </v-card-text>

        <v-card flat>
            <v-btn color="info" @click="addTestNotifications">Add test notifications</v-btn>
            <v-btn color="error" @click="clearNotifications">Remove notifications</v-btn>
            <v-data-table
                    :headers="headers"
                    :items="soundQueue"
                    class="elevation-1"
            >
                <template v-slot:items="props">
                    <td>{{ props.item.key }}</td>
                    <td>{{ props.item.code }}</td>
                    <td>{{ props.item.eventKey }}</td>
                    <td class="text-xs-center">{{ props.item.priority }}</td>
                    <td class="text-xs-center">{{ props.item.merge }}</td>
                    <td class="text-xs-center">{{ props.item.url }}</td>
                    <td class="text-xs-center">{{ props.item.createdAt }}</td>
                    <td class="text-xs-center">{{ props.item.processedAt }}</td>
                </template>
            </v-data-table>
        </v-card>
    </v-card>
</template>

<script>

    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'dev-sounds',
        created() {
        },
        data() {
            return {
                headers: [
                    {text: 'Key', align: 'left', sortable: false, value: 'key'},
                    {text: 'Method', align: 'left', sortable: false, value: 'method'},
                    {text: 'Title', align: 'left', sortable: false, value: 'title'},
                    {text: 'Code', align: 'left', sortable: false, value: 'key'},
                    {text: 'EventKey', align: 'left', sortable: false, value: 'key'},
                    {text: 'Priority', align: 'center', value: 'priority'},
                    {text: 'Merge', align: 'center', value: 'merge'},
                    {text: 'Url', align: 'center', value: 'url'},
                    {text: 'Created', align: 'center', value: 'createdAt'},
                    {text: 'Processed', align: 'center', value: 'processedAt'},
                    {text: 'Loops', align: 'center', value: 'loops'},
                    {text: 'State', align: 'center', value: 'state'},
                ],
                soundKey: null,
            }
        },
        computed: {
            ...mapGetters('notifications', {
                isPlaying: 'isPlaying',
                isPaused: 'isPaused',
            }),
            ...mapState({
                soundQueue: state => state.notifications.queue,
                soundCurrent: state => state.notifications.current,
                soundLibrary: state => state.notifications.messageSounds,
            })
        },
        methods: {
            clearNotifications() {
                this.$store.dispatch('notifications/resetState');
            },
            addTestNotifications() {
                // this.$store.dispatch('notifications/add', {
                //     method: 'sound',
                //     code: 'X11',
                //     icon: 'fullscreen',
                //     title: 'test 1',
                //     content: 'test 1',
                //     state: 0,
                //     actions: [
                //         {
                //             sort: 0,
                //             title: "Accept",
                //             type: "success",
                //             call: "openEvent",
                //             close: 1,
                //         },
                //     ],
                //     merge: 1,
                //     loops: 3,
                //     priority: 2,
                // });

                this.$store.dispatch('notifications/add', {
                    method: 'sound',
                    code: 'X11',
                    icon: 'fullscreen',
                    title: 'test 1',
                    content: 'test 1',
                    state: 0,
                    actions: [
                        {
                            sort: 0,
                            title: "Accept",
                            type: "success",
                            call: "openEvent",
                            close: 1,
                        },
                    ],
                    merge: 1,
                    loops: 3,
                    priority: 2,
                    url: null,
                    tts: "Potvrzuji příjem zprávy -"
                });
                

                // this.$store.dispatch('notifications/add', {
                //     method: 'dialog',
                //     eventKey: '22769488-2faf-536b-87e5-04e287f74e20',
                //     code: 'EVENT_NEW',
                //     icon: 'fullscreen',
                //     title: 'test 1',
                //     content: 'test 1',
                //     state: 0,
                //     actions: [
                //         {
                //             sort: 0,
                //             title: "Accept",
                //             type: "success",
                //             call: "openEvent",
                //             close: 1,
                //         },
                //     ],
                //     merge: 0,
                //     loops: 3,
                //     priority: 1,
                //     url: '34',
                // });
                //
                // this.$store.dispatch('notifications/add', {
                //     type: 'success',
                //     message: "Test notifikace",
                // });
            },
        },
    }
</script>
