import {
    EVENT_DIALOG_CLOSE,
    EVENT_DIALOG_OPEN,
    EVENT_DIALOG_RESET_STATE,
    EVENT_DIALOG_TOGGLE
} from "../actions/eventDialogs";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";

const getDefaultState = () => {
    return {
        dialog: false,
    }
};

const state = getDefaultState();

const getters = {
    getEventsDialogs: (state, getters, rootState, rootGetters) => {
        let events = rootGetters['events/getFilteredEvents'];
        return filter(events, function (item) {
            return item.eventStateId === 0 && item.alert !== null
        });
    },
    show: (state, getters) => {
        return !isEmpty(getters.getEventsDialogs);
    },
};

const actions = {
    onNewEvent() { // {dispatch, rootState}, payload
    },
    [EVENT_DIALOG_TOGGLE]({commit}) {
        commit(EVENT_DIALOG_TOGGLE)
    },
};

const mutations = {
    [EVENT_DIALOG_TOGGLE](state) {
        state.dialog = !state.dialog;
    },
    [EVENT_DIALOG_CLOSE](state) {
        state.dialog = false;
    },
    [EVENT_DIALOG_OPEN](state) {
        state.dialog = true;
    },
    [EVENT_DIALOG_RESET_STATE](state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}
