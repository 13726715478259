<template>
    <vl-layer-group :zIndex="99">
        <vl-feature v-if="showLine" :properties="{type: 'jobLine'}">
            <vl-geom-line-string :coordinates="line"></vl-geom-line-string>
            <vl-style-func :factory="styleFuncProp"/>
        </vl-feature>
        <vl-feature v-if="showWaypoints" :properties="{type: 'jobWaypoints'}">
            <vl-geom-line-string :coordinates="waypoints"></vl-geom-line-string>
            <vl-style-func :factory="styleWaypointFuncProp"/>
        </vl-feature>
    </vl-layer-group>
</template>

<script>
    import Style from 'ol/style/Style'
    import Stroke from 'ol/style/Stroke'
    import {mapGetters, mapState} from "vuex";

    export default {
        props: {
            event: Object,
            job: Object,
            coordinates: Array,
        },
        computed: {
            ...mapState({
                zoom: state => state.map.zoom,
            }),
            showLine() {
                return this.job.showWaypointsTimeout === undefined && this.line;
            },
            showWaypoints() {
                return this.job.showWaypointsTimeout > 0 && this.waypoints;
            },
            ...mapGetters('resources', {
                vehicleById: 'byId',
            }),
            line() {
                let vehicle = this.vehicle;
                if (vehicle && vehicle.pnt && vehicle.pnt.destinationLongitude && vehicle.pnt.destinationLatitude) {
                    return [
                        this.coordinates,
                        [
                            parseFloat(vehicle.pnt.destinationLongitude),
                            parseFloat(vehicle.pnt.destinationLatitude),
                        ]
                    ];
                }
                return false;
            },
            waypoints() {
                let vehicle = this.vehicle;
                if (vehicle && vehicle.pnt && vehicle.pnt.waypoints) {
                    return JSON.parse(vehicle.pnt.waypoints);
                }
                return false;
            },
            vehicle() {
                return this.vehicleById(this.job.vehicle);
            },
            styleFuncProp() {
                return () => {
                    return () => {
                        return [
                            new Style({
                                stroke: new Stroke({
                                    width: 4.5,
                                    color: "white",
                                }),
                            }),
                            new Style({
                                stroke: new Stroke({
                                    width: 2,
                                    color: "black",
                                    lineCap: 'square',
                                    lineDash: [4, 4],
                                }),
                            }),
                        ];
                    }
                }
            },
            styleWaypointFuncProp() {

                return () => {

                    let width = parseInt(this.zoom / 4) + 2;

                    return () => {
                        return [
                            new Style({
                                stroke: new Stroke({
                                    width: width * 1.8,
                                    color: "white",
                                }),
                            }),
                            new Style({
                                stroke: new Stroke({
                                    width: width,
                                    color: "black",
                                    lineCap: 'square',
                                    lineDash: [6, 8],
                                }),
                            }),
                        ];
                    }
                }
            },
        },
        methods: {},
    }
</script>
