<template>
    <vl-layer-vector :zIndex="58">
        <vl-feature v-for="(route, i) in routes" :key="i" :properties="{type: 'route', label: route.label }">
            <vl-geom-line-string :coordinates="route.coordinates"></vl-geom-line-string>
            <vl-style-func :function="styleFuncFactory()"/>
        </vl-feature>
    </vl-layer-vector>
</template>

<script>
    import {mapState} from 'vuex'
    import Style from 'ol/style/Style'
    import Stroke from 'ol/style/Stroke'
    import Point from 'ol/geom/Point'
    import Text from 'ol/style/Text'
    import Fill from 'ol/style/Fill'

    export default {
        props: {
            routes: Array,
        },
        computed: {
            ...mapState({
                zoom: state => state.map.zoom,
            }),
        },
        methods: {
            styleFuncFactory() {
                return (feature) => {
                    let width = parseInt(this.zoom / 4) + 2;

                    let styles = [
                        new Style({
                            stroke: new Stroke({
                                width: width * 1.8,
                                color: "white",
                            }),
                        }),
                        new Style({
                            stroke: new Stroke({
                                width: width,
                                color: "black",
                                lineCap: 'square',
                                lineDash: [6, 8],
                            }),
                        }),
                    ];

                    let geometry = feature.getGeometry();
                    let start = geometry.getFirstCoordinate();
                    styles.push(new Style({
                        geometry: new Point(start),
                        text: new Text({
                            text: feature.getProperties().label,
                            font: 'bold 13px Roboto',
                            fill: new Fill({
                                color: 'black'
                            }),
                            padding: [5, 5, 3, 5],
                            backgroundStroke: new Stroke({color: 'black', width: 2}),
                            overflow: true,
                            backgroundFill: new Fill({
                                color: 'white'
                            }),
                            placement: "point",
                            textBaseline: "top",
                            offsetY: 5,
                        })
                    }));
                    return styles;
                }
            },
        },
    }
</script>
