<template>
  <v-card v-if="isEnabled" class="hunting-regions-wrapper" min-width="460">
    <v-toolbar color="default" dark dense>
      <v-toolbar-title>
        <small>
          Informace o honitbě
        </small>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="pl-3">
        <v-btn fab x-small color="error" @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card max-height="50vh" class="scrollable" v-for="feature in features" v-bind:key="feature.properties.id">
        <v-card-text>
          <h3 class="black--text">{{ feature.properties.name }}</h3>
          <ul class="hr-list-unstyled pl-4">
            <li>
              <span class="font-weight-bold">Kraj: </span>
              {{ feature.properties.kraj }}
            </li>
            <li>
              <span class="font-weight-bold">Okres: </span>
              {{ feature.properties.okres }}
            </li>
            <li>
              <span class="font-weight-bold">ORP: </span>
              {{ feature.properties.orp }}
            </li>
          </ul>
          <v-divider class="my-1"></v-divider>
          <div v-if="feature.properties.huntingCompany !== null">
            <h4 class="black--text">Správce honitby</h4>
            <ul class="hr-list-unstyled pl-4">
              <li v-if="feature.properties.huntingCompany.name">
                <span class="font-weight-bold">Správce honitby:</span>
                {{ feature.properties.huntingCompany.name }}
              </li>
              <li v-if="feature.properties.huntingCompany.address">
                <span class="font-weight-bold">Adresa:</span>
                {{ feature.properties.huntingCompany.address }}
              </li>
              <li v-if="feature.properties.huntingCompany.postalCode">
                <span class="font-weight-bold">PSČ:</span>
                {{ feature.properties.huntingCompany.postalCode }}
              </li>
              <li v-if="feature.properties.huntingCompany.phone">
                <span class="font-weight-bold">Telefon</span>
                {{ feature.properties.huntingCompany.phone }}
              </li>
            </ul>
          </div>
        </v-card-text>

        <contact-list
            @onSave="onSave"
            @onDelete="onDelete"
            can-insert
            can-send
            can-delete
            :send="{contactTypeId:13}"
            :autocomplete="false"
            :items="feature.properties.contacts"
            :input="{huntingRegionId:feature.properties.id}"
        >
        </contact-list>
    </v-card>
  </v-card>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import mapapi from "@/api/mapapi";
import ContactList from "@/components/ui/contactList/ContactList";

export default {
  name: "map-card-hunting-region",
  components: {
    ContactList,
  },
  data() {
    return {
      status: 5,

      cancelSource: null,
      type: 'card',
    }
  },
  computed: {
    ...mapState({
      coordinates: state => state.huntingRegions.coordinates,
      features: state => state.huntingRegions.features,
    }),
    ...mapGetters('huntingRegions', {
      isEnabled: 'isEnabled',
    }),
    coordinates: {
      get() {
        return this.$store.state.huntingRegions.coordinates;
      },
      set(value) {
        this.$store.commit('huntingRegions/setCoordinates', value)
      }
    },
  },
  methods: {
    ...mapActions('huntingRegions', {
      closeHuntingRegions: 'close',
      updateFeature: 'update',
    }),
    async onSave(item, input, callback) {
      let data = this.requestData(item, input.huntingRegionId)
      const response = await mapapi.post('/hunting-region/contact', data);
      if(response.type === 'success') {
        callback(true);
      } else {
        callback(false);
      }
      this.updateFeature();
    },
    async onDelete(item, input, callback) {
      const response = await mapapi.get(`/hunting-region/contact/delete/${item.id}`);
      if(response.type === 'success') {
        callback(true);
      } else {
        callback(false);
      }
      this.updateFeature();
    },
    close() {
      this.status = 0;
      this.closeHuntingRegions();
    },
    requestData(item, huntingRegionId) {
      let data = {
        hunting_region_id: huntingRegionId,
        name: item.name,
        phone: item.number,
      };
      if (item.id !== null) {
        data['id'] = item.id;
      }
      return data;
    },
  },
}
</script>
