/**
 *
 */
import Vue from 'vue';

Vue.filter('phone', function (phone) {
    let cleaned = ('' + phone).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
    if (match)
        return '' + match[1] + ' ' + match[2] + ' ' + match[3]
    return phone;
});
