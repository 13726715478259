import moment from "moment/moment";
import api from "../../api/api";

const getDefaultState = () => {
    return {
        status: 0,
        results: {
            0: {
                name: 'Vysoké riziko nefunkčnosti aplikace',
                cellClass: 'red',
            },
            1: {
                name: 'Zařízení pracuje bez závad',
                cellClass: 'green',
            },
            2: {
                name: 'Funkce zařízení může být omezena',
                cellClass: 'yellow',
            }
        },
    }
};

const state = getDefaultState();

const getters = {
    isEnabled: state => {
        return state.status > 0;
    },
    result: (state) => (item) => {

        let result = {
            status: false,
            text: 'Neproběhla!',
            cellClass: '',
            rowClass: '',
        };

        if (item.testedAt === null) {
            result.text = 'Neproběhla!';
        }

        let testedAt = moment(item.testedAt * 1000);
        let connectedAt = moment(item.connectedAt * 1000);

        if (testedAt.isSame(moment(), 'day')) {
            result.text = testedAt.format('HH:mm');
            result.status = parseInt(item.testResultStatus);
            result.cellClass = state.results[result.status].cellClass;
        } else if (connectedAt.isSame(moment(), 'day')) {
            result.cellClass = 'red--text font-weight-bold';
        } else {
            result.rowClass = 'red--text';
            result.cellClass = 'font-weight-bold';
        }

        return result;
    },
};

const actions = {
    start: ({commit}) => {
        commit('resetState');
        commit('setStatus', 1);
    },
    end: ({commit}) => {
        commit('resetState');
        commit('setStatus', false);
    },
    toggle({state, commit}) {
        commit('setStatus', !state.status);
    },
    test({dispatch}, ids) {
        return api.put('/v2/devices/request-test', {id: ids})
            .then(() => {
                dispatch("notifications/add", {type: 'success', message: 'Test pro zařízení byl zadán'}, {root: true});
            })
            .catch((error) => {
                dispatch("notifications/add", {type: 'error', message: error}, {root: true});
            })
    },
    resetState({commit}) {
        commit('resetState');
    },
};

const mutations = {
    setStatus: (state, status) => {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
