import routingApi from '../../api/routing'

const getDefaultState = () => {
    return {
        items: [],
        item: null,
        timeout: null,
    }
};

const state = getDefaultState();

const getters = {};

const actions = {
    vehicle() {

    },
    async event({dispatch, rootGetters}, payload) {
        let base = rootGetters['bases/byId'](payload.event.baseId);
        let routes = await routingApi.getRouting(
            payload.coordinates,
            [base.longitude, base.latitude],
            [
                {
                    'eventUid': payload.event.uid
                }
            ]
        );
        if (routes.length > 0)
            dispatch('addSingle', routes[0]);

    },
    async base({dispatch, rootGetters}, payload) {
        let base = rootGetters['bases/getUserBase'];

        if (base.longitude === null || base.latitude === null) {
            dispatch("notifications/add", {
                type: 'warning',
                message: 'Vaše domovská jednotka nemá zadané souřadnice.'
            }, {root: true});
            return;
        }

        let routes = await routingApi.getRouting(
            payload.coordinates,
            [base.longitude, base.latitude],
            [
                {
                    'eventUid': payload.event.uid
                }
            ]
        );
        if (routes.length > 0)
            dispatch('addSingle', routes[0]);

    },
    async eventJob({dispatch, rootGetters}, payload) {

        let destinationCoordinates;

        let destination = rootGetters['eventJobs/destinationType'](payload.jobId);

        if (destination === 'event') {
            destinationCoordinates = rootGetters['events/destinationCoordinates'](payload.event.uuid);
        } else {
            let job = rootGetters['eventJobs/byId'](payload.jobId);
            let base = rootGetters['bases/byId'](job.baseId);
            destinationCoordinates = [base.longitude, base.latitude];
        }

        let routes = await routingApi.getRouting(
            payload.coordinates,
            destinationCoordinates,
            [
                {
                    'eventUid': payload.event.uid
                }
            ]
        );
        if (routes.length > 0)
            dispatch('addSingle', routes[0]);

    },

    addSingle({commit, dispatch}, payload) {
        commit("ADD_SINGLE", payload);
        if (state.timeout)
            clearTimeout(state.timeout);

        state.timeout = setTimeout(() => {
            dispatch('removeSingle')
        }, 1000 * 60)
    },

    removeSingle({commit}) {
        commit("REMOVE_SINGLE");
        state.timeout = null;
    },

    resetState({commit}) {
        commit('resetState');
    },
};

const mutations = {
    ADD_SINGLE(state, payload) {
        state.item = payload;
        state.items = [payload];
    },
    REMOVE_SINGLE(state) {
        state.item = null;
        state.items = [];
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
