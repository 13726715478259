<template>
    <div class="media">
        <v-overlay opacity="0.50" :value="loading">Nahrávám...</v-overlay>
        <template v-if="type === 'jpg'">
            <img
                    v-for="(image) in images"
                    :key="image.id"
                    :src="getMediaUrl('small')"
                    :id="'media-' + id"
                    class="image"
                    :data-media="media"
                    :data-event="eventId"
                    :data-message-id="messageId"
                    :data-src="getMediaUrl()"
                    @click="onClick()"
            >
        </template>
        <template v-if="type === 'pdf'">
          <a @click="downloadMedia">{{ media }}</a>
        </template>
        <div class="audio" v-if="type === 'mp3'">
            <audio controls :src="getMediaUrl()" v-play="playing" :autoplay="autoplay"></audio>
        </div>
        <div v-if="type === 'false'">
            Media není k dispozici
        </div>
    </div>
</template>

<script>

import app from "@/api/app";
import {appBus} from "@/main";
import {ON_MEDIA_SHOW} from "@/events";

export default {
    directives: {
        play: {
            bind(el, binding, vnode) {
                el.addEventListener('playing', () => {
                    vnode.context[binding.expression] = !el.paused;
                });
                el.addEventListener('pause', () => {
                    vnode.context[binding.expression] = !el.paused;
                });
                vnode.context[binding.expression] = !el.paused;
            }
        }
    },
    data() {
        return {
            loading: false,
            images: [
                {
                    id: 1,
                    media: this.media,
                    thumb: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
                    full: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
                    loaded: false,
                }
            ],
            playing: false,
            type: false,
            audio: null,
        }
    },
    props: {
        id: {
            type: Number,
        },
        eventId: Number,
        messageId: Number,
        messageType: String,
        messageCategoryId: Number,
        media: String,
        autoplay: {
            type: Boolean,
            default: false,
        },
    },
    created: function () {
        this.setMediaType();
        if (this.type === 'jpg') {
            // this.getFullMedia();
        } else if (this.type !== false) {
            this.getAudio();
        }
    },
    computed: {},
    methods: {
        getAudio() {
            app.getMedia(this.eventId, this.media).then((response) => {
                let reader = new FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = () => {
                    this.audio = reader.result;
                }
            }).catch(function (error) {
                window.console.error('error', error);
            });
        },
        getMediaUrl(size = null) {
            return app.getMediaUrl(this.eventId, this.media, size)
        },
        downloadMedia() {
            app.getMedia(this.eventId, this.media, 'large').then((response) => {
                let reader = new FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = () => {
                  // this.onVisit();
                  let link = document.createElement('a')
                  link.href = reader.result
                  link.download = this.media
                  document.body.appendChild(link);
                  link.click()
                  document.body.removeChild(link);
                }
            }).finally(() => {
                this.loading = false;
            }).catch(function (error) {
                window.console.error('error', error);
            });
        },
        setMediaType() {
            if (this.media)
                this.type = this.media.split('.').pop();
        },
        onClick() {
            appBus.$emit(ON_MEDIA_SHOW, this.media, this.eventId, this.messageCategoryId);
        },
    },
    // watch: {
    //     playing: function (value) {
    //         if (value)
    //             this.onVisit();
    //     },
    // }
}
</script>
