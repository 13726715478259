import {boundingExtent} from 'ol/extent';
import {transformExtent} from 'ol/proj';
import {find, forEach, isEmpty, pickBy} from "lodash";
import {uuid} from 'vue-uuid';
import addressApi from "../../../api/address";
import Vue from 'vue'
import TileGrid from 'ol/tilegrid/TileGrid.js';

const resolutions = [
    529.16772500211675,
    264.58386250105838,
    132.29193125052919,
    52.916772500211671,
    26.458386250105836,
    13.229193125052918,
    5.2916772500211673,
    2.6458386250105836,
    1.3229193125052918,
    0.52916772500211673,
    0.26458386250105836,
    // manual zooms
    0.105833545,
    0.048333418,
];

const tileGridFactory = () => {
    const size = 512
    return () => {
        return new TileGrid({
            extent: [-961337.79165191948, -1272298.7703143731, -392338.62928383797, -894487.03136110306],
            resolutions: resolutions,
            tileSize: [size, size]
        })
    }
};


const getDefaultState = () => {
    return {
        map: null,
        dataProjection: 'EPSG:4326',
        defaultProjection: 'EPSG:3857',
        viewProjection: 'EPSG:5514,EPSG:1623',
        center: [15.43, 50],
        zoom: 0,
        zoomTo: 8,
        minZoom: 0,
        maxZoom: 12,
        resolutions: resolutions,
        opacity: 1,
        extend: null,
        extraExtents: {},
        isAutoFocus: true,
        activeObject: false,
        originObject: false,
        points: [],
        drawnFeatures: {},
        searchHoverPoint: false,
        activeLayers: ['mask'],

        jpoAreasLayer: false,
        muvLayer: false,
        droneLayer: false,
        droneLayerLayers: [],
        droneLayerActiveLayer: "",

        baseLayerMenuItems: [
            {
                position: 2,
                disabled: false,
                layerId: 'secondary-layer',
                title: 'IZS-TereZa',
            },
            {
                position: 3,
                disabled: false,
                layerId: 'ortofoto-izs',
                title: 'ORTO',
            },
            {
                position: 4,
                disabled: false,
                layerId: 'orto-description',
                title: 'ORTO s popisem',
            },
            {
                position: 5,
                disabled: false,
                layerId: 'default-cuzk',
                title: 'Turistická',
            },
            {
                position: 6,
                disabled: false,
                unavailable: false,
                layerId: 'mapycz',
                title: 'Mapy.cz',
            },
        ],

        activeBaseLayer: 'secondary-layer',
        defaultBaseLayer: 'secondary-layer',

        layers: [
            {
                id: 'secondary-layer',
                layers: [
                    {
                        layerId: 'wmts-tereza',
                        opacity: 1,
                        zIndex: 1,
                    },
                ],
                settings: {
                    minZoom: 0,
                    maxZoom: 12,
                    maxTo: 'orto-description',
                },
            },
            {
                id: 'ortofoto-izs',
                layers: [
                    {
                        layerId: 'wms-cuzk-ortofoto',
                        opacity: 1,
                        zIndex: 1,
                    },
                ],
                settings: {
                    minZoom: 8,
                    maxZoom: 12,
                    minTo: 'secondary-layer',
                },
            },
            {
                id: 'default-cuzk',
                layers: [
                    {
                        layerId: 'wms-cuzk-default',
                        opacity: 1,
                        zIndex: 1,
                    },
                ],
                settings: {
                    minZoom: 0,
                    maxZoom: 10,
                    maxTo: 'orto-description',
                    minTo: 'secondary-layer',
                },
            },
            {
                id: 'orto-description',
                layers: [
                    {
                        layerId: 'wms-cuzk-ortofoto',
                        opacity: 1,
                        zIndex: 1,
                    },
                    {
                        layerId: 'wmts-tereza',
                        opacity: 0.35,
                        zIndex: 2,
                    },
                ],
                settings: {
                    minZoom: 8,
                    minTo: 'secondary-layer',
                    maxZoom: 12,
                },
            },
            {
                id: 'mapycz',
                layers: [
                    {
                        layerId: 'wmts-mapy-cz',
                        opacity: 1,
                        zIndex: 1,
                    },
                ],
                settings: {
                    minZoom: 0,
                    maxZoom: 10,
                    maxTo: 'orto-description',
                    minTo: 'secondary-layer',
                },
            },
        ],

        baseLayers: {
            'wmts-tereza': {
                id: 'wmts-tereza',
                title: 'IZS-TereZa',
                cmp: 'vl-layer-tile',
                visible: false,
                opacity: 1,
                zIndex: 1,
                source: {
                    // zDirection: 0,
                    cmp: 'vl-source-wmts',
                    url: 'https://train.tere-za.eu/mapproxy/wmts/hasici_prod/krovak_2_grid/{TileMatrix}/{TileCol}/{TileRow}.jpeg',
                    layerName: 'hasici_prod',
                    origin: [-37069593.709789924, 37072896.023036771],
                    tileSize: [256, 256],
                    resolutions: [
                        529.16772500211675,
                        264.58386250105838,
                        132.29193125052919,
                        52.916772500211671,
                        26.458386250105836,
                        13.229193125052918,
                        5.2916772500211673,
                        2.6458386250105836,
                        1.3229193125052918,
                        0.52916772500211673,
                        0.26458386250105836
                    ],
                    matrixIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    matrixSet: 'krovak_2_grid',
                    projection: 'EPSG:5514,EPSG:1623',
                    format: 'image/jpeg',
                    styleName: 'default',
                    requestEncoding: 'REST',
                    crossOrigin: 'Anonymous',
                    extent: [-961337.79165191948, -1272298.7703143731, -392338.62928383797, -894487.03136110306],
                },
            },
            'wms-orto-izs': {
                id: 'wms-orto-izs',
                title: 'Ortofoto SŽDC',
                cmp: 'vl-layer-tile',
                visible: false,
                opacity: 1,
                zIndex: 1,
                source: {
                    cmp: 'vl-source-tile-wms',
                    url: process.env.VUE_APP_STOPA_PROXY_URL + '/mapproxy.php?url=' + 'https://ispdmapy.szdc.cz:8443/geoserver/gwc/service/wms/',
                    layers: 'ispd:viewM12_actual',
                    version: '1.1.1',
                    projection: 'EPSG:102067',
                    format: 'image/png',
                    params: {
                        'TILED': true,
                    },
                    tileGridFactory: tileGridFactory(),
                    crossOrigin: 'Anonymous',
                },
            },
            'wms-cuzk-ortofoto': {
                id: 'wms-cuzk-ortofoto',
                title: 'Ortofoto CUZK',
                cmp: 'vl-layer-tile',
                visible: false,
                zIndex: 1,
                source: {
                    cmp: 'vl-source-wms',
                    url: 'http://geoportal.cuzk.cz/WMS_ORTOFOTO_PUB/WMService.aspx',
                    layers: 'GR_ORTFOTORGB',
                    styles: 'default',
                    projection: 'EPSG:5514,EPSG:1623',
                    format: 'image/jpeg',
                    crossOrigin: 'Anonymous',
                },
            },
            'wms-cuzk-default': {
                id: 'wms-cuzk-default',
                title: 'Turistická CUZK',
                cmp: 'vl-layer-tile',
                visible: false,
                opacity: 1,
                zIndex: 1,
                source: {
                    cmp: 'vl-source-tile-wms',
                    url: 'https://geoportal.cuzk.cz/wms_zm_tisk/wmservice.aspx',
                    layers: 'ZM',
                    styles: 'default',
                    projection: 'EPSG:5514,EPSG:1623',
                    format: 'image/jpeg',
                    crossOrigin: 'Anonymous',
                },
            },
            'wmts-mapy-cz': {
                id: 'wmts-mapy-cz',
                title: 'Mapy.cz',
                cmp: 'vl-layer-tile',
                visible: false,
                opacity: 1,
                source: {
                    cmp: 'vl-source-xyz',
                    attributions: '<a href="http://mapy.cz/" target="_blank"><img src="https://api.mapy.cz/img/api/logo.svg" ></a>',
                    attributionsCollapsible: false,
                    url: 'https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=' + process.env.VUE_APP_MAPYCZ_API_KEY,
                    crossOrigin: 'Anonymous',
                },
            },
        },

        droneLayerWms: {
            id: 'dron-event',
            title: 'Dron',
            cmp: 'vl-layer-tile',
            visible: true,
            opacity: 1,
            source: {
                cmp: 'vl-source-tile-wms',
                attributions: null,
                attributionsCollapsible: false,
                url: process.env.VUE_APP_STOPA_PROXY_URL + '/mapproxy.php?url=' + 'https://mapy.tudc.cz/wms/hromada',
                projection: 'EPSG:5514',
                format: 'image/png',
                crossOrigin: 'Anonymous',
            },
        },
    }
};

const state = getDefaultState();

const getters = {
    activeBaseLayer: (state) => {
        return find(state.layers, {'id': state.activeBaseLayer});
    },
    activeBaseLayerMenuItem: (state, getters) => {
        let activeLayer = getters['activeBaseLayer'];
        return find(state.baseLayerMenuItems, {layerId: activeLayer.id});
    },
    isActiveObject: state => {
        return state.activeObject !== false;
    },
    isPoints: state => {
        return !isEmpty(state.points);
    },
    isOriginObject: state => {
        return state.originObject !== false;
    },
    features: state => {
        let items = [];
        forEach(state.drawnFeatures, (item, key) => {
            item.feature.properties.key = key;
            items.push(item.feature);
        });
        return items;
    },
    editFeatures: state => {
        return pickBy(state.drawnFeatures, function (item) {
            return item.edit === true;
        });
    }

};

const actions = {
    setSearchHoverPoint: (context, item) => {
        item = item === null ? false : item;
        context.commit("setSearchHoverPoint", item);
    },
    clearOriginObject: (context) => {
        context.commit("setOriginObject", false);
    },
    extendTo: ({state, commit}, coordinates) => {
        let coords = [];
        coords.push(coordinates);
        let ext = boundingExtent(coords);
        ext = transformExtent(ext, state.dataProjection, state.viewProjection);
        commit("setExtend", ext);
    },
    onZoom: ({getters, dispatch}, level) => {
        let layer = getters['activeBaseLayer'];

        if (level > layer.settings.maxZoom)
            dispatch('setBaseLayer', layer.settings.maxTo);

        if (level < layer.settings.minZoom)
            dispatch('setBaseLayer', layer.settings.minTo);
    },
    extendToArray: ({state, commit}, coordinates) => {
        if (!isEmpty(coordinates)) {
            let ext = boundingExtent(coordinates);
            ext = transformExtent(ext, state.dataProjection, state.viewProjection);
            commit("setExtend", ext);
        }
    },
    calculatePointsExtend: ({state, commit}) => {
        if (!isEmpty(state.points)) {
            let ext = boundingExtent(state.points.map(item => item.coordinates));
            ext = transformExtent(ext, state.dataProjection, state.viewProjection);
            commit("setExtend", ext);
        }
    },
    addDrawnFeature: ({commit, rootState}, feature) => {
        let timestamp = ~~(Date.now() / 1000);
        let uuid5Name = rootState.auth.user.baseId.toString() + timestamp.toString();
        let key = uuid.v5(uuid5Name, rootState.setup.setup.uuid);

        let item = {
            edit: true,
            title: '',
            feature: feature,
        };
        commit("addDrawnFeature", {key: key, item: item});
    },
    updateDrawnFeature: ({commit}, payload) => {
        commit("setDrawnFeature", payload);
    },
    setEditDrawnFeature: ({commit}, key) => {
        commit("setEditDrawnFeature", key);
    },
    updateDrawnFeatures: ({commit}, items) => {
        commit("setDrawnFeatures", items);
    },
    setDefaultBaseLayer: ({state, dispatch}) => {
        dispatch("setBaseLayer", state.defaultBaseLayer);
    },
    setBaseLayer: ({state, commit, getters, dispatch}, key) => {

        let targetMenuItem = find(state.baseLayerMenuItems, {'layerId': key})
        if (targetMenuItem?.unavailable === true) {
            dispatch("notifications/add", {
                type: 'warning',
                message: "Litujeme, ale tato volba je momentálně nedostupná."
            }, {root: true});
            return;
        }

        let currentLayer = getters['activeBaseLayer'];
        let targetLayer = find(state.layers, {'id': key})

        if (currentLayer != null) {
            commit("hideBaseLayers", currentLayer.layers);
        }
        commit("showBaseLayers", targetLayer);
        commit("setActiveBaseLayer", key);
    },
    addPointFromCoordinates: ({commit}, coordinates) => {
        let key = uuid.v1();
        let point = {
            uuid: key,
            title: 'načítám...',
            type_code: 'custom',
            type: 'point',
            base_title: null,
            base_id: null,
            coordinates: coordinates,
        };

        addressApi.reverse(coordinates).then((response) => {
            commit('updatePoint', {
                key: key,
                item: {
                    title: response.address.title,
                    base_title: response.address.jpo_name,
                    base_id: response.address.jpo_id,
                }
            });
        });
        commit("addPoint", point);
    },
    removePoint: ({commit, state}, uuid) => {
        commit("removePoint", uuid);
        if (state.points.length === 0) {
            commit("setAutoFocus", true);
        } else {
            commit("setAutoFocus", false);
        }
    },
    removeAllPoints: ({commit}) => {
        commit("removeAllPoints");
        commit("setAutoFocus", true);
    },
    setAutoFocus: ({commit}, value) => {
        commit("setAutoFocus", value);
    },
    toggleAutoFocus: ({commit, state}) => {
        commit("setAutoFocus", !state.isAutoFocus);
    },
    resetState({commit}) {
        commit('resetState');
    },
};

const mutations = {
    setMap: (state, map) => {
        state.map = map;
    },
    clearExtraExtents: (state) => {
        Vue.set(state, 'extraExtents', {});
    },
    setExtraExtents: (state, payload) => {
        Vue.set(state.extraExtents, payload.key, payload.values);
    },
    setCenter: (state, coordinates) => {
        state.center = coordinates;
    },
    setOpacity: (state, value) => {
        state.opacity = value;
    },
    setZoom: (state, zoom) => {
        if (zoom >= state.minZoom && zoom <= state.maxZoom)
            state.zoom = zoom;
    },
    setSearchHoverPoint: (state, item) => {
        state.searchHoverPoint = item;
    },
    setActiveObject: (state, item) => {
        state.activeObect = item;
    },
    setOriginObject: (state, item) => {
        state.originObject = item;
    },
    setActiveBaseLayer: (state, key) => {
        state.activeBaseLayer = key;
    },
    addDrawnFeature: (state, payload) => {
        Vue.set(state.drawnFeatures, payload.key, payload.item);
    },
    setDrawnFeature: (state, payload) => {
        forEach(payload.item, (value, key) => {
            state.drawnFeatures[payload.key][key] = value;
        });
        state.drawnFeatures[payload.key].edit = false;
    },
    setEditDrawnFeature: (state, key) => {
        state.drawnFeatures[key].edit = true;
    },
    setDrawnFeatures: (state, items) => {
        Vue.set(state, 'drawnFeatures', items);
    },
    removeDrawnFeature: (state, key) => {
        Vue.delete(state.drawnFeatures, key);
    },
    setExtend: (state, extend) => {
        state.extend = extend;
    },
    showBaseLayer: (state, key) => {
        state.baseLayers[key].visible = true;
    },
    showBaseLayers: (state, layer) => {
        forEach(layer.layers, (value) => {
            state.baseLayers[value.layerId].opacity = value.opacity;
            state.baseLayers[value.layerId].zIndex = value.zIndex;
            state.baseLayers[value.layerId].visible = true;
        });
    },
    hideBaseLayers: (state, layers) => {
        forEach(layers, (layer) => {
            state.baseLayers[layer.layerId].visible = false;
        });
    },
    addPoint: (state, item) => {
        if (state.points.length === 0) {
            state.originObject = {
                type: "point",
                title: "Origin point",
                coordinates: item.coordinates
            };
        }
        state.points.push(item);
    },
    updatePoint(state, payload) {
        let index = state.points.findIndex(item => item.uuid === payload.key);
        forEach(payload.item, (value, key) => {
            state.points[index][key] = value;
        });
    },
    removePoint: (state, uuid) => {
        if (state.points.length > 0)
            state.originObject = false;

        state.points.splice(state.points.findIndex(item => item.uuid === uuid), 1);
    },
    removeAllPoints: (state) => {
        state.originObject = false;
        state.points = [];
    },
    setAutoFocus: (state, status) => {
        state.isAutoFocus = status;
    },
    setJpoAreas: (state, status) => {
        state.jpoAreasLayer = status;
    },
    setMuvLayer: (state, status) => {
        state.muvLayer = status;
    },
    setDroneLayer: (state, value) => {
        state.droneLayer = value;
    },
    setDroneLayerActiveLayer: (state, layer) => {
        state.droneLayerActiveLayer = layer;
    },
    setDroneLayerLayers: (state, layers) => {
        state.droneLayerLayers = layers;
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
