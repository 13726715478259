<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
            v-bind="$attrs"
            :class="options.class"
            @click="click"
            v-on="on"
        >
          <v-icon left>{{ options.icon }}</v-icon>
          <slot></slot>
        </v-btn>
      </template>
      {{ options.tooltip }}
    </v-tooltip>
    <confirm-dialog ref="confirm" type="delete"></confirm-dialog>
  </div>
</template>
<script>

export default {
  name: "ConfirmButton",
  components: {
    ConfirmDialog: () => import("@/components/ui/ConfirmDialog"),
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    async click(e) {
      if (this.options.confirm) {
        if (
            await this.$refs.confirm.open(null, this.options.confirm)
        ) {
          this.$emit("click", e);
        }
      } else {
        this.$emit("click", e);
      }
    }
  },
};
</script>