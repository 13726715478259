<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-container class="primary" fluid fill-height>
        <v-layout flex align-center justify-center>
            <v-flex xs12 sm4 elevation-6>
                <v-card :loading="loading" :disabled="loading">
                    <v-toolbar dark>
                        <v-toolbar-title>Ověření stavu přihlášení uživatele
                            <v-chip v-if="env!=='production'" color="warning" class="mr-1">{{ env }}</v-chip>
                        </v-toolbar-title>
                        <div class="flex-grow-1"></div>
                    </v-toolbar>
                    <v-card-text class="pt-6">
                        <div>Uživatel: <strong>{{ user.firstName }} {{ user.lastName }}</strong></div>
                      <div v-if="hasDevices">Pracoviště/zařízení: <strong>{{ devices[0].code }} - {{ devices[0].name }}</strong></div>
                        <div v-if="!hasDevices">
                            {{ noDevicesMessages }}
                        </div>
                        <v-card-actions>
                            <v-layout justify-space-between>
                                <v-btn @click="onSubmit" i-if="hasDevices"  color="primary">Potvrdit</v-btn>
                                <v-btn @click="logout" color="text">Odhlásit</v-btn>
                            </v-layout>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
    name: 'panel',
    mixins: [validationMixin],
    data() {
        return {
            env: process.env.VUE_APP_ENV,
            loading: false,
            show: true,
            sound: null,
            form: {
                username: null,
                password: null,
            },
        }
    },
    mounted() {
        let url = '/assets/sounds/login.mp3';
        let audio = new Audio();
        audio.autoplay = false;
        audio.src = url;
        this.sound = audio;

        if (this.env === 'local')
            this.onSubmit();
    },
    validations: {
        form: {
            username: {
                required,
            },
            password: {
                required,
            }
        }
    },
    computed: {
        ...mapState({
            setup: state => state.setup.setup,
            user: state => state.auth.user,
            devices: state => state.auth.devices,
            device: state => state.auth.device,
        }),
        ...mapGetters('auth', {
            hasDevices: 'hasDevices',
        }),
        noDevicesMessages() {
            if (this.devices === undefined) {
                return "Prosím o opětovné přihlíšení, pro načtení zařízení."
            } else if (!this.hasDevices) {
                return "K tomuto uživateli není přiřazano žádné zařízeni."
            } else {
                return "";
            }
        }
    },
    methods: {
        onSubmit() {
            this.loading = true;

            this.$store.dispatch('auth/setDevice', this.devices[0])
                .then(() => {
                    this.sound.play();
                    this.$noty.success("Přihlášen jako: " + this.user.firstName + ' ' + this.user.lastName + '<br />zařízení: ' + this.device.code + ' - ' + this.device.name);
                }, (error) => {
                    this.$noty.error('' + error);
                }).finally(() => {
                    this.loading = false;
                }
            );
        },
        logout() {
            this.$store.dispatch('auth/logout')
        }
    }
}
</script>
