<template>
    <v-card flat dark :color="eventCategory.color" v-if="event !== undefined" class="ma-2">
        <v-card-text class="white--text" v-if="isValid">
            <v-row>
                <v-col sm="2">
                    <v-icon size="90">{{ eventCategory.icon }}</v-icon>
                </v-col>
                <v-col sm="7">
                    <div>Odesláno: {{ event.startedAt | moment("DD.MM.YYYY HH:mm:ss")}}</div>
                    <div :class="event.alert.title.class">{{ event.alert.title.value }}</div>
                    <div :class="event.alert.subtitle.class" v-linkified v-html="event.alert.subtitle.value"></div>
                    <div :class="event.alert.description.class" v-linkified v-html="event.alert.description.value"></div>
                </v-col>
                <v-col class="text-xs-center pl-4" sm="3">
                    <div class="text-h6">{{ base.name }}</div>
                    <v-btn large color="secondary" @click="confirm">Přijmout zprávu</v-btn>
                    <div class="caption pt-1">Vystaveno upozornění:<br /> {{ event.createdAt | moment("DD.MM.YYYY HH:mm:ss")}}</div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

    export default {
        name: 'event-alert',
        props: {
            notification: {
                type: Object,
            },
        },
        computed: {
            ...mapState({
                event(state) {
                    if (this.notification.event) {
                        return this.notification.event;
                    } else {
                        return state.events.local[this.notification.eventKey];    
                    }
                },
                eventCategory(state) {
                    return state.eventCategories.items[this.event.eventCategoryId]
                },
            }),
            ...mapGetters('bases', {
                baseById: 'byId',
            }),
            base() {
              return this.baseById(this.event.baseId);
            }
        },
        methods: {
            isValid() {
              return this.event.alert && this.event.alert.title;
            },
            ...mapActions('events', [
                'setActiveEvent',
                'save',
            ]),
            confirm() {
                if (this.notification.event === null)
                    this.setActiveEvent(this.event.uuid);

                if (this.event.eventStateId === 0)
                    this.save({key: this.event.uuid, event: {id: this.event.id, eventStateId: 1}});

                this.$store.commit('notifications/remove', this.notification.key);
                this.$store.commit('notifications/dialogClose');
            },
        },
        watch: {},
    }
</script>
