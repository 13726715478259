export const stateMixin = {
    methods: {
        getState() {
            return JSON.stringify(this.$store.state);
        },
        getStateBlob() {
            let state = this.getState();
            return new Blob([state], {type: 'application/json'});
        },
        downloadState() {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(this.getStateBlob());
            link.download = 'stateJson';
            link.click();
            URL.revokeObjectURL(link.href)
        },
        importState() {
            const file = this.jsonFile;
            const reader = new FileReader();
            reader.onload = e => this.startImport(e.target.result);
            reader.readAsText(file);
        },
        startImport(state) {
            let jsonState = JSON.parse(state);
            jsonState.setup.autoRefresh = false;
            jsonState.setup.devPanel.visible = true;
            window.indexedDB.deleteDatabase('panel');
            this.$store.dispatch('app/replaceState', jsonState);
            this.$pubnub.unsubscribeAll();
            this.importDialog = false;
        },
    }
};
