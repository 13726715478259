module.exports = {

    closestContextElement: (el) => {
        while (!el?.dataset?.context) {
            el = el.parentNode
            if (!el) {
                return null
            }
        }
        return el
    },

    shift: (date = date.now()) => {

        date = Math.round(date / 1000)

        let shifts = ['A', 'B', 'C'];
        let from = Math.round(new Date(2011, 0, 1).getTime() / 1000);
        let days = Math.ceil((date - (from)) / (60 * 60 * 24));
        let shift = days % 3;
        return shifts[shift];
    }
};
