<template>
  <fragment>
    <li @click="setCoordinates">
      <v-icon left color="green">phone</v-icon>
      Myslivci
    </li>
  </fragment>
</template>

<script>
export default {
  name: "hunting-region-context-menu",
  props: ['data'],
  methods: {
    setCoordinates() {
      this.$store.dispatch('huntingRegions/setCoordinates', this.data.coordinates);
    },
  }
}
</script>
