import isEmpty from "lodash/isEmpty";

const getDefaultState = () => {
    return {
        enable: false,
        coordinates: [],
        userCoordinates: [],
        icon: "http://api.mapy.cz/img/api/marker/eye.png",
        angle: 0,
        anchor: [0.46, 0.62],
    }
};

const state = getDefaultState();

const getters = {
    showPoint: state => {
        return !isEmpty(state.coordinates) && state.enable;
    },
    isEnabled: state => {
        return state.enable;
    },
};

const actions = {
    setUserCoordinates: ({state, commit}, coords) => {
        commit('setCoordinates', []);
        commit('setUserCoordinates', coords);

        if (!state.enable)
            commit('setStatus', true);
    },
    close: ({commit}) => {
        commit('setCoordinates', []);
        commit('setAngle', null);
        commit('setStatus', false);
    },
    resetState({commit}) {
        commit('resetState');
    },
};

const mutations = {
    setUserCoordinates: (state, coordinates) => {
        state.userCoordinates = coordinates;
    },
    setCoordinates: (state, coordinates) => {
        state.coordinates = coordinates;
    },
    setAngle: (state, angle) => {
        state.angle = angle;
    },
    setStatus: (state, status) => {
        state.enable = status;
    },
    setMarker: (state, marker) => {
        state.marker = marker;
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
