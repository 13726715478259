<template>
    <div v-if="isMobile">
        <input ref="uploader" type="file" accept="image/*" capture="camera" @change="onFileChanged" class="d-none">
        <v-tooltip top min-width="140">
            <template v-slot:activator="{ on }">
                <v-btn fab fixed bottom right light color="yellow" :loading="isSelecting" v-on="on"
                       @click="handleFileImport" class="mr-16">
                    <v-icon large>photo_camera</v-icon>
                </v-btn>
            </template>
            <span>Pořídit fotografii</span>
        </v-tooltip>
        <file-upload v-show="0"
                     class=""
                     :post-action="upload.postAction"
                     :extensions="upload.extensions"
                     :accept="upload.accept"
                     :multiple="upload.multiple"
                     :headers="upload.headers"
                     :size="upload.size"
                     :drop="upload.drop"
                     v-model="files"
                     @input-filter="inputFilter"
                     @input-file="inputFile"
                     ref="upload">
        </file-upload>
    </div>
</template>

<script>

import ImageBlobReduce from "image-blob-reduce";

const reduce = new ImageBlobReduce();
import {mapGetters, mapState} from "vuex";
import {forEach, isEmpty} from "lodash";
import moment from "moment/moment";
import FileUpload from "vue-upload-component";

export default {
    components: {
        FileUpload,
    },
    props: {
        messageCategoryId: {
            type: Number,
            default: 2,
        }
    },
    data() {
        return {
            isSelecting: false,
            selectedFile: null,
            visible: false,
            photos: [],
            sending: false,
            files: [],
            messageType: 'X22',
        };
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
            user: state => state.auth.user,
            device: state => state.auth.device,
            apiUrl: state => state.tereza.apiUrl,
        }),
        ...mapGetters('events', {
            isActiveEvent: 'isActiveEvent',
            event: 'getActiveEvent',
        }),
        ...mapGetters('eventTypes', {
            getEventType: 'get',
        }),
        canCancel() {
            return this.capturing === false && this.sending === false;
        },
        canSend() {
            return this.capturing === false && this.sending === false;
        },
        upload() {
            return {
                accept: 'image/jpeg',
                extensions: 'jpg',
                headers: {
                    'Authorization': this.$store.getters['auth/token'],
                },
            }
        },
    },
    methods: {
        handleFileImport() {
            this.selectedFile = null;
            this.sending = false;
            this.isSelecting = true;
            window.addEventListener('focus', () => {
                this.isSelecting = false
            }, {once: true});

            this.$refs.uploader.click();
        },
        onFileChanged(e) {
            if (e.target.files) {
                this.selectedFile = e.target.files[0];
                this.sendPhoto();
            }
        },
        open() {
            this.visible = true;
        },
        close() {
            this.visible = false;
        },
        sendPhoto() {
            this.sending = true;
            this.$watch(
                () => {
                    return this.$refs.upload.uploaded
                },
                (val) => {
                    if (val === true)
                        this.sendMessage();
                }
            )

            this.uploadImage();
        },
        uploadImage() {
            reduce
                .toBlob(this.selectedFile, {max: 1900})
                .then(blob => {
                    let file = new window.File([blob], 'capture.jpg', {
                        type: blob.type,
                        headers: {
                            'X-File-Size-Total': blob.size
                        }
                    });
                    this.$refs.upload.add(file);
                });
        },
        sendMessage() {
            this.sending = true;
            let message = {
                content: '',
                messageCategoryId: this.messageCategoryId,
                type: this.messageType,
                media: this.getFiles(),
            };
            this.$store.dispatch('messages/send', message).then(() => {
                this.sending = false;
                this.$emit('onSend', message);
                this.close();
            });
        },
        isFiles() {
            return !isEmpty(this.files);
        },
        getFiles() {
            if (this.isFiles()) {
                let files = [];
                forEach(this.files, function (file) {
                    files.push(file.filename);
                });
                return files;
            } else {
                return null
            }
        },
        inputFilter(newFile, oldFile) {

            // if (newFile && newFile.file && newFile.type.substr(0, 6) === 'image/' && !oldFile) {
            //     // Set an error to prevent being uploaded
            //     newFile.error = 'compressor'
            //     // new ImageCompressor(newFile.file, {
            //     //     quality: .2,
            //     //     width: 20,
            //     //     height: 20,
            //     //     success: (result) => {
            //     //         // Successfully deleted the error to write the new file
            //     //         this.$refs.upload.update(newFile, { file: result, error: '', size: result.size, type: result.type })
            //     //     },
            //     //     error: (e) => {
            //     //         this.$refs.upload.update(newFile, { error: e.message })
            //     //     },
            //     // });
            // }

            if (newFile && !oldFile) {
                let extension = newFile.name.split('.').pop();
                newFile.filename = this.event.id + '-' + moment().format('YYYYMMDD_HHmmss') + '-' + this.device.code + '.' + extension;
                newFile.headers['X-File-Size-Total'] = newFile.size;
                newFile.postAction = this.apiUrl + '/v2/media/event/' + this.event.id + '/' + newFile.filename;
            }
        },
        inputFile() {
            if (!this.$refs.upload.active) {
                this.$refs.upload.active = true
            }
        },
    },
};
</script>
