<template>
    <div>
        <vl-layer-vector  ref="vectorLayer" :opacity="isActiveEvent?0.5:1" :visible="!screenShotActive" :zIndex="101">
            <vl-source-vector :features="features">
                <vl-style-func :function="styleFunc"/>
            </vl-source-vector>
        </vl-layer-vector>
        <vl-layer-vector v-if="isActiveEvent" :visible="!screenShotActive" :zIndex="102">
            <vl-source-vector :features="activeEventFeatures">
                <vl-style-func :function="styleFunc"/>
            </vl-source-vector>
        </vl-layer-vector>
    </div>
</template>

<script>
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import Text from 'ol/style/Text'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import {mapGetters, mapState} from "vuex";
import {isEmpty} from "lodash";

export default {
    name: 'layer-events-jobs',
    props: {
        screenShotActive: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        ...mapState({
            activeEventUuid: state => state.events.active,
        }),
        ...mapGetters('bases', {
            getBaseCodeById: 'getBaseCodeById',
        }),
        ...mapGetters('eventJobs', {
            eventJobs: 'jobs',
            jobs: 'recentJobs',
            label: 'label',
            isOnTheSpot: 'isOnTheSpot',
            eventJobOptions: 'optionsByJob',
        }),
        ...mapGetters('resourceTypes', {
            resourceTypeById: 'byId',
        }),
        ...mapGetters('events', {
            events: 'getFilteredEvents',
            isActiveEvent: 'isActiveEvent',
            activeEvent: 'getActiveEvent',
        }),
        activeEventFeatures() {
            const activeJobs = this.eventJobs(this.activeEventUuid)
            return this.eventToGeoJson(activeJobs)
        },
        features() {
            const geoJson = this.eventToGeoJson(this.jobs);
            if (isEmpty(geoJson))
                this.$refs.vectorLayer.refresh();
            return geoJson;
        },
    },
    methods: {
        eventToGeoJson(jobs) {
            const geoJson = Object.values(jobs)
                .filter(job => this.coordinates(job))
                .map((job, index) => {
                    const coordinates = this.coordinates(job);
                    const label = this.label(job);
                    const options = this.eventJobOptions(job);
                    const resource = this.$store.state.resources.items[job.resourceId]
                    const resourceType = this.resourceTypeById(resource.resourceTypeId)

                    return {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: coordinates,
                        },
                        properties: {
                            type: 'eventJob',
                            id: job.id,
                            eventKey: job.eventUuid,
                            options: options,
                            label: label,
                            text: this.getBaseCodeById(job.baseId),
                            template: this.template(resource, resourceType),
                            icon: this.icon(resource, resourceType),
                            tooltipAction: 'eventJobs/tooltipByKey',
                            zIndex: index,
                        }
                    }
                });
            return geoJson;
        },
        template(resource, resourceType) {
            if (resource.resourceTypeId === 1)
                return 1;

            return parseInt(resourceType.options?.resourceTemplateId);
        },
        icon(resource, resourceType) {
            if (resourceType?.options?.icon) {
                return this.$store.state.setup.endpoints.imageProxyUrl + process.env.VUE_APP_TEREZA_API_URL + resourceType.options.icon.file;
            } else {
                if (resource && resource.icon)
                    return this.$store.state.resources.icons[resource.icon];
            }
            return 'x';
        },
        coordinates(job) {
            if (job?.resourceId) {
                const resource = this.$store.state.resources.items[job.resourceId]
                return resource?.pnt?.longitude && resource?.pnt?.latitude ? [parseFloat(resource.pnt.longitude), parseFloat(resource.pnt.latitude)] : false;
            }
            return false;
        },
        styleFunc(feature) {
            const markerHeight = 100;
            const icon = feature.get('icon');
            const label = feature.get('label');
            const options = feature.get('options');
            const template = feature.get('template');
            const scale = 0.5;
            const style = [];
            // const zindex = parseFloat(feature.getGeometry().getCoordinates()[0])
            const zIndex = feature.get('zIndex')

            if (label?.content) {
                const labelStyle =
                    new Style({
                        text: new Text({
                            text: label.content,
                            font: 'bold 11px Arial, Verdana, Helvetica, sans-serif',
                            offsetY: -1 * ((markerHeight / 100) * 98) * scale,
                            placement: 'point',
                            textBaseline: 'bottom',
                            textAlign: 'center',
                            scale: scale * 2.5,
                            stroke: new Stroke({
                                width: 3,
                                color: "white",
                            }),
                            fill: new Fill({
                                color: '#000000'
                            })
                        }),
                    })
                labelStyle.setZIndex(zIndex);
                style.push(labelStyle);
            }

            const markerStyle = new Style({
                image: new Icon({
                    src: './assets/icons/map/marker-job-' + template + '.svg',
                    crossOrigin: 'anonymous',
                    anchor: [0.5, 1],
                    color: options.background,
                    scale: scale
                }),
            });
            markerStyle.setZIndex(zIndex);
            style.push(markerStyle);

            if (icon) {
                const iconStyle = new Style({
                    image: new Icon({
                        crossOrigin: "Anonymous",
                        src: icon,
                        anchor: [0.49, 1.08],
                        color: options.content,
                        scale: scale * 2,
                    }),
                });
                iconStyle.setZIndex(zIndex);
                style.push(iconStyle);
            }

            const textStyle = new Style({
                text: new Text({
                    text: '' + feature.get('text'),
                    offsetY: -1 * ((markerHeight / 100) * 75) * scale,
                    placement: 'point',
                    textBaseline: 'middle',
                    textAlign: 'center',
                    scale: scale * 2.4,
                    fill: new Fill({
                        color: options.content
                    })
                }),
            })
            textStyle.setZIndex(zIndex);
            style.push(textStyle);

            return style;
        },
    },
}
</script>
