import {appBus} from "@/main";
import {ON_ACTIVE_EVENT_BLOCK, ON_ACTIVE_EVENT_UNBLOCK} from "@/events";

const getDefaultState = () => {
    return {
        status: 0,
        eventUuid: null,
        coordinates: [null, null],
        processed: false,
    }
};

const state = getDefaultState();

const getters = {
    isEnabled: state => {
        return state.status > 0;
    },
    tooltip: (state, getters, rootState, rootGetters) => (event) => {
        return {
            title: 'Místo dojezdu techniky',
            content: rootGetters['events/tooltipContent'](event),
        };
    },
};

const actions = {
    start: ({commit, rootGetters}, coords) => {
        appBus.$emit(ON_ACTIVE_EVENT_BLOCK);
        let event = rootGetters['events/getActiveEvent'];
        commit('resetState');
        commit('setCoordinates', coords);
        commit('setEventKey', event.uuid);
        commit('setStatus', 1);
    },
    end: ({commit}) => {
        appBus.$emit(ON_ACTIVE_EVENT_UNBLOCK);
        commit('resetState');
    },
    save: ({commit, state, dispatch, rootGetters}) => {

        commit('setProcessed', true);

        let event = rootGetters['events/getActiveEvent'];
        let action = 'events/updateAttributes';
        let payload = {
            key: event.uuid,
            item: {
                destinationLongitude: state.coordinates[0],
                destinationLatitude: state.coordinates[1]
            }
        }

        if (event.id > 0) {
            action = 'events/save';
            payload.event = {
                uuid: event.uuid,
                id: event.id,
                destinationLongitude: state.coordinates[0],
                destinationLatitude: state.coordinates[1]
            };
        }

        dispatch(action, payload, {root: true}).then(() => {
            dispatch('notifications/add', {
                type: 'success',
                message: 'Místo dojezdu techniky bylo nastaveno'
            }, {root: true});
            dispatch('end');
        });
    },
    remove: ({commit, dispatch, rootGetters}) => {
        commit('setProcessed', true);

        let event = rootGetters['events/getActiveEvent'];

        if (event.destinationLatitude === null && event.destinationLongitude === null) {
            dispatch('end');
            return;
        }

        // Todo: make one save attributes function
        if (event.id) {
            dispatch('events/update', {
                key: event.uuid,
                item: {
                    id: event.id,
                    destinationLongitude: null,
                    destinationLatitude: null
                },
            }, {root: true}).then(() => {
                dispatch('notifications/add', {
                    type: 'success',
                    message: 'Místo dojezdu techniky bylo odebráno'
                }, {root: true});
                dispatch('end');
            });
        } else {
            dispatch('events/updateAttributes', {
                key: event.uuid,
                payload: {
                    destinationLongitude: null,
                    destinationLatitude: null
                },
            }, {root: true}).then(() => {
                dispatch('notifications/add', {
                    type: 'success',
                    message: 'Místo dojezdu techniky bylo odebráno'
                }, {root: true});
                dispatch('end');
            });
        }
    },
    resetState({commit}) {
        commit('resetState');
    },
};

const mutations = {
    setCoordinates: (state, coordinates) => {
        state.coordinates = coordinates;
    },
    setEventKey: (state, key) => {
        state.eventUuid = key;
    },
    setProcessed: (state, value) => {
        state.processed = value;
    },
    setStatus: (state, status) => {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
