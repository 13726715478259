<template>
    <div>
        <vl-layer-vector :zIndex="101">
            <vl-source-vector :features.sync="features" ident="railImagePoint">
                <vl-feature :properties="{type: 'railImage'}">
                    <vl-geom-point :coordinates="object.coordinates"></vl-geom-point>
                    <vl-style-func :factory="styleFuncProp"></vl-style-func>
                </vl-feature>
            </vl-source-vector>
        </vl-layer-vector>
        <vl-interaction-translate
                v-if="features !== undefined && features.length > 0"
                source="railImagePoint"
                @translatestart="beginTranslate"
                @translateend="endTranslate"
        >
        </vl-interaction-translate>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import Style from 'ol/style/Style'
    import Icon from 'ol/style/Icon'
    import Text from 'ol/style/Text'

    export default {
        methods: {
            beginTranslate(event) {
                this.offsetLon = this.features[0].geometry.coordinates[0] - event.coordinate[0];
                this.offsetLat = this.features[0].geometry.coordinates[1] - event.coordinate[1];
            },
            endTranslate(event) {
                const coordinates = [event.coordinate[0] + this.offsetLon, event.coordinate[1] + this.offsetLat];
                this.$store.dispatch('railImages/setUserCoordinates', coordinates);
            },
        },
        data() {
            return {
                offsetLat: 0,
                offsetLon: 0,
                features: undefined,
            }
        },
        computed: {
            ...mapState({
                object: state => state.railImages,
            }),
            ...mapGetters('railImages', {
                visible: 'visible',
            }),
            styleFuncProp() {
                return () => {
                    return () => {

                        let scale = 1;
                        let markerHeight = 48;

                        return [
                            new Style({
                                image: new Icon({
                                    src: './assets/icons/map/marker.png',
                                    crossOrigin: 'anonymous',
                                    anchorXUnits: 'fraction',
                                    anchorYUnits: 'fraction',
                                    anchor: [0.5, 1],
                                }),
                                text: new Text({
                                    text: 'I',
                                    offsetY: -1 * ((markerHeight / 100) * 63) * scale,
                                    placement: 'point',
                                    textBaseline: 'middle',
                                    textAlign: 'center',
                                    scale: scale,
                                }),
                            })
                        ];
                    }
                }
            },
        },
    }
</script>
