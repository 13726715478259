<template>
    <v-tooltip left content-class="map-tooltip-wrapper">
        <template v-slot:activator="{ on }">
            <div v-on="on" class="event-job-icon" :class="'category-'+template"
                 :style="{color: color.content, backgroundColor: color.background}">
                {{ baseCode }}<br/>
                <div v-if="iconType === 1"
                     :style="{maskImage: 'url(' + icon + ')', backgroundColor: color.content}"></div>
                <v-icon v-if="iconType === 0" :color="color.content" size="30" left>{{ icon }}</v-icon>
            </div>
        </template>
        <tooltip-content :tooltip="tooltip(job)"></tooltip-content>
    </v-tooltip>

</template>

<script>
import {mapGetters, mapState} from 'vuex'
import TooltipContent from "@/components/ui/TooltipContent.vue";

export default {
    name: 'event-job-icon',
    components: {TooltipContent},
    props: {
        resource: Object,
        job: Object,
    },
    computed: {
        ...mapGetters('bases', {
            getBaseCodeById: 'getBaseCodeById',
        }),
        ...mapGetters('eventJobs', {
            eventJobTooltip: 'tooltip',
        }),
        tooltip() {
            return (job) => {
                if (job) {
                    job._vehicle = this.resource;
                    return this.eventJobTooltip(job);
                }
                return null;
            }
        },
        baseCode() {
            if (this.resource.baseCode)
                return this.resource.baseCode;

            return this.getBaseCodeById(this.resource.baseId)
        },
        ...mapGetters('resourceTypes', {
            resourceTypeById: 'byId',
        }),
        iconType() {
            if (this.resourceType.options?.icon) {
                return 1;
            } else {
                return 0;
            }
        },
        icon() {
            if (this.resourceType.options?.icon) {
                return this.$store.state.setup.endpoints.imageProxyUrl + process.env.VUE_APP_TEREZA_API_URL + this.resourceType.options.icon.file;
            } else {
                if (this.resource && this.resource.icon)
                    return '$vuetify.icons.vehicle_' + this.resource.icon;
            }
            return '$vuetify.icons.vehicle_missing';
        },
        template() {
            if (this.resource.resourceTypeId === 1)
                return 1;

            return parseInt(this.resourceType.options?.resourceTemplateId);
        },
        resourceType() {
            return this.resourceTypeById(this.resource.resourceTypeId);
        },
        label() {
            return this.getLabel(this.job);
        },
        ...mapState({
            vehicle(state) {
                if (this.resource.id)
                    return state.resources.items[this.resource.id];
                return null;
            },
        }),
        color() {
            if (this.job) {
                return this.$store.getters['eventJobs/optionsByJob']({
                    vehicle: this.resource.id,
                    eventJobStatusId: this.job.eventJobStatusId,
                })
            }
            return this.$store.getters['eventJobs/status'](this.job?.eventJobStatusId)
        },
    },
}
</script>
