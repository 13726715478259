<template>
    <v-card tile flat class="event-form">
        <v-overlay :value="submitting">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <event-guide v-if="guide" @onResult="onGuideResult" @onClose="onGuideClose"
                     ref="guide"></event-guide>
        <event-tags v-if="isCall" :event="event" class="event-tags"></event-tags>
        <v-form ref="form" model="validForm" @submit="onSubmit">
            <v-container class="py-0">
                <!--event category-->
                <v-select
                        append-icon="arrow_drop_down"
                        class="mb-2"
                        dark solo text hide-details
                        color="white"
                        :background-color="selectedCategoryBackgroundColor"
                        v-model="eventCategoryId"
                        v-if="isCategoryOptions"
                        :readonly="!canChangeCategory()"
                        @focus.native="hideGuide"
                        :items="categoryOptions"
                        label="vyberte kategorii"
                        single-line
                >
                    <template v-slot:selection="{ item }">
                        <v-icon left size="34">{{ item.icon }}</v-icon>
                        {{ item.text }}
                    </template>

                    <template v-slot:item="{ item }">
                        <v-icon left size="34" :color="item.color">{{ item.icon }}</v-icon>
                        {{ item.text }}
                    </template>
                </v-select>

                <v-layout column class="inner-form">
                    <!--reported-->
                    <v-row v-if="isInput('reporterNumber')" :class="inputClass('reporterNumber')"
                           :style="inputStyle('reporterNumber')">
                        <div v-if="hasSectionTitle('reporterNumber')" class="section-title">
                            {{ sectionTitle('reporterNumber') }}
                        </div>
                        <div v-if="hasSectionTitle('reporterName')" class="section-title">
                            {{ sectionTitle('reporterNumber') }}
                        </div>
                        <v-col cols="5" :style="inputStyle('reporterNumber')">
                            <contact-combobox
                                    hide-no-data
                                    v-model="reporterNumber"
                                    v-bind:attribute="'number'"
                                    v-bind:tab="inputOptions('reporterNumber').tabindex"
                                    v-bind="inputOptions('reporterNumber')"
                                    class="autoItemHeight"
                                    @focus.native="hideGuide"
                                    @choose="setReporterName"
                                    tabindex="-1"
                                    v-bind:autofocus="eventCategory.inputs['reporterNumber'].sort < eventCategory.inputs['reporterName'].sort"
                            >
                            </contact-combobox>
                        </v-col>
                        <v-col cols="7 sep" :style="inputStyle('reporterName')">
                            <contact-combobox
                                    hide-no-data
                                    v-if="isInput('reporterName')"
                                    uppercase
                                    v-model="reporterName"
                                    v-bind:attribute="'name'"
                                    v-bind:tab="inputOptions('reporterName').tabindex"
                                    v-bind="inputOptions('reporterName')"
                                    class="autoItemHeight"
                                    @focus.native="hideGuide"
                                    @choose="setReporterNumber"
                                    tabindex="-1"
                                    v-bind:autofocus="eventCategory.inputs['reporterNumber'].sort > eventCategory.inputs['reporterName'].sort"
                            >
                            </contact-combobox>
                        </v-col>
                    </v-row>

                    <!--Reporter description -->
                    <v-row v-if="isInput('reporterDescription')" :class="inputClass('reporterDescription')"
                           :style="inputStyle('reporterDescription')">
                        <div v-if="hasSectionTitle('reporterDescription')" class="section-title">
                            {{ sectionTitle('reporterDescription') }}
                        </div>
                        <v-col cols="12">
                            <v-text-field
                                    v-model="reporterDescription"
                                    v-bind="inputOptions('reporterDescription')"
                                    @focus.native="hideGuide"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <!-- Bases -->
                    <v-row v-if="isInput('eventToBase')" :class="inputClass('eventToBase')"
                           :style="inputStyle('eventToBase')">
                        <div v-if="hasSectionTitle('eventToBase')" class="section-title">
                            {{ sectionTitle('eventToBase') }}
                        </div>
                        <v-col>
                            <v-select
                                    class="dense-menu"
                                    multiple
                                    chips
                                    append-icon="arrow_drop_down"
                                    v-model="eventToBase"
                                    v-bind="inputOptions('eventToBase')"
                                    @focus.native="hideGuide"
                                    :items="localBaseOptions"
                                    item-disabled="disabled"
                                    :menu-props="{ maxHeight: '80vh', closeOnContentClick: true }"
                            >
                                <template v-slot:append-item>
                                    <v-divider></v-divider>
                                    <v-list-item
                                            ripple
                                            @click="toggleBases"
                                    >
                                        <v-list-item-action>
                                            <v-icon :color="eventToBase.length > 0 ? 'blue' : ''">
                                                {{ icon }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Označ vše
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <template v-slot:selection="{ parent, item, index }">
                                    <v-chip v-if="index < 4">
                                        <span>{{ item.code }}</span>
                                        <v-icon v-if="!item.disabled" small @click.stop="parent.selectItem(item)">
                                            close
                                        </v-icon>
                                    </v-chip>
                                    <span v-if="index === 4" class="grey--text">
                                        (+{{ eventToBase.length - 4 }})
                                    </span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <!--Service -->
                    <v-row v-if="isInput('serviceId')" :class="inputClass('serviceId')"
                           :style="inputStyle('serviceId')">
                        <div v-if="hasSectionTitle('serviceId')" class="section-title">{{ sectionTitle('serviceId') }}
                        </div>
                        <v-col>
                            <v-select
                                    append-icon="arrow_drop_down"
                                    v-model="serviceId"
                                    :menu-props="{maxHeight: 800}"
                                    v-bind="inputOptions('serviceId')"
                                    @focus.native="hideGuide"
                                    :items="serviceOptions(eventCategoryId)"
                            >
                                <template v-slot:selection="{ item }">
                                    <v-icon left size="24" :color="item.color" class="ml-2">{{ item.icon }}</v-icon>
                                    {{ item.text }}
                                </template>

                                <template v-slot:item="{ item }">
                                    <v-icon left size="24" :color="item.color">{{ item.icon }}</v-icon>
                                    {{ item.text }}
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <!--Accesses -->
                    <v-row v-if="isInput('investigator')" :class="inputClass('investigator')"
                           :style="inputStyle('investigator')">
                        <div v-if="hasSectionTitle('investigator')" class="section-title">
                            {{ sectionTitle('investigator') }}
                        </div>
                        <v-col>
                            <v-autocomplete
                                    append-icon="arrow_drop_down"
                                    v-model="investigator"
                                    :menu-props="{maxHeight: 300}"
                                    v-bind="inputOptions('investigator')"
                                    @focus.native="hideGuide"
                                    :items="investigatorOptions()"
                                    item-text="title"
                                    item-value="uid"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-if="isInput('manager')" :class="inputClass('manager')"
                           :style="inputStyle('manager')">
                        <div v-if="hasSectionTitle('manager')" class="section-title">{{ sectionTitle('manager') }}
                        </div>
                        <v-col>
                            <v-autocomplete
                                    append-icon="arrow_drop_down"
                                    v-model="manager"
                                    :menu-props="{maxHeight: 300}"
                                    v-bind="inputOptions('manager')"
                                    @focus.native="hideGuide"
                                    :items="investigatorOptions()"
                                    item-text="title"
                                    item-value="uid"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <!-- Event access-->
                    <v-row v-if="isInput('eventAccess')" :class="inputClass('eventAccess')"
                           :style="inputStyle('eventAccess')">
                        <div v-if="hasSectionTitle('eventAccess')" class="section-title">
                            {{ sectionTitle('eventAccess') }}
                        </div>
                        <v-col>
                            <v-select
                                    class="dense-menu"
                                    multiple
                                    chips
                                    append-icon="arrow_drop_down"
                                    v-model="eventAccess"
                                    v-bind="inputOptions('eventAccess')"
                                    @focus.native="hideGuide"
                                    :items="localEventAccessOptions"
                                    item-disabled="disabled"
                                    item-text="title"
                                    item-value="uid"
                                    :menu-props="{ maxHeight: '80vh', closeOnContentClick: true }"
                            >
                                <template v-slot:selection="{ parent, item, index }">
                                    <v-chip v-if="index < 4">
                                        <span>{{ item.code }}</span>
                                        <v-icon v-if="!item.disabled" small @click.stop="parent.selectItem(item)">
                                            close
                                        </v-icon>
                                    </v-chip>
                                    <span v-if="index === 4" class="grey--text">
                                        (+{{ eventAccess.length - 4 }})
                                    </span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <!--eventTerrainId-->
                    <v-row v-if="isInput('eventTerrainId')" :class="inputClass('eventTerrainId')"
                           :style="inputStyle('eventTerrainId')">
                        <div v-if="hasSectionTitle('eventTerrainId')" class="section-title">{{
                                sectionTitle('eventTerrainId')
                            }}
                        </div>
                        <v-col>
                            <v-btn-toggle borderless background-color="secondary" tile color="white"
                                          v-model="eventTerrainId" class="v-btn-toggle--radio w100">
                                <v-btn v-for="(option) in eventTerrainOptions" :value="option.id" :key="option.id"
                                       class="flex-grow-1" :disabled="!canEditAttribute(event.uuid, 'eventTerrainId')">
                                    <v-icon medium left v-if="option.icon">{{ option.icon }}</v-icon>
                                    {{ option.name }}
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>

                    <!--Address-->
                    <v-row v-if="isInput('address')" :class="inputClass('address')" :style="inputStyle('address')">
                        <div v-if="hasSectionTitle('address')" class="section-title">{{ sectionTitle('address') }}</div>
                        <v-col @click="onAddress">
                            <v-card class="d-flex flex-row" flat tile>
                                <div class="text-center pl-2 pr-1 py-2">
                                    <v-icon>{{ inputPrependIcon('address') }}</v-icon>
                                    <div class="text-caption">{{ addressDistance }}</div>
                                </div>
                                <div class="pa-2 text-wrap-pre-line" v-html="addressFormatted"></div>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!--Address description-->
                    <v-row v-if="isInput('addressDescription')" :class="inputClass('addressDescription')"
                           :style="inputStyle('addressDescription')">
                        <div v-if="hasSectionTitle('addressDescription')" class="section-title">{{
                                sectionTitle('addressDescription')
                            }}
                        </div>
                        <v-col>
                            <v-textarea
                                    auto-grow
                                    v-bind="inputOptions('addressDescription')"
                                    v-model="addressDescription"
                                    ref="addressDescription"
                                    @focus.native="hideGuide"
                                    :rows="1"
                                    :max-rows="8"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>

                    <!--Type-->
                    <v-row v-if="isInput('eventTypeId')" :class="inputClass('eventTypeId')"
                           :style="inputStyle('eventTypeId')">
                        <v-col>
                            <div v-if="hasSectionTitle('eventTypeId')" class="section-title">
                                {{ sectionTitle('eventTypeId') }}
                            </div>
                            <v-select
                                    append-icon="arrow_drop_down"
                                    v-bind="inputOptions('eventTypeId')"
                                    v-model="eventTypeId"
                                    @focus.native="hideGuide"
                                    :items="eventTypeOptions"
                            >
                            </v-select>
                        </v-col>
                    </v-row>

                    <!--Subject-->
                    <v-row v-if="isInput('subject')" :class="inputClass('subject')" :style="inputStyle('subject')">
                        <v-col>
                            <div v-if="hasSectionTitle('subject')" class="section-title">{{ sectionTitle('subject') }}
                            </div>
                            <v-text-field
                                    v-bind="inputOptions('subject')"
                                    v-model="subject"
                                    @focus.native="hideGuide"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <!--Description-->
                    <v-row v-if="isInput('description')" :class="inputClass('description')"
                           :style="inputStyle('description')">
                        <v-col>
                            <div v-if="hasSectionTitle('description')" class="section-title">{{
                                    sectionTitle('description')
                                }}
                            </div>
                            <v-textarea
                                    auto-grow
                                    v-bind="inputOptions('description')"
                                    v-model="description"
                                    ref="description"
                                    :rows="3"
                                    :max-rows="8"
                                    v-if="canEditAttribute(event.uuid, 'description')"
                            >
                            </v-textarea>
                            <div v-else v-html="description" v-linkified class="white pa-2"></div>
                        </v-col>
                    </v-row>

                    <!--Start/End dates-->
                    <v-row v-if="isInput('startAt')" :class="inputClass('startAt')" :style="inputStyle('startAt')">
                        <div v-if="hasSectionTitle('startAt')" class="section-title">{{ sectionTitle('startAt') }}</div>
                        <v-col cols="6">
                            <v-datetime-picker
                                    :textFieldProps="inputOptions('startAt')"
                                    :disabled="canEditAttribute('startAt')"
                                    :readonly="!canEditAttribute('startAt')"
                                    :datePickerProps="datePickerProps"
                                    :timePickerProps="timePickerProps"
                                    @focus.native="hideGuide"
                                    v-bind="datetimeOptions"
                                    v-model="startAt"
                            >
                            </v-datetime-picker>
                        </v-col>
                        <v-col cols="6 sep">
                            <v-datetime-picker
                                    v-if="isInput('endAt')"
                                    :disabled="canEditAttribute('endAt')"
                                    :textFieldProps="inputOptions('endAt')"
                                    :datePickerProps="datePickerProps"
                                    :timePickerProps="timePickerProps"
                                    v-bind="datetimeOptions"
                                    @focus.native="hideGuide"
                                    v-model="endAt"
                            >
                            </v-datetime-picker>
                        </v-col>
                    </v-row>
                </v-layout>

            </v-container>

            <!--Actions-->
            <v-container>
                <v-row row wrap class="actions">
                    <v-col xs12 sm4>
                        <v-btn
                                block
                                depressed
                                v-if="canUpdate && !isUpdateDisabled"
                                type="submit"
                                :class="actionOptions('update').class"
                                color="secondary">
                            <v-icon small left>{{ actionOptions('update').icon }}</v-icon>
                            {{ actionOptions('update').label }}
                        </v-btn>
                        <confirm-button
                                text
                                block
                                v-if="canClose"
                                v-bind:options="actionOptions('close')"
                                @click="closeEvent">
                            {{ actionOptions('close').label }}
                        </confirm-button>
                        <confirm-button
                                text
                                block
                                v-if="canRemove"
                                :disabled="!canEditEvent"
                                v-bind:options="actionOptions('remove')"
                                @click="removeEvent">
                            {{ actionOptions('remove').label }}
                        </confirm-button>
                        <v-btn
                                text
                                block
                                v-if="event.id !== null && isEventChanged"
                                v-on:click="cancel"
                                :disabled="submitting || canceling">
                            Zahodit změny
                        </v-btn>
                    </v-col>
                    <v-col xs12 sm8>
                        <v-btn
                                block
                                depressed
                                v-if="canCreate"
                                @click="createFromThis(false)"
                                :disabled="!canEditEvent"
                                color="success">
                            <v-icon small left>$vuetify.icons.save</v-icon>
                            {{ actionOptions('create').label }}
                        </v-btn>
                        <confirm-button
                                block
                                depressed
                                v-if="isSendToQueue"
                                :disabled="!canSendToQueue"
                                @click="sendToQueue"
                                type="button"
                                color="success"
                                v-bind:options="sendToQueueConfirmOptions()"
                        >
                            {{ actionOptions('queue').label }}
                        </confirm-button>
                        <v-btn
                                block
                                depressed
                                v-if="canAction('calendar')"
                                @click="save"
                                type="button"
                                color="success">
                            <v-icon small left>$vuetify.icons.calendar</v-icon>
                            {{ actionOptions('calendar').label }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <!-- Messages-->
            <event-messages class="white" v-if="allowChat && isInput('chat') && event.id > 0" ref="eventMessages"
                            :eventId="event.id"></event-messages>

        </v-form>
        <div class="guide-toggle" v-if="isInput('guide')">
            <v-btn fab x-large dark color="red" @click="toggleGuide" title="Průvodce">
                <v-icon large>$vuetify.icons.guide</v-icon>
            </v-btn>
        </div>
        <confirm-dialog ref="confirm" type="delete"></confirm-dialog>
        <link-event-dialog ref="linkEventDialog" @linkEvent="linkEvent"></link-event-dialog>
    </v-card>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import eventGuide from './Guide'
import contactCombobox from './ContactCombobox'
import {appBus} from '@/main'
import {dateFormatMixin} from "@/mixins/dateFormatMixin";
import eventMessages from './Messages'
import {values, forEach, zipObject, map, includes, uniq, toArray} from "lodash";
import {formatDistance} from "@/helpers/format";
import {validationMixin} from "vuelidate";
import {required, helpers} from "vuelidate/lib/validators";
import EventTags from "./EventTags";
import moment from "moment/moment";
import {ON_SHOW_EVENT_LINK_DIALOG} from "@/events";
import LinkEventDialog from "@/components/linkEvent/Dialog";

const minDate = (param) =>
    helpers.withParams(
        {type: 'minDate', value: param},
        (value) => param && value ? moment(value, 'DD.MM.YYYY HH:mm').isSameOrAfter(moment(param, 'DD.MM.YYYY HH:mm')) : true
    )

export default {
    name: 'sidebar-event-form',
    components: {
        LinkEventDialog,
        EventTags,
        eventGuide,
        contactCombobox,
        eventMessages,
        ConfirmDialog: () => import("@/components/ui/ConfirmDialog"),
        ConfirmButton: () => import("@/components/ui/ConfirmButton"),
    },
    inject: ['theme'],
    mixins: [dateFormatMixin, validationMixin],
    beforeMount() {
        if (this.$refs.description)
            this.$nextTick(() => this.$refs.description.$el.focus());
    },
    created() {
        appBus.$on(ON_SHOW_EVENT_LINK_DIALOG, (eventUuid) => {
            this.showLinkEventDialog(eventUuid);
        });
    },
    props: {
        isCall: {
            type: Boolean,
            default: true,
        },
        allowChat: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            datetimeOptions: {
                locale: 'cs-CZ',
                dateFormat: 'dd.MM.yyyy',
                timeFormat: 'HH:mm',
                okText: 'Použít',
                clearText: 'Vymazat',
                firstDayOfWeek: 1
            },
            datePickerProps: {
                locale: 'cs-CZ',
                dateFormat: 'dd.MM.yyyy',
                timeFormat: 'HH:mm',
                firstDayOfWeek: 1
            },
            timePickerProps: {
                locale: 'cs-CZ',
                dateFormat: 'dd.MM.yyyy',
                timeFormat: 'HH:mm',
                format: '24hr',
                firstDayOfWeek: 1
            },
            closeDialog: false,
            removeDialog: false,
            validForm: true,
            guide: false,
            submitting: false,
            canceling: false,
            datetime: null,
        }
    },
    validations() {
        return this.validationRules;
    },
    computed: {
        validationRules() {
            let rules = {};
            if (this.eventCategory.inputs !== undefined) {
                forEach(this.eventCategory.inputs, (options, name) => {
                    if (options.required === 1) {
                        rules[name] = {
                            required,
                        };
                    }
                    if (name === 'endAt') {
                        rules[name] = {
                            minDate: minDate(this.startAt)
                        }
                    }
                });
            }
            return rules;
        },
        errors() {
            const errors = [];

            for (const fieldName in this.validationRules) {
                if (Object.prototype.hasOwnProperty.call(this.validationRules, fieldName)) {
                    const fieldRules = this.validationRules[fieldName];

                    if (!this.$v[fieldName].$dirty) continue;

                    const name = this.eventCategory.inputs[fieldName].label;

                    if (fieldRules.required && !this.$v[fieldName].required) {
                        errors.push(`Vyplňte <strong>${name}</strong>`);
                    }

                    if (fieldRules.minDate && !this.$v[fieldName].minDate) {
                        errors.push(`Chybné datum <strong>${name}</strong>`);
                    }
                }
            }
            return errors;
        },
        localEventAccessOptions() {
            let options = [];
            // load event access uids

            forEach(this.eventAccessOptions, (item) => {
                options.push(item);
            });
            return options;
        },
        localBaseOptions() {
            let baseOptions = [];
            let eventJobsBases = uniq(map(this.jobsByEventKey(this.event.uuid), 'baseId'));
            forEach(this.baseOptions, (item) => {
                if (this.isMine || this.isCentralBase || includes(this.eventToBase, item.value)) {
                    baseOptions.push({
                        value: item.value,
                        text: item.text,
                        code: item.code,
                        disabled: !this.canRemoveBase(item.value, eventJobsBases),
                    });
                }
            });
            return baseOptions;
        },
        isCategoryOptions() {
            return this.categoryOptions.length > 0;
        },
        categoryOptions() {
            return this.eventCategoryToggleOptions(this.eventCategoryId);
        },
        selectedCategoryBackgroundColor() {
            return this.eventCategory.color ? this.eventCategory.color : '';
        },
        canAction() {
            return action => this.eventCategory.actions && this.eventCategory.actions[action] !== undefined;
        },
        canCreate() {
            return this.canAction('create') && this.event.parentId === null;
        },
        canUpdate() {
            return this.canAction('update');
        },
        isUpdateDisabled() {
            if (this.event.id > 0) {
                if (this.canUpdate && this.isEventChanged)
                    return false;
                return true;
            }
            return false;
        },
        isMine() {
            return this.event.baseId === this.$store.state.auth.user.baseId
        },
        isCentralBase() {
            return this.$store.state.auth.user.baseId === this.$store.state.bases.centralBaseId
        },
        canClose() {
            return this.canUpdate && this.canAction('close') && this.isMine;
        },
        isSendToQueue() {
            return this.canAction('queue');
        },
        canSendToQueue() {
            return this.event.uid < 1 || !this.hasTags(this.event.uuid, ['ISPO_QUEUE', 'ISPO']);
        },
        canRemove() {
            if (this.eventCategory.code === 'UCKOPIS')
                return this.isCentralBase;

            return this.canAction('remove') && !this.hasTags(this.event.uuid, ['ISPO']) && this.isMine;
        },
        actionOptions() {
            return action => {
                let actionSettings = this.eventCategory.actions[action];
                return {
                    label: actionSettings?.label,
                    tooltip: actionSettings?.tooltip,
                    confirm: actionSettings?.confirm,
                    icon: actionSettings?.icon,
                    class: actionSettings?.class,
                }
            }
        },
        eventCategory() {
            return this.getEventCategoryById(this.eventCategoryId);
        },
        ...mapGetters('eventTypes', {
            eventTypeOptions: 'options',
        }),
        ...mapGetters('eventStatuses', {
            eventStatusOptions: 'options',
        }),
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
            eventCategoryToggleOptions: 'toggleOptions',
            investigatorOptions: 'investigatorOptions',
        }),
        ...mapGetters('eventTerrains', {
            eventTerrainOptions: 'options',
        }),
        ...mapGetters('services', {
            getServiceById: 'getById',
            serviceOptions: 'optionsByEventCategoryId',
        }),
        ...mapGetters('eventJobs', {
            jobsByEventKey: 'byEventKey',
        }),
        ...mapGetters('bases', {
            baseOptions: 'options',
        }),
        ...mapGetters('events', {
            isLocal: 'isLocal',
            isCreated: 'isCreated',
            canEditEvent: 'canEdit',
            isActiveEvent: 'isActiveEvent',
            canEditAttribute: 'canEditAttribute',
            hasTags: 'hasTags',
            isEventChanged: 'isActiveEventChanged',
        }),
        ...mapGetters('eventTypes', {
            getEventType: 'get',
        }),
        ...mapState({
            activeEvent: state => state.events.active,
            event: state => state.events.local[state.events.active],
        }),
        eventCategoryId: {
            get() {
                return this.event.eventCategoryId;
            },
            set(value) {
                this.updateAttribute('eventCategoryId', value);
            }
        },
        eventTypeId: {
            get() {
                return this.event.eventTypeId;
            },
            set(value) {
                this.updateAttribute('eventTypeId', value);
            }
        },
        reporterName: {
            get() {
                return this.event.reporterName;
            },
            set(value) {
                this.updateAttribute('reporterName', value);
            }
        },
        reporterNumber: {
            get() {
                return this.event.reporterNumber;
            },
            set(value) {
                this.updateAttribute('reporterNumber', value);
            }
        },
        reporterDescription: {
            get() {
                return this.event.reporterDescription;
            },
            set(value) {
                this.updateAttribute('reporterDescription', value);
            }
        },
        subject: {
            get() {
                return this.event.subject;
            },
            set(value) {
                this.updateAttribute('subject', value);
            }
        },
        description: {
            get() {
                return this.event.description;
            },
            set(value) {
                value = value === '' ? null : value;
                this.updateAttribute('description', value);
            }
        },
        serviceId: {
            get() {
                return this.event.serviceId;
            },
            set(value) {
                this.updateAttribute('eventTypeId', this.getServiceById(value).eventTypeId);
                this.updateAttribute('serviceId', value);
            }
        },
        eventAccess: {
            get() {
                return values(this.event.eventAccess);
            },
            set(values) {
                this.updateAttribute('eventAccess', '_RK_');
                this.updateAttribute('eventAccess', zipObject(values, values));
            }
        },
        investigator: {
            get() {
                return this.event.investigator;
            },
            set(value) {
                value = value === '' ? null : value;
                this.updateAttribute('investigator', value);
            }
        },
        manager: {
            get() {
                return this.event.manager;
            },
            set(value) {
                value = value === '' ? null : value;
                this.updateAttribute('manager', value);
            }
        },
        eventTerrainId: {
            get() {
                return this.event.eventTerrainId;
            },
            set(value) {
                if (value === undefined)
                    value = '';
                this.updateAttribute('eventTerrainId', value);
            }
        },
        addressDistance() {
            if (this.event.addressData !== null && this.event.addressData.distance !== undefined)
                return formatDistance(this.event.addressData.distance);

            return null;
        },
        addressFormatted() {
            return this.address;
        },
        address: {
            get() {
                return this.event.address;
            },
            set(value) {
                this.updateAttribute('address', value);
            }
        },
        addressDescription: {
            get() {
                return this.event.addressDescription;
            },
            set(value) {
                value = value === '' ? null : value;
                this.updateAttribute('addressDescription', value);
            }
        },
        startAt: {
            get() {
                return this.dateTimeFromDb(this.event.startAt);
            },
            set(value) {
                this.updateAttribute('startAt', this.dateTimeToDb(value));
            }
        },
        endAt: {
            get() {
                return this.dateTimeFromDb(this.event.endAt);
            },
            set(value) {
                this.updateAttribute('endAt', this.dateTimeToDb(value));
            }
        },
        eventToBase: {
            get() {
                return values(this.event.eventToBase);
            },
            set(values) {
                this.updateAttribute('eventToBase', '_RK_');
                this.updateAttribute('eventToBase', zipObject(values, values));
            }
        },
        selectedAllBases() {
            return this.eventToBase.length === this.localBaseOptions.length
        },
        selectedSomeBases() {
            return this.eventToBase.length > 0 && !this.selectedAllBases
        },
        icon() {
            if (this.selectedAllBases) return 'mdi-close-box'
            if (this.selectedSomeBases) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        sendToQueueConfirmOptions() {
            let actionOptions = this.actionOptions('queue');
            const events = toArray(this.$store.getters['events/getNearestEvents'](this.event));
            if (events.length > 0) {
                actionOptions.confirm = this.$tc('sendToQueueConfirm', 1);
            }
            return actionOptions;
        },
        canRemoveBase(baseId, eventJobsBases) {
            if (this.event.baseId === baseId)
                return false;

            if (includes(eventJobsBases, baseId))
                return false;

            if (this.isCentralBase)
                return true

            if (!this.isMine && baseId !== this.$store.state.auth.user.baseId)
                return false;

            return true;
        },
        toggleBases() {
            this.$nextTick(() => {
                if (this.selectedAllBases) {
                    this.eventToBase = []
                } else {
                    this.eventToBase = map(this.localBaseOptions, 'value');
                }
            })
        },
        updateAttribute(attribute, value) {
            if (this.canceling === true)
                return;

            let payload = {key: this.event.uuid, item: {}};
            payload.item[attribute] = value;
            return this.$store.dispatch('events/updateLocalAttributes', payload)
        },
        isInput(input) {
            return (this.eventCategory.inputs !== null && Object.prototype.hasOwnProperty.call(this.eventCategory.inputs, input));
        },
        hasSectionTitle(input) {
            return this.eventCategory.inputs[input].title !== null;
        },
        sectionTitle(input) {
            return this.eventCategory.inputs[input].title;
        },
        inputPrependIcon(input) {
            let inputSettings = this.eventCategory.inputs[input];
            return inputSettings.prepend;
        },
        canChangeCategory() {
            if (this.isMine)
                return true;

            if (this.$store.state.auth.user.baseId === this.$store.state.bases.centralBaseId)
                return true;

            return false;
        },
        inputOptions(input) {

            let inputSettings = this.eventCategory.inputs[input];

            let options = {
                placeholder: inputSettings.label,
                appendIcon: inputSettings.append,
                prependInnerIcon: inputSettings.prepend,
                hideDetails: true,
                tabindex: inputSettings.sort + 2,
            };

            if (this.canEditAttribute(this.event.uuid, input) === false) {
                options.readonly = true;
            }

            if (this.$v[input] !== undefined && this.$v[input].$error) {
                options.backgroundColor = 'red lighten-4';
                options.error = true;
            }

            return options
        },
        inputStyle(input) {
            return {
                order: Number(this.eventCategory.inputs[input].sort) + 5,
            }
        },
        inputClass(input) {
            return [
                this.eventCategory.inputs[input].color,
                this.eventCategory.inputs[input].group,
                this.eventCategory.inputs[input].title ? "has-title" : null,
                this.eventCategory.inputs[input].view || this.isMine ? null : 'd-none',
            ]
        },
        ...mapActions('events', {
            remove: 'remove',
            saveEvent: 'save',
            createFrom: 'createFrom',
            setActiveEvent: 'setActiveEvent',
            sendToSystem: 'sendToSystem',
        }),
        onAddress() {
            if (this.canEditAttribute(this.event.uuid, 'address')) {
                appBus.$emit('toggleMapSearch', true);
                appBus.$emit('clearAddressSearch');
            }
        },
        back() {
            this.$store.dispatch('events/setActiveEvent', false).then();
        },
        cancel() {
            this.$store.dispatch('events/cancel', this.activeEvent).then();
        },
        onGuideResult(result) {
            this.$store.commit('events/appendDescription', result);
            this.$refs.description.$el.focus();
        },
        onGuideClose() {
            this.guide = false;
        },
        edit() {
            this.$store.commit('events/setState', 1);
        },
        async save() {
            this.hideGuide();
            if (this.validate()) {
                this.submitting = true;
                this.saveEvent({event: this.event}).finally(() => {
                    this.submitting = false;
                });
            }
        },
        async createFromThis(sentToQueue) {
            if (this.validate()) {
                this.hideGuide();
                this.submitting = true;
                this.createFrom({
                    event: this.event,
                    sendToQueue: sentToQueue,
                }).finally(() => {
                    this.submitting = false;
                });
            }
        },
        sendToQueue() {
            if (this.validate()) {
                if (this.isCall) {
                    this.submitting = false;

                    if (this.isEventChanged) {
                        this.saveEvent({event: this.event}).then(() => {
                            this.createFromThis(true);
                        });
                        this.submitting = false;
                    } else {
                        this.createFromThis(true);
                    }
                } else {
                    this.hideGuide();
                    this.submitting = true;
                    this.sendToSystem(this.event.uuid).then(() => {

                    }).finally(() => {
                        this.submitting = false;
                    });
                }
            }
        },
        onSubmit(evt) {
            evt.preventDefault();
            this.save();
        },
        validate() {
            this.submitting = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.$noty.error(`<strong>Prosím opravte následujích chyby:</strong><br /><br />${this.errors.join('<br />')}`);
                this.submitting = false;
                return false;
            } else {
                return true;
            }
        },
        async closeEvent() {
            this.hideGuide();
            if (this.validate()) {
                this.submitting = true;
                let event = Object.assign({}, this.event);
                event.eventStatusId = 11;
                event.eventStateId = 5;
                this.saveEvent({event: event}).finally(() => {
                    this.submitting = false;
                });
            }
        },
        async removeEvent() {
            if (this.event.id > 0) {

                let payload = {key: this.event.uuid, item: {}};
                payload.item.id = this.event.id;
                payload.item.eventStatusId = 11;
                payload.item.eventStateId = 6;

                this.$store.dispatch('events/updateAttributes', payload).then(() => {
                    this.submitting = true;
                    this.$store.dispatch('events/update', payload).then(() => {
                        this.setActiveEvent(false);
                        this.submitting = false;
                    }).finally(() => {
                        this.submitting = false;
                    });
                });
            } else {
                this.remove();
            }
        },
        showLinkEventDialog(uuid) {
            this.$refs.linkEventDialog.open(uuid);
        },
        linkEvent(uuid) {
            this.submitting = true;

            const linkToEvent = this.$store.getters["events/getByKey"](uuid);
            if (!linkToEvent)
                this.$noty.error("Chyba. Nebyla nalezena cílová událost");

            let event = Object.assign({}, this.event);
            event.eventStateId = 4;
            event.parentId = linkToEvent.id;

            this.saveEvent({event: event}).finally(() => {
                this.submitting = false;
                this.$noty.success("Událost byla přiřazena.");
                this.setActiveEvent(uuid)
            });
        },
        toggleGuide() {
            this.guide = !this.guide;
        },
        hideGuide() {
            this.guide = false;
        },
        setReporterName(item) {
            this.event.reporterName = item.name;
            this.event.reporterDescription = item.description;
        },
        setReporterNumber(item) {
            this.event.reporterNumber = item.number;
            this.event.reporterDescription = item.description;
        },
    },
    watch: {
        eventCategoryId: function () {

            if (this.event.id > 0)
                return;

            if (this.eventCategory.defaultValues !== undefined && this.eventCategory.defaultValues) {
                forEach(this.eventCategory.defaultValues, (value, attribute) => {
                    if (this.isInput(attribute)) {
                        this[attribute] = value
                    }
                });
            }

            this.startAt = this.isInput('startAt') ? moment().format("YYYY-MM-DD HH:mm") : null;
        }
    },
    beforeDestroy() {
        appBus.$off(ON_SHOW_EVENT_LINK_DIALOG);
    }
}
</script>
