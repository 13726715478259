import Vue from 'vue';
import {uuid} from "vue-uuid";
import api from "../../api/api";
import {forEach, mapValues, pickBy, toArray} from "lodash";
import {stateMerge} from "@/store/object-merge";
import Vector from "ol/source/Vector";
import {GeoJSON} from "ol/format";

const getDefaultState = () => {
    return {
        items: {},
        layerVisible: true,
        active: null,
        type: null,
        status: 0,
        types: [
            {
                type: 'point',
                label: 'Draw Point',
                icon: 'map-marker',
            },
            {
                type: 'line-string',
                label: 'Draw LineString',
                icon: 'minus',
            },
            {
                type: 'polygon',
                label: 'Draw Polygon',
                icon: 'square-o',
            },
            {
                type: 'circle',
                label: 'Draw Circle',
                icon: 'circle-thin',
            },
        ],
    }
};
const state = getDefaultState();

const getters = {
    isActive: (state) => {
        return state.status > 0;
    },
    isEditing: (state, getters, rootState) => {
        return toArray(getters.activeByEvent(rootState.events.active)).length > 0;
    },
    active: (state) => {
        if (state.active)
            return state.items[state.active];
        return null;
    },
    byEvent: state => (key) => {
        return pickBy(state.items, (o) => {
            return o && o.eventUuid === key;
        });
    },
    activeByEvent: (state, getters) => (key) => {
        return pickBy(getters.byEvent(key), (o) => {
            return o.edit === true;
        });
    },
    inactiveByEvent: (state, getters) => (key) => {
        return pickBy(getters.byEvent(key), (o) => {
            return o.edit === false;
        });
    },
    extent: (state, getters) => (key) => {
        let extent = [];
        if (state.layerVisible) {
            let objects = getters.byEvent(key);
            let jsons = toArray(mapValues(objects, function (o) {
                return o.geojson;
            }));
            if (jsons.length > 0) {
                forEach(jsons, function (json) {
                    let jsonExtent = new Vector({
                        features: new GeoJSON().readFeatures(json)
                    }).getExtent()
                    extent.push([jsonExtent[0], jsonExtent[1]])
                    extent.push([jsonExtent[2], jsonExtent[3]])
                });
            }
        }
        return extent;
    },
};

const actions = {
    set({commit}, items) {
        commit("MERGE_STATE", items)
    },
    toggleLayerVisibility({state, commit}) {
        commit('setLayerVisible', !state.layerVisible);
    },
    create({commit, rootState}, payload) {

        let key = uuid.v4();
        Vue.set(payload.geojson.properties, 'uuid', key);

        commit('setStatus', 1);
        let item = {
            id: null,
            uuid: key,
            geojson: payload.geojson,
            eventId: payload.event.id,
            eventUuid: payload.event.uuid,
            sequence: null,
            title: '',
            edit: true,
            createdBy: rootState.auth.user.id, // Todo: Remove - server side must handle this
        };
        commit('add', item);
        commit('setType', null);
        commit('setActive', key);
        commit('setStatus', 2);
    },
    modify({commit, state}, geojson) {
        commit('modify', {key: state.active, json: geojson});
    },
    save({commit, getters, dispatch}) { // 

        let object = getters.active;

        return new Promise((resolve) => {
            api.save('/v2/geos', object).then((result) => { // result
                // let data = {};
                // data[result.uuid] = result;
                // dispatch('set', data);
                object.id = result.id;
                object.sequence = result.sequence;
                dispatch('notifications/add', {type: 'success', message: 'Objekt byl uložen'}, {root: true});
                resolve();
            }).catch(function (error) {
                dispatch('notifications/add', {
                    type: 'error',
                    message: 'Chyba při ukládáni objektu.' + error
                }, {root: true});
            }).finally(() => {
                commit('setActive', null);
            });
        })
    },
    edit({commit}, key) {
        commit('setActive', key);
        commit('setStatus', 1);
    },
    remove({state, commit, dispatch}) {
        return new Promise((resolve) => {
            let item = state.items[state.active];
            if (item.id) {
                api.delete('/v2/geos/' + item.id).then(() => {
                    dispatch('notifications/add', {
                        type: 'success',
                        message: 'Objekt z mapy byl odebrán'
                    }, {root: true});
                    commit('remove', state.active);
                    commit('setActive', null);
                    commit('setStatus', 0);
                    resolve();
                })
                .catch(function (error) {
                        dispatch('notifications/add', {
                            type: 'error',
                            message: 'Chyba při odebrání objektu.' + error
                        }, {root: true});
                    });
            } else {
                commit('remove', state.active);
                commit('setActive', null);
                commit('setStatus', 0);
                resolve();
            }
        })
    },
    close({state, commit, getters}) {
        if (getters.active?.id === null)
            commit('remove', state.active);

        commit('setActive', null);
        commit('setStatus', 0);
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    setStatus(state, status) {
        state.status = status;
    },
    setActive(state, key) {
        if (key !== null) {
            Vue.set(state.items[key], 'edit', true);
        } else if (state.active) {
            let object = state.items[state.active];
            if (object) {
                Vue.set(state.items[state.active], 'edit', false);
            }
        }
        state.active = key;
    },
    setType(state, value) {
        state.type = value;
    },
    setLayerVisible(state, value) {
        state.layerVisible = value;
    },
    updateItem(state, payload) {
        state.activeItem = payload;
    },
    add(state, item) {
        Vue.set(state.items, item.uuid, item);
    },
    modify(state, payload) {
        state.items[payload.key].geojson.geometry.coordinates = payload.geojson.geometry.coordinates;
    },
    remove(state, key) {
        Vue.delete(state.items, key);
    },
    update(state, payload) {
        forEach(payload.item, (value, key) => {
            state.items[payload.key][key] = value
        });
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
