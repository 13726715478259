<template>
    <v-card class="fill-height elevation-1" color="grey lighten-2">
        <v-list class="tab-overflow event-resource-list" color="grey lighten-2">
            <v-list-item class="align-start justify-start">
                <v-list-item-content class="grey--text text-body-2 flex-fill">
                    Přehled nasazené techniky
                </v-list-item-content>
                <v-list-item-action class="justify-end">
                    <v-btn icon small outlined @click="newJob()"  color='black'>
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
            <event-job v-for="(job, key) in eventJobs(event.uuid)" :key="key" v-bind:job="job"
                       @onClick="openJob(job)"></event-job>
            <v-divider></v-divider>
        </v-list>
        <job-form :event="event" ref="jobForm"></job-form>
    </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import EventJob from "@/components/panel/sidebar/event/EventJob";
import JobForm from './JobForm';

export default {
    name: 'sidebar-event-jobs',
    components: {
        EventJob,
        JobForm,
    },
    props: {
        event: Object,
    },
    data() {
        return {
            menu: false,
        }
    },
    computed: {
        ...mapGetters('eventJobs', {
            eventJobs: 'jobs',
        }),
    },
    methods: {
        newJob() {
            this.$refs.jobForm.new();
        },
        openJob(job) {
            this.$refs.jobForm.open(job);
        }
    }
}
</script>
