<template>
    <div class="panel d-flex flex-column fill-height">
        <version-component v-if="env !== 'local'"></version-component>
        <panel-header/>
        <v-row no-gutters row class="panel-body fill-height" id="panel-body">
            <v-col v-if="!isMobile" class="panel-body-content flex-grow-1 flex-shrink-0" id="panel-body-content">
                <map-component ref="map" v-if="page === 'map'"></map-component>
            </v-col>
            <v-col :class="{ hidden: !isSidebarVisible}"
                   class="panel-body-sidebar d-flex flex-grow-0 flex-column flex-shrink-1"
                   @contextmenu.stop.prevent=""
            >
                <nav-component v-if="!isMobile"/>
                <v-btn @click="toggleSidebar" v-if="isSidebarToggle" x-small fab depressed tile
                       class="panel-sidebar-toggle-button">
                    <v-icon small>{{ toggleSidebarIcon }}</v-icon>
                </v-btn>
                <search></search>
                <events-component v-show="isEventPanel && !isActiveEvent"
                                  v-bind:events="recentEvents"></events-component>
                <calendar-component v-show="isCalendarPanel && !isActiveEvent"></calendar-component>
                <event-component v-if="isActiveEvent && !isMobile"></event-component>
                <event-mobile-component v-if="isActiveEvent && isMobile"></event-mobile-component>
            </v-col>
        </v-row>
        <notifications></notifications>
        <image-viewer></image-viewer>
        <sap-component></sap-component>
        <event-view-dialog></event-view-dialog>
        <device-list-component></device-list-component>
        <microphone-test-component v-if="microphoneTest" @onClose="microphoneTest=false"></microphone-test-component>
        <app-log-component></app-log-component>
        <capture-screen></capture-screen>
        <vue-context ref="sidebarContextMenu" tag="aside" class="sidebar-context" :close-on-click="false"
                     id="sidebar-context-menu" v-slot="{ data }">
            <message-context-menu :data="data"></message-context-menu>
        </vue-context>
        <iframe-modal></iframe-modal>
    </div>
</template>
<script>

import Vue from 'vue'
import fullscreen from 'vue-fullscreen'
import VersionComponent from '@/components/utils/VersionComponent'
import {mapState, mapGetters, mapActions} from 'vuex'
import {appBus} from "@/main";
import EventsComponent from './panel/sidebar/Events.vue'
import EventViewDialog from './panel/sidebar/event/ViewDialog'
import EventComponent from './panel/sidebar/event/Event.vue'
import EventMobileComponent from './panel/sidebar/event/EventMobile.vue'
import Search from './panel/sidebar/Search.vue'
import CalendarComponent from "./panel/sidebar/Calendar.vue"
import PanelHeader from "./panel/header/Index"
import MapComponent from "./map/Map"
import SapComponent from "./sap/SapComponent"
import DeviceListComponent from "./devicesList/DeviceListComponent"
import MicrophoneTestComponent from "./utils/MicrophoneTestComponent"
import AppLogComponent from "./utils/AppLogComponent"
import Notifications from "./Notifications"
import CaptureScreen from "./CaptureScreen"
import {ON_MICROPHONE_TEST, ON_RESIZE, ON_CONTEXT_OPEN, ON_CONTEXT_CLOSE} from "@/events";
import NavComponent from "@/components/panel/header/Nav";
import VueContext from 'vue-context';
import MessageContextMenu from "@/components/contextMenu/MessageContextMenu"
import {forEach} from "lodash";
import ImageViewer from "@/components/ui/ImageViewer.vue";
import IframeModal from "@/components/ui/IframeModal.vue";

Vue.use(fullscreen);

export default {
    name: 'panel',
    components: {
        IframeModal,
        ImageViewer,
        MicrophoneTestComponent,
        AppLogComponent,
        MapComponent,
        SapComponent,
        DeviceListComponent,
        Search,
        EventViewDialog,
        CalendarComponent,
        NavComponent,
        MessageContextMenu,
        // ShiftsComponent,
        EventsComponent,
        EventComponent,
        EventMobileComponent,
        PanelHeader,
        Notifications,
        CaptureScreen,
        VersionComponent,
        VueContext,
    },
    created() {
        appBus.$on(ON_MICROPHONE_TEST, () => {
            this.microphoneTest = !this.microphoneTest;
        });
        appBus.$on(ON_CONTEXT_OPEN, (event, data) => {
            this.$refs.sidebarContextMenu.open(event, data);
        });
        appBus.$on(ON_CONTEXT_CLOSE, () => {
            this.$refs.sidebarContextMenu.close();
        });
        this.subscribe();
    },
    mounted() {
        this.$store.dispatch('notifications/getData');
        this.startAutoRefresh();

        this.$nextTick(function () {
            window.addEventListener('resize', this.getWindowWidth);
            window.addEventListener('resize', this.getWindowHeight);
            this.getWindowWidth();
            this.getWindowHeight();
        });
    },
    data() {
        return {
            env: process.env.VUE_APP_ENV,
            version: process.env.VUE_APP_VERSION,
            uuidt: ~~(Date.now() / 1000).toString(),
            uuid: 'none',
            timer: null,
            ch1: [],
            zoom: 2,
            timeout: null,
            retrieving: false,
            retries: 0,
            center: [0, 0],
            rotation: 0,
            geolocPosition: undefined,
            showCalendar: false,
            windowWidth: 0,
            windowHeight: 0,
            microphoneTest: false,
            pubnub: null,
        }
    },
    computed: {
        ...mapState({
            features: state => state.map.drawnFeatures,
            setup: state => state.setup.setup,
            activeEventKey: state => state.events.active,
            autoRefresh: state => state.setup.autoRefresh,
            autoRefreshPeriod: state => state.setup.autoRefreshPeriod,
            autoRefreshTimeout: state => state.setup.autoRefreshTimeout,
            sidebar: state => state.panel.sidebar,
            user: state => state.auth.user,
            isMobile: state => state.isMobile,
            sidebarStatus: state => state.panel.sidebarStatus,
        }),
        isSidebarVisible() {
            return this.isActiveEvent || this.sidebarStatus;
        },
        isSidebarToggle() {
            return !this.isActiveEvent;
        },
        toggleSidebarIcon() {
            return this.sidebarStatus ? 'fa-caret-right' : 'fa-caret-left';
        },
        isEventPanel() {
            return this.sidebar === 'event';
        },
        isCalendarPanel() {
            return this.sidebar === 'calendar';
        },
        ...mapGetters('panel', {
            page: 'page',
        }),
        ...mapGetters('auth', {
            isUser: 'isUser',
        }),
        ...mapGetters('events', {
            isActiveEvent: 'isActiveEvent',
            recentEvents: 'getFilteredEvents',
        }),
    },
    watch: {
        isActiveEvent(newValue) {
            if (newValue === true) {
                this.$store.dispatch('panel/showSidebar');
            }
        },
        autoRefresh(val) {
            if (val) {
                this.startAutoRefresh();
            } else {
                this.stopAutoRefresh();
            }
        },
        autoRefreshPeriod() {
            this.stopAutoRefresh();
            this.startAutoRefresh();
        },
        windowWidth() {
            this.onResize();
        },
        windowHeight() {
            this.onResize();
        }
    },
    methods: {
        ...mapActions('panel', {
            toggleSidebar: 'toggleSidebar',
        }),
        ...mapActions('setup', {
            'setAutoRefreshTimeout': 'setAutoRefreshTimeout',
        }),
        startAutoRefresh() {
            if (this.autoRefresh && !this.timeout) {
                this.timeout = setInterval(this.getData, this.autoRefreshPeriod);
            }
        },
        stopAutoRefresh() {
            if (this.timeout) {
                clearInterval(this.timeout);
                this.retrieving = false;
                this.retries = 0;
            }
        },
        getData() {

            this.retries++;

            if (this.retrieving === true && this.retries > 4) {
                this.retrieving = false;
                this.retries = 0;
            }

            if (this.retrieving)
                return;

            this.retrieving = true;

            this.$store.dispatch('app/getData').then(() => {
                this.retrieving = false;
                this.retries = 0;
            }).catch(() => {
                this.retrieving = false;
                this.retries = 0;
            });
        },
        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
        },
        getWindowHeight() {
            this.windowHeight = document.documentElement.clientHeight;
        },
        onResize() {
            this.$store.commit('setIsMobile', this.windowWidth <= process.env.VUE_APP_MOBILE_BREAKPOINT);
            appBus.$emit(ON_RESIZE);
        },
        presence(ps) {
            this.occupancy = ps.occupancy;
        },
        push() {
            this.$pnPublish({
                channel: this.user.baseId,
                message: Date.now()
            }, (status, response) => window.console.log(status, response));
        },
        receptor(msg) {
            const payload = JSON.parse(JSON.stringify(msg.message));
            // payload.source = 'push';
            this.$store.dispatch('app/setData', payload);
        },
        subscribe() {

            this.$pubnub.setUUID(this.user.username);

            const channels = [];

            if (this.user.baseId) {
                channels.push(this.user.baseId);
            }

            if (this.user?.groups) {
                forEach(this.user.groups, (groupCode) => {
                    channels.push('group.' + groupCode.toLowerCase());
                });
            }

            this.$pubnub.subscribe({
                channels: channels,
                withPresence: true
            });

            this.$pubnub.addListener({
                message: this.receptor,
                presence: this.presence,
                status: this.status
            });
        },
        status(st) {
            this.$store.commit('setPubSubStatus', st);
            if (st.category === "PNNetworkUpCategory") {
                this.subscribe();
            }
        },
    },
    beforeDestroy() {
        this.stopAutoRefresh();
        this.$pubnub.unsubscribeAll();
        window.removeEventListener('resize', this.getWindowWidth);
        window.removeEventListener('resize', this.getWindowHeight);

        appBus.$off(ON_MICROPHONE_TEST);
        appBus.$off(ON_CONTEXT_OPEN);
        appBus.$off(ON_CONTEXT_CLOSE);
    }
}
</script>
