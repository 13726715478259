<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-col align-items-center class="pr-2 d-flex justify-end" :class="{ 'flex-grow-0 pl-2': isMobile }">
        <v-menu v-if="!isMobile" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on:menu }">
                <v-btn dark text tile v-on="menu">
                    <div class="text-right" v-if="!isMobile">
                        <div class="subtitle-1 font-weight-bold lh-1-3">{{ userFullName }}</div>
                        <div class="caption">{{ auth.device.name }} ({{ auth.device.code }})</div>
                    </div>
                    <v-icon v-if="isMobile">person</v-icon>
                </v-btn>
            </template>
            <header-user-card></header-user-card>
        </v-menu>

        <v-divider vertical class="mx-2 grey" v-if="!isMobile"></v-divider>

        <v-tooltip bottom v-if="!isMobile">
          <template v-slot:activator="{ on }">
            <v-btn dark :class="{red: hasVersionChanged}" icon @click="restart" v-on="on">
                <v-icon>ter-refresh</v-icon>
            </v-btn>
          </template>
          <span>Znovunačtení panelu.</span>
          <div class="red pa-2" v-if="hasVersionChanged">Je k dispozici nová aktulizace panelu.</div>
        </v-tooltip>

      <v-tooltip bottom v-if="!isMobile">
        <template v-slot:activator="{ on }">
          <v-btn dark icon @click="toggleFullscreen" v-on="on">
                <v-icon>{{ fullscreenIcon }}</v-icon>
          </v-btn>
        </template>
        Režim celé obrazovky
      </v-tooltip>

        <v-menu :close-on-content-click="true" offset-y :close-on-click="true">
            <template v-slot:activator="{ on:onMenu }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on:onTooltip }">
                <v-btn dark icon v-on="{ ...onMenu, ...onTooltip }">
                    <v-icon>ter-three-bars</v-icon>
                </v-btn>
                </template>
                  Další možnosti
              </v-tooltip>
            </template>
            <header-user-card v-if="isMobile"></header-user-card>
            <v-card>
                <v-list>
                    <v-list-item @click="toggleDeviceList" v-if="$can('manage', 'Device')">
                        <v-list-item-icon>
                            <v-icon>perm_device_information</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Správa mobilních zařízení</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
              
              <span v-if="$can('manage', 'Message')">              
                  <v-list >
                  <v-list-item @click="showMicrophoneTest">
                    <v-list-item-icon>
                      <v-icon>mic</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Test mikrofonu</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                        <v-list-item-content @click="toggleSendOnEnter">
                            <v-list-item-title>Odesílat textové zprávy stiskem klávesy
                                Enter
                            </v-list-item-title>
                            <v-list-item-subtitle>Pokud zapnuto, Ctrl+Enter zastupuje tvorbu nového řádku
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch v-model="sendOnEnter"></v-switch>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
               <v-divider></v-divider>
              </span>
              
              <span v-if="$can('view', 'Debug')">
                <v-subheader>Debug tools</v-subheader>
                <v-list-item @click="toggleFilter">
                    <v-list-item-icon>
                        <v-icon>filter_alt</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Filtery událostí</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="sendLog">
                    <v-list-item-icon>
                        <v-icon>publish</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Odeslat aplikační log</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list two-line>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Stav připojení k pubsub</v-list-item-subtitle>
                            <v-list-item-title>{{ isOnline }} {{ pubSubStatus('subscribedChannels') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title @click="toggleDev">Debug Bar</v-list-item-title>
                            <v-list-item-subtitle>Vyvojovy bar se zobrazí v levem spodnim rohu</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch v-model="devPanel"></v-switch>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                </span>
            </v-card>
        </v-menu>
        <v-dialog v-if="filterDialog" v-model="filterDialog" scrollable max-width="500">
            <event-filters @onClose="toggleFilter"></event-filters>
        </v-dialog>
    </v-col>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";
    import moment from "moment/moment";
    import {appBus} from "@/main";
    import {ON_APP_LOG, ON_MICROPHONE_TEST} from "@/events";
    import HeaderUserCard from "@/components/panel/header/UserCard";
    import EventFilters from "@/components/panel/sidebar/EventFilters";

    export default {
        name: 'header-menu',
        components: {EventFilters, HeaderUserCard},
        data() {
          return {
              filterDialog: false,
          }
        },
        computed: {
            fullscreenIcon() {
                return this.fullscreen ? 'ter-resize-down' : 'ter-resize-up'
            },
            ...mapGetters({
                isOnline: 'isOnline',
            }),
            ...mapGetters('auth', {
                userFullName: 'fullName',
            }),
            ...mapGetters('app', {
              hasVersionChanged: 'hasVersionChanged',
            }),
            ...mapGetters({
                pubSubStatus: 'pubSubStatus',
            }),
            ...mapState({
                auth: state => state.auth,
                fullscreen: state => state.panel.fullscreen,
                pubNubStatus: state => state.pubNubStatus,
                isMobile: state => state.isMobile,
            }),
            devPanel: {
                get() {
                    return this.$store.state.setup.devPanel.visible;
                },
                set() {
                    this.toggleDev();
                }
            },
            mode: {
                get() {
                    return this.$store.state.setup.darkMode;
                },
                set(value) {
                    this.$store.commit('setup/setDarkMode', value);
                }
            },
            sendOnEnter: {
                get() {
                    return this.$store.state.setup.events.messages.form.sendOnEnter
                },
                set(v) {
                    this.$store.commit('setup/setSetting', {path: 'events.messages.form.sendOnEnter', value: v});
                }
            },
        },
        methods: {
            toggleFilter() {
                this.filterDialog = !this.filterDialog;
            },
            restart() {
                window.location.href = "/?" + moment();
            },
            toggleDarkMode() {
                this.mode = !this.mode;
            },
            toggleSendOnEnter() {
                this.sendOnEnter = !this.sendOnEnter;
            },
            showMicrophoneTest() {
              appBus.$emit(ON_MICROPHONE_TEST);
            },
            sendLog() {
                appBus.$emit(ON_APP_LOG);
            },
            ...mapActions('setup', {
                'resetSetup': 'resetState',
                'toggleDev': 'toggleDevPanel',
            }),
            ...mapActions('panel', {
                'toggleFullscreen': 'toggleFullscreen'
            }),
            ...mapActions('deviceList', {
                toggleDeviceList: 'toggle'
            })
        },
    }
</script>
