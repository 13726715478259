<template>
    <div class="map-dialogs-wrapper">
        <screenshot v-bind:map="map"></screenshot>
        <terrain v-bind:map="map"></terrain>
    </div>
</template>
<script>
    import Screenshot from "./Screenshot";
    import Terrain from "./Terrain";

    export default {
        name: 'map-cards',
        components: {
            Screenshot,
            Terrain,
        },
        props: {
            map: [Object, null]
        },
    }
</script>
