<template>
  <div v-if="isEnabled">
    <vl-layer-image :z-index="zIndex+1">
      <vl-source-image-wms
          url="http://geoportal.uhul.cz/wms_mysl/service.svc/get?"
          layers="MYSL_honitby"
          styles="Default"
          format="image/png"
      >
      </vl-source-image-wms>
    </vl-layer-image>
    <vl-layer-vector :z-index="zIndex+1">
      <vl-source-vector :features.sync="points" ident="huntingRegionPoint">
        <vl-style-func :function="stylePointFunc()"></vl-style-func>
      </vl-source-vector>
    </vl-layer-vector>
    <vl-layer-vector :z-index="zIndex">
      <vl-source-vector :features="features">
        <vl-style-func :function="styleFunc()"/>
      </vl-source-vector>
    </vl-layer-vector>

    <vl-interaction-translate 
        v-if="points !== undefined && points.length > 0" 
        source="huntingRegionPoint"
        @translatestart="beginTranslate"
        @translateend="endTranslate"
    ></vl-interaction-translate>
  </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';

export default {
  name: "hunting-regions-map-layer",
  data() {
    return {
      layerIdent: 'huntingRegionsLayer',
      zIndex: 160,
      offsetLat: 0,
      offsetLon: 0,
    }
  },
  methods: {
    beginTranslate(event) {
      this.offsetLon = this.points[0].geometry.coordinates[0] - event.coordinate[0];
      this.offsetLat = this.points[0].geometry.coordinates[1] - event.coordinate[1];
    },
    endTranslate(event) {
      const coordinate = [event.coordinate[0] + this.offsetLon, event.coordinate[1] + this.offsetLat];
      this.$store.dispatch('huntingRegions/setCoordinates', coordinate);
    },
    stylePointFunc() {
      return () => {
        return [
          new Style({
            image: new Icon({
              src: './assets/icons/map/marker.png',
              crossOrigin: 'anonymous',
              color: 'rgba(0,255,0,0.5)',
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              anchor: [0.5, 1],
              // rotateWithView: true,
            }),
          }),
        ];
      }
    },
    styleFunc() {
      return () => {
        return [
          new Style({
            stroke: new Stroke({
              color: 'green',
              width: 3,
            }),
            fill: new Fill({
              color: 'rgba(0, 255, 0, 0.2)',
            }),
          }),
        ];
      }
    }
  },
  computed: {
    points: {
      get() {
        if (this.object.length === 0) {
          return [];
        }
        return [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              'coordinates': this.object
            },
            properties: {
              type: 'huntingRegions'
            }
          }
        ];
      },
      set() {
      }
    },
    ...mapGetters('huntingRegions', {
      isEnabled: 'isEnabled',
    }),
    ...mapState({
      features: state => state.huntingRegions.features,
      object: state => state.huntingRegions.coordinates,
    }),
  }
}
</script>
