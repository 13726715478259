import moment from "moment/moment";
import {appBus} from "@/main";
import {ON_RESIZE, ON_SIDEBAR_HIDE, ON_SIDEBAR_SHOW} from "@/events";
import {find, findIndex} from "lodash";
import Vue from "vue";

const getDefaultState = () => {
    return {
        fullscreen: false,
        sidebarStatus: true,
        page: 'map',
        sidebar: 'event',
        mapSearch: false,
        contextMenu: false,
        mutedEvents: [],
        date: moment().format(moment.HTML5_FMT.DATE),
        pages: [
            'map',
            'calendar',
        ],
        sidebars: [
            'event',
            'calendar',
        ]
    }
};

const state = getDefaultState();

const getters = {
    page: state => {
        return state.page;
    },
    isEventMuted: (state) => (eventUuid) => {
        const muted = find(state.mutedEvents, function (o) {
            return o === eventUuid;
        });
        return muted !== undefined;
    }
};

const actions = {
    setPage: (commit, page) => {
        commit("SET_PAGE", page);
    },
    setSidebar: ({commit, dispatch}, sidebar) => {
        commit("SET_SIDEBAR", sidebar);
        dispatch('showSidebar');
    },
    toggleSidebar: ({state, dispatch}) => {
        if (state.sidebarStatus) {
            dispatch('hideSidebar')
        } else {
            dispatch('showSidebar')
        }
    },
    hideSidebar: ({state, commit}) => {
        if (state.sidebarStatus) {
            commit('SET_SIDEBAR_STATUS', false);
            appBus.$emit(ON_SIDEBAR_HIDE);
            appBus.$emit(ON_RESIZE);
        }
    },
    showSidebar: ({state, commit}) => {
        if (!state.sidebarStatus) {
            commit('SET_SIDEBAR_STATUS', true);
            appBus.$emit(ON_SIDEBAR_SHOW);
            appBus.$emit(ON_RESIZE);
        }
    },
    setDate: ({commit}, date) => {
        commit("SET_DATE", date);
    },
    toggleMapSearch: ({state, commit, dispatch}) => {
        commit("SET_MAP_SEARCH", !state.mapSearch);
        dispatch("showSidebar");
    },
    showMapSearch: ({commit, dispatch}) => {
        commit("SET_MAP_SEARCH", true);
        dispatch("showSidebar");
    },
    hideMapSearch: ({commit}) => {
        commit("SET_MAP_SEARCH", false);
    },
    toggleFullscreen: ({commit}) => {
        commit("TOGGLE_FULLSCREEN");
    },
    resetState({commit}) {
        commit('resetState');
    },
};

const mutations = {
    SET_PAGE: (state, page) => {
        state.page = page;
    },
    SET_SIDEBAR: (state, sidebar) => {
        state.sidebar = sidebar;
    },
    SET_SIDEBAR_STATUS: (state, value) => {
        state.sidebarStatus = value;
    },
    SET_CONTEXT_MENU: (state, value) => {
        state.contextMenu = value;
    },
    SET_DATE: (state, date) => {
        state.date = date;
    },
    TOGGLE_FULLSCREEN: (state) => {
        state.fullscreen = !state.fullscreen;
    },
    SET_MAP_SEARCH: (state, status) => {
        state.mapSearch = status;
    },
    toggleEventMute(state, eventUuid) {
        const index = findIndex(state.mutedEvents, function (o) {
            return o === eventUuid;
        });
        if (index > -1) {
            Vue.delete(state.mutedEvents, index);
        } else {
            state.mutedEvents.push(eventUuid);
            Vue.set(state, 'mutedEvents', state.mutedEvents)
        }
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
