<template>
    <v-flex class="d-flex map-icons ma-0">
        <v-btn-toggle
                class="mr-1"
                v-model="buttons_state"
                background-color="grey darken-2"
                dense
                dark
                rounded
                multiple
        >
            <v-btn @click="toggleMapSearch" color="grey darken-2">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon size="20" v-on="on">$vuetify.icons.searchLocation</v-icon>
                    </template>
                    Vyhledávání místa
                </v-tooltip>
            </v-btn>
            <v-btn @click="toggleAutoFocus" color="grey darken-2">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon size="20" v-on="on">{{ autoFocusIcon }}</v-icon>
                    </template>
                    Vystřeďovat
                </v-tooltip>
            </v-btn>
            <v-btn fab small dark :color="muvLayer ? 'blue darken-2' : 'grey darken-2'" @click="toggleMuvLayer">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">ter-muv</v-icon>
                    </template>
                    Speciální drážní vozidla
                </v-tooltip>
            </v-btn>
            <v-btn fab small dark :color="jpoAreasLayer ? 'blue darken-2' : 'grey darken-2'" @click="toggleJpoAreas">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">ter-regions</v-icon>
                    </template>
                    Zásahové obvody
                </v-tooltip>
            </v-btn>
        </v-btn-toggle>

        <v-menu open-on-hover v-model="baseLayerMenu" bottom offset-y max-height="80vh">
            <template v-slot:activator="{ on }">
                <v-btn
                        rounded
                        color="grey darken-2"
                        dark
                        depressed
                        v-on="on"
                >
                    {{ activeBaseLayerMenuItemName }}
                </v-btn>
            </template>

            <v-list subheader>
                <v-subheader>Volba podkladove mapy</v-subheader>
                <v-list-item-group>
                    <v-list-item
                            v-for="(menuItem) in baseLayerMenuItems"
                            :key="menuItem.layerId"
                            @click="setBaseLayer(menuItem.layerId)"
                            :disabled="menuItem.disabled"
                    >
                        <v-list-item-title>
                            {{ menuItem.title }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <v-list subheader>
                <v-subheader>Volba vrstvy</v-subheader>
                <v-list-item-group multiple>
                    <v-list-item @click.stop="toggleDroneLayer">
                        <v-list-item-action class="ml-0 mr-2">
                            <v-checkbox v-model="droneLayer" @click.prevent></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-title>Letecká mapa (DRON)</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
                <v-list-item-group mandatory v-if="droneLayer" v-model="droneLayerActiveLayer">
                    <v-list-item
                            v-for="(layer) in droneLayerLayers"
                            :key="layer"
                            :value="layer"
                    >
                        <v-list-item-action class="pl-0 ml-0 mr-2">
                            <v-checkbox on-icon="radio_button_checked" off-icon="radio_button_unchecked" v-model="droneLayerActiveLayer" :value="layer" @click.prevent></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-title>{{ layer }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

        </v-menu>
    </v-flex>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {find, forEach, isEmpty} from "lodash";
import axios from "axios";
import {parseStringPromise} from "xml2js";

export default {
    name: 'map-toolbar',
    data() {
        return {
            buttons_state: [],
            baseLayerMenu: false,
            baseLayerMenuItems: [],
        }
    },
    created() {
        this.setMenuItems();
    },
    computed: {
        ...mapState({
            activeEvent: state => state.events.active,
            points: state => state.map.points,
            isAutoFocus: state => state.map.isAutoFocus,
            extend: state => state.map.extend,
            originObject: state => state.map.originObject,
            panoramaCoordinates: state => state.panorama.coordinates,
            cadastreCoordinates: state => state.cadastre.coordinates,
            layers: state => state.map.layers,
            baseLayers: state => state.map.baseLayers,
            rawBaseLayerMenuItems: state => state.map.baseLayerMenuItems,
            jpoAreasLayer: state => state.map.jpoAreasLayer,
            muvLayer: state => state.map.muvLayer,
            zoom: state => state.map.zoom,
            droneLayerLayers: state => state.map.droneLayerLayers,
        }),
        ...mapGetters('events', {
            isActiveEvent: 'isActiveEvent',
            event: 'getActiveEvent',
            events: 'getFilteredEvents',
        }),
        ...mapGetters('map', {
            activeBaseLayerMenuItem: 'activeBaseLayerMenuItem',
            activeBaseLayer: 'activeBaseLayer',
        }),
        ...mapGetters('panorama', {
            isPanoramaEnabled: 'isEnabled',
            isPanoramaPoint: 'showPoint',
        }),
        ...mapGetters('cadastre', {
            isCadastreEnabled: 'visible',
        }),
        activeBaseLayerMenuItemName() {
            if (this.activeBaseLayerMenuItem)
                return this.activeBaseLayerMenuItem.title;
            return '-';
        },
        autoFocusIcon() {
            if (this.isAutoFocus)
                return 'ter ter-fit-on';

            return 'ter ter-fit-off';
        },
        autoFocus() {
            if (this.isAutoFocus) {
                let extend = [];
                if (this.isPanoramaEnabled) {
                    if (this.isPanoramaPoint)
                        extend = [this.panoramaCoordinates];
                } else if (this.isCadastreEnabled) {
                    extend = [this.cadastreCoordinates];
                } else if (this.isActiveEvent) {
                    extend = this.$store.getters['events/extentEvent'](this.activeEvent);
                } else {
                    forEach(this.events, (event) => {
                        extend = extend.concat(this.$store.getters['events/extentEvent'](event.uuid));
                    });
                }
                return extend;

            } else {
                return null;
            }
        },
        droneLayer: {
            get() {
                return this.$store.state.map.droneLayer
            },
            set() {
            }
        },
        droneLayerActiveLayer: {
            get() {
                return this.$store.state.map.droneLayerActiveLayer
            },
            set(layer) {
                console.log("layer", layer)
                this.$store.commit('map/setDroneLayerActiveLayer', layer);
            }
        }
    },
    methods: {
        nextBaseLayer() {

        },
        ...mapActions('map', {
            clearMapOriginObject: 'clearOriginObject',
            calculateExtend: 'calculateExtend',
            calculatePointsExtend: 'calculatePointsExtend',
            toggleAutoFocus: 'toggleAutoFocus',
            extendToArray: 'extendToArray',
            setBaseLayer: 'setBaseLayer',
        }),
        ...mapActions('panel', {
            toggleMapSearch: 'toggleMapSearch',
        }),
        toggleJpoAreas() {
            this.$store.commit('map/setJpoAreas', !this.jpoAreasLayer);
        },
        toggleMuvLayer() {
            this.$store.commit('map/setMuvLayer', !this.muvLayer);
        },
        toggleDroneLayer() {
            if (!this.droneLayer)
                this.setDroneWmsLayers()

            this.$store.commit('map/setDroneLayer', !this.droneLayer);
        },
        setMenuItems() {
            let items = [];
            let zoom = this.zoom;
            forEach(this.rawBaseLayerMenuItems, (item) => {
                let layer = find(this.layers, {'id': item.layerId});
                item.disabled = zoom > layer.settings.maxZoom || zoom < layer.settings.minZoom;
                items.push(item);
            });
            this.baseLayerMenuItems = items;
        },
        setDroneWmsLayers() {
            this.getDroneWMSCapabilities().then(
                (result) => {
                    this.$store.commit('map/setDroneLayerLayers', result);
                }
            )
        },
        async getDroneWMSCapabilities() {
            const response = await axios.get(process.env.VUE_APP_STOPA_PROXY_URL + '/mapproxy.php?url=' + 'https://mapy.tudc.cz/wms/hromada' + `&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities`);
            if (response.status !== 200) {
                throw new Error('Failed to get WMS capabilities');
            }
            const result = await parseStringPromise(response.data);
            return result.WMS_Capabilities.Capability[0].Layer[0].Layer.map(l => l.Name[0]);
        }
    },
    watch: {
        baseLayerMenu(state) {
            if (state) {
                this.setMenuItems();
            }
        },
        autoFocus(coordinates) {

            if (isEmpty(coordinates) || coordinates === null)
                return;

            this.extendToArray(coordinates);
        },
    }
}
</script>
