<template>
    <v-btn
            v-bind="$attrs"
            v-on="$listeners"
            @click="onClick"
    >
        <slot></slot>
        <v-dialog v-model="modal" width="auto" content-class="img-comparison-slider-dialog">
            <div v-if="show">
                <img-comparison-slider style="max-height: 90vh">
                    <img slot="first" style="width: 100%; height: 90vh; object-fit: cover;" :src="getMediaUrl(before)"/>
                    <img slot="second" style="width: 100%; height: 90vh; object-fit: cover;" :src="getMediaUrl(after)"/>
                </img-comparison-slider>
            </div>
        </v-dialog>
    </v-btn>
</template>
<script>

import app from "@/api/app";
import 'img-comparison-slider';

export default {
    name: "ImageComparison",
    data() {
        return {
            show: false,
            modal: false,
        }
    },
    props: {
        before: {
            type: String,
            required: true,
        },
        after: {
            type: String,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onClick() {
            this.modal = true;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        getMediaUrl(image) {
            return app.getMediaUrl(this.event.id, image);
        },
    },
}
</script>
<style>
.img-comparison-slider-dialog {
    overflow-y: hidden !important;
}
</style>