var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 dense-last",attrs:{"dense":_vm.dense,"headers":_vm.headers,"items":_vm.items,"disable-pagination":"","hide-default-footer":true,"hide-default-header":_vm.hideHeader,"sort-by":"priority","sort-desc":true},scopedSlots:_vm._u([{key:"header.actions",fn:function(){return [(_vm.canInsert)?_c('contact-button',{attrs:{"color":"secondary","autocomplete":_vm.autocomplete,"outlined":false},on:{"onSave":_vm.onSave,"onDelete":_vm.onDelete}}):_vm._e()]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-sm-subtitle-1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"text-sm-body-2 text--secondary"},[_vm._v(_vm._s(item.contactTypeName))])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-sm-subtitle-1 text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("phone")(_vm.sanitizeNumber(item.number)))+" "),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.number),expression:"item.number"}],on:{"click":function($event){return _vm.call(item)}}},[_vm._v("fa-phone-volume")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$can('manage', 'EventContact'))?_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.isActiveEvent && _vm.canSend && _vm.canAddItem(item)),expression:"isActiveEvent && canSend && canAddItem(item)"}],attrs:{"fab":"","x-small":"","color":_vm.buttons.send.color,"disabled":!_vm.canAddToEvent(item),"dark":_vm.canAddToEvent(item),"depressed":"","outlined":_vm.buttons.send.outlined},on:{"click":function($event){return _vm.addToEventConfirm(item)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.buttons.send.icon)}})],1):_vm._e()]}}],null,true)},[_vm._v(" Odeslání kontaktu na výjezdový telefon ")]),(item.editable)?_c('contact-button',{attrs:{"icon":'more_vert',"autocomplete":_vm.autocomplete,"contact":item},on:{"onSave":_vm.onSave,"onDelete":_vm.onDelete}}):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" žádné kontakty ")]},proxy:true}],null,true)}),_c('confirm-dialog',{ref:"confirm",attrs:{"type":"delete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }