<template>
    <div class="event-detail tab-overflow fill-height grey lighten-2 pt-2">
        <event-form :is-call="false" :allow-chat="allowChat"></event-form>
        <v-chip class="ma-1" color="primary" v-for="(tag, index) in event.eventToTags" :key="index">
            {{ tag }}
        </v-chip>
    </div>
</template>

<script>
    import eventForm from './Form'
    import { mapState} from "vuex";
    import {appBus} from "@/main";
    import {SHOW_EVENT_VIEW} from "@/events";

    export default {
        name: 'sidebar-event-detail',
        components: {
            eventForm,
        },
        props: {
            allowChat: {
                type: Boolean,
                default: true,
            }
        },
        computed: {
            ...mapState({
                event: state => state.events.local[state.events.active],
                state: state => state.events.local[state.events.active].state,
            }),
        },
        methods: {
            showEventJournal() {
                appBus.$emit(SHOW_EVENT_VIEW, this.event);
            },
        },
    }
</script>
