<template>
    <v-flex d-flex fill-height>
        <v-layout column class="event-messages">
            <message-form ref="messageForm" :eventId="eventId"
                          v-bind:messageCategory="messageCategory"></message-form>
            <v-flex fill-height column class="messages" :class="messageCategory.color">
<!--                <image-viewer>-->
                    <v-lazy v-for="(message, index) in messages"
                            :key="message.id" min-height="40" class="flex-shrink-0">
                        <message v-bind:unvisited="messages"
                                 v-bind:previous="previous(index)"
                                 v-bind:next="next(index)"
                                 v-bind:isNew="isNew"
                                 v-bind:message="message"
                                 v-bind:category="messageCategory"
                                 v-bind="message"/>
                    </v-lazy>
<!--                </image-viewer>-->
            </v-flex>
        </v-layout>
    </v-flex>
</template>

<script>

import {mapGetters, mapState} from 'vuex'
import MessageForm from './MessageForm'
import Message from './Message'

export default {
    name: 'sidebar-event-messages',
    components: {
        MessageForm,
        Message,
    },
    data() {
        return {
            isNew: false,
        }
    },
    updated() {
        this.isNew = true;
    },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        messageCategory: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('messages', {
            unvisitedByEvent: 'unvisitedByEvent',
            eventMessages: 'eventMessages',
        }),
        ...mapState({
            messageCategories: state => state.messages.categories,
            event: state => state.events.local[state.events.active],
        }),
        unvisited() {
            return this.unvisitedByEvent(this.event, this.messageCategory.id);
        },
        messages() {
            return this.eventMessages({eventUuid: this.event.uuid, messageCategoryId: this.messageCategory.id});
        },
    },
    methods: {
        previous(index) {
            let messages = this.messages;
            if (index < messages.length)
                return messages[index - 1];

            return null;
        },
        next(index) {
            let messages = this.messages;
            if (index >= 0 && index < messages.length - 1)
                return messages[index + 1];

            return null;
        },
    },
}
</script>
