<template>
    <div v-if="status">
        <v-dialog v-model="status" max-width="1100" scrollable>
            <v-card class="grey lighten-2" :loading="requestingTest" :disabled="requestingTest">
                <v-toolbar color="default" dark dense>
                    <v-toolbar-title>
                        Přehled zařízení jednotky
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items class="pl-3">
                        <v-btn fab x-small color="red" @click="status = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-title>
                  <v-btn color="error" @click="test" class="mb-3">Otestovat služební zařízení</v-btn>
                  <v-select
                      v-if="userCan('device.all')"
                      append-icon="arrow_drop_down"
                      v-model="selectedBaseId"
                      :items="baseOptions"
                      class="ml-3"
                  ></v-select>
                </v-card-title>
                <v-card-text max-height="90vh">
                    <device-table v-if="jobPhoneDevices.length > 0"
                                  v-on:onDetail="onDetail"
                                  v-bind:title="'Výjezdový mobilní telefon'"
                                  v-bind:devices="jobPhoneDevices"></device-table>
                    <device-table v-if="jobTabletDevices.length > 0"
                                  v-on:onDetail="onDetail"
                                  v-bind:title="'Výjezdový tablet'"
                                  v-bind:devices="jobTabletDevices"></device-table>
                    <device-table v-if="navigationDevices.length > 0"
                                  v-on:onDetail="onDetail"
                                  v-bind:title="'Vozidlová navigace'"
                                  v-bind:devices="navigationDevices"></device-table>
                    <device-table v-if="droneDevices.length > 0"
                                  v-on:onDetail="onDetail"
                                  v-bind:allowTests="false"
                                  v-bind:title="'Dron zažízení'"
                                  v-bind:devices="droneDevices"></device-table>
                    <device-table v-if="otherDevices.length > 0"
                                  v-on:onDetail="onDetail"
                                  v-bind:title="'Ostatní služební zařízení'"
                                  v-bind:devices="otherDevices"></device-table>
                    <device-table v-if="privateDevices.length > 0"
                                  v-on:onDetail="onDetail"
                                  v-bind:title="'Soukromá zařízení'" v-bind:show="false" v-bind:toggle="true"
                                  v-bind:devices="privateDevices"></device-table>
                </v-card-text>
                <v-divider></v-divider>
            </v-card>
        </v-dialog>
        <v-dialog v-if="detail" v-model="detail" max-width="600" scrollable>
            <device-detail v-bind:device="detail"></device-detail>
        </v-dialog>
    </div>
</template>
<script>
    import {mapGetters, mapState} from "vuex";
    import deviceTable from "./DeviceTable";
    import {filter, map} from "lodash";
    import deviceDetail from "./DeviceDetail";

    export default {
        components: {
            deviceTable,
            deviceDetail
        },
      created() {
          this.selectedBaseId = this.baseId
      },
      data() {
            return {
                requestingTest: false,
                detail: false,
                selectedBaseId: null,
            }
        },
        computed: {
            status: {
                get() {
                    return this.$store.state.deviceList.status
                },
                set() {
                    this.$store.dispatch("deviceList/toggle");
                }
            },
            ...mapState({
                baseId: state => state.auth.user.baseId,
            }),
            ...mapGetters('auth', {
              'userCan': 'userCan'
            }),
            ...mapGetters('bases', {
              baseOptions: 'options',
            }),
            ...mapGetters('devices', {
                'baseDevices': 'byBase'
            }),
            devices() {
                return this.baseDevices(this.selectedBaseId);
            },
            corporateDevices() {
                return filter(this.devices, function (o) {
                    return o.ownership === 'corporate';
                });
            },
            jobPhoneDevices() {
                return filter(this.corporateDevices, function (o) {
                    return o.code.substring(0, 1) === 'M';
                });
            },
            jobTabletDevices() {
                return filter(this.corporateDevices, function (o) {
                    return o.code.substring(0, 1) === 'T';
                });
            },
            navigationDevices() {
                return filter(this.corporateDevices, function (o) {
                    return o.code.substring(0, 1) === 'N';
                });
            },
            droneDevices() {
                return filter(this.corporateDevices, function (o) {
                    return o.code.substring(0, 2) === 'DC';
                });
            },
            otherDevices() {
                return filter(this.corporateDevices, function (o) {
                    return o.code.substring(0, 1) === 'X';
                });
            },
            privateDevices() {
                return filter(this.devices, function (o) {
                    return o.ownership === 'private';
                });
            }
        },
        methods: {
            test() {
                this.requestingTest = true;
                this.$store.dispatch('deviceList/test', map(this.corporateDevices, 'id')).then(() => {
                    this.requestingTest = false;
                });
            },
            onDetail(device) {
                this.detail = device;
            }
        },
    }
</script>
