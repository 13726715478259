<template>
    <div>
        <layer-events-target :screen-shot-active="screenShotActive"></layer-events-target>
        <layer-events-jobs :screen-shot-active="screenShotActive"></layer-events-jobs>
        <event v-if="isActiveEvent" v-bind:event="activeEvent"
               :screenShotActive="screenShotActive"></event>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import Event from './event/Event'
import LayerEventsTarget from "@/components/map/layers/event/Targets.vue";
import LayerEventsJobs from "@/components/map/layers/event/Jobs.vue";

export default {
    name: 'layer-events',
    components: {
        LayerEventsJobs,
        LayerEventsTarget,
        Event,
    },
    props: {
        screenShotActive: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        ...mapGetters('events', {
            isActiveEvent: 'isActiveEvent',
            activeEvent: 'getActiveEvent',
            activeEventKey: 'getActiveEventId',
        }),
        ...mapState({
            isMobile: state => state.isMobile,
            activeEventUuid: state => state.events.active,
        }),
    },
    methods: {
    },
}
</script>
