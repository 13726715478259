<template>
    <v-card v-if="active" class="event-object-wrapper">
        <v-toolbar
                color="default"
                dark
                dense
        >
            <v-toolbar-title>
                <small>
                    Upravujete poznámku na mapě
                </small>
            </v-toolbar-title>
        </v-toolbar>
    </v-card>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: 'map-card-event-object',
        computed: {
            ...mapState({
                active: state => state.eventPoises.active,
            }),
        },
    }
</script>
