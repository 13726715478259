<template>
    <v-chip small :color="tag.color" :dark="!darkMode">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                    <span v-on="on">
                        {{ tag.shortName }}
                    </span>
            </template>
            {{ tooltip }}
        </v-tooltip>
    </v-chip>
</template>

<script>

export default {
    name: 'event-tag',
    props: [
        'tag',
    ],
    computed: {
        tooltip() {
            return this.tag.state === 1 ? this.tag.tooltipActive : this.tag.tooltipInactive;
        },
        darkMode() {
            return this.tag.colorText === 'dark';
        },
        colorText() {
            return this.tag.colorText === 'light' ? 'white' : 'black';
        },
    },
}

</script>
