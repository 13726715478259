<template>
    <v-card flat class="text-caption" style="max-height: 60vh; overflow: auto">
        <v-btn color="info" text @click="error">Simulate Error</v-btn>
    </v-card>
</template>

<script>

    export default {
        name: 'dev-errors',
        computed: {
          errors() {
                return this.$store.state.errors;
            }
        },
        methods: {
          error() {
            throw new Error('Test error');
          }
        }
    }
</script>
