<template>
    <v-card dark class="event-guide">
        <v-card-title>
            <h3>Otázka A?</h3>
            <div>
                <v-btn @click="choose('Toto je text z odpovedi A1')"
                       v-b-tooltip.hover title="Test">Odpověd A1
                </v-btn>
                <v-btn @click="choose('Toto je text z odpovedi A2')"
                       v-b-tooltip.hover title="Test">Odpověd A2
                </v-btn>
                <v-btn @click="choose('Toto je text z odpovedi A3')"
                       v-b-tooltip.hover title="Test">Odpověd A3
                </v-btn>
            </div>
        </v-card-title>
        <v-card-title>
            <h3>Otázka B?</h3>
            <div>
                <v-btn
                        @click="choose('Toto je text z odpovedi B1')">
                    Odpověd A1
                </v-btn>
                <v-btn
                        @click="choose('Toto je text z odpovedi B2')">
                    Odpověd A2
                </v-btn>
            </div>
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="info" @click="close">Zavřít</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'event-guide',
        methods: {
            choose(content) {
                this.$emit('onResult', content);
            },
            close() {
                this.$emit('onClose', true);
            }
        }
    }
</script>
