<template>
    <div class="message-tags-dots">
        <v-tooltip v-for="(tag, key) in tags" :key="key" left>
            <template v-slot:activator="{ on }">
                <v-chip small :dark="tag.colorText === 'light'" v-on="on" :color="tag.color" class="mr-1 mb-1">
                    {{ tag.shortName }}
                </v-chip>
            </template>
            {{ tag.tooltipActive }}
        </v-tooltip>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {sortBy} from "lodash/collection";

export default {
    name: 'message-tag-dots',
    props: [
        'message',
    ],
    computed: {
        ...mapGetters('messages', {
            appliedTags: 'appliedTags',
        }),
        tags() {
           return sortBy(this.appliedTags(this.message), ['sort']);
        },
    },
}
</script>