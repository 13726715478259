import moment from "moment/moment";
import {filter, forEach} from "lodash";
import {stateMerge} from "../object-merge";

const deviceLocationInvalidateAfterSeconds = 300;

const getDefaultState = () => {
    return {
        items: {},
        statuses: {
            0: 'Vypnuto',
            1: 'Na výjezdu',
            2: 'Pozorovatel',
            3: 'Navigace',
        },
    }
};

const state = getDefaultState();

const getters = {
    byEventJobIdForMap: (state) => (id) => {
        return filter(state.items, function (o) {
            return o !== null && o.status === 1 && o.eventJobId === id && o.locationLatitude > 0 && o.locationLongitude && moment().diff(moment.unix(o.locationAt), 'seconds') <= deviceLocationInvalidateAfterSeconds;
        });
    },
    byBase: (state) => (id) => {
        return filter(state.items, function (o) {
            return o.baseId === id;
        });
    },
    extend: (state, getters) => {
        return (eventJobId) => {
            let extent = [];
            forEach(getters['byEventJobIdForMap'](eventJobId), function (object) {
                extent.push([object.locationLongitude, object.locationLatitude])
            });
            return extent;
        }
    },
    status: (state) => {
        return (device) => {
            return state.statuses[device.status];
        }
    },
    tooltip: () => (device) => {

        let content = 'Poloha: +-' + device.locationAccuracy + 'm<br />' +
            'Aktuální k: ' + moment(device.locationAt * 1000).format('HH:mm DD.MM.YYYY');

        return {
            title: device.code + ' ' + device.name,
            content: content,
        };
    },
};

const actions = {
    set({commit}, payload) {
        commit("MERGE_STATE", payload);
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
