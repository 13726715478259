<template>
  <v-dialog v-model="dialog" max-width="460">
    <v-card dark :loading="loading" :disabled="loading">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <div>
              <v-icon>sms</v-icon>
            </div>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Odeslání sms zprávy</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="close()">
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-form ref="form" class="pa-3">
        <div class="text-center mb-4">
          <span class="title">{{ name }}</span><br>
          {{ form.number }}
        </div>
        <v-textarea
            label="Text zprávy"
            v-model="form.text"
            outlined
            counter="155"
            maxlength="155"
            :error-messages="textErrors"
        ></v-textarea>
      </v-form>
      <v-card-actions>
        <v-btn text @click="close()">Zavřít</v-btn>
        <div class="flex-grow-1"></div>
        <v-btn color="success" @click="send">Odeslat SMS</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {validationMixin} from "vuelidate";
import { maxLength, minLength, required} from "vuelidate/lib/validators";
import stopa from "@/api/stopa";
import {mapGetters} from "vuex";

export default {
  name: 'sms-contact-form',
  mixins: [validationMixin],
  mounted() {
    this.form = {
      name: this.name,
      number: this.number ? this.number.toString() : '',
      text: '',
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      form: {
        number: null,
        baseUid: null,
      },
    }
  },
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    number: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
  validations: {
    form: {
      text: {
        required,
      },
      number: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(9)
      }
    }
  },
  computed: {
    ...mapGetters('events', {
      eventById: 'byId',
    }),
    ...mapGetters('bases', {
      baseById: 'byId',
    }),
    textErrors() {
      const errors = []
      if (!this.$v.form.text.$dirty) return errors
      !this.$v.form.text.required && errors.push('Zadejte text sms zprávy.')
      return errors
    },
  },
  methods: {
    open() {
      let event = this.eventById(this.eventId);
      let base = this.baseById(event.baseId);

      this.form.baseUid = base.uid;
      this.form.number = this.number;

      this.loading = true;
      this.dialog = true;

      let url = '/tereza/occurrence/info?id='+event.uid+'&base_id='+this.form.baseUid;
      stopa.get(url).then((response) => {
        this.form.text = response.data.sms;
      })
          .finally(() => {
            this.loading = false;
          })
          .catch(() => {
          })
    },
    validate() {
      this.loading = true;
      this.$v.form.$touch();
      return !this.$v.$invalid;
    },
    close() {
      this.dialog = false;
      this.$emit('onClose');
    },
    send() {
      this.loading = true;

      if (!this.validate()) {
        this.loading = false;
        return;
      }

      let url = '/tereza/contact/sms';

      let formData = new FormData();
      formData.append('base_id', this.form.baseUid);
      formData.append('number', this.form.number);
      formData.append('content', this.form.text);

      stopa.post(url, formData).then(() => {
        this.$store.dispatch('notifications/add', {type: 'success', message: 'Sms byla odeslána'}, {root: true});
        this.close();
      }).finally(() => {
        this.loading = false;
      }).catch(() => {
        this.$store.dispatch('notifications/add', {type: 'error', message: 'Sms nebyla odeslána'}, {root: true});
      })
    },
  }
}
</script>
