<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-container class="primary" fluid fill-height>
        <v-layout flex align-center justify-center>
            <v-flex xs12 sm4 elevation-6>
                <v-card :loading="loading" :disabled="loading">
                    <v-toolbar dark>
                        <v-toolbar-title>Panel operační situace HZS SŽ
                            <v-chip v-if="env!=='production'" color="warning" class="mr-1">{{ env }}</v-chip>
                        </v-toolbar-title>
                        <div class="flex-grow-1"></div>
                    </v-toolbar>
                    <v-card-text class="pt-6">
                        <div>
                            <v-form @keyup.native.enter="onSubmit" @submit="onSubmit" ref="form" v-if="show">
                                <v-text-field
                                    label="Uživatelské jméno"
                                    prepend-icon="person"
                                    v-model="form.username"
                                    :error-messages="usernameErrors"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    label="Heslo"
                                    prepend-icon="lock"
                                    v-model="form.password"
                                    :error-messages="passwordErrors"
                                    type="password"
                                    required
                                ></v-text-field>
                                <v-card-actions>
                                    <v-layout justify-space-between>
                                        <v-btn @click="onSubmit" color="primary">Přihlásit se</v-btn>
                                    </v-layout>
                                </v-card-actions>
                            </v-form>
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import {mapState} from 'vuex'
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
    name: 'panel',
    mixins: [validationMixin],
    data() {
        return {
            env: process.env.VUE_APP_ENV,
            loading: false,
            show: true,
            form: {
                username: null,
                password: null,
            },
        }
    },
    mounted() {
        this.$pubnub.unsubscribeAll();
    },
    validations: {
        form: {
            username: {
                required,
            },
            password: {
                required,
            }
        }
    },
    computed: {
        ...mapState({
            setup: state => state.setup.setup
        }),
        usernameErrors() {
            const errors = []
            if (!this.$v.form.username.$dirty) return errors
            !this.$v.form.username.required && errors.push('Prosím zadejte uživatelské jméno')
            return errors
        },
        passwordErrors() {
            const errors = []
            if (!this.$v.form.password.$dirty) return errors
            !this.$v.form.password.required && errors.push('Prosím zadejte heslo')
            return errors
        },
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault();
            this.login(this.form);
        },
        login(credentials) {

            this.loading = true;

            this.$v.form.$touch();
            if (this.$v.$invalid) {
                this.loading = false;
                return false;
            }

            this.$store.dispatch('auth/login', credentials)
                .then(response => {
                    this.$noty.success("Úspěšné přihlášení.<br />Vítejte: " + response.user.firstName + ' ' + response.user.lastName);
                }, (error) => {
                    this.$noty.error('' + error);
                }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>
