import {stateMerge} from "../object-merge";
import {findKey, forEach, pickBy} from "lodash";

const getDefaultState = () => {
    return {
        items: {},
    }
};

export const confirmTagCode = 'CONF';

const state = getDefaultState();

const getters = {

    /**
     * 
     * @param state
     * @returns {function(*): *}
     */
    byId: (state) => (id) => {
        return state.items[id];
    },

    /**
     * 
     * @param state
     * @param getters
     * @returns {(function(*): (*|null))|*}
     */
    byCode: (state, getters) => (code) => {
        let key = findKey(state.items, function (o) {
            return o.code === code;
        });
        let item = getters.byId(key);
        if (item !== undefined)
            return item;
        return null;
    },

    /**
     *
     * @param state
     * @returns {function(*): *}
     */
    byCategory: (state) => (id) => {
        id = parseInt(id);
        let tags = pickBy(state.items, (o) => {
            return o.tagCategoryId === id;
        });
        return tags;
    },

    /**
     *
     * @param state
     * @param getters
     * @returns {function(*=): *}
     */
    byMessage: (state, getters) => (message) => {
        let tags = [];
        if (message?.tags) {
            forEach(message.tags, function (item, id) {
                if (item && id > 0) {
                    let tag = JSON.parse(JSON.stringify(getters['byId'](id)));
                    tag.state = 1;
                    tag.show = 1;
                    tags.push(tag);
                }
            });
        }
        return tags;
    }
};

const actions = {
    async set({commit}, items) {
        commit("MERGE_STATE", items)
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
