var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-chip-group',{staticClass:"px-2",attrs:{"column":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}},[_c('v-chip',{attrs:{"filter":"","outlined":"","value":69}},[_vm._v("Dron")]),_c('v-chip',{attrs:{"filter":"","outlined":"","value":70}},[_vm._v("Inspektor")])],1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filtrovat","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"height":_vm.tableHeight,"fixed-header":"","dense":"","multi-sort":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.filteredResources,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"disable-pagination":"","hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.select},scopedSlots:_vm._u([{key:"item.event",fn:function(ref){
var item = ref.item;
return [(item.event)?_c('span',[_c('v-tooltip',{attrs:{"right":"","content-class":"map-tooltip-wrapper"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{attrs:{"size":"40","color":_vm.eventCategory(item.event.eventCategoryId).color},on:{"click":function($event){$event.stopPropagation();return _vm.showEventView(item.event)}}},[_vm._v(_vm._s(_vm.eventCategory(item.event.eventCategoryId).icon))])],1)]}}],null,true)},[_c('tooltip-content',{attrs:{"tooltip":_vm.tooltip(item.event)}})],1)],1):_vm._e()]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('event-job-icon',{staticClass:"my-2",attrs:{"resource":item,"job":item.eventJob ? item.eventJob : null}})]}},{key:"item.baseId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.baseName)+" ")]}},{key:"item.resourceTypeId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.resourceTypeById(item.resourceTypeId).name)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }