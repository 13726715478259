<template>
    <v-dialog v-model="dialog" scrollable>
        <v-card height="90vh">
            <v-toolbar flat dense dark>
                <v-toolbar-title>{{ name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn rounded small light class="mt-1" @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <iframe id="iframe" ref="iframe" :src="url" @load="load" v-show="loaded" width="100%"
                    height="100%"></iframe>
            <div v-if="!loaded">Nahrávám</div>
        </v-card>
    </v-dialog>
</template>
<script>

import {appBus} from "@/main";
import {ON_OPEN_IFRAME} from "@/events";

export default {
    name: "IframeModal",
    props: {
        viewerOptions: {
            type: Object,
            required: false,
            default: () => ({})
        },
    },
    created() {
        appBus.$on(ON_OPEN_IFRAME, (url, name) => {
            this.url = null;
            this.loaded = false;
            this.$nextTick(() => {
                this.url = url;
                this.name = name;
                this.openIframe();
            });
        });
    },
    data() {
        return {
            dialog: false,
            loaded: false,
            url: null,
            name: null,
        }
    },
    methods: {
        openIframe() {
            this.dialog = true;
        },
        unloadIframe() {
            this.url = 'about:blank';
            this.loaded = false;
        },
        load: function () {
            this.loaded = true;
            // console.log('panel', this.$refs.iframe.contentWindow.document );
            // console.log('panel', this.$refs.iframe.contentWindow.document.getSelection('div.panel-header') );
            // console.log('panel', this.$refs.iframe.isContentEditable );
        }
    },
    watch: {
        dialog(value) {
            if (value === false)
                this.unloadIframe();
        }
    },
    beforeDestroy() {
        this.unloadIframe();
    }
}
</script>
