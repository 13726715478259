// import setup from '../../api/setup'
import {set, find} from "lodash";

// initial state
const getDefaultState = () => {
    return {
        endpoints: {
            apiUrl: process.env.VUE_APP_TEREZA_API_URL,
            apiSearchUrl: process.env.VUE_APP_TEREZA_API_URL + '/v2/search/query',
            // apiSearchUrl: 'https://address-api.test.tereza.giss.cz/jsonrpc/',
            apiReverseGeoUrl: process.env.VUE_APP_TEREZA_API_URL + '/v2/search/reverse',
            // apiCadastreUrl: process.env.VUE_APP_TEREZA_API_URL + '/v2/cadastre',
            apiCadastreUrl: process.env.VUE_APP_STOPA_PROXY_URL + '/tereza/cadastre/get',
            proxyUrl: process.env.VUE_APP_TEREZA_API_URL + '/proxy.php',
            featureUrl: process.env.VUE_APP_TEREZA_API_URL + '/feature-proxy.php',
            imageProxyUrl: process.env.VUE_APP_ENV === 'local' ? '' : process.env.VUE_APP_TEREZA_API_URL + '/image-proxy.php?url=',
            railImageUrl: process.env.VUE_APP_STOPA_PROXY_URL + '/tereza/railPhotos',
            stopaMapProxyUrl: process.env.VUE_APP_STOPA_PROXY_URL + '/mapproxy.php?url=',
            terezaMapProxyUrl: process.env.VUE_APP_TEREZA_API_URL + '/mapproxy.php?url=',
        },

        search: {
            types: {
                town: {
                    title: 'Obec',
                    icon: 'fa-city',
                    terrainId: 2,
                },
                townPart: {
                    title: 'Část obce',
                    icon: 'fa-city',
                    terrainId: 2,
                },
                street: {
                    title: 'Ulice',
                    icon: 'fa-road',
                    terrainId: 2,
                },
                address: {
                    title: 'Adresa',
                    icon: 'fa-building',
                    terrainId: 2,
                },
                bases: {
                    title: 'Základna',
                    icon: null,
                    terrainId: 1,
                },
                borders: {
                    title: 'Hranice',
                    icon: null,
                    terrainId: 1,
                },
                crossings: {
                    title: 'Přejezd',
                    icon: 'fa-traffic-light',
                    terrainId: 1,
                },
                hectometers: {
                    title: 'Hektometr',
                    icon: 'fa-grip-lines',
                    terrainId: 1,
                },
                inspectors: {
                    title: 'Vyšetřovatel',
                    icon: null,
                    terrainId: 1,
                },
                others: {
                    title: 'Ostatní',
                    icon: null,
                    terrainId: 1,
                },
                stations: {
                    title: 'Stanice',
                    icon: 'fa-school',
                    terrainId: 1,
                },
                stops: {
                    title: 'Zastávka',
                    icon: 'fa-school',
                    terrainId: 1,
                },
                transports: {
                    title: 'Dopravna',
                    icon: null,
                    terrainId: 1,
                },
                traumapoints: {
                    title: 'Traumabod',
                    icon: null,
                    terrainId: 1,
                },
                trackgroups: {
                    title: 'Kolejová skupina',
                    icon: null,
                    terrainId: 1,
                },
                trainnumber: {
                    title: 'Číslo vlaku',
                    icon: 'train',
                    terrainId: 1,
                },
                turnouts: {
                    title: 'Výhybna',
                    icon: null,
                    terrainId: 1,
                },
            },
            groups: [
                {
                    id: 'isor',
                    icon: 'train',
                    name: 'ISOR',
                    label: 've Vlacích',
                    placeholder: '',
                    type: ['trainnumber'],
                    key: '*',
                    default: 0,
                    active: 0,
                    inputType: 'number',
                },
                {
                    id: 'ruian',
                    icon: 'ruian',
                    name: 'Rui',
                    label: 'v Obci',
                    placeholder: 'adresních',
                    type: null,
                    typeRules: [
                        {
                            order: 1,
                            regex: '\\D+\\s+\\d',
                            type: ['address', 'street'],
                        },
                        {
                            order: 2,
                            regex: null,
                            type: ['street', 'town', 'townPart'],
                        }
                    ],
                    key: '/',
                    default: 0,
                    active: 0,
                    inputType: 'text',
                },
                {
                    id: 'train',
                    icon: 'sations',
                    name: 'Žel',
                    label: 'na Železnici',
                    placeholder: 'železnicních',
                    type: ['stations', 'hectometers', 'crossings', 'traumapoints', 'stops', 'borders', 'transports', 'trackgroups', 'turnouts'],
                    key: '*',
                    default: 1,
                    active: 1,
                    inputType: 'text',
                }
            ],
        },
        events: {
            messages: {
                form: {
                    sendOnEnter: true,
                }
            },
            excludeTypes: []
        },
        autoRefresh: true,
        autoRefreshPeriod: 8000,
        autoRefreshTimeout: 0,
        darkMode: false,
        radio: {
            minDuration: 0.5,
            maxDuration: 60,
        },
        setup: {
            uuid: '1ad7a04e-0149-5799-bfc5-7ae8e180b61f',
            unit: {
                "id": 17,
                "uid": 9909999,
                "spaceId": 1,
                "name": "JPO TEST",
                "code": "TST",
                "latitude": 50.15601400,
                "longitude": 15.82544400
            },
            eventSourceId: 2,
            eventTypes: [
                {
                    "id": 1,
                    "uid": 3200,
                    "spaceId": 1,
                    "code": "DN",
                    "name": "DOPRAVNÍ NEHODA",
                    "priority": 0
                }, {"id": 2, "uid": 3900, "spaceId": 1, "code": "JZN", "name": "JINÉ, ZATÍM NEURČENO", "priority": 0}, {
                    "id": 3,
                    "uid": 3700,
                    "spaceId": 1,
                    "code": "OMU",
                    "name": "OSTATNÍ MIMOŘÁDNÁ UDÁLOST",
                    "priority": 0
                }, {"id": 4, "uid": 4100, "spaceId": 1, "code": "OP", "name": "OSTATNÍ POMOC", "priority": 1}, {
                    "id": 5,
                    "uid": 3800,
                    "spaceId": 1,
                    "code": "PP",
                    "name": "PLANÝ POPLACH",
                    "priority": 0
                }, {"id": 6, "uid": 3100, "spaceId": 1, "code": "POŽÁR", "name": "POŽÁR", "priority": 0}, {
                    "id": 7,
                    "uid": 4000,
                    "spaceId": 1,
                    "code": "TH",
                    "name": "TECHNICKÁ HAVÁRIE",
                    "priority": 0
                }, {"id": 8, "uid": 3500, "spaceId": 1, "code": "TP", "name": "TECHNICKÁ POMOC", "priority": 1}, {
                    "id": 9,
                    "uid": 5000,
                    "spaceId": 1,
                    "code": "UNO",
                    "name": "UDÁLOST NA OBJEKT",
                    "priority": 0
                }, {
                    "id": 10,
                    "uid": 3400,
                    "spaceId": 1,
                    "code": "ÚChNL",
                    "name": "ÚNIK CHEMICKÉ NEBEZP. LÁTKY",
                    "priority": 0
                }, {
                    "id": 11,
                    "uid": 3550,
                    "spaceId": 1,
                    "code": "ZOZ",
                    "name": "ZÁCHRANA OSOB A ZVÍŘAT",
                    "priority": 0
                }, {
                    "id": 12,
                    "uid": 1010,
                    "spaceId": 1,
                    "code": "ZOČ",
                    "name": "ZOČ",
                    "priority": 0
                }
            ],
            eventStatuses: [
                {"id": 1, "uid": 100, "spaceId": 1, "name": "Čekající na odbavení", "code": "100"}, {
                    "id": 2,
                    "uid": 200,
                    "spaceId": 1,
                    "name": "Inicializovaná",
                    "code": "200"
                }, {"id": 3, "uid": 210, "spaceId": 1, "name": "Převzatá", "code": "210"}, {
                    "id": 4,
                    "uid": 300,
                    "spaceId": 1,
                    "name": "Odložená",
                    "code": "300"
                }, {"id": 5, "uid": 400, "spaceId": 1, "name": "Otevřená, bez SaP", "code": "400"}, {
                    "id": 6,
                    "uid": 410,
                    "spaceId": 1,
                    "name": "Otevřená SaP na cestě",
                    "code": "410"
                }, {"id": 7, "uid": 420, "spaceId": 1, "name": "Otevřená, SaP na místě", "code": "420"}, {
                    "id": 8,
                    "uid": 430,
                    "spaceId": 1,
                    "name": "Lokalizovaná",
                    "code": "430"
                }, {"id": 9, "uid": 440, "spaceId": 1, "name": "Likvidovaná", "code": "440"}, {
                    "id": 10,
                    "uid": 500,
                    "spaceId": 1,
                    "name": "Ukončená OS",
                    "code": "500"
                }, {"id": 11, "uid": 510, "spaceId": 1, "name": "Uzavřená OS", "code": "510"}, {
                    "id": 12,
                    "uid": 520,
                    "spaceId": 1,
                    "name": "Exportovaná do SK",
                    "code": "520"
                }, {"id": 13, "uid": 600, "spaceId": 1, "name": "Převzatá VZ", "code": "600"}, {
                    "id": 14,
                    "uid": 610,
                    "spaceId": 1,
                    "name": "Ukončená VZ",
                    "code": "610"
                }, {"id": 15, "uid": 620, "spaceId": 1, "name": "Exportovaná ZOZ Online", "code": "620"}, {
                    "id": 16,
                    "uid": 700,
                    "spaceId": 1,
                    "name": "Převzatá ZPP",
                    "code": "700"
                }, {"id": 17, "uid": 710, "spaceId": 1, "name": "Ukončená ZPP", "code": "710"}, {
                    "id": 18,
                    "uid": 750,
                    "spaceId": 1,
                    "name": "Převzatá garantem za SSU",
                    "code": "750"
                }, {"id": 19, "uid": 760, "spaceId": 1, "name": "Ukončená garantem za SSU", "code": "760"}, {
                    "id": 20,
                    "uid": 780,
                    "spaceId": 1,
                    "name": "Potvrzená krajským garantem",
                    "code": "780"
                }, {"id": 21, "uid": 800, "spaceId": 1, "name": "Připravená k archivaci", "code": "800"}
            ],
        },
        devPanel: {
            visible: false,
            activeTab: null,
        },
        event: {
            tabs: {
                detail: 1,
            }
        }
    }
};

const state = getDefaultState();

// getters
const getters = {
    excludedEventTypeIds: state => {
        return state.events.excludeTypes;
    },
    getTerrainIdByCode: state => code => {
        if (state.search.types[code] !== undefined)
            return state.search.types[code].terrainId;

        return false;
    },
    defaultSearchGroup: state => {
        let defaultGroup = find(state.search.groups, function (o) {
            return o.default === 1;
        });
        return defaultGroup.id;
    },
    searchTypeName: state => code => {
        if (state.search.types[code] !== undefined)
            return state.search.types[code].title;

        return code;
    },
};

// actions
const actions = {
    toggleAutoRefresh({commit, state}) {
        commit('setAutoRefresh', !state.autoRefresh);
    },
    toggleDevPanel: ({commit, state}) => {
        commit("setDevPanelVisibility", !state.devPanel.visible);
    },
    toggleDarkMode({commit, state}) {
        commit('setDarkMode', !state.darkMode);
    },
    setAutoRefreshTimeout({commit}, payload) {
        commit('setAutoRefreshTimeout', payload);
    },
};

// mutations
const mutations = {
    toggleSearchType(state, id) {     // TODO: Chage to action
        let item = state.search.groups.find(o => o.id === id);
        if (item !== undefined) {
            state.search.groups.forEach((type) => {
                if (type.id !== id) {
                    type.active = 0;
                }
            });
            item.active = 1;
        }
    },
    setSetting(state, payload) {
        set(state, payload.path, payload.value);
    },
    setSetup(state, setup) {
        state.setup = setup
    },
    setAutoRefresh(state, value) {
        clearTimeout(state.autoRefreshTimeout);
        state.autoRefresh = value
    },
    setDevPanelActiveTab(state, value) {
        state.devPanel.activeTab = value;
    },
    setDarkMode(state, value) {
        state.darkMode = value;
    },
    setAutoRefreshPeriod(state, value) {
        clearTimeout(state.autoRefreshTimeout);
        state.autoRefreshPeriod = value
    },
    setAutoRefreshTimeout(state, value) {
        state.autoRefreshTimeout = value
    },
    setDevPanelVisibility: (state, value) => {
        state.devPanel.visible = value;
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state: state,
    getters,
    actions,
    mutations
}
