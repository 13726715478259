<template>
    <div>
        <v-divider></v-divider>
        <v-list-item class="align-start justify-start">
            <v-list-item-content>
                <v-tooltip left content-class="map-tooltip-wrapper">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="event-job-icon" :class="'category-'+template"
                             :style="{color: options.content, backgroundColor: options.background}">
                            {{ base.code }}<br/>
                            <div v-if="iconType === 1"
                                 :style="{maskImage: 'url(' + icon + ')', backgroundColor: options.content}"></div>
                            <v-icon v-if="iconType === 0" :color="options.content" size="30" left>{{ icon }}</v-icon>
                        </div>
                    </template>
                    <tooltip-content :tooltip="tooltip(job)"></tooltip-content>
                </v-tooltip>
            </v-list-item-content>
            <v-list-item-content class="align-self-center">
                <v-list-item-subtitle class="font-weight-black text-center">
                    {{ label.content }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content class="align-self-start flex-fill">
                <v-list-item-title>{{ vehicle.name }}</v-list-item-title>
                <v-list-item-subtitle>
                    {{ base.name }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content class="align-self-start pl-2">
                <v-list-item-title class="text-left">
                    {{ vehicle.code }}
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="justify-end">
                <v-btn @click="onClick" icon outlined small color="grey darken-1">
                    <v-icon>more_vert</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import TooltipContent from "@/components/ui/TooltipContent";

export default {
    name: 'event-job',
    components: {TooltipContent},
    props: {
        job: Object,
    },
    computed: {
        base() {
            return this.getBaseById(this.job.baseId);
        },
        ...mapGetters('bases', {
            getBaseById: 'byId',
        }),
        ...mapGetters('messages', {
            unvisitedByEvent: 'unvisitedByEvent',
        }),
        ...mapGetters('eventTypes', {
            getEventType: 'get',
        }),
        ...mapGetters('resourceTypes', {
            resourceTypeById: 'byId',
        }),
        ...mapGetters('eventJobs', {
            getLabel: 'label',
            tooltip: 'tooltip',
            eventJobOptions: 'optionsByJob',
        }),
        icon() {
            if (this.resourceType.options?.icon) {
                return this.$store.state.setup.endpoints.imageProxyUrl + process.env.VUE_APP_TEREZA_API_URL + this.resourceType.options.icon.file;
            } else {
                if (this.vehicle && this.vehicle.icon)
                    return '$vuetify.icons.vehicle_' + this.vehicle.icon;
            }
            return '$vuetify.icons.vehicle_missing';
        },
        iconType() {
            if (this.resourceType.options?.icon) {
                return 1;
            } else {
                return 0;
            }
        },
        template() {
            if (this.vehicle.resourceTypeId === 1)
                return 1;

            return parseInt(this.resourceType.options?.resourceTemplateId);
        },
        resourceType() {
            return this.resourceTypeById(this.vehicle.resourceTypeId);
        },
        label() {
            return this.getLabel(this.job);
        },
        ...mapState({
            vehicle(state) {
                if (this.job.vehicle)
                    return state.resources.items[this.job.vehicle];
                return null;
            },
        }),
        options() {
            return this.eventJobOptions(this.job);
        },
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        }
    }
}
</script>
