<template>
  <v-card elevation="6" class="map-tooltip">
    <v-card-title v-html="tooltip.title"></v-card-title>
    <v-card-text class="header" v-html="tooltip.content"></v-card-text>
    <message-media class="pa-2" v-if="tooltip.comp" :is="tooltip.comp.is"
                   v-bind="tooltip.comp.data"></message-media>
    <v-card-text v-show="tooltip.overview" class="overview"
                 v-html="tooltip.overview"></v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TooltipContent",
  props: {
    tooltip: {
      type: Object,
      default: () => {
        return {
          title: '',
          content: '',
          overview: '',
        }
      }
    }
  },
};
</script>