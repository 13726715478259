<template>
    <div>
        <vl-layer-vector :zIndex="40">
            <vl-source-vector ref="points" ident="points">
                <vl-feature v-for="point in points" :key="point.uuid" :properties="{type: 'point', uuid: point.uuid, tooltip: { title: 'Adresní bod', content: point.title}}">
                    <vl-geom-point :coordinates.sync="point.coordinates"></vl-geom-point>
                </vl-feature>
                <vl-style-func :function="styleFuncFactory()"/>
            </vl-source-vector>
        </vl-layer-vector>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import Style from 'ol/style/Style'
    // import Icon from 'ol/style/Icon'
    import Circle from 'ol/style/Circle'
    import RegularShape from 'ol/style/RegularShape'
    import Fill from 'ol/style/Fill'
    import Stroke from 'ol/style/Stroke'

    export default {
        name: 'layer-points',
        computed: {
            ...mapState({
                points: state => state.map.points,
            }),
        },
        methods: {
            ...mapActions('map', [
                'calculatePointsExtend',
            ]),
            styleFuncFactory() {
                return () => {
                    return [
                        new Style({
                            zIndex: 1,
                            image: new RegularShape({
                                stroke: new Stroke({color: "black", width: 1}),
                                points: 4,
                                radius: 6,
                                radius2: 0,
                            })
                        }),
                        new Style({
                            zIndex: 1,
                            image: new Circle({
                                fill: new Fill({
                                    color: 'rgba(255, 0, 0, 0.2)',
                                }),
                                stroke: new Stroke({color: "red", width: 2}),
                                radius: 30,
                            }),
                        }),
                    ];
                }
            },
        },
        watch: {
            points: function () {
                this.calculatePointsExtend()
            }
        }
    }
</script>
