<template>
    <v-btn-toggle background-color="grey darken-2" rounded dense dark class="mr-2">
        <v-btn fab small @click="zoomIn" color="grey darken-2">
            <v-icon size="22">add</v-icon>
        </v-btn>
        <div class="pt-2 zoomLevel">
            {{ zoom }}
        </div>
        <v-btn fab small @click="zoomOut" color="grey darken-2">
            <v-icon size="28">remove</v-icon>
        </v-btn>
    </v-btn-toggle>
</template>
<script>

    import {mapState} from "vuex";

    export default {
        name: 'map-zoom-component',
        computed: {
            ...mapState({
                zoom: state => state.map.zoom,
            }),
        },
        methods: {
            zoomIn() {
                this.$store.commit('map/setZoom', this.zoom + 1);
                this.$store.commit("map/setAutoFocus", false);
            },
            zoomOut() {
                this.$store.commit('map/setZoom', this.zoom - 1);
                this.$store.commit("map/setAutoFocus", false);
            },
        },
    }
</script>
