<template>
    <v-dialog transition="slide-x-transition" fullscreen dark hide-overlay v-model="dialog">
        <file-upload v-show="0"
                     class=""
                     :post-action="upload.postAction"
                     :extensions="upload.extensions"
                     :accept="upload.accept"
                     :multiple="upload.multiple"
                     :headers="upload.headers"
                     :size="upload.size"
                     :drop="upload.drop"
                     v-model="files"
                     @input-filter="inputFilter"
                     @input-file="inputFile"
                     ref="upload">
        </file-upload>
        <v-card color="rgb(0, 0, 0, 0.7)">
            <v-container grid-list-md text-xs-center>
                <span v-show="capturing">Generuji mapu</span>
                <v-layout row wrap v-show="!capturing">
                    <v-flex xs12>
                        <v-img max-height="85vh" contain v-if="clientImageDataUrl" :src="clientImageDataUrl"></v-img>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-card-actions class="justify-center">
                <v-btn large text :disabled="!canCancel" @click="close">
                    <v-icon left>$vuetify.icons.cancel</v-icon>
                    Zrušit
                </v-btn>
                <v-btn large color="success" :disabled="!canSend" @click="send">
                    Odeslat
                    <v-icon right>$vuetify.icons.sendMessage</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import {appBus} from "@/main";
    import {mapGetters, mapState} from "vuex";
    import FileUpload from 'vue-upload-component'
    import {isEmpty, forEach} from "lodash";
    import moment from "moment/moment";

    export default {
        name: 'map-screenshot-dialog',
        components: {
            FileUpload,
        },
        created() {
            appBus.$on('onScreenshotStart', () => {
                this.clientImageDataUrl = null;
                this.serverImageBlob = null;
                this.capturing = true;
                this.dialog = true;
                this.files = [];
                this.extent = null;
            });
            appBus.$on('onScreenshotCapture', (payload) => {
                this.clientImageDataUrl = payload.clientImageDataUrl;
                this.serverImageBlob = payload.serverImageBlob;
                this.projection = payload.projection;
                this.eventId = payload.eventId;
                this.extent = payload.extent;
                this.dialog = true;
                this.capturing = false;
            });
        },
        data() {
            return {
                dialog: false,
                sending: false,
                capturing: true,
                clientImageDataUrl: null,
                serverImageBlob: null,
                projection: null,
                extent: null,
                eventId: null,
                files: [],
            }
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
                device: state => state.auth.device,
                apiUrl: state => state.tereza.apiUrl,
                viewProjection: state => state.map.viewProjection,
            }),
            ...mapGetters('events', {
                isActiveEvent: 'isActiveEvent',
                event: 'getActiveEvent',
            }),
            ...mapGetters('eventTypes', {
                getEventType: 'get',
            }),
            canCancel() {
                return this.capturing === false && this.sending === false;
            },
            canSend() {
                return this.capturing === false && this.sending === false;
            },
            upload() {
                return {
                    accept: 'image/jpeg',
                    extensions: 'jpg',
                    headers: {
                        'Authorization': this.$store.getters['auth/token'],
                    },
                }
            },
        },
        methods: {
            inputFilter(newFile, oldFile) {
                if (newFile && !oldFile) {
                    let extension = newFile.name.split('.').pop();
                    newFile.filename = this.eventId + '-' + moment().format('YYYYMMDD_HHmmss') + '-' + this.device.code + '.' + extension;
                    newFile.headers['X-File-Size-Total'] = newFile.size;
                    newFile.postAction = this.apiUrl + '/v2/media/event/' + this.eventId + '/' + newFile.filename;
                }
            },
            inputFile() {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            },
            send() {

                this.sending = true;

                this.$watch(
                    () => {
                        return this.$refs.upload.uploaded
                    },
                    (val) => {
                        if (val === true)
                            this.sendMessage();
                    }
                )

                this.uploadImage();
            },
            isFiles() {
                return !isEmpty(this.files);
            },
            getFiles() {
                if (this.isFiles()) {
                    let files = [];
                    forEach(this.files, function (file) {
                        files.push(file.filename);
                    });
                    return files;
                } else {
                    return null
                }
            },
            uploadImage() {
                this.sending = true;
                let file = new window.File([this.serverImageBlob], 'screenshot.jpg', {
                    type: this.serverImageBlob.type,
                    headers: {
                        'X-File-Size-Total': this.serverImageBlob.size
                    }
                });
                this.$refs.upload.add(file);
            },
            sendMessage() {
                this.sending = true;
                let bounds = [this.extent[3], this.extent[0], this.extent[1], this.extent[2]];
                let message = {
                    content: 'Událost č. ' + this.event.code + ', ' + ', ' + this.getEventType(this.event.eventTypeId).name + ', ' + this.event.address + ', ' + moment().format('HH:mm'),
                    type: 'X21',
                    data: JSON.stringify({
                        "ttsNotification": {"text": "Zaslán snímek mapy", "timeValidity": 60},
                        "mapBounds": bounds,
                        "projection": this.viewProjection,
                    }),
                    media: this.getFiles(),
                };
                this.$store.dispatch('messages/send', message).then(() => {
                    this.sending = false;
                    this.close();
                });
            },
            close() {
                this.dialog = false;
            },
        },
        watch: {},
        beforeDestroy() {
            appBus.$off('onScreenshotStart');
            appBus.$off('onScreenshotCapture');
        }
    }

</script>
