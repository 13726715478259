<template>
    <component :is="layer.cmp" :visible="layer.visible" :key="layer.id" v-bind="layer" :zIndex="10">
        <component :is="layer.source.cmp" v-bind="layer.source" :layers="activeLayer" :zIndex="10"></component>
    </component>
</template>

<script>

import {mapState} from "vuex";

export default {
    name: 'layer-drone',
    computed: {
        ...mapState({
            layer: state => state.map.droneLayerWms,
            activeLayer: state => state.map.droneLayerActiveLayer,
        })
    },
}
</script>
