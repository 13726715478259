<template>
  <fragment>
    <li @click="run">
      <v-icon left color="light-blue">ter-ispd</v-icon>
      Náhled v ISPD mapě
    </li>
  </fragment>
</template>
<script>
export default {
  name: 'map-ispd-context-menu',
  props: [
    'data',
  ],
  methods: {
    run() {
      window.open('https://ispdmapy.szdc.cz/?x='+this.data.coordinates[0]+'&y='+this.data.coordinates[1], '_blank');
    },
  },
}
</script>
