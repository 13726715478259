<template>
  <v-dialog v-if="isActiveEvent" v-model="dialog" transition="slide-x-transition" persistent
            max-width="400">
    <v-card>
      <v-card-title class="text-h5 justify-center">Vyberte charakter události</v-card-title>
      <v-card-text class="text-center mt-2">
          <v-btn v-for="(option) in eventTerrainOptions" :key="option.id" large
                 class="mx-1" :disabled="!canEditAttribute(event.uuid, 'eventTerrainId')" @click="setTerrainId(option.id)">
            <v-icon left v-if="option.icon">{{ option.icon }}</v-icon>
            {{ option.name }}
          </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">Zrušit</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {appBus} from "@/main";
import {mapGetters} from "vuex";
import {ON_TERRAIN_DIALOG} from "@/events";

export default {
  name: 'event-terrain-dialog',
  data() {
    return {
      dialog: false,
      contextData: null,
    }
  },
  created() {
    appBus.$on(ON_TERRAIN_DIALOG, (payload) => {
      this.dialog = true;
      this.eventTerrainId = false;
      this.contextData = payload;
    });
  },
  computed: {
    ...mapGetters('events', {
      isActiveEvent: 'isActiveEvent',
      event: 'getActiveEvent',
      canEditAttribute: 'canEditAttribute',
    }),
    ...mapGetters('eventTerrains', {
      eventTerrainOptions: 'options',
    }),
  },
  methods: {
    setTerrainId(value) {
      if (value === false)
        return;

      if (value === undefined) {
        this.dialog = false;
        this[this.contextData.action]();
        return;
      }

      let payload = {
        key: this.event.uuid,
        item: {
          eventTerrainId: value
        }
      };

      this.$store.dispatch('events/updateAttributes', payload).then(() => {
        this.dialog = false;
        this[this.contextData.action]();
      });
    },
    setPointAsEventTarget() {
      let uuid = this.contextData.feature.getProperties().uuid;
      this.$store.dispatch('events/setPointAsEventTarget', uuid);
    },
    setMouseCoordinatesAsEventTarget() {
      this.$store.dispatch('events/setCoordinatesAsEventTarget', this.contextData.coordinates);
    },
    close() {
      this.dialog = false;
    },
  },
  watch: {},
  beforeDestroy() {
    appBus.$off(ON_TERRAIN_DIALOG);
  }
}

</script>
