import axios from 'axios';
import * as Sentry from "@sentry/vue";

// initial state
const state = {
    user: null,
    token: null,
    device: null,
    devices: [],
    rules: [],
    loaded: null,
};

// getters
const getters = {
    isUser: state => {
        return state.user !== null;
    },
    isDevice: state => {
        return state.device !== null;
    },
    hasDevices: state => {
        return state.devices.length > 0;
    },
    userCan: (state, getters, rootState) => (action) => {
        if (action === 'device.all') {
            return state.user.baseId === rootState.bases.centralBaseId;
        }
        return false;
    },
    fullName: state => {
        return state.user !== null ? state.user.firstName + ' ' + state.user.lastName : 'nepřihlášen';
    },
    id: state => {
        return state.user.id;
    },
    token: state => {
        return state.user ? 'Bearer ' + state.token : null;
    },
    config: state => {
        return {
            headers: {
                'Authorization': 'Bearer ' + state.token,
                'panel': true,
            }
        }
    },
};

// actions
const actions = {
    login({commit, dispatch}, payLoad) {
        return axios.post(
            process.env.VUE_APP_TEREZA_API_URL + '/v2/auth/login',
            payLoad,
            {
                headers: {
                    panel: true
                }
            }
        ).then(
            (response) => {
                if (response.data.result === 'success') {
                    commit('setUser', response.data);
                    dispatch('setIdentity');
                    return response.data;
                } else {
                    throw new Error(response.data.message);
                }

            },
            () => {
                throw new Error('Chyba při autorizaci');
            }
        )
    },
    logout({commit, dispatch}) {
        commit('logout');
        dispatch('app/resetState', null, {root: true});
        commit('panel/resetState', null, {root: true});

        if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
                type: 'SET_TOKEN',
                token: null
            })
        }
    },
    setIdentity({state, commit}) {
        Sentry.setUser({
            id: state.user.id,
            username: state.user.username,
            baseId: state.user.baseId,
        });
        commit('setIdentity', {roles: state.user.roles, groups: state.user.groups, baseId: state.user.baseId});

        if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
                type: 'SET_TOKEN',
                token: state.token
            })
        }
    },
    setDevice({state, commit}, payload) {
        commit('setDevice', payload);
        Sentry.setContext("Device", {
            id: state.device.id,
            name: state.device.name,
            code: state.device.code,
        });
    },
};

// mutations
const mutations = {
    setUser(state, payload) {
        state.user = payload.user;
        state.token = payload.message;
        state.device = null;
        state.devices.push(payload.device);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload.message;
    },
    setIdentity(state, payload) {
        state.rules = payload;
    },
    setDevice(state, payload) {
        state.device = payload;
    },
    logout(state) {
        state.user = null;
        state.device = null;
        state.devices = [];
        Sentry.configureScope(scope => scope.setUser(null));
    },
    resetState(state, payload) {
        Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
