<template>
    <vl-layer-vector v-if="isVisible" :z-index="zIndex">
        <vl-source-cluster :distance="40">
            <vl-source-vector :features.sync="features" :overlaps="false">
                <vl-style-func :function="styleFunc()"/>
            </vl-source-vector>
        </vl-source-cluster>
    </vl-layer-vector>
</template>

<script>

import mapapi from "@/api/mapapi";
import {createStyle} from 'vuelayers/dist/ol-ext'
import {mapState} from "vuex";
import {forEach} from "lodash";

export default {
    name: "muv-layer",
    data() {
        return {
            apiEndpoint: '/muv-location',
            features: [],
            layerIdent: 'muvLayer',
            lastUpdate: undefined,
            visible: false,
            zIndex: 160,
            interval: null,
        }
    },
    props: {
        updateInterval: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapState({
            isVisible: state => state.map.muvLayer
        }),
    },
    methods: {
        styleFunc() {
            return (feature) => {
                let styles = [];
                let active = false;
                let activeCount = 0;

                const features = feature.get('features');
                const size = features.length
                const markerHeight = 100;
                const scale = (size > 1) ? 0.40 : 0.45;

                forEach(features, (feature) => {
                    active = this.isActive(feature);
                    if (active)
                        activeCount++;
                });
                
                styles.push(createStyle({
                    zIndex: active ? 2 : 1,
                    imageSrc: size > 1 ? './assets/icons/map/marker-circle.svg' : './assets/icons/map/marker-square.svg',
                    imageScale: scale,
                    imageAnchor: [0.5, 1],
                    imageColor: activeCount > 0 ? 'orange' : 'grey',
                    textFont: 'normal ' + (size > 1 ? '40px' : '60px') +  ' tereza',
                    text: '5',
                    textFillColor: '#000',
                    textScale: scale,
                    textOffsetY: -1 * ((markerHeight / 100) * (size > 1 ? 28 : 50)) * scale,
                    textBaseline: 'middle',
                    textAlign: 'center',
                }));

                if (size > 1) {
                    styles.push(createStyle({
                        zIndex: 2,
                        text: size.toString(),
                        textFont: 'normal 32px Roboto',
                        textScale: scale,
                        textOffsetY: -1 * ((markerHeight / 100) * 60) * scale,
                        textBaseline: 'middle',
                        textAlign: 'center',
                    }));
                }

                return styles;
            }
        },
        isActive(feature) {
            return ((new Date() - new Date(feature.get('created'))) / (1000 * 60 * 60)) <= 1
        },
        async loadFeatures() {
            const currentTime = new Date();
            if (
                this.features.length === 0 || // none features are loaded
                (this.updateInterval > 0 && (currentTime - this.lastUpdate) >= this.updateInterval) //features shloud be reloaded
            ) {
                const data = await mapapi.post(this.apiEndpoint);
                if (data) {
                    this.features = data.features;
                    this.lastUpdate = currentTime;
                }
            }
        }
    },
    mounted() {
        if (this.isVisible) {
            this.loadFeatures();
        }
        if (this.updateInterval > 0) {
            this.interval = setInterval(this.loadFeatures, this.updateInterval * 1000);
        }

    },
    watch: {
        isVisible(val) {
            if (val)
                this.loadFeatures();
        },
    },
    beforeDestroy() {
        clearInterval(this.interval);
    }
}
</script>
