import Vue from "vue";
import {isEqual, isObject, toArray, isEmpty} from "lodash";

const stateMergeRemoveKeyValue = '_RK_';

export const stateMerge = function (state, value, propName, ignoreNull = false, removeNull = true, remoteState = null, remotePropName = null, backup = false, clear = false, level = 0) {

    if (value === undefined)
        return;

    if (backup) {
        if (!Object.prototype.hasOwnProperty.call(remoteState, propName)) {
            if (Object.prototype.toString.call(value) === "[object Object]" && (Object.keys(value).length !== 0)) {
                // window.console.log("CREATE EMPTY REMOTE STATE", propName, {});
                Vue.set(remoteState, propName, {});
            }
        }
    }

    if (Object.prototype.toString.call(value) === "[object Object]" && Object.keys(value).length !== 0 && clear && level > 1) {
        Vue.set(state, propName, value);
    } else if (
        Object.prototype.toString.call(value) === "[object Object]" && Object.keys(value).length !== 0 &&
        (propName == null || Object.prototype.hasOwnProperty.call(state, propName))
    ) {
        let o = propName == null ? state : state[propName];
        let ro = null;

        if (remoteState)
            ro = remotePropName == null ? remoteState : remoteState[remotePropName];

        if (o != null) {
            level++;
            for (var prop in value) {
                stateMerge(o, value[prop], prop, ignoreNull, removeNull, ro, prop, backup, clear, level);
            }
            return;
        }
    }

    if (backup) {
        // window.console.log('PROCESS MERGE STATE WITH BACKUP', propName, value);

        if (!Object.prototype.hasOwnProperty.call(remoteState, propName)) {
            Vue.set(remoteState, propName, state[propName]);
        }

        if (removeNull && value === stateMergeRemoveKeyValue) {
            Vue.delete(state, propName);
        } else if (!ignoreNull || value !== null) {
            Vue.set(state, propName, value);
        }

        let val1 = state[propName];
        if (isObject(val1))
            val1 = toArray(val1);

        let val2 = remoteState[propName];
        if (isObject(val2))
            val2 = toArray(val2);

        if (isEqual(val1, val2) || ((typeof(val2) === 'object' && isEmpty(val2)) && (typeof(val1) === 'object' && isEmpty(val1)))) {
            Vue.delete(remoteState, propName);

            if (removeNull && value === stateMergeRemoveKeyValue) {
                Vue.delete(state, propName);
            } else if (!ignoreNull || value !== null) {
                Vue.set(state, propName, value);
            }
        }
    } else if (remoteState && remoteState[propName] !== undefined) {

        // window.console.log('PROCESS MERGE STATE WITH REMOTE', propName, value);

        if (removeNull && value === stateMergeRemoveKeyValue) {
            Vue.delete(remoteState, propName);
        } else if (!ignoreNull || value !== null) {
            Vue.set(remoteState, propName, value);
        }

        let val1 = state[propName];
        if (isObject(val1))
            val1 = toArray(val1);

        let val2 = remoteState[propName];
        if (isObject(val2))
            val2 = toArray(val2);

        if (isEqual(val1, val2) || isEmpty(val2)) {
            Vue.delete(remoteState, propName);

            if (removeNull && value === stateMergeRemoveKeyValue) {
                Vue.delete(state, propName);
            } else if (!ignoreNull || value !== null) {
                Vue.set(state, propName, value);
            }
        }
    } else {

        // window.window.console.log('PROCESS MERGE STATE', propName, value);

        if (removeNull && value === stateMergeRemoveKeyValue) {
            Vue.delete(state, propName);
        } else if (!ignoreNull || value !== null) {
            Vue.set(state, propName, value);
        }
    }


};

export default stateMerge;
