<template>
    <div></div>
</template>
<script>
    import {appBus} from "@/main";
    import {mapActions, mapState} from "vuex";
    import {map} from "lodash";
    import api from "@/api/api";

    export default {
        name: 'sap',
        data() {
            return {
                coordinates: null,
            }
        },
        created() {
            appBus.$on('ON_SAP_COMPONENT_START', (payload) => {
                this.coordinates = payload.coordinates;
                this.start();
            });
            appBus.$on('ON_SAP_COMPONENT_END', () => {
                this.end();
            });
        },
        computed: mapState({
            status: state => state.sap.status
        }),
        methods: {
            ...mapActions('map', {
                extendToArray: 'extendToArray',
            }),
            start() {
                this.$store.dispatch("sap/start", this.coordinates);
                appBus.$emit('ON_SAP_COMPONENT_STARTED');
                this.getInitialData();
            },
            end() {
                this.$store.dispatch("sap/end");
                appBus.$emit('ON_SAP_COMPONENT_ENDED');
            },
            // Todo: move to store.js
            getInitialData() {
                return api.get('/v2/explore/sap/' + this.coordinates[0] + '/' + this.coordinates[1])
                    .then((response) => {
                        if (this.status > 0) {
                            this.$store.dispatch("sap/setInitialData", response);
                            this.mapExtend(response.data)
                            this.getExtendedData();
                        }
                    })
                    .catch(() => {
                        // this.$store.dispatch("notifications/add", {'type' => 'error', ''});
                    })
            },
            mapExtend(data) {
                let coordinates = map(data, item => [item.lon, item.lat]);
                this.extendToArray(coordinates);
            },
            getExtendedData() {
                return api.get('/v2/explore/sap/routes/' + this.coordinates[0] + '/' + this.coordinates[1])
                    .then((response) => {
                        if (this.status > 0) {
                            this.$store.commit("sap/setExtendedData", response);
                            this.$store.commit("sap/setStatus", 5);
                        }
                    })
                    .catch(() => {
                        // this.$store.dispatch("notifications/add", {'type' => 'error', ''});
                    })
            }
        },
        beforeDestroy() {
            appBus.$off('ON_SAP_COMPONENT_START');
            appBus.$off('ON_SAP_COMPONENT_END');
        }
    }
</script>
