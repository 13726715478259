<template>
    <v-dialog
            v-if="uuid"
            v-model="dialog"
            @keydown.esc="close"
            max-width="380px"
    >
        <v-card>
            <v-card-title class="text-h6 justify-center">Sloučení událostí</v-card-title>
            <v-card-text class="px-10 text-body-1 black--text text-center">
                Ve vzdálenosti {{ event.distance }}m je již evidována událost. Sloučit s touto událostí?
                <v-card elevation="6" class="map-tooltip">
                    <v-card-title v-html="tooltip.title"></v-card-title>
                    <v-card-text class="header" v-html="tooltip.content"></v-card-text>
                </v-card>
                <div class="text-caption mt-4 grey--text text--darken-1">Pokud chcete vytvořit novou samostatnou událost zvolte tlačítko <strong>Zpět</strong></div>
            </v-card-text>
            <v-card-actions class="pt-3 justify-center">
                <v-btn
                        large
                        color="success"
                        @click="confirm"
                >Připojit</v-btn>
                <v-btn
                        large
                        color="grey"
                        outlined
                        @click="close"
                >Zpět</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'link-event-dialog',
    data() {
        return {
            dialog: false,
            uuid: null,
        }
    },
    computed: {
        ...mapGetters('events', {
            getTargetTooltip: 'targetTooltip',
            getEventByKey: 'getByKey',
        }),
        event() {
            return this.getEventByKey(this.uuid);
        },
        tooltip() {
            return this.getTargetTooltip(this.event);
        }
    },
    methods: {
        open(uuid) {
            this.uuid = uuid;
            this.dialog = true;
        },
        confirm() {
            this.$emit('linkEvent', this.uuid)
            this.dialog = false;
        },
        close() {
            this.dialog = false;
        }
    },
}

</script>
