<template>
  <v-card :loading="loading">
    <v-list dense>
      <v-list-item>
        <v-list-item-content class="font-weight-bold">Kód / název</v-list-item-content>
        <v-list-item-content class="font-weight-bold">{{ device.code }} / {{ device.name }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Sim</v-list-item-content>
        <v-list-item-content>{{ device.number }}</v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Typ</v-list-item-content>
        <v-list-item-content><span v-iexf="testResults">{{ testResults.parameters.device }}</span>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Test proveden</v-list-item-content>
        <v-list-item-content>{{ device.testedAt | moment('DD.MM.YYYY HH:mm:ss') }}</v-list-item-content>
      </v-list-item>
      <v-list-item :class="result.cellClass">
        <v-list-item-content>Celkový výsledek testu</v-list-item-content>
        <v-list-item-content>{{ result.text }}</v-list-item-content>
      </v-list-item>
    </v-list>
    <v-simple-table dense class="mx-3 mb-3" v-if="testResults">
      <template v-slot:default>
        <thead>
        <tr class="grey lighten-3">
          <th colspan="2" class="text-left">Parametry</th>
          <th>Stav</th>
          <th class="text-left">Instrukce</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, key) in parameters" :key="key">
          <td>{{ item.name }}</td>
          <td :class="item.class" style="width: 20px"></td>
          <td>{{ item.value }}</td>
          <td>{{ item.text }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
import {mapGetters} from "vuex";
import api from "../../api/api";


export default {
  props: {
    device: Object,
  },
  created() {
    // call api
    this.load();
  },
  data() {
    return {
      data: {},
      testResults: false,
      loading: true,
      parameters: {}
    }
  },
  computed: {
    toggleText() {
      return this.visible ? 'skrýt' : 'zobrazit';
    },
    ...mapGetters('devices', {
      'deviceStatus': 'status',
    }),
    ...mapGetters('deviceList', {
      'deviceResult': 'result',
    }),
    result() {
      return this.deviceResult(this.device);
    },
  },
  methods: {
    load() {
      api.get('/v2/devices/' + this.device.id)
          .then((response) => {
            this.loading = false;
            this.data = response;
            if (response.testResult) {
              this.testResults = JSON.parse(response.testResult);
              this.setParameters();
            }
          })
          .catch(() => {
          })
    },
    setParameters() {
      this.parameters.status = this.checkStatus();
      this.parameters.version = this.checkVersion();
      this.parameters.mobileData = this.checkMobileData();
      this.parameters.wifi = this.checkWifi();
      this.parameters.gps = this.checkGps();
      this.parameters.gpsType = this.checkGpsType();
      this.parameters.battery = this.checkBattery();
      this.parameters.time = this.checkTime();
      this.parameters.storage = this.checkStorage();
    },
    checkStatus() {

      let cssClass = 'success';
      let text = '';

      if (this.device.status === 0) {
        cssClass = 'error';
        text = 'Přepněte Terezu do aktivního režimu';
      }

      return {
        name: 'Režim',
        class: cssClass,
        value: this.deviceStatus(this.device),
        text: text,
      }
    },
    checkVersion() {

      let cssClass = 'success';
      let value = this.testResults.parameters.app_version;
      let text = '';

      if (this.testResults.parameters.app_version !== this.testResults.parameters.expect_app_version) {
        cssClass = 'error';
        text = 'Proveďte aktualizaci aplikace';
        value = value + ' -> ' + this.testResults.parameters.expect_app_version;
      }

      return {
        name: 'Verze',
        class: cssClass,
        value: value,
        text: text,
      }
    },
    checkMobileData() {

      let cssClass = 'success';
      let text = '';

      if (this.testResults.parameters.mobile_data_enabled === false) {
        cssClass = 'error';
        text = 'Zapněte mobilní data';
      }

      return {
        name: 'Mobilní data',
        class: cssClass,
        value: this.testResults.parameters.mobile_data_enabled ? 'zapnuto' : 'vypnuto',
        text: text,
      }
    },
    checkWifi() {

      let cssClass = 'success';
      let text = '';

      if (this.testResults.parameters.wifi_enabled === true) {
        cssClass = 'error';
        text = 'Vypněte modul WIFI';
      }

      return {
        name: 'WiFi připojení',
        class: cssClass,
        value: !this.testResults.parameters.wifi_enabled ? 'vypnuto' : 'zapnuto',
        text: text,
      }
    },
    checkGps() {

      let cssClass = 'success';
      let text = '';

      if (this.testResults.parameters.gps_enabled === false) {
        cssClass = 'warning';
        text = 'Zapněte modul sledování polohy';
      }

      return {
        name: 'Sledování polohy (GPS)',
        class: cssClass,
        value: this.testResults.parameters.gps_enabled ? 'zapnuto' : 'vypnuto',
        text: text,
      }
    },
    checkGpsType() {

      let cssClass = 'success';
      let text = '';

      if (this.testResults.parameters.location_high_precision === false) {
        cssClass = 'warning';
        text = 'Doporučujeme nastavit nejvyšší přesnost GPS';
      }

      return {
        name: 'Přesnost sledování polohy',
        class: cssClass,
        value: this.testResults.parameters.location_high_precision ? 'vysoká' : 'základní',
        text: text,
      }
    },
    checkBattery() {

      let cssClass = 'success';
      let text = '';

      if (this.testResults.parameters.battery < 50) {
        cssClass = this.testResults.parameters.battery < 30 ? 'error' : 'warning';
        text = this.testResults.parameters.battery < 30 ? 'Nabijte baterii' : '';
      }

      return {
        name: 'Stav baterie',
        class: cssClass,
        value: this.testResults.parameters.battery + '%',
        text: text,
      }
    },
    checkTime() {

      let cssClass = 'success';
      let timeDiff = this.testResults.parameters.device_to_server_time_diff;
      let text = '';

      if (timeDiff == null) {
        cssClass = 'warning';
      } else if (timeDiff >= 60000 && timeDiff <= 60000) {
        cssClass = 'warning';
      } else if (timeDiff > 120000) {
        text = 'Opravte nastavení hodin';
        cssClass = 'error';
      }

      return {
        name: 'Odchylka hodin',
        class: cssClass,
        value: timeDiff !== null ? (Math.round((timeDiff / 1000) * 1000) / 1000) + 's' : 'nezjištěno',
        text: text,
      }
    },
    checkStorage() {

      let cssClass = 'success';
      let text = '';
      let percent = Math.round(parseFloat(this.testResults.parameters.storage_fill) * 100);
      if (percent > 85) {
        cssClass = 'error';
      } else if (percent > 45 ) {
        cssClass = 'warning';
      }

      return {
        name: 'Zaplnění paměti zařízení',
        class: cssClass,
        value: percent + '%',
        text: text,
      }
    },
  },
}
</script>
