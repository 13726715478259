<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <file-upload v-show="0"
                     class=""
                     :post-action="upload.postAction"
                     :extensions="upload.extensions"
                     :accept="upload.accept"
                     :multiple="upload.multiple"
                     :headers="upload.headers"
                     :size="upload.size"
                     :drop="upload.drop"
                     v-model="files"
                     @input-filter="inputFilter"
                     @input-file="inputFile"
                     ref="upload">
        </file-upload>
        <audio-recorder v-show="0" ref="radio"
                        :attempts="1"
                        :time="maxDuration/60"
                        :before-recording="onStartRecording"
                        :after-recording="onStopRecording"
        />
        <v-tooltip top min-width="140">
            <template v-slot:activator="{ on }">
                <v-btn fab fixed bottom right light :color="buttonColor"
                       :class="{
                            'ar-icon--pulse': isRecording && volume > 0.1
                          }"
                       v-on="on"
                       @mousedown.left="startRecordRadio"
                       @mouseup.left="stopRecordRadio"
                       v-shortkey.push="['ctrl', 'space']" @shortkey.native="toggleRecordRadio"
                       :disabled="disabled">
                    <v-icon v-if="!isRecording" large>keyboard_voice</v-icon>
                    <span class="white--text font-weight-bold" v-if="isRecording">{{ recordedTime }}</span>
                </v-btn>
            </template>
            <span><strong>Vysílání TereZa Rádio</strong><br/>- Podržet tlačítko, nebo<br/>- Podržet Ctrl + mezerník</span>
        </v-tooltip>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import FileUpload from 'vue-upload-component'
    import {isEmpty, forEach} from "lodash";
    import moment from "moment/moment";

    export default {
        name: 'sidebar-event-message-radio',
        components: {
            FileUpload,
        },
        mounted() {
            document.addEventListener('mouseup', this.onMouseUp);

            this.$watch(
                () => {
                    return this.$refs.radio.isRecording
                },
                (val) => {
                    this.isRecording = val;
                }
            );

            this.$watch(
                () => {
                    return this.$refs.radio.recorder.duration
                },
                (val) => {
                    this.recordedTime = val;
                    this.lastRecordedTime = val;
                }
            );

            this.$watch(
                () => {
                    return this.$refs.upload.uploaded
                },
                (val) => {
                    if (val === true)
                        this.sendMessage();
                }
            );

            this.$watch(
                () => {
                    return this.$refs.radio.volume
                },
                (val) => {
                    this.volume = val;
                }
            )
        },
        data() {
            return {
                files: [],
                recordedTime: 0,
                lastRecordedTime: 0,
                radioStatus: 0,
                isRecording: false,
                disabled: false,
                volume: 0,
            }
        },
        props: {
            eventId: Number,
            messageCategory: Number,
            messageCategoryId: {
                type: Number,
                default: 2,
            }
        },
        computed: {
            ...mapState({
                device: state => state.auth.device,
                apiUrl: state => state.tereza.apiUrl,
                minDuration: state => state.setup.radio.minDuration,
                maxDuration: state => state.setup.radio.maxDuration,
            }),
            upload() {
                return {
                    accept: 'audio/mp3',
                    extensions: 'mp3',
                    headers: {
                        'Authorization': this.$store.getters['auth/token'],
                    },
                }
            },
            buttonColor() {
                if (this.isRecording) {
                    if (this.recordedTime > this.minDuration)
                        return 'red';
                    return 'grey';
                }
                return 'yellow';
            },
        },
        methods: {
            toggleRecordRadio() {
                if (this.isRecording === false) {
                    this.startRecordRadio();
                } else {
                    this.stopRecordRadio();
                }
            },
            startRecordRadio() {
                if (this.isRecording === false) {
                    this.$refs.radio.stopRecorder();
                    this.$refs.radio.removeRecord(0);
                    this.play('start').then(() => {
                        this.$refs.radio.toggleRecorder();
                    });

                }
            },
            stopRecordRadio() {
                if (this.isRecording === true) {
                    this.$refs.radio.stopRecorder();
                }
            },
            onStartRecording() {
                this.lastRecordedTime = 0;
            },
            onStopRecording(record) {
                if (this.lastRecordedTime > this.minDuration) {
                    this.play('end');
                    this.uploadAudio(record);
                } else {
                    this.removeRecord(record);
                }
            },
            removeRecord(record) {
                this.$refs.radio.choiceRecord(record);
                this.$refs.radio.removeRecord(0);
            },
            isFiles() {
                return !isEmpty(this.files);
            },
            getFiles() {
                if (this.isFiles()) {
                    let files = [];
                    forEach(this.files, function (file) {
                        files.push(file.filename);
                    });
                    return files;
                } else {
                    return null
                }
            },
            inputFilter(newFile, oldFile) {
                if (newFile && !oldFile) {
                    let extension = newFile.name.split('.').pop();
                    newFile.filename = this.eventId + '-' + moment().format('YYYYMMDD_HHmmss') + '-' + this.device.code + '.' + extension;
                    newFile.headers['X-File-Size-Total'] = newFile.size;
                    newFile.postAction = this.apiUrl + '/v2/media/event/' + this.eventId + '/' + newFile.filename;
                }
            },
            inputFile() {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            },
            uploadAudio(record) {
                this.disabled = true;
                let file = new window.File([record.blob], 'radio.mp3', {
                    type: record.blob.type,
                    headers: {
                        'X-File-Size-Total': record.blob.size
                    }
                });
                this.$refs.upload.add(file);
            },
            sendMessage() {
                this.disabled = true;
                let message = {
                    content: '',
                    messageCategoryId: this.messageCategoryId,
                    type: 'X11',
                    media: this.getFiles(),
                };
                this.$store.dispatch('messages/send', message).then(() => {
                    this.disabled = false;
                });
            },
            onMouseUp() {
                this.stopRecordRadio();
            },
            play(sound) {
                return new Promise((resolve, reject) => {
                    let url = process.env.VUE_APP_PUBLIC_PATH + 'assets/sounds/rec-' + sound + '.mp3';
                    let audio = new Audio();
                    audio.preload = "auto";
                    audio.autoplay = true;
                    audio.onerror = reject;
                    audio.onended = resolve;
                    audio.src = url;
                }).catch(function (error) {
                    // eslint-disable-next-line
                    console.error(error);
                });
            },

        },
        beforeDestroy() {
            document.removeEventListener('mouseup', this.onMouseUp);
        },
    }
</script>
