<template>
    <div class="event-head" :class="active ? 'grey lighten-3' : null">
        <v-list-item v-ripple v-on:click="onSelect" class="event-head-info">
            <v-list-item-action class="icon justify-start">
                {{ event.start | moment("HH:mm") }}
                <v-icon size="40" :color="eventCategory.color">{{ eventCategory.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="py-2">
                <v-list-item-title class="d-flex">
                    <div :class="getTitleClass">{{ getTitle }}</div>
                    <event-tags v-bind:event="event"></event-tags>
                </v-list-item-title>
                <v-list-item-subtitle>
                    <div :class="getSubtitleClass" v-html="getSubtitle"></div>
                    <div :class="getDescriptionClass" v-html="getDescription"></div>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <slot></slot>
        <v-divider v-if="list" class="grey darken-1"></v-divider>
    </div>
</template>

<script>
import EventTags from './EventTags';

import {
    faCommentAlt as messages,
} from '@fortawesome/free-solid-svg-icons'
import {mapGetters} from "vuex";
import {SHOW_EVENT_VIEW} from "@/events";
import {appBus} from "@/main";

export default {
    name: 'event-head-inactive',
    data() {
        return {
            icons: {
                messages,
            },
        }
    },
    components: {
        EventTags
    },
    props: {
        event: Object,
        active: {
            type: Boolean,
            default: false,
        },
        link: {
            type: Boolean,
            default: true,
        },
        list: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        getTitle() {
            return this.event.header && this.event.header.title.value !== undefined ? this.event.header.title.value : null;
        },
        getSubtitle() {
            return this.event.header && this.event.header.subtitle.value !== undefined ? this.event.header.subtitle.value : null;
        },
        getDescription() {
            return this.event.header && this.event.header.description.value !== undefined ? this.event.header.description.value : null;
        },
        getOverview() {
            return this.event.header && this.event.header.overview !== undefined && this.event.header.overview.value !== undefined ? this.event.header.overview.value : null;
        },
        getTitleClass() {
            return this.event.header && this.event.header.title.class !== undefined ? this.event.header.title.class : null;
        },
        getSubtitleClass() {
            return this.event.header && this.event.header.subtitle.class !== undefined ? this.event.header.subtitle.class + ' text-truncate' : null;
        },
        getDescriptionClass() {
            return this.event.header && this.event.header.description.class !== undefined ? this.event.header.description.class + ' text-truncate' : null;
        },
        getOverviewClass() {
            return this.event.header && this.event.header.overview !== undefined && this.event.header.overview.class !== undefined ? this.event.header.overview.class : null;
        },
        eventCategory() {
            return this.getEventCategoryById(this.event.eventCategoryId);
        },
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
        }),
    },
    methods: {
        onSelect() {
            if (this.link) 
                appBus.$emit(SHOW_EVENT_VIEW, this.event);
        },
    }
}
</script>
