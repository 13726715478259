<template>
    <v-card class="event-geo-wrapper" :loading="processed">
        <v-toolbar
                color="default"
                dark
                dense
        >
            <v-toolbar-title>
                <small>
                    Upravujete zákres v mapě
                </small>
                <v-btn color="success" small @click="save" class="mx-1">Uložit</v-btn>
                <v-btn color="error" small @click="remove" class="mr-1">Smazat</v-btn>
                <v-btn fab x-small color="error" @click="close">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-text-field label="popis" v-model="title"></v-text-field>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'map-card-geo',
    props: {
      processed: Boolean,
    },
    created() {
        this.title = this.item.title;
    },
    computed: {
        ...mapGetters('geo', {
            isEditing: 'isEditing',
            item: 'active',
        }),
        ...mapGetters('events', {
            isActiveEvent: 'isActiveEvent',
            event: 'getActiveEvent',
        }),
    },
    data() {
      return {
        title: '',
      }
    },
    methods: {
        save() {
          this.$emit('onSave', this.title);
        },
        remove() {
            this.$store.dispatch('geo/remove');
        },
        close() {
            this.$store.dispatch('geo/close');
        }
    },
    beforeDestroy() {
        this.close();
    }
}
</script>
