import {orderBy} from "lodash";
import {stateMerge} from "../object-merge";

const getDefaultState = () => {
    return {
        items: {},
    }
};

const state = getDefaultState();

const getters = {
    getById: (state) => {
        return (id) => {
            return state.items[id];
        }
    },
    options: state => {
        return orderBy(state.items, ['sort'], ['asc']);
    },
};

const actions = {
    async set({commit}, items) {
        commit("MERGE_STATE", items)
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
