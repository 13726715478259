/* eslint-disable */
import forEach from "lodash/forEach";
import {stateMerge} from "../object-merge";

const getDefaultState = () => {
    return {
        items: {},
    }
};

const state = getDefaultState();

const getters = {
    get(state) {
        return (key) => {
            if (key && state.items[key]) {
                return state.items[key];
            } else {
                return {
                    code: null,
                    name: 'EVENT TYPE IS NOT SET',
                    priority: 0,
                };
            }
        }
    },
    options: state => {
        let options = [];
        forEach(state.items, (item, id) => {
            options.push({
                value: Number(id),
                text: item.name,
            });
        });
        return options;
    },
};

const actions = {
    async set({commit, state, dispatch}, items) {
        commit("MERGE_STATE", items)
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
