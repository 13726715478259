<template>
    <fragment>
        <li v-if="showEditGeo" @click="edit">
            <v-icon left>edit</v-icon>
            Upravit zákres do mapy
        </li>
    </fragment>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: 'map-geo-context-menu',
        props: [
            'data',
        ],
        computed: {
            showEditGeo() {
                return this.isActive && this.data.type === 'geo';
            },
            isActive() {
                return this.isActiveEvent && this.event.id > 0 && this.event.baseId === this.$store.state.auth.user.baseId && this.data;
            },
            ...mapGetters('geo', {
                isObjectEditing: 'isEditing',
            }),
            ...mapGetters('events', {
                isActiveEvent: 'isActiveEvent',
                event: 'getActiveEvent',
            }),
        },
        methods: {
            getUuid() {
                return this.data.feature.getProperties().uuid
            },
            edit() {
                this.$store.dispatch('geo/edit', this.getUuid());
            },
        },
    }
</script>
