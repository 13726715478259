<template>
    <v-card class="event-detail-child-events tab-overflow"  color="grey lighten-2">
        <v-card class="elevation-0 event-list white" :loading="loading" color="grey lighten-2">
          <v-list class="mt-3" color="grey lighten-2">
                <template v-for="(event) in childEvents">
                    <event-head :key="event.uuid" @onSelect="showDetail" :event="event"></event-head>
                </template>
            </v-list>
          <v-skeleton-loader v-show="loading" class="ma-3" color="grey lighten-2" type="list-item-avatar-three-line"></v-skeleton-loader>
        </v-card>
    </v-card>
</template>

<script>

    import EventHead from "./HeadInactive";
    import {appBus} from "@/main";
    import {SHOW_EVENT_VIEW, UPDATE_CHILD_EVENTS} from "@/events";
    import eventsApi from "@/api/events";
    import {orderBy} from "lodash";

    export default {
        name: 'sidebar-event-child-events',
        components: {
            EventHead
        },
        props: {
            event: {
                type: Object,
            },
        },
      created() {
          appBus.$on(UPDATE_CHILD_EVENTS, () => {
              this.getItems();
          });
          this.getItems();
      },
      data() {
        return {
          items: {},
          loading: true,
        }
      },
        computed: {
          childEvents() {
            return orderBy(this.items, ['start'], ['desc']);
          },
        },
        methods: {
            showDetail() {
                appBus.$emit(SHOW_EVENT_VIEW, this.event);
            },
            getItems() {
              this.items = {};
              eventsApi.getEventList({id: this.event.id}).then((response) => {
                this.items = response;
                this.loading = false;
              });
            },
        }
    }
</script>
