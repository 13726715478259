<template>
  <v-dialog v-model="status" max-width="700">
    <v-card class="grey lighten-2">
      <v-toolbar color="default" dark dense>
        <v-toolbar-title>
          Test mikrofonu
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="pl-3">
          <v-btn fab x-small color="red" @click="status = false" class="elevation-0">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-row>
          <v-col sm="6">
            <audio ref="player"></audio>
            <av-media type="frequ"
                      :media="media"
                      :canv-height="160"
                      line-color="black"
                      canv-class="grey lighten-3 pa-2"
            />
          </v-col>
          <v-col sm="6">
            <audio-recorder ref="micTest"
                            v-show="0"
                            :attempts="1"
                            :time="1"
                            :before-recording="onStartTest"
                            :after-recording="onStopTest"
            />
            <v-btn @click="startTest" block x-large color="success" dark v-show="!isRecording">Zahájit test mikrofonu
            </v-btn>
            <v-btn @click="stopTest" block x-large color="error" dark v-show="isRecording">Ukončení test mikrofonu
              ({{ recordedTimeFormatted }})
            </v-btn>
            <div v-if="isFile" class="mt-5">
              <audio ref="testFile" controls autoplay="true" preload="auto" :src="file"></audio>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import moment from "moment/moment";

export default {
  name: 'microphone-test',
  data() {
    return {
      status: true,
      mtype: null,
      media: null,
      file: null,
      recordedTime: 0,
      lastRecordedTime: 0,
      radioStatus: 0,
      isRecording: false,
      disabled: false,
      volume: 0,
    }
  },
  mounted() {
    const audio = this.$refs.player
    const constraints = {
      audio: true,
      video: false
    }
    navigator.mediaDevices.getUserMedia(constraints)
        .then(media => {
          this.media = media
          audio.srcObject = media
        })

    this.$watch(
        () => {
          return this.$refs.micTest.isRecording
        },
        (val) => {
          this.isRecording = val;
        }
    );
    this.$watch(
        () => {
          return this.$refs.micTest.recorder.duration
        },
        (val) => {
          this.recordedTime = val;
          this.lastRecordedTime = val;
        }
    );
    this.$watch(
        () => {
          return this.$refs.micTest.volume
        },
        (val) => {
          this.volume = val;
        }
    )
  },
  computed: {
    recordedTimeFormatted() {
      return moment.utc(moment.duration(this.recordedTime, 'seconds').asMilliseconds()).format('ss:SS')
    },
    isFile() {
      return this.file !== null;
    },
  },
  methods: {
    startTest() {
      if (this.isRecording === false) {
        this.file = null;
        this.$refs.micTest.stopRecorder();
        this.$refs.micTest.removeRecord(0);
        this.$refs.micTest.toggleRecorder();
      }
    },
    stopTest() {
      if (this.isRecording === true) {
        this.$refs.micTest.stopRecorder();
      }
    },
    onStartTest() {
      this.lastRecordedTime = 0;
    },
    onStopTest(record) {
      this.file = URL.createObjectURL(record.blob);
    },
    removeRecord(record) {
      this.$refs.micTest.choiceRecord(record);
      this.$refs.micTest.removeRecord(0);
    },
  },
  watch: {
    status(state) {
      if (state === false) {
        this.stopTest();
        this.$emit('onClose');
      }
    },
  },
  beforeDestroy() {
    this.stopTest();
  }
}
</script>