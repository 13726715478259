import axios from 'axios';
import store from "../store";
import {transform} from 'ol/proj'


export default {
    getUrl() {
        return store.state.setup.endpoints.apiCadastreUrl;
    },
    getData(coordinates) {
        coordinates = transform(coordinates, store.state.map.dataProjection, 'EPSG:5514');
        return axios.get(this.getUrl() + '?latitude=' + coordinates[1] + '&longitude=' + coordinates[0], { // Stopa endpoint
        // return axios.get(this.getUrl() + '/' + coordinates[0] + '/' + coordinates[1], { // Tereza endpoint
            responseType: 'json',
        }).then((response) => {
            return response.data;
        }).catch(function (error) {
            throw new Error(error);
        });
    },
}