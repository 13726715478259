<template>
    <fragment>
        <li v-if="showAddPointAsEventTarget" @click="setEventTarget('setPointAsEventTarget')">
            <v-icon left color="red">ter-reversed-exclamation-triangle</v-icon>
            <span>Nastavit bod jako místo události</span>
        </li>
        <li v-if="showAddMouseCoordinatesAsEventTarget" @click="setEventTarget('setMouseCoordinatesAsEventTarget')">
            <v-icon left color="red">ter-reversed-exclamation-triangle</v-icon>
            <span>Nastavit jako místo události</span>
        </li>
    </fragment>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {appBus} from "../../../main";
    import {ON_TERRAIN_DIALOG} from "../../../events";


    export default {
        name: 'map-point-context-menu',
        props: [
            'data',
        ],
        data() {
            return {
                contextData: null,
                action: null,
                dialog: false,
            }
        },
        computed: {
            showAddPointAsEventTarget() {
                return this.isActiveEvent && this.canEditAddress && this.data && this.data.type === 'point';
            },
            showAddMouseCoordinatesAsEventTarget() {
                return this.isActiveEvent && this.canEditAddress && this.data && this.data.area === 'map' && !this.data.feature;
            },
            canEditAddress() {
                return this.canEditAttribute(this.event.uuid, 'address');
            },
            ...mapGetters('events', {
                isActiveEvent: 'isActiveEvent',
                event: 'getActiveEvent',
                canEditAttribute: 'canEditAttribute',
            }),
        },
        methods: {
            setEventTarget(action) {
                this.contextData = this.data;
                this.contextData.action = action;

                if (action === 'setPointAsEventTarget') {
                    let uuid = this.contextData.feature.getProperties().uuid;
                    let point = this.$store.state.map.points.find(item => item.uuid === uuid);
                    if (point.type_code !== 'custom') {
                        this[action]();
                        return;
                    }
                }
                appBus.$emit(ON_TERRAIN_DIALOG, this.contextData);
            },
            setPointAsEventTarget() {
                let uuid = this.contextData.feature.getProperties().uuid;
                this.$store.dispatch('events/setPointAsEventTarget', uuid);
            },
        },
    }
</script>
