<template>
    <div>
        <vl-layer-vector :visible="layerVisible" :zIndex="20">
            <vl-source-vector ref="objectLayer"> // :features="eventFeatures"
                <vl-feature v-for="feature in eventFeatures" :key="feature.uuid"
                            :properties="{type: 'geo', sequence: feature.sequence, tooltip: tooltip(feature), uuid: feature.uuid }">
                    <vl-geom-polygon v-if="feature.geojson.geometry.type === 'Polygon'"
                                     :coordinates="feature.geojson.geometry.coordinates"></vl-geom-polygon>
                    <vl-geom-point v-if="feature.geojson.geometry.type === 'Point'"
                                   :coordinates="feature.geojson.geometry.coordinates"></vl-geom-point>
                    <vl-geom-line-string v-if="feature.geojson.geometry.type === 'LineString'"
                                         :coordinates="feature.geojson.geometry.coordinates"></vl-geom-line-string>
                    <vl-geom-circle v-if="feature.geojson.geometry.type === 'Circle'"
                                    :coordinates="feature.geojson.geometry.coordinates"></vl-geom-circle>
                </vl-feature>
                <vl-style-func :function="styleFunc()"></vl-style-func>
            </vl-source-vector>
        </vl-layer-vector>
        <vl-layer-vector :zIndex="151">
            <vl-source-vector ref="activeObjectLayer" ident="activeEventGeo" :features.sync="activeEventFeature"/>
            <vl-style-func :function="styleActiveFunc()"></vl-style-func>
        </vl-layer-vector>

        <vl-interaction-draw
                source="activeEventGeo"
                type="point"
                :stop-click="true"
                :active="type === 'point'"
                @drawstart="drawStart"
                @drawend="drawEnd"
        >
            <vl-style-func :function="styleActiveFunc()"></vl-style-func>
        </vl-interaction-draw>

        <vl-interaction-draw
                source="activeEventGeo"
                type="line-string"
                :stop-click="true"
                :active="type === 'line-string'"
                @drawstart="drawStart"
                @drawend="drawEnd"
        >
            <vl-style-func :function="styleActiveFunc()"></vl-style-func>
        </vl-interaction-draw>

        <vl-interaction-draw
                source="activeEventGeo"
                type="polygon"
                :stop-click="true"
                :active="type === 'polygon'"
                @drawstart="drawStart"
                @drawend="drawEnd"
        >
            <vl-style-func :function="styleActiveFunc()"></vl-style-func>
        </vl-interaction-draw>

        <vl-interaction-modify
                :active="processed === false"
                source="activeEventGeo"

        />

        <Teleport to="#cards" v-if="isEditing && activeUuid">
            <card @onSave="save" v-bind:processed="processed"></card>
        </Teleport>

    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
// import Point from 'ol/geom/Point';
import {createPointGeom, createStyle} from "vuelayers/dist/ol-ext";
import Teleport from 'vue2-teleport';
import Card from './GeoCard';

export default {
    name: 'layer-event-objects',
    components: {
        Teleport,
        Card,
    },
    props: {
        event: Object,
    },
    data() {
        return {
            processed: false,
            content: '',
            geojson: null,
        }
    },
    computed: {
        ...mapState({
            activeUuid: state => state.geo.active,
            type: state => state.geo.type,
            status: state => state.geo.status,
            mapZoom: state => state.map.zoom,
            layerVisible: state => state.geo.layerVisible,
        }),
        ...mapGetters('geo', {
            object: 'active',
            objects: 'byEvent',
            inactiveObjects: 'inactiveByEvent',
            activeObjects: 'activeByEvent',
            isEditing: 'isEditing',
        }),
        ...mapGetters('events', {
                isActiveEvent: 'isActiveEvent',
            }
        ),
        eventFeatures() {
            return this.inactiveObjects(this.event.uuid);
        },
        activeEventFeature: {
            get() {
                return this.object ? [this.object.geojson] : [];
            },
            set(value) {
                this.geojson = value[0];
            }
        }
    },
    methods: {
        drawStart(event) {
            this.$store.commit('geo/setStatus', 1);
            if (this.type === 'point')
                this.drawEnd(event);
        },
        drawEnd(event) {
            let json = event.json;
            json.properties = {
                type: 'geo',
            };
            const payload = {
                event: this.event,
                geojson: json,
            };
            this.$store.dispatch('geo/create', payload).finally(() => {
            });
        },
        ...mapActions('geo', {
            close: 'close',
        }),
        updateCoord(event) {
            this.object.longitude = event.coordinate[0];
            this.object.latitude = event.coordinate[1];
        },
        tooltip(object) {
            return {
                title: 'Zákres č. ' + object.sequence,
                content: object.title,
            }
        },
        remove() {
            this.processed = true;
            this.$store.dispatch('geo/remove').finally(() => {
                this.processed = false;
            });
        },
        save(title) {
            this.processed = true;
            this.$store.commit('geo/modify', {
                key: this.activeUuid,
                geojson: this.geojson,
            })
            // this.$refs.activeObjectLayer.refresh();
            this.object.title = title;

            let payload = this.object;
            this.$store.dispatch('geo/save', payload).finally(() => {
                this.processed = false;
            });
        },
        isObjectActive(uuid) {
            return this.activeUuid && this.object && this.object.id && this.object.uuid === uuid;
        },
        styleActiveFunc() {
            return (feature) => {
                const geometry = feature.getGeometry();

                let styles = [];

                styles.push(createStyle({
                    imageRadius: 10,
                    imageStrokeColor: '#000',
                    imageStrokeWidth: 3,
                    imageFillColor: [255, 0, 0, 0.2],
                    strokeColor: 'black',
                    strokeWidth: 4
                }))

                styles.push(createStyle({
                    geom: geometry,
                    strokeColor: 'black',
                    strokeWidth: 4
                }))

                styles.push(createStyle({
                    strokeColor: 'red',
                    strokeLineDash: [5],
                    strokeWidth: 2,
                    fillColor: [255, 0, 0, 0.2],
                }))

                return styles;
            }
        },
        styleFunc() {
            return (feature) => {

                const geometry = feature.getGeometry();
                const sequence = feature.get('sequence');
                const hover = feature.get('hover');
                let zIndex = sequence;
                let styles = [];
                let opacity = 0.3;

                if (hover) {
                    zIndex = 100;
                    opacity = 0.6;
                }

                styles.push(createStyle({
                    geom: geometry,
                    strokeColor: 'black',
                    strokeWidth: 4
                }))

                styles.push(createStyle({
                    strokeColor: 'yellow',
                    zIndex: zIndex,
                    strokeLineDash: [5],
                    strokeWidth: 1,
                    fillColor: [255, 156, 0, opacity],
                }))

                let label = '#' + sequence + ' ' + feature.get('tooltip').content;
                let labelCoordinates = null;
                let labelOffsetY = 0;
                let labelOffsetX = 0;
                let labelTextAlign = 'left';

                // if (geometry instanceof Point) {
                //   styles.push(createStyle({
                //     imageRadius: 10,
                //     imageStrokeColor: '#000',
                //     imageStrokeWidth: 3,
                //     imageFillColor: [255, 255, 0, 0.3],
                //     strokeColor: 'black',
                //     strokeWidth: 4
                //   }))
                //  
                //     labelCoordinates = geometry.getCoordinates();
                //     labelOffsetX = 7;
                //     labelOffsetY = -10;
                //     labelTextAlign = 'left';
                // }

                if (geometry instanceof Polygon)
                    labelCoordinates = geometry.getInteriorPoint().getCoordinates();

                if (geometry instanceof LineString) {
                    labelCoordinates = geometry.getLastCoordinate();
                    zIndex = zIndex + 100;
                }

                if (label && labelCoordinates) {

                    let scale = 1;

                    styles.push(createStyle({
                        geom: createPointGeom(labelCoordinates),
                        strokeColor: 'black',
                        // strokeWidth: 100,
                        text: label,
                        textFont: 'bold 12px Roboto',
                        textFillColor: 'black',
                        textStrokeColor: 'white',
                        textStrokeWidth: 3,
                        // textBackgroundFillColor: [50,50,50,0.2],
                        textBaseline: 'middle',
                        textAlign: labelTextAlign,
                        textOffsetX: labelOffsetX,
                        textOffsetY: labelOffsetY,
                        // textPadding: [2, 2, 2, 2],
                        textScale: scale + (this.mapZoom / 20),
                        zIndex: hover ? zIndex : 10,
                    }))
                }

                return styles;
            }
        },
    },
    watch: {
        type(value) {
            if ((value === null || value === undefined) && this.activeEventFeature.length === 0) {
                this.$store.commit('geo/setStatus', 0);
            }
        }
    }
}
</script>
