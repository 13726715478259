<template>
    <fragment>
        <li v-if="showEdit" @click="edit">
            Upravit objekt
        </li>
        <li v-if="showRemove" @click="remove">
            Odebrat objekt
        </li>
    </fragment>
</template>

<script>
    import {mapMutations} from 'vuex'

    export default {
        name: 'map-point-context-menu',
        props: [
            'data',
        ],
        computed: {
            showRemove() {
                return this.data && this.data.target === 'feature' && this.data.feature.getProperties().type === 'draw';
            },
            showEdit() {
                return this.showRemove;
            },
        },
        methods: {
            ...mapMutations('map', {
                removeDrawnFeature: 'removeDrawnFeature',
                setEditDrawnFeature: 'setEditDrawnFeature',
            }),
            remove() {
                this.removeDrawnFeature(this.data.feature.getProperties().key);
            },
            edit() {
                this.setEditDrawnFeature(this.data.feature.getProperties().key);
            },
        },
    }
</script>
