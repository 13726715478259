<template>
    <fragment>
<!--        <li v-if="showAdd" @click="add">-->
<!--            <v-icon left color="green">ter-target</v-icon>-->
<!--            <span>Přidat bod</span>-->
<!--        </li>-->
        <li v-if="showRemove" @click="remove">
            <v-icon left color="red">$vuetify.icons.trash</v-icon>
            <span>Odebrat bod</span>
        </li>
    </fragment>
</template>

<script>
    import {mapMutations, mapGetters} from 'vuex'

    export default {
        name: 'map-point-context-menu',
        props: [
            'data',
        ],
        computed: {
            // showAdd() {
            //     return this.data && this.data.area === 'map' && this.data.feature === undefined;
            // },
            showRemove() {
                return this.data && this.data.type === 'point';
            },
            ...mapGetters('events', {
                event: 'getActiveEvent',
            }),
        },
        methods: {
            ...mapMutations('map', {
                removePoint: 'removePoint',
            }),
            remove() {
                this.removePoint(this.data.feature.getProperties().uuid)
            },
            // add() {
            //     this.$store.dispatch('map/addPointFromCoordinates', this.data.coordinates);
            // },
        },
    }
</script>
