<template>
    <v-layout fill-height column class="event-list fill-sidebar">
        <div class="grey darken-3 d-flex flex-grow-0 justify-center">
                <v-text-field :value="search"
                              @input="updateSearch"
                              flat
                              hide-details
                              prepend-inner-icon="search"
                              placeholder="..."
                              solo
                              dark
                              background-color="grey darken-3"
                              dense
                              clearable
                >
                </v-text-field>
                <v-chip-group
                        v-model="filter"
                        mandatory
                        active-class="white"
                        color="white"
                        class="event-filter-group pl-2"
                >
                    <!--                    <v-chip dark filter outlined v-for="(item, key) in filters" :value="key" :key="key">{{-->
                    <!--                            item.name-->
                    <!--                        }}-->
                    <!--                    </v-chip>-->
                    <v-chip dark outlined value="all" key="all">Vše</v-chip>
                    <v-chip dark outlined value="primary" key="primary">Oper</v-chip>
                    <v-chip dark outlined value="secondary" key="secondary">Jiné</v-chip>

                    <v-chip dark outlined value="custom" key="custom">...
                        <v-menu v-model="filterDialog" offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                        <v-icon right size="20"
                                :color="isCustomFilterEmpty? 'grey' : 'grey lighten-2'"  v-on="on">filter_alt
                        </v-icon>
                            </template>
                            <event-filters @onClose="toggleFilter"></event-filters>
                        </v-menu>
                    </v-chip>
                    
                </v-chip-group>
            <!--            <v-spacer></v-spacer>-->
            <!--            <v-tooltip bottom>-->
            <!--                <template v-slot:activator="{ on }">-->
            <!--                    <div v-on="on">-->
            <!--                        <v-switch-->
            <!--                                class="ml-4 mt-1 mb-1 pr-5"-->
            <!--                                inset-->
            <!--                                dark-->
            <!--                                hide-details-->
            <!--                                color="red"-->
            <!--                                v-model="onlyActive"-->
            <!--                                label="Kal"-->
            <!--                        ></v-switch>-->
            <!--                    </div>-->
            <!--                </template>-->
            <!--                Skryje neuzavřené události na kterých již neprobíhá činnost-->
            <!--            </v-tooltip>-->
        </div>
        <v-list class=" d-flex flex-column overflow-auto">
            <v-lazy v-for="(event) in events"
                    :key="event.uuid" min-height="40" class="flex-shrink-0">
                <event-head :key="event.uuid" @onSelect="setActiveEvent(event.uuid)"
                            v-bind:eventKey="event.uuid"></event-head>
            </v-lazy>
        </v-list>
    </v-layout>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import eventHead from "./event/Head";
import {debounce, isEmpty} from "lodash";
import EventFilters from "@/components/panel/sidebar/EventFilters.vue";

export default {
    name: 'events',
    components: {
        EventFilters,
        eventHead,
    },
    props: {
        events: {
            type: Array,
        },
    },
    methods: {
        ...mapActions('events', [
            'setActiveEvent',
        ]),
        updateSearch: debounce(function (value) {
            this.$store.commit('events/SET_SEARCH', value)
        }, 300),
        toggleFilter() {
            this.filterDialog = !this.filterDialog;
        },
    },
    data() {
        return {
            filterDialog: false,
        }
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
            currentFilter: state => state.events.currentFilterKey,
        }),
        ...mapGetters('settings', {
            defaultFilterKey: 'getDefaultFilterKey',
        }),
        filter: {
            get() {
                return this.currentFilter;
            },
            set(key) {
                this.$store.dispatch('events/setFilterByKey', key)
                if (key === 'custom' && this.isCustomFilterEmpty) {
                    this.toggleFilter();
                }
            }
        },
        isCustomFilterEmpty() {
            return isEmpty(this.$store.state.events.customFilters);
        },
        search() {
            return this.$store.state.events.filters?.search;
        },
        onlyActive: {
            get() {
                return this.$store.state.events.onlyActive;
            },
            set(value) {
                this.$store.commit('events/setOnlyActive', value)
            }
        },
    },
}
</script>
