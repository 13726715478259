<template>
    <div>
        <vl-layer-vector id="draw-pane">
            <vl-source-vector ident="draw-target" :features.sync="drawnFeatures"></vl-source-vector>
        </vl-layer-vector>

        <vl-interaction-draw v-if="draw && drawType" @drawstart="onDrawStart" @drawend="onDrawEnd" source="draw-target"
                             :type="drawType"></vl-interaction-draw>

        <vl-layer-vector id="modify-pane">
            <vl-source-vector ident="modify-target" :features.sync="features"></vl-source-vector>
        </vl-layer-vector>

        <vl-interaction-modify source="modify-target"></vl-interaction-modify>

        <vl-overlay
                v-for="(feature, key) in editFeatures"
                :key="key"
                :id="key"
                :position="pointOnSurface(feature.feature.geometry)"
                positioning="top-left"
        >
            <template>
                <draw-form :drawKey="key" v-bind="feature"></draw-form>
            </template>
        </vl-overlay>
    </div>
</template>

<script>
    import {appBus} from "@/main";
    import drawForm from "./DrawForm";
    import {findPointOnSurface} from 'vuelayers/dist/ol-ext'

    export default {
        name: 'layer-draw',
        components: {
            drawForm,
        },
        data() {
            return {
                submitting: false,
                draw: false,
                drawType: undefined,
                drawnFeatures: [],
                form: {
                    content: '',
                    media: {},
                    data: {},
                },
            }
        },
        created() {
            appBus.$on('onToggleDraw', (type) => {
                this.toggleDraw(type)
            });
        },
        watch: {
            drawnFeatures: function (newState) {
                if (newState[0] !== undefined) {
                    this.setProps();
                    this.$store.dispatch('map/addDrawnFeature', this.drawnFeatures[0]);
                    this.$nextTick(() => {
                        this.drawnFeatures = [];
                    })
                }
            }
        },
        computed: {
            editFeatures: {
                get() {
                    return this.$store.getters['map/editFeatures'];
                },
                set() {
                    // this.$store.dispatch('map/updateDrawnFeatures', values)
                }
            },
            features: {
                get() {
                    return this.$store.getters['map/features'];
                },
                set(values) {
                    this.$store.dispatch('map/updateDrawnFeatures', values)
                }
            },
        },
        methods: {
            pointOnSurface: findPointOnSurface,
            setProps() {
                this.drawnFeatures[0].properties = {
                    type: 'draw'
                };
            },
            toggleDraw(type) {
                this.drawType = type;
                this.draw = true;
            },
            onDrawStart() {
            },
            onDrawEnd() {
                this.drawType = false;
            },
        },
        beforeDestroy() {
            appBus.$off('onToggleDraw');
        }
    }
</script>
