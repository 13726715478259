<template>
    <li v-if="visible" @click="run">
        <v-icon left color="green">$vuetify.icons.screenshot</v-icon>
        Odeslat výřez mapy
    </li>
</template>

<script>

    import {mapGetters} from 'vuex'
    import {appBus} from "@/main";
    import {ON_MAP_SCREEN_SHOT} from "@/events";
    import {includes} from "lodash";

    export default {
        name: 'map-send-screenshot-context-menu',
        props: ['data'],
        computed: {
            ...mapGetters('events', {
                isActiveEvent: 'isActiveEvent',
                event: 'getActiveEvent',
            }),
            ...mapGetters('eventCategories', {
                getEventCategoryById: 'getEventCategoryById',
            }),
            visible() {
                return this.isActiveEvent 
                    && this.event.id > 0
                    && this.$can('manage', 'Message2')
                    && !includes(['call', 'chat', 'monitor', 'support'], this.getEventCategoryById(this.event.eventCategoryId).type);
            },
        },
        methods: {
            run() {
                appBus.$emit(ON_MAP_SCREEN_SHOT, this.event.id);
            }
        },
    }
</script>
