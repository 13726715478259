<template>
    <div>
        <v-data-table
                :dense="dense"
                :headers="headers"
                :items="items"
                disable-pagination
                :hide-default-footer="true"
                :hide-default-header="hideHeader"
                sort-by="priority"
                :sort-desc="true"
                class="elevation-0 dense-last"
        >
            <template v-slot:[`header.actions`]>
                <contact-button v-if="canInsert" color='secondary' :autocomplete="autocomplete" @onSave="onSave"
                                @onDelete="onDelete"
                                :outlined="false"></contact-button>
            </template>

            <template v-slot:[`item.name`]="{ item }">
                <div class="text-sm-subtitle-1">{{ item.name }}</div>
                <div class="text-sm-body-2 text--secondary">{{ item.contactTypeName }}</div>
            </template>

            <template v-slot:[`item.number`]="{ item }">
                <span class="text-sm-subtitle-1 text-no-wrap">
                  {{ sanitizeNumber(item.number) | phone }}
                  <v-icon v-show="item.number" @click="call(item)">fa-phone-volume</v-icon>
                </span>

            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="$can('manage', 'EventContact')" fab x-small :color="buttons.send.color"
                               v-show="isActiveEvent && canSend && canAddItem(item)"
                               :disabled="!canAddToEvent(item)"
                               :dark="canAddToEvent(item)" depressed :outlined="buttons.send.outlined"
                               @click="addToEventConfirm(item)" v-on="on">
                            <v-icon v-text="buttons.send.icon"></v-icon>
                        </v-btn>
                    </template>
                    Odeslání kontaktu na výjezdový telefon
                </v-tooltip>

                <contact-button v-if="item.editable" :icon="'more_vert'" :autocomplete="autocomplete" @onSave="onSave"
                                @onDelete="onDelete"
                                :contact="item"></contact-button>
            </template>
            <template v-slot:no-data>
                žádné kontakty
            </template>
        </v-data-table>
        <confirm-dialog ref="confirm" type="delete"></confirm-dialog>
    </div>
</template>
<script>
import {appBus} from "@/main";
import {ADD_CONTACT_TO_EVENT} from "@/events";
import {mapGetters} from "vuex";
import {trim} from "lodash";
import {forEach} from "vuelayers/dist/utils";

export default {
    components: {
        ConfirmDialog: () => import("@/components/ui/ConfirmDialog"),
        ContactButton: () => import("@/components/ui/contactList/ContactListButton"),
    },
    props: {
        items: Array,
        input: {
            type: [Array, Object, String, Number],
            default: null,
        },
        autocomplete: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        showType: {
            type: Boolean,
            default: false,
        },
        canInsert: {
            type: Boolean,
            default: false,
        },
        canSend: {
            type: Boolean,
            default: false,
        },
        send: {
            type: Object,
            default: () => ({
                contactTypeId: null,
            })
        },
    },
    data() {
        return {
            itemRefs: [],
            buttons: {
                options: {
                    color: 'secondary',
                    outlined: false,
                    icon: 'options',
                },
                new: {
                    color: 'secondary',
                    outlined: false,
                    icon: 'add',
                },
                send: {
                    color: 'secondary',
                    outlined: false,
                    icon: 'send',
                },
                delete: {
                    color: 'error',
                    outlined: true,
                    icon: 'remove',
                }
            }
        }
    },
    computed: {
        headers() {
            let headers = [
                {
                    text: 'jméno',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'číslo',
                    align: 'end',
                    sortable: false,
                    value: 'number',
                    divider: true,
                },
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'actions',
                    class: 'w1px'
                }
            ];

            if (this.showType) {
                headers.unshift({
                    text: 'typ',
                    align: 'center',
                    sortable: false,
                    value: 'type',
                },);
            }

            return headers;
        },
        ...mapGetters('events', {
            isActiveEvent: 'isActiveEvent',
            event: 'getActiveEvent',
        }),
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
        }),
        ...mapGetters('eventContacts', {
            existInEvent: 'existInEvent',
        }),
    },
    methods: {
        onSave(item, callback) {
            this.$emit('onSave', item, this.input, callback)
        },
        onDelete(item, callback) {
            this.$emit('onDelete', item, this.input, callback)
        },
        canAddToEvent(item) {
            return this.isActiveEvent && this.getEventCategoryById(this.event.eventCategoryId).type === 'event' && this.canAddItem(item) && !this.existInEvent(this.event.uuid, item.number);
        },
        canAddItem(item) {
            return item.number !== null && parseInt(item.number) > 0 && trim(this.sanitizeNumber(item.number)).length === 9;
        },
        sanitizeNumber(number) {
            if (number) {
                const countryCode = number.substring(0, 5);
                if (countryCode === '00420') {
                    return number.substring(5);
                }
            }
            return number;
        },
        async addToEventConfirm(item) {
            if (
                await this.$refs.confirm.open(
                    "Potvrzení odeslání",
                    "Odeslat číslo na výjezdový telefon?"
                )
            ) {
                this.addToEvent(item);
            }
        },
        addToEvent(item) {
            let contact = {...item}

            contact.number = this.sanitizeNumber(contact.number);

            if (this.send.contactTypeId)
                contact.contactTypeId = this.send.contactTypeId;

            if (contact.send !== undefined) {
                if (contact.send.name !== undefined && contact.send.name !== null)
                    contact.name = contact.send.name;
                if (contact.send.prefix !== undefined && contact.send.prefix !== null)
                    contact.name = contact.send.prefix + ' ' + contact.name;
                if (contact.send.suffix !== undefined && contact.send.suffix !== null)
                    contact.name = contact.name + ' ' + contact.send.suffix;
            }

            appBus.$emit(ADD_CONTACT_TO_EVENT, contact);
        },
        call(item) {
            window.location = 'tel:' + item.number;
        },
        addFirstToEvent() {
            forEach(this.items, (item) => {
                if (this.canAddItem(item)) {
                    if (this.canAddToEvent(item))
                        this.addToEvent(item);
                    return false;
                }
            });
        }
    }
}
</script>
