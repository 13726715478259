<template>
    <vl-layer-vector v-if="messages" :zIndex="160">
        <vl-source-vector>
            <vl-feature v-for="(message, id) in messages" :key="id"
                        :properties="{
                        type: 'message',
                        code: message.type,
                        id: message.id,
                        tooltip: tooltip(message),
                         }">
                <vl-geom-point :coordinates="[message.longitude, message.latitude]"></vl-geom-point>
            </vl-feature>
            <vl-style-func :function="styleFunc()"/>
        </vl-source-vector>
    </vl-layer-vector>
</template>

<script>
    import Style from 'ol/style/Style'
    import Icon from 'ol/style/Icon'
    import {mapGetters} from "vuex";

    export default {
        props: {
            event: Object,
            job: Object,
        },
        data() {
            return {
                message: null,
            }
        },
        computed: {
            ...mapGetters('messages', {
                messagesByEventKeyForMap: 'byEventKeyForMap',
                tooltip: 'tooltip',
            }),
            messages() {
                return this.messagesByEventKeyForMap(this.event.uuid)
            }
        },
        methods: {
            styleFunc() {
                    return (feature) => {

                        let code = feature.get('code');

                        return [
                            new Style({
                                image: new Icon({
                                    src: './assets/icons/map/message-point-' + code + '.png',
                                    crossOrigin: 'anonymous',
                                    anchor: [0, 1],
                                }),
                            }),
                        ];
                    }
            },
        },
    }
</script>
