<template>
    <v-card class="event-view" :loading="loading" :disabled="loading" flat>
        <event-head :event="eventToEvent.sourceEvent" :active="false" :link="false"></event-head>
        <div v-if="eventToEvent.sourceEvent.overview">
            <v-simple-table dense class="elevation-0">
                <template v-slot:default>
                    <tbody>
                    <tr v-for="(row, index) in getTableData()" :key="index">
                        <td v-for="(cell, cellIndex) in row.cells" :key="cellIndex" v-linkified v-html="cell.content"
                            :colspan="cell.colspan"></td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <v-card-actions>
            <confirm-button
                    text
                    block
                    v-if="canRemoveFromEventToEvent"
                    v-bind:options="{
                        tooltip: 'Zrušení vazby k nadřízené události. Po provedení bude nutno událost znovu odbavit.',
                        confirm: 'Zrušit vazbu k nadřízené události?',
                        icon: 'link_off',
                        class: 'error',
                    }"
                    @click="removeFromEventToEvent">
                Zrušit připojení
            </confirm-button>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>

<script>

import eventHead from './HeadInactive';
import {mapActions, mapGetters} from "vuex";
import ConfirmButton from "@/components/ui/ConfirmButton";
import api from "@/api/api";
import {appBus} from "@/main";
import {UPDATE_CHILD_EVENTS} from "@/events";

export default {
    name: 'event-view-event-to-event',
    components: {
        ConfirmButton,
        eventHead,
    },
    props: {
        eventToEvent: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
        }),
        category() {
            return this.getEventCategoryById(this.eventToEvent.sourceEvent.eventCategoryId);
        },
        categoryName() {
            return this.category !== undefined ? this.category.name : '-';
        },
        canRemoveFromEventToEvent() {
            return this.category.type === 'event' && this.$store.state.auth.user.baseId === this.$store.state.bases.centralBaseId
        }
    },
    methods: {
        ...mapActions('events', {
            saveEvent: 'save',
        }),
        getTableData() {
            return this.parseHtmlTable();
        },
        parseHtmlTable() {
            let tableRows = [];
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.eventToEvent.sourceEvent.overview, 'text/html');
            const table = doc.querySelector('table');
            const rows = table.querySelectorAll('tr');

            rows.forEach((row) => {
                const cells = row.querySelectorAll('td');
                const rowData = {
                    cells: [],
                };

                cells.forEach((cell) => {
                    const colspan = cell.getAttribute('colspan');
                    const cellData = {
                        content: cell.innerHTML,
                        colspan: parseInt(colspan) || 1,
                    };
                    rowData.cells.push(cellData);
                });

                tableRows.push(rowData);
            });

            return tableRows;
        },
        sectionTitle(inputKey, defaultValue = null) {
            let title = this.getSectionTitle(this.category, inputKey);
            return title ? title : defaultValue;
        },
        removeFromEventToEvent() {
            return api.delete('/v2/event-to-events/' + this.eventToEvent.id)
                .then(() => {
                    this.loading = true;
                    this.$store.dispatch("notifications/add", {
                        type: 'success',
                        message: 'Požadavek odeslán'
                    }, {root: true});
                    appBus.$emit(UPDATE_CHILD_EVENTS);
                    this.$store.dispatch('app/getData');
                })
                .catch((error) => {
                    this.$store.dispatch("notifications/add", {type: 'error', message: error}, {root: true});
                })
                .finally(() => {
                    this.$emit('onProcessing', false);
                })
        },
    },
}
</script>
