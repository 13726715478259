import mapapi from "@/api/mapapi";
import {DateTime} from "luxon";

function getRequestData(tr = null, trainNumber = null, travelPoint = null) {
    if (tr !== null) {
        return {
            TR: tr
        };
    }
    const datetimeFrom = this.datetimeFrom ? this.datetimeFrom : (DateTime.local()).minus({hours: 12});
    const datetimeTo = this.datetimeTo ? this.datetimeTo : (DateTime.local()).plus({hours: 12});
    let requestData = {
        CasOd: datetimeFrom.toFormat('yyyy-MM-dd HH:mm:ss'),
        CasDo: datetimeTo.toFormat('yyyy-MM-dd HH:mm:ss'),
    };
    if (trainNumber !== null) {
        requestData.VlakCislo = trainNumber;
        return requestData;
    }
    if (travelPoint !== null) {
        requestData.DopravniBod = travelPoint;
        return requestData;
    }
}

const getDefaultState = () => {
    return {
        status: false,
        realTime: true,
        locationEndpoint: '/train-location',
        trackEndpoint: '/train-track',
        trains: [],
        tracks: [],
        tr: null, // current train in card
        trs: [], // all trains
        currentLocation: null
    }
}

const state = getDefaultState();

const getters = {
    getTrains: state => {
        return state.trains;
    },
    getTrain: state => {
        if (state.trains.length === 0) {
            return null;
        }
        return state.trains.find(item => item.properties.tr === state.tr);
    },
    getTr: state => {
        return state.tr;
    },
    getTrs: state => {
        return state.trs;
    },
    getTracks: state => {
        return state.tracks;
    },
    isEnabled: state => {
        return state.status;
    },
};

const actions = {
    updateTrains: ({state, dispatch}) => {
        if(state.tr !== undefined && state.trs.length > 0 && state.status === true) {
            dispatch('setTrains', state.trs);
        }
    },
    setTrains: ({state, commit}, tr = null, trainNumber = null, travelPoint = null) => {
        if (tr === null && trainNumber === null && trainNumber === null) {
            return;
        }
        let requestData = getRequestData(tr, trainNumber, travelPoint);
        requestData.RealTime = state.realTime;
        mapapi.post(state.locationEndpoint, requestData)
            .then(res => {
                commit('setTrains', res.features);
                commit('setTrs', res.tr);
            })
            .catch(err => {
                window.console.log(err);
            });
        commit('setStatus', true);
    },
    setTracks: ({state, commit}, tr) => {
        const requestData = Array.isArray(tr) ? {TR: tr} : {TR: [tr]};
        mapapi.post(state.trackEndpoint, requestData)
            .then(res => {
                commit('setTracks', res.features);
            })
            .catch(err => {
                window.console.log(err);
            });
    },
    setTr: ({state, commit, dispatch}, tr) => {
        if (state.trains.length > 0) {
            dispatch('resetState');
        }
        dispatch('setTrains', tr);
        commit('setStatus', true);
        commit('setTr', tr);
    },
    close: ({commit}) => {
        commit('setTrains', []);
        commit('setTracks', []);
        commit('setTr', null);
        commit('setStatus', false);
    },
    resetState({commit}) {
        commit('resetState');
    }
};

const mutations = {
    setTrains: (state, features) => {
        state.trains = features;
    },
    setTracks: (state, features) => {
        state.tracks = features;
    },
    setStatus: (state, status) => {
        state.status = status;
    },
    setTr: (state, tr) => {
        state.tr = tr;
    },
    setTrs: (state, trs) => {
        state.trs = trs;
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
