<template>
    <div>
        <vl-layer-tile v-for="layer in baseLayers" :key="layer.id" :zIndex="layer.zIndex" :id="layer.id"
                       :visible="layer.visible" :opacity="layer.opacity">
            <component :is="layer.source ? layer.source.cmp : 'vl-source-' + layer.name"
                       v-bind="layer.source !== undefined ? layer.source : layer"></component>
        </vl-layer-tile>

        <!--Layers-->
        <component v-for="layer in layers" :is="layer.cmp" :visible="layer.visible" :key="layer.id" v-bind="layer" @created="onLayerCreate">
            <!--Layer-->
            <component :is="layer.source.cmp" v-bind="layer.source"></component>
            <vl-feature v-for="feature in layer.source.staticFeatures" :key="feature.id"
                        :id="feature.id" :properties="feature.properties">
                <component :is="geometryTypeToCmpName(feature.geometry.type)" v-bind="feature.geometry"></component>
            </vl-feature>
            <component v-for="(style, i) in layer.style" :key="i" :is="style.cmp" v-bind="style">
                <component v-for="(st, cmp) in style.styles" :key="cmp" :is="cmp" v-bind="st">
                    <!-- vl-style-fill, vl-style-stroke if provided -->
                    <vl-style-fill v-if="st.fill" v-bind="st.fill"></vl-style-fill>
                    <vl-style-stroke v-if="st.stroke" v-bind="st.stroke"></vl-style-stroke>
                </component>
            </component>
        </component>

        <!-- interactions -->
        <vl-interaction-select :features.sync="selectedFeatures" :filter="filter">
            <template v-slot="select">
                <!--                <vl-style-func :function="styleFunc()"></vl-style-func>-->
                <vl-overlay
                        v-for="feature in select.features"
                        :key="feature.id"
                        :id="feature.id"
                        :position="feature.geometry.coordinates"
                        :auto-pan="true"
                        :auto-pan-animation="{ duration: 150 }"
                        :positioning="positioning"
                        class="feature-popup"
                >
                    <template v-slot="popup">
                        <v-card>
                            <v-card-title>
                                <div>
                                    <v-btn @click="sendAsMessage(feature, tooltip)">Přidat do zprávy
                                    </v-btn>
                                    <p v-html="tooltip">{{ popup }}</p>
                                </div>
                            </v-card-title>
                        </v-card>
                    </template>
                </vl-overlay>
            </template>
        </vl-interaction-select>
        <!--        // interactions -->
    </div>
</template>

<script>

/* eslint-disable */
import {mapActions, mapGetters, mapState} from 'vuex'
import {transformExtent, findPointOnSurface} from 'vuelayers/dist/ol-ext'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Feature from 'ol/Feature'
import Stroke from 'ol/style/Stroke'
import GeoJSON from 'ol/format/GeoJSON';
import Mask from 'ol-ext/filter/Mask'
import Icon from 'ol/style/Icon'
import axios from 'axios';
import {appBus} from "@/main";
import {pointerMove} from 'ol/events/condition'
import Vue from 'vue'
import {isEmpty, find, forEach, kebabCase, filter} from 'lodash'

export default {
    created() {
        appBus.$on('toggleLayers', (layer) => {
            this.toggleLayers(layer);
        });
    },
    mounted() {
        this.setDefaultBaseLayer();
        this.setLayers();
    },
    data() {
        return {
            layers: [],
            hoveredFeatures: [],
            selectedFeatures: [],
            tooltip: null,
            positioning: 'top-right',
            interval: null,
            token: null,
        }
    },
    name: 'layers',
    props: {
        map: undefined
    },
    computed: {
        activeBaseLayerName() {
            let layer = find(this.baseLayers, function (o) {
                return o.visible;
            });
            if (layer)
                return layer.title;

            return '-';
        },
        visibleLayers() {
            return filter(this.layers, function (o) {
                return o.options.visibleInMenu;
            });
        },
        pointerMoveCondition() {
            return pointerMove
        },
        ...mapState({
            zoom: state => state.map.zoom,
            baseLayers: state => state.map.baseLayers,
            dataProjection: state => state.map.dataProjection,
            viewProjection: state => state.map.viewProjection,
            stopaMapProxyUrl: state => state.setup.endpoints.stopaMapProxyUrl,
            terezaMapProxyUrl: state => state.setup.endpoints.terezaMapProxyUrl,
            featureUrl: state => state.setup.endpoints.featureUrl,
            darkMode: state => state.setup.darkMode,
        }),
    },
    watch: {
        zoom: function (zoom) {
            let mask = find(this.layers, {id: 'mask'});
            mask.visible = (mask.options.visibleTo > zoom);
        },
        selectedFeatures: function (newState) {
            if (!isEmpty(newState))
                this.loadTooltip(newState[0]);
        },
    },
    methods: {
        async onLayerCreate(component) {
            if (component.getId() === 'mask') {
                let layer = await component.resolveLayer();
                let color = this.darkMode ? [50, 50, 50, 0.95] : [255, 255, 255, 0.95];
                return axios.get(process.env.VUE_APP_PUBLIC_PATH + 'assets/czech2.geojson').then((response) => {
                    let geoJson = new GeoJSON({
                        dataProjection: this.dataProjection,
                        featureProjection: this.viewProjection,
                    });
                    let geometry = geoJson.readGeometry(response.data.geometry);
                    let feature = new Feature(geometry);
                    let mask = new Mask({feature: feature, inner: false, fill: new Fill({color: color})});
                    layer.addFilter(mask);
                });
            }
        },
        ...mapActions('map', {
            setDefaultBaseLayer: 'setDefaultBaseLayer',
        }),
        setLayers() {
            this.setOverlayLayers();
        },
        setOverlayLayers() {
            let proxyUrl = this.proxyUrl;

            this.layers = [
                {
                    id: 'rsd-doprava',
                    title: 'Doprava - ŘSD ČR',
                    cmp: 'vl-layer-vector',
                    visible: false,
                    options: {
                        visibleInMenu: true,
                    },
                    zIndex: 100,
                    source: {
                        projection: 'EPSG:5514',
                        cmp: 'vl-source-vector',
                        features: [],
                        url(extent, resolution) {
                            let inchesPerMeter = 39.37;
                            let dotsPerInches = 72;
                            let date = new Date();
                            let fullDate = date.getFullYear() + '-' + (('00' + (date.getMonth() + 1)).slice(-2)) + '-' + date.getDate();
                            let randInt = Math.floor(Math.random() * (9999 + 1)) + 90000;
                            let jtskExtent = extent;
                            transformExtent(jtskExtent, 'EPSG:4326', 'EPSG:5514');
                            let scale = resolution * dotsPerInches * inchesPerMeter;
                            scale = Math.round(scale);
                            let url = proxyUrl + '?url=http://www.dopravniinfo.cz/MapServices/DynamicLayers.ashx/GetFeatures?data={"resolution":' + resolution + ',"extent":{"xmin":' + jtskExtent[0] + ',"xmax":' + jtskExtent[2] + ',"ymin":' + jtskExtent[1] + ',"ymax":' + jtskExtent[3] + '},"layers":["TI","TIU","Kamery","Mereni","ZPI","Meteo","PocasiOblast","SjizdnostKomunikace","TL"],"layerDefs":{"TI":"(([MinZoom] is null) or ([MinZoom]>=' + scale + ')) and ([PlatnostOd] <= \'' + fullDate + ' 23:59:59\' AND [PlatnostDo] >= \'' + fullDate + ' 0:00\')","TIU":"(([MinZoom] is null) or ([MinZoom]>=' + scale + ')) and ([PlatnostOd] <= \'' + fullDate + ' 23:59:59\' AND [PlatnostDo] >= \'' + fullDate + ' 0:00\')","TL": "(([MinZoom] is null) or ([MinZoom]>=' + scale + '))"}}';

                            return url + '&callback=map_jsonp_callback_' + randInt;
                        },
                        // strategyFactory() {
                        // return loadingBBox
                        // },
                        crossOrigin: 'Anonymous',
                    },
                    style: [{
                        cmp: 'vl-style-func',
                        function: this.styleFunc(),
                    }],
                },
                {
                    id: 'trains',
                    title: 'Trains',
                    cmp: 'vl-layer-vector',
                    visible: false,
                    options: {
                        visibleInMenu: true,
                    },
                    zIndex: 99,
                    source: {
                        projection: 'EPSG:4326',
                        cmp: 'vl-source-vector',
                        features: [],
                        // url() {
                        //     return 'http://api.tereza.localhost:8180/v2/grapp/trains';
                        // },
                        crossOrigin: 'Anonymous',
                    },
                    style: [{
                        cmp: 'vl-style-func',
                        function: this.styleFunc(),
                    }],
                },
                {
                    id: 'mask',
                    title: 'Mask',
                    cmp: 'vl-layer-vector',
                    visible: true,
                    options: {
                        visibleInMenu: false,
                        visibleFrom: null,
                        visibleTo: 3,
                    },
                    features: [],
                    zIndex: 2,
                    source: {
                        projection: 'EPSG:4326',
                        cmp: 'vl-source-vector',
                        url: process.env.VUE_APP_PUBLIC_PATH + 'assets/czech2.geojson',
                    },
                    style: [{
                        cmp: 'vl-style-func',
                        function: this.styleMaskFunc(),
                    }],
                },
                {
                    id: 'kn',
                    title: 'Katastr nemovitostí',
                    cmp: 'vl-layer-tile',
                    visible: false,
                    options: {
                        visibleInMenu: true,
                    },
                    zIndex: 3,
                    source: {
                        cmp: 'vl-source-tile-wms',
                        url: 'http://wms.cuzk.cz/wms.asp',
                        layers: 'DKM,RST_KN,RST_KMD,RST_PK,hranice_parcel,parcelni_cisla,prehledka_kat_prac,prehledka_kat_uz,prehledka_kraju-linie',
                        styles: 'default',
                        projection: 'EPSG:5514',
                        format: 'image/png',
                        crossOrigin: 'Anonymous',
                    },
                },
                {
                    id: 'kni',
                    title: 'Katastr nemovitostí inverzní',
                    cmp: 'vl-layer-tile',
                    visible: false,
                    options: {
                        visibleInMenu: true,
                    },
                    zIndex: 2,
                    source: {
                        cmp: 'vl-source-tile-wms',
                        url: 'http://wms.cuzk.cz/wms.asp',
                        layers: 'DKM_I,RST_KN_I,RST_KMD_Ipolygony_budov,RST_PK_I,parcelni_cisla_i,hranice_parcel_i,prehledka_kat_prac,prehledka_kat_uz,prehledka_kraju-linie',
                        styles: 'default',
                        projection: 'EPSG:5514',
                        format: 'image/png',
                        crossOrigin: 'Anonymous',
                    },
                },
            ]
        },
        sendAsMessage(feature, tooltip) {
            appBus.$emit('onAddToMessageContent', this.stripHtml(tooltip));
        },
        loadTooltip(feature) {
            this.tooltip = 'Nahrávám';
            let url = this.featureUrl + '?id=' + feature.id + '&layer=' + feature.properties.type;
            return axios.get(url).then((response) => {
                this.tooltip = response.data;
            });
        },
        stripHtml(html) {
            let temporalDivElement = document.createElement("div");
            temporalDivElement.innerHTML = html;
            return temporalDivElement.innerText.trim();
        },
        filter(vector, layer) {
            return layer && layer.get('id') === 'rsd-doprava';
        },
        styleMaskFunc() {
            return () => {
                return [
                    new Style({
                        stroke: new Stroke({color: "black", width: 4}),
                    })
                ];
            }
        },
        styleFunc() {
            return () => {
                let type = feature.get('type');
                let icon = './assets/icons/unknown.png';
                let rotation = 0;
                let scale = 1;

                switch (type) {
                    case 'train':
                        icon = this.getIconPathFromIconCode(feature.get('icon'));
                        rotation = (feature.get('angle')) * Math.PI / 180;
                        scale = this.zoom > 10 ? ((this.zoom) / 10) : 1;
                        break;
                    case 'Kamery':
                        icon = './assets/icons/c.png';
                        break;
                    case 'ZPI':
                        icon = './assets/icons/z.png';
                        break;
                    case 'TI':
                        icon = process.env.VUE_APP_TEREZA_API_URL + '/image-proxy.php?url=' + 'http://www.dopravniinfo.cz/content/tilemap/img/mapobjects/ti/' + feature.get('0') + '.png';
                        break;
                    case 'TIU':
                        icon = process.env.VUE_APP_TEREZA_API_URL + '/image-proxy.php?url=' + 'http://www.dopravniinfo.cz/content/tilemap/img/mapobjects/ti/' + feature.get('0') + '.png';
                        break;
                    case 'SjizdnostKomunikace':
                        icon = process.env.VUE_APP_TEREZA_API_URL + '/image-proxy.php?url=' + 'http://www.dopravniinfo.cz/content/tilemap/img/mapobjects/sjizdnostkomunikace/' + feature.get('1');
                        break;
                    case 'PocasiOblast':
                        icon = process.env.VUE_APP_TEREZA_API_URL + '/image-proxy.php?url=' + 'http://www.dopravniinfo.cz/content/tilemap/img/mapobjects/pocasioblast/p' + feature.get('1');
                        break;
                }

                return [
                    new Style({
                        image: new Icon({
                            src: icon,
                            crossOrigin: 'anonymous',
                            // color: 'red',
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'fraction',
                            anchor: [0.5, 0.5],
                            rotation: rotation,
                            rotateWithView: true,
                            scale: scale
                        }),
                    })
                ];
            }
        },
        pointOnSurface: findPointOnSurface,
        nextBaseLayer() {

        },
        // setBaseLayer(newLayer) {
        //     // let layer = this.baseLayers.find(layer => layer.visible);
        //     // if (layer != null) {
        //     //     layer.visible = false
        //     // }
        //     //
        //     // layer = this.baseLayers.find(layer => layer.id === newLayer.id);
        //     // if (layer != null) {
        //     //     layer.visible = true
        //     // }
        //     this.$store.dispatch('map/setActiveBaseLayer', newLayer.id);
        // },
        toggleLayers(payload) {

            if (payload.show !== undefined) {
                forEach(payload.show, (key) => {
                    let layer = this.layers.find(layer => layer.id === key);
                    layer.visible = true;
                });
            }
            if (payload.hide !== undefined) {
                forEach(payload.hide, (key) => {
                    let layer = this.layers.find(layer => layer.id === key);
                    layer.visible = false;
                });
            }
            if (payload.toggle !== undefined) {
                forEach(payload.toggle, (key) => {
                    let layer = this.layers.find(layer => layer.id === key);
                    this.toggleLayer(layer);
                });
            }
        },
        toggleLayer(layer) {
            layer.visible = !layer.visible;
            if (layer.id === 'trains') {
                this.refresh();
                this.autoRefreshTrainsData(layer.visible);
            }
        },
        getIconPathFromIconCode(icon) {
            let src = process.env.VUE_APP_TEREZA_API_URL + '/image-proxy.php?url=http://provoz.szdc.cz/GrappNV/styles/images/';
            switch (icon) {
                case 111:
                    return src.concat("osobni_seda_stojici.png");
                case 112:
                    return src.concat("osobni_seda.png");
                case 113:
                    return src.concat("osobni_pokracujici_seda.png");
                case 121:
                    return src.concat("osobni_modra_stojici.png");
                case 122:
                    return src.concat("osobni_modra.png");
                case 123:
                    return src.concat("osobni_pokracujici_modra.png");
                case 131:
                    return src.concat("osobni_zelena_stojici.png");
                case 132:
                    return src.concat("osobni_zelena.png");
                case 133:
                    return src.concat("osobni_pokracujici_zelena.png");
                case 141:
                    return src.concat("osobni_zluta_stojici.png");
                case 142:
                    return src.concat("osobni_zluta.png");
                case 143:
                    return src.concat("osobni_pokracujici_zluta.png");
                case 151:
                    return src.concat("osobni_oranzova_stojici.png");
                case 152:
                    return src.concat("osobni_oranzova.png");
                case 153:
                    return src.concat("osobni_pokracujici_oranzova.png");
                case 161:
                    return src.concat("osobni_cervena_stojici.png");
                case 162:
                    return src.concat("osobni_cervena.png");
                case 163:
                    return src.concat("osobni_pokracujici_cervena.png");
                case 171:
                    return src.concat("osobni_hneda_stojici.png");
                case 172:
                    return src.concat("osobni_hneda.png");
                case 173:
                    return src.concat("osobni_pokracujici_hneda.png");
                case 211:
                    return src.concat("nakladni_seda_stojici.png");
                case 212:
                    return src.concat("nakladni_seda.png");
                case 213:
                    return src.concat("nakladni_pokracujici_seda.png");
                case 221:
                    return src.concat("nakladni_modra_stojici.png");
                case 222:
                    return src.concat("nakladni_modra.png");
                case 223:
                    return src.concat("nakladni_pokracujici_modra.png");
                case 231:
                    return src.concat("nakladni_zelena_stojici.png");
                case 232:
                    return src.concat("nakladni_zelena.png");
                case 233:
                    return src.concat("nakladni_pokracujici_zelena.png");
                case 241:
                    return src.concat("nakladni_zluta_stojici.png");
                case 242:
                    return src.concat("nakladni_zluta.png");
                case 243:
                    return src.concat("nakladni_pokracujici_zluta.png");
                case 251:
                    return src.concat("nakladni_oranzova_stojici.png");
                case 252:
                    return src.concat("nakladni_oranzova.png");
                case 253:
                    return src.concat("nakladni_pokracujici_oranzova.png");
                case 261:
                    return src.concat("nakladni_cervena_stojici.png");
                case 262:
                    return src.concat("nakladni_cervena.png");
                case 263:
                    return src.concat("nakladni_pokracujici_cervena.png");
                case 271:
                    return src.concat("nakladni_hneda_stojici.png");
                case 272:
                    return src.concat("nakladni_hneda.png");
                case 273:
                    return src.concat("nakladni_pokracujici_hneda.png");
                default:
                    return src.concat("osobni_seda_stojici.png")
            }
        },
        // refreshTrainsData() {
        //     this.layers[1].source.features = [
        //         {
        //             "type": "Feature",
        //             "id": 46470477,
        //             "geometry": {"type": "Point", "coordinates": [15.005357, 50.094737]},
        //             "properties": {
        //                 "type": "train",
        //                 "text-h6": "EC 114 Cracovia",
        //                 "layer": 1,
        //                 "icon": 152,
        //                 "angle": 289,
        //                 "order": 0
        //             }
        //         }
        //     ];
        // },
        autoRefreshTrainsData(state) {
            clearInterval(this.interval);
            if (state) {
                this.interval = setInterval(function () {
                    this.refresh();
                }.bind(this), 10000);
            } else {
                clearInterval(this.interval);
            }
        },
        refresh() {
            let url = process.env.VUE_APP_TEREZA_API_URL + '/v2/grapp/trains/' + (this.token ? this.token : 'null');
            return axios.get(url).then((response) => {
                this.token = response.data.token;
                Vue.set(this.layers[1].source, 'features', []);
                this.$nextTick(() => {
                    Vue.set(this.layers[1].source, 'features', response.data.features);
                });
            });
        },
        geometryTypeToCmpName(type) {
            return 'vl-geom-' + kebabCase(type)
        },
    },
    beforeDestroy() {
        appBus.$off('toggleLayers');
    }
}
</script>
