<template>
    <vl-layer-group>
        <devices v-if="isCurrentEventActive && isOnTheSpot(job)"
                 :opacity="screenShotActive ? 0 : 1"
                 v-bind:event="event"
                 v-bind:job="job"></devices>
        <waypoints v-if="isCurrentEventActive && !isOnTheSpot(job) && coordinates"
                   :opacity="screenShotActive ? 0 : 1"
                   v-bind:coordinates="coordinates"
                   v-bind:event="event"
                   v-bind:job="job"></waypoints>
    </vl-layer-group>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import Devices from './Devices'
import Waypoints from './Waypoints'

export default {
    name: 'layer-job',
    props: {
        job: Object,
        event: Object,
        screenShotActive: {
            default: false,
            type: Boolean,
        },
    },
    components: {
        Devices,
        Waypoints,
    },
    computed: {
        ...mapGetters('events', {
            activeEventId: 'getActiveEventId',
        }),
        ...mapGetters('bases', {
            getBaseCodeById: 'getBaseCodeById',
        }),
        ...mapGetters('resourceTypes', {
            resourceTypeById: 'byId',
        }),
        ...mapGetters('eventJobs', {
            tooltip: 'tooltip',
            label: 'label',
            isOnTheSpot: 'isOnTheSpot',
            eventJobOptions: 'optionsByJob',
        }),
        isCurrentEventActive() {
            return this.activeEventId === this.event.uuid;
        },
        ...mapState({
            vehicle(state) {
                if (this.job._vehicle !== undefined) {
                    return this.job._vehicle;
                }
                return state.resources.items[this.job.vehicle]
            },
        }),
        options() {
            return this.eventJobOptions(this.job);
        },
        icon() {
            if (this.resourceType.options?.icon) {
                return this.$store.state.setup.endpoints.imageProxyUrl + process.env.VUE_APP_TEREZA_API_URL + this.resourceType.options.icon.file;
            } else {
                if (this.vehicle && this.vehicle.icon)
                    return this.$store.state.resources.icons[this.vehicle.icon];
            }
            return 'x';
        },
        resourceType() {
            return this.resourceTypeById(this.vehicle.resourceTypeId);
        },
        template() {
            if (this.vehicle.resourceTypeId === 1)
                return 1;

            return parseInt(this.resourceType.options?.resourceTemplateId);
        },
        baseCode() {
            return this.getBaseCodeById(this.vehicle.baseId);
        },
        coordinates() {
            return this.vehicle !== undefined && this.vehicle.pnt !== undefined && this.vehicle.pnt && this.vehicle.pnt.longitude && this.vehicle.pnt.latitude ? [parseFloat(this.vehicle.pnt.longitude), parseFloat(this.vehicle.pnt.latitude)] : false;
        },
    },
    methods: {},
}
</script>
