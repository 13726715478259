<template>
  <div>
    <v-btn-toggle v-model="type" background-color="grey darken-2" rounded dense dark class="ml-2">
<!--      <v-btn value="point" fab small color="grey darken-2">-->
<!--        <v-tooltip bottom>-->
<!--          <template v-slot:activator="{ on }">-->
<!--            <v-icon size="22" v-on="on">fiber_manual_record</v-icon>-->
<!--          </template>-->
<!--          Point-->
<!--        </v-tooltip>-->
<!--      </v-btn>-->
        <v-btn v-if="isVisible" value="line-string" fab small color="grey darken-2">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon size="22" v-on="on">timeline</v-icon>
                </template>
                Zakreslit čáru
            </v-tooltip>
        </v-btn>
        <v-btn v-if="isVisible" value="polygon" fab small color="grey darken-2">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon :size="18" v-on="on">fa-draw-polygon</v-icon>
                </template>
                Zakreslit mnohoúhelník
            </v-tooltip>
        </v-btn>
        <v-btn v-if="isActiveEvent && event.id > 0" rounded dense dark small :color="layerVisible ? 'blue darken-2' : 'grey darken-2'" @click="toggleLayer">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon size="22" v-on="on">layers</v-icon>
                    {{ geoFeaturesCount }} 
                </template>
                Zobrazit/Schovat zakreslené objekty
            </v-tooltip>
        </v-btn>
<!--        <v-btn value="circle" fab small color="grey darken-2">-->
<!--            <v-tooltip bottom>-->
<!--                <template v-slot:activator="{ on }">-->
<!--                    <v-icon size="28" v-on="on">circle</v-icon>-->
<!--                </template>-->
<!--                Circle-->
<!--            </v-tooltip>-->
<!--        </v-btn>-->
<!--        <v-btn :value="-1" fab small color="grey darken-2">-->
<!--            <v-tooltip bottom>-->
<!--                <template v-slot:activator="{ on }">-->
<!--                    <v-icon size="28" v-on="on">close</v-icon>-->
<!--                </template>-->
<!--                Close-->
<!--            </v-tooltip>-->
<!--        </v-btn>-->
    </v-btn-toggle>
<!--    <v-btn-toggle v-if="isActiveEvent && event.id > 0 && event.eventCategoryId === 15" background-color="grey darken-2" rounded dense dark class="ml-2">-->
<!--      <v-btn rounded dense dark small :color="layerVisible ? 'blue darken-2' : 'grey darken-2'" @click="toggleLayer">-->
<!--        <v-tooltip bottom>-->
<!--          <template v-slot:activator="{ on }">-->
<!--            <v-icon size="22" v-on="on">layers</v-icon>-->
<!--          </template>-->
<!--          Zobrazit/Schovat objekty-->
<!--        </v-tooltip>-->
<!--      </v-btn>-->
<!--    </v-btn-toggle>-->
  </div>
</template>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
<script>

import {mapActions, mapGetters, mapState} from "vuex";
import {toArray} from "lodash"

export default {
    name: "eventGeoMenu",
    data() {
        return {
          geoLayer: false,
        }
    },
    computed: {
        isVisible() {
          return this.isActiveEvent && this.event.id > 0 && this.event.baseId === this.$store.state.auth.user.baseId;
        },
        isDisabled() {
            return this.status > 1;
        },
        geoFeaturesCount() {
            return toArray(this.geoFeatures(this.event.uuid)).length;
        },
        ...mapGetters('events', {
            isActiveEvent: 'isActiveEvent',
            event: 'getActiveEvent',
        }),
        ...mapGetters('geo', {
                geoFeatures: 'byEvent',
        }), 
        ...mapState({
            status: state => state.events.status,
            layerVisible: state => state.geo.layerVisible,
            activeEvent: state => state.events.active,
        }),
        type: {
            get() {
                return this.$store.state.geo.type;
            },
            set(value) {
                value = value === -1 ? null : value;
                this.$store.commit('geo/setType', value)
            }
        }
    },
    methods: {
      ...mapActions('geo', {
        toggleLayer: 'toggleLayerVisibility',
      }),
    },
    watch: {
        activeEvent() {
            this.$store.commit('geo/setType', null)
        }
    }
}
</script>
