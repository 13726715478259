<template>
    <div>
        <span class="text-subtitle-1 text--black">{{ title }}</span>
        <v-btn small color="info" text v-if="toggle" class="ml-1" @click="toggleList">[{{ toggleText }}]</v-btn>
        <v-simple-table dense class="mb-3 device-list" v-show="visible">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">Kód / název</th>
                    <th class="text-left">Přiděleno</th>
                    <th class="text-left" style="width: 110px">Sim</th>
                    <th class="text-left" style="width: 110px">Režim</th>
                    <th class="text-left" style="width: 130px">Poslední spojení</th>
                    <th colspan="2" class="text-left" style="width: 220px"><span v-if="allowTests">Denní zkouška spojení</span></th>
                </tr>
                </thead>
                <tbody>
                <device-table-row
                        v-for="device in devices"
                        :key="device.id"
                        v-bind:allowTests="allowTests"
                        v-bind:device="device"
                        v-on:onDetail="onDetail"
                ></device-table-row>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import deviceTableRow from "./DeviceTableRow";

export default {
    components: {
        deviceTableRow
    },
    props: {
        devices: Array,
        title: String,
        toggle: {
            type: Boolean,
            default: false,
        },
        allowTests: {
            type: Boolean,
            default: true,
        },
        show: {
            type: Boolean,
            default: true,
        },
    },
    created() {
        this.visible = this.show;
    },
    data() {
        return {
            visible: false,
        }
    },
    computed: {
        toggleText() {
            return this.visible ? 'skrýt' : 'zobrazit';
        },
        ...mapGetters('devices', {
            'deviceStatus': 'status',
        }),
    },
    methods: {
        test(device) {
            this.$emit('onTest', device);
        },
        toggleList() {
            this.visible = !this.visible;
        },
        onDetail(device) {
            this.$emit('onDetail', device)
        }
    }
}
</script>
