<template>
    <div v-if="visible">
        <vl-layer-group :zIndex="100">
            <job v-for="(job, i) in jobs" :key="i" v-bind:job="job" v-bind:event="{uuid: null}"></job>
            <vl-feature :properties="{type: 'sapTarget'}">
                <vl-geom-point :coordinates="coordinates"></vl-geom-point>
                <vl-style-func :function="targetStyleFuncFactory()"/>
            </vl-feature>
            <vl-feature v-for="(item) in lines" :key="item.id" :properties="{type: 'sapLine'}">
                <vl-geom-line-string :coordinates="item.line"></vl-geom-line-string>
                <vl-style-func :function="styleFuncFactory()"/>
            </vl-feature>
            <layer-routes v-bind:routes="routes"></layer-routes>
        </vl-layer-group>
    </div>
</template>
<script>
    import {mapGetters, mapState} from "vuex";
    import Job from '../map/layers/event/Job'
    import Style from 'ol/style/Style'
    import Stroke from 'ol/style/Stroke'
    import LayerRoutes from '../map/layers/Routes'
    import Circle from 'ol/style/Circle'
    import RegularShape from 'ol/style/RegularShape'
    import Fill from 'ol/style/Fill'

    export default {
        name: 'sap-map-layer',
        components: {
            Job,
            LayerRoutes,
        },
        computed: {
            ...mapState({
                items: state => state.sap.items,
                coordinates: state => state.sap.coordinates,
                routes: state => state.sap.routes,
                status: state => state.sap.status
            }),
            ...mapGetters('sap', {
                visible: 'visible',
                jobs: 'jobs',
                lines: 'lines',
            }),
        },
        methods: {
            targetStyleFuncFactory() {
                return () => {
                    return [
                        new Style({
                            zIndex: 1,
                            image: new RegularShape({
                                stroke: new Stroke({color: "black", width: 1}),
                                points: 4,
                                radius: 6,
                                radius2: 0,
                            })
                        }),
                        new Style({
                            zIndex: 1,
                            image: new Circle({
                                fill: new Fill({
                                    color: 'rgba(255, 0, 0, 0.2)',
                                }),
                                stroke: new Stroke({color: "red", width: 2}),
                                radius: 30,
                            }),
                        }),
                    ];
                }
            },
            styleFuncFactory() {
                return () => {
                    return [
                        new Style({
                            stroke: new Stroke({
                                width: 4.5,
                                color: "white",
                            }),
                        }),
                        new Style({
                            stroke: new Stroke({
                                width: 2,
                                color: "black",
                                lineCap: 'square',
                                lineDash: [8, 8],
                            }),
                        }),
                    ];
                }
            },
        },
    }
</script>
