<template>
    <fragment>
        <li @click="panorama">
            <v-icon left color="light-blue">$vuetify.icons.panorama</v-icon>
            Náhled na silnici
        </li>
    </fragment>
</template>

<script>
    export default {
        name: 'panorama-context-menu',
        props: [
            'data',
        ],
        methods: {
            panorama() {
                this.$store.dispatch('panorama/setUserCoordinates', this.data.coordinates);
            },
        },
    }
</script>
