<template>
    <v-col v-if="date" class="header-datetime text-center">
        <div>
            <div class="text-h6 font-weight-black lh-1-3">
                {{date | moment("H:mm:ss") }}
            </div>
            <div class="text-caption lh-1-0">
                {{date | moment("dddd") }} {{date | moment("D.M.YYYY") }}
            </div>
        </div>
    </v-col>
</template>

<script>
    import {mapState} from "vuex";
    import moment from "moment/moment";

    export default {
        computed: {
            title() {
                return  "PANEL " + moment(this.date).format('HH:mm:ss');
            },
            ...mapState({
                date: state => state.app.secondDate,
            })
        },
        watch: {
            title(val) {
                window.document.title = val;
            }
        }
    }
</script>
