<template>
    <div class="v-search" v-show="enabled">
        <div class="panel-map-search-content">
            <search-component
                    dark
                    flat
                    ref="addressSearch"
                    v-show="enabled"
                    v-bind:enabled="enabled"
                    v-bind:groups="searchGroups"
                    v-bind:types="searchTypes"
                    v-bind:origin="mapOrigin"
                    v-bind:points="points"
                    v-bind:urls="urls"
                    @onClearOrigin="clearMapOriginObject"
                    @onRemovePoint="removePoint"
                    @onRemoveAllPoints="removeAllPoints"
                    @onClose="toggleMapSearch"
                    @onSuggestionSelect="onSuggestionSelect"
                    @onPointFocus="onPointFocus"
                    @onSuggestionFocus="onSuggestionFocus"
                    @onGroupChange="onGroupChange"
            ></search-component>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex'
    import {uuid} from 'vue-uuid';
    import SearchComponent from "./SearchComponent";
    import {appBus} from "@/main";
    import mapapi from "@/api/mapapi";

    export default {
        name: 'map-search',
        inject: ['theme'],
        components: {
            SearchComponent,
        },
        created() {
            appBus.$on('clearAddressSearch', () => {
                this.clearAddressSearch();
            });
            appBus.$on('toggleMapSearch', () => {
                this.toggleMapSearch();
            });
            appBus.$on('showMapSearch', () => {
                this.showMapSearch();
            });
        },
        data() {
            return {
              urls: {
                ruian: null,
                train: null,
                isor: `${mapapi.getUrl()}/search/query`,
              },
            };
        },
        computed: {
            classes() {
                return {
                    'theme--dark': this.theme.isDark,
                    'theme--light': !this.theme.isDark
                }
            },
            ...mapGetters('setup', {
                defaultSearchGroup: 'defaultSearchGroup',
            }),
            ...mapState({
                zoomTo: state => state.map.zoomTo,
                mapOrigin: state => state.map.originObject,
                url: state => state.setup.endpoints.apiSearchUrl,
                searchGroups: state => state.setup.search.groups,
                searchTypes: state => state.setup.search.types,
                points: state => state.map.points,
                eventId: state => state.events.active,
                enabled: state => state.panel.mapSearch,
                event: state => state.events.local[state.events.active],
            }),
        },
        methods: {
            ...mapActions('panel', {
                toggleMapSearch: 'toggleMapSearch',
            }),
            ...mapActions('map', {
                setSearchHoverPoint: 'setSearchHoverPoint',
                clearMapOriginObject: 'clearOriginObject',
                setAutoFocus: 'setAutoFocus',
                extendTo: 'extendTo',
                removeAllPoints: 'removeAllPoints',
                removePoint: 'removePoint',
            }),
            clearAddressSearch() {
                this.onGroupChange(this.defaultSearchGroup);
                if (this.$refs['addressSearch'] !== undefined) // Todo: proc neni nekdy definovano
                    this.$refs['addressSearch'].clearAddressSearch();
            },
            onSuggestionFocus(item) {
                this.extendTo([item.longitude, item.latitude])
            },
            onSuggestionSelect(item) {
                this.setAutoFocus(false);
                if(item.type_code === 'trainnumber') {
                  this.$store.dispatch('trainLocation/setTr', item.id);
                  this.extendTo([item.longitude, item.latitude]);
                  this.clearAddressSearch();
                  this.toggleMapSearch();
                } else {
                  this.$store.commit('map/addPoint', this.toMapObject(item));
                }
            },
            onSuggestHover() { // suggest
                // this.setSearchHoverPoint([suggest.longitude, suggest.latitude])
            },
            onGroupChange(id) {
                this.$store.commit('setup/toggleSearchType', id);
            },
            onPointFocus(item) {
                if(item.type_code === 'trainnumber') {
                  this.$store.dispatch('trainLocation/setTr', item.id);
                }
                this.$store.commit('map/setZoom', this.zoomTo);
                this.$store.commit('map/setCenter', item.coordinates);
            },
            toMapObject(suggest, type = 'point') {
                if (suggest == null)
                    return false;

                return {
                    id: suggest.id,
                    uuid: uuid.v1(),
                    title: suggest.title + (suggest.note ? ' ' +  suggest.note : ''),
                    type_code: suggest.type_code,
                    type: type,
                    base_title: suggest.jpo_name,
                    base_id: suggest.jpo_id,
                    coordinates: [
                        parseFloat(suggest.longitude),
                        parseFloat(suggest.latitude)
                    ]
                }
            },
        },
      mounted() {
          this.urls.ruian = this.url;
          this.urls.train = this.url;
      },
      beforeDestroy() {
            appBus.$off('clearAddressSearch');
            appBus.$off('toggleMapSearch');
            appBus.$off('showMapSearch');
        }
    }
</script>
