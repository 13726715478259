import {forEach, find, pickBy} from "lodash";
import {stateMerge} from "../object-merge";

const DEFAULT_CATEGORY = 1;

const getDefaultState = () => {
    return {
        items: {},
        centralBaseId: 2,
    }
};

const state = getDefaultState();

const getters = {
    getUserBase: (state, getters, rootState) => {
        let baseId = rootState.auth.user.baseId;
        return baseId > 0 ? getters.byId(baseId) : null;
    },
    byId: (state) => (id) => {
        if (id && state.items[id] !== undefined)
            return state.items[id];

        return {
            id: '-',
            code: '-',
            name: '-',
        }
    },
    defaultBases: (state, getters) => {
        return getters.byCategoryId(DEFAULT_CATEGORY);
    },
    byCategoryId: (state) => (id) => {
        return pickBy(state.items, function (item) {
            return item.baseCategoryId === id;
        });
    },
    getByUid: (state) => (uid) => {
        return find(state.items, {'uid': parseInt(uid)});
    },
    getBaseCodeById: (state, getters) => (id) => {
        return getters.byId(id).code;
    },
    options: (state, getters) => {
        let options = [];
        forEach(getters.defaultBases, (item, id) => {
            options.push({
                value: Number(id),
                text: item.name,
                code: item.code,
                disabled: false,
            });
        });
        return options;
    },
    tooltip: () => (base) => {
        return {
            title: 'Požární stanice',
            content: base.name,
            overview: null,
        };
    },
};

const actions = {
    async set({commit}, items) {
        commit("MERGE_STATE", items)
    },
    async getBaseByUid({state}, uid) {
        return find(state.items, {'uid': uid});
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
