import moment from "moment/moment";

export const dateFormatMixin = {
    methods: {
        dateTimeFromDb: function (datetime) {
            let d = moment(datetime, ['YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm:ss'], true);
            if (d.isValid())
                return moment(datetime, ['YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm:ss']).format('DD.MM.YYYY HH:mm');
            return datetime;
        },
        dateTimeToDb: function (datetime) {

            let d = moment(datetime, ['DD.MM.YYYY HH:mm'], true);
            if (d.isValid())
                return moment(datetime, ['DD.MM.YYYY HH:mm']).format('YYYY-MM-DD HH:mm');
            return datetime;
        }
    }
};
