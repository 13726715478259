<template>
    <vue-context class="context-menu" ref="contextMenu" @close="onClose" @open="onOpen" id="context-menu">
        <ol v-if="opened && data">
            <div class="context-menu-title" v-html="title"></div>
            <div v-show="header" class="context-menu-info" v-html="header"></div>
            <li class="context-menu__event-context-group">
                <ol>
                    <li @click="link">
                        <v-icon left color="red">ter-paperclip</v-icon>
                        <span>Připojit k této události</span>
                    </li>
                </ol>
            </li>
        </ol>
    </vue-context>
</template>

<script>
import VueContext from 'vue-context';
import {mapGetters} from "vuex";
import {appBus} from "@/main";
import {HIDE_TOOLTIP, ON_SHOW_EVENT_LINK_DIALOG} from "@/events";

export default {
    name: 'link-event-context-menu',
    data() {
        return {
            data: null,
            opened: false,
            title: null,
            header: null,
            overview: null,
        }
    },
    components: {
        VueContext,
    },
    computed: {
        ...mapGetters('events', [
            'isActiveEvent',
            'targetTooltip',
            'getActiveEvent',
        ]),
    },
    methods: {
        link() {
            appBus.$emit(HIDE_TOOLTIP);
            appBus.$emit(ON_SHOW_EVENT_LINK_DIALOG, this.data.feature.getId());
        },
        setHeaderAndTitle() {
            this.title = null;
            this.header = null;
            this.overview = null;

            if (this.data.feature !== null) {
                let tooltip = this.data.feature.getProperties().tooltip;
                if (tooltip !== undefined) {
                    this.title = tooltip.title;
                    this.header = tooltip.content;
                }
            } else if (this.isActiveEvent) {
                let tooltip = this.targetTooltip(this.getActiveEvent);
                this.title = tooltip.title;
                this.header = tooltip.content;
            }
        },
        onClose() {
            this.opened = false;
            this.$store.commit('panel/SET_CONTEXT_MENU', 0);
        },
        onOpen() { // event, data, top, left
            this.$store.commit('panel/SET_CONTEXT_MENU', 1);

            this.opened = true;
            this.setHeaderAndTitle();
        },
        open(event, data) {
            this.opened = false;
            this.data = false;
            if (data) {
                this.$nextTick(() => {
                    this.data = data;
                    this.opened = true;
                    this.$refs.contextMenu.open(event, data);
                });
            }
        }
    },
}

</script>
