import {find} from "lodash";
import {stateMerge} from "../object-merge";

const getDefaultState = () => {
    return {
        items: {},
    }
};

const state = getDefaultState();

const getters = {
    getByType: (state) => {
        return (code) => {
            return find(state.items, function(obj) {
                if (obj.type === code)
                    return true;
            });
        }
    },
};

const actions = {
    async set({commit}, items) {
        commit("MERGE_STATE", items)
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
