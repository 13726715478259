<template>
    <tr :class="result.rowClass">
        <td>{{ device.code }} / {{ device.name }}</td>
        <td>{{ device.resourceName }}</td>

        <td>{{ device.number }}</td>
        <td>{{ deviceStatus(device) }}</td>
        <td>{{ connectedAt(device) }}</td>
        <td :class="result.cellClass" class="text-center" @click="detail(device)"><span v-if="allowTests">{{ result.text }}</span></td>
        <td>
            <v-btn v-if="allowTests" small text @click="test(device)" :disabled="requestingTest">{{ requestTestButtonText }}
            </v-btn>
        </td>
    </tr>
</template>
<script>

    import {mapGetters} from "vuex";
    import moment from "moment/moment";

    const REQUEST_BUTTON_SEND_TEXT = 'otestovat';
    const REQUEST_BUTTON_SENDING_TEXT = 'odeslám';

    export default {
        props: {
            device: Object,
            allowTests: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                requestTestButtonText: REQUEST_BUTTON_SEND_TEXT,
                requestingTest: false,
            }
        },
        computed: {
            toggleText() {
                return this.visible ? 'skrýt' : 'zobrazit';
            },
            ...mapGetters('devices', {
                'deviceStatus': 'status',
            }),
            ...mapGetters('deviceList', {
                'deviceResult': 'result',
            }),
            result() {
                return this.deviceResult(this.device);
            },
        },
        methods: {
            detail() {
                if (this.result.status !== false)
                    this.$emit('onDetail', this.device);
            },
            test() {
                this.requestingTest = true;
                this.requestTestButtonText = REQUEST_BUTTON_SENDING_TEXT;
                this.$store.dispatch('deviceList/test', this.device.id).then(() => {
                    this.requestTestButtonText = REQUEST_BUTTON_SEND_TEXT;
                    this.requestingTest = false;
                });
            },
            toggleList() {
                this.visible = !this.visible;
            },
            connectedAt(item) {
                if (item.connectedAt) {
                    let connectedAt = moment(item.connectedAt * 1000);
                    return connectedAt.format(connectedAt.isSame(moment(), 'day') ? 'HH:mm:ss' : 'DD.MM.YYYY') ;
                }
                return '-';
            }
        }
    }
</script>
