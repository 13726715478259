<template>
    <div class="grid-container">
        <v-btn tile dark depressed :color="buttonColor" text height="auto" @click="page('chat')">
            <v-icon :size="iconSize">question_answer</v-icon>
            <span>Zprávy</span>
        </v-btn>
        <v-btn tile dark depressed :color="buttonColor" text height="auto" @click="page('contacts')">
            <v-icon flat :size="iconSize">$vuetify.icons.eventContacts</v-icon>
            <span>Telefony</span>
        </v-btn>
        <v-btn-hold :color="buttonColor"
                    dark
                    depressed
                    height="auto"
                    overlayTitle="Start nahrávání zvuku"
                    progressIcon="keyboard_voice"
                    text
                    tile
                    @onClick="photo()"
                    @onHold="audioStart()"
                    @onRelease="audioStop()">
            <v-icon :size="iconSize">photo_camera</v-icon>
            <span>Foto | Hlas</span>
        </v-btn-hold>
        <v-btn-hold :color="buttonColor"
                    dark
                    depressed
                    height="auto"
                    overlayTitle="Spustit navigaci"
                    progressIcon="route"
                    text
                    tile
                    @onClick="page('map')"
                    @onHold="navigate()">
            <v-icon tile :size="iconSize">location_on</v-icon>
            <span>Mapa | Navigace</span>
        </v-btn-hold>
        <v-btn tile dark depressed :color="buttonColor" text height="auto" @click="actionDialog = true">
            <v-icon tile :size="iconSize">handyman</v-icon>
            <span>Činnost</span>
        </v-btn>
        <v-btn tile dark depressed :color="buttonColor" text height="auto" @click="docDialog = true">
            <v-icon tile :size="iconSize">menu_book</v-icon>
            <span>Dokumentace</span>
        </v-btn>

        <v-dialog v-model="actionDialog" @keydown.esc="actionDialog = false" scrollable>
            <v-card dark :loading="sending" :disabled="sending">
                <v-card-title class="text-h6 justify-center">
                    <v-icon left tile>handyman</v-icon>
                    Činnost
                </v-card-title>
                <v-card-text class="px-4 text-body-1 black--text text-center">
                    <v-row>
                        <v-col>
                            <v-btn block light x-large @click="take">Převzít poruchu</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn block light x-large @click="start">Zahájení opravy</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn block light x-large @click="end">Ukončení opravy</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pt-3 justify-center">
                    <v-btn
                            large
                            color="grey"
                            text
                            @click="actionDialog = false"
                    >Zpět
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="docDialog" @keydown="docDialog = false">
            <v-card dark>
                <v-card-title class="text-h6 justify-center">
                    <v-icon left tile>menu_book</v-icon>
                    Dokumentace
                </v-card-title>
                <v-card-text
                        class="px-4 text-body-1 black--text text-center"
                ></v-card-text>
                <v-card-actions class="pt-3 justify-center">
                    <v-btn
                            large
                            color="grey"
                            text
                            @click="docDialog = false"
                    >Zpět
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <capture-photo style="display: none" :message-category-id="3" ref="photo" :event-id="event.id"></capture-photo>
        <radio style="display: none" ref="audio" :message-category-id="3" :event-id="event.id"></radio>
        <v-overlay :value="audioRecording">
            <v-progress-circular
                    class="hold-button"
                    :rotate="-90"
                    :size="100"
                    :width="15"
                    :value="0"
                    color="white"
            >
                <v-icon x-large>keyboard_voice</v-icon>
            </v-progress-circular>
            <div class="mt-3 text-uppercase">Nahrávám</div>
        </v-overlay>
    </div>
</template>
<script>

import moment from "moment";
import VBtnHold from "@/components/ui/HoldButton.vue";
import CapturePhoto from "@/components/camera/CapturePhoto.vue";
import Radio from "@/components/panel/sidebar/event/MessageRadio.vue";

export default {
    name: 'sidebar-event-mobile-index',
    components: {Radio, CapturePhoto, VBtnHold},
    props: {
        event: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            audioRecording: false,
            actionDialog: false,
            docDialog: false,
            iconSize: 50,
            buttonColor: 'white',
            sending: false
        }
    },
    methods: {
        take() {
            this.sending = true;

            this.$store.dispatch('eventContacts/save', {
                eventId: this.event.id,
                contactTypeId: 4,
                custom: 1,
                name: this.$store.state.auth.user.firstName + ' ' + this.$store.state.auth.user.lastName,
                number: this.$store.state.auth.device.number
            }).then(() => {
                let message = {
                    messageCategoryId: 3,
                    content: 'Porucha/událost byla převzata k řešení. Řešitel: ' + this.$store.state.auth.user.firstName + ' ' + this.$store.state.auth.user.lastName + ', ' + moment().format('DD.MM.YYYY HH:mm'),
                    type: 'X03',
                };
                this.send(message);
            });
        },
        start() {
            let message = {
                messageCategoryId: 3,
                content: 'Oprava zahájena ' + moment().format('DD.MM.YYYY HH:mm'),
                type: 'X03',
            };
            this.send(message);
        },
        end() {
            let message = {
                messageCategoryId: 3,
                content: 'Oprava ukončena ' + moment().format('DD.MM.YYYY HH:mm'),
                type: 'X03',
            };
            this.send(message);
        },
        send(message) {
            this.sending = true;
            this.$store.dispatch('messages/send', message).then(() => {
                this.sending = false;
                this.$emit('onSend', message);
                this.actionDialog = false;
            });
        },
        page(page) {
            this.$emit('onPage', page);
        },
        photo() {
            this.$refs.photo.handleFileImport();
        },
        audioStart() {
            this.audioRecording = true;
            this.$refs.audio.startRecordRadio();
        },
        audioStop() {
            this.audioRecording = false;
            this.$refs.audio.stopRecordRadio();
        },
        navigate() {
            window.open(this.getNavigationLink());
        },
        getNavigationLink() {
            // todo: Get destination lon/lat if exists 
            if (this.isIos()) {
                return 'http://maps.apple.com/maps?daddr=' + this.event.targetLatitude + ',' + this.event.targetLongitude
            } else if (this.isMobile()) {
                return 'google.navigation:q=' + this.event.targetLatitude + ',' + this.event.targetLongitude
            } else {
                return 'http://maps.google.com/maps?daddr=' + this.event.targetLatitude + ',' + this.event.targetLongitude
            }
        },
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        isIos() {
            const standalone = window.navigator.standalone,
                userAgent = window.navigator.userAgent.toLowerCase(),
                safari = /safari/.test(userAgent),
                ios = /iphone|ipod|ipad/.test(userAgent);

            if (ios) {
                if (!standalone && safari || standalone && !safari || !standalone && !safari) {
                    return true;
                }
            } else {
                return false;
            }
        }
    }
}

</script>