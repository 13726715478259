<template>
    <v-dialog v-model="modal" max-width="600" eager persistent>
        <v-card>
            <v-toolbar flat dense dark>
                <div>Přeposlání zprávy do kanálu: {{ messageCategory.name }}</div>
                <v-spacer></v-spacer>
                <v-btn rounded small light class="mt-1" @click="modal=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <message-form ref="messageForm" :eventId="event.id"
                          :messageCategory="messageCategory" :multiple="true" @onMessageSend="onMessageSend"></message-form>
        </v-card>
    </v-dialog>
</template>

<script>
import {appBus} from "@/main";
import MessageForm from './MessageForm'
import {ON_FORWARD_MESSAGE} from "@/events";

export default {
    name: 'sidebar-event-message-forward',
    components: {
        MessageForm
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    created() {
        appBus.$on(ON_FORWARD_MESSAGE, (message, messageCategory, tag) => {
            this.messageCategory = messageCategory;
            this.message = message;
            this.tag = tag;
            this.showModal();
        });
    },
    data() {
        return {
            modal: false,
            message: null,
            messageCategory: {},
            tag: {},
        }
    },
    computed: {},
    methods: {
        showModal() {
            this.modal = true;
            this.$nextTick(() => {
                this.$refs.messageForm.resetForm();
                this.$refs.messageForm.setContentFromMessage(this.message)
            });
        },
        async onMessageSend() {
            await this.$store.dispatch('messages/applyTag', {message: this.message, tag: this.tag});
            this.modal = false;
        }
    },
    watch: {
        modal(value) {
            if (value === false) {
                this.message = null;
                this.messageCategory = {};
                this.tag = {};
            }
        }
    },
    beforeDestroy() {
        appBus.$off(ON_FORWARD_MESSAGE);
    }
}
</script>
