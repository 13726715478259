<template>
    <v-card>
        <v-card-text>
            <div class="d-flex justify-center">
            <v-chip-group
                    v-model="filter"
                    column
                    class="px-2"
            >
                <v-chip filter outlined :value="69">Dron</v-chip>
                <v-chip filter outlined :value="70">Inspektor</v-chip>
            </v-chip-group>
        <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Filtrovat"
                single-line
                hide-details
                outlined
                dense
        ></v-text-field>
            </div>
        </v-card-text>
        <v-data-table
                :height="tableHeight"
                fixed-header
                dense
                multi-sort
                :loading="loading"
                :headers="headers"
                :items="filteredResources"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                class="elevation-1"
                @click:row="select"
                disable-pagination
                hide-default-footer
        >
            <template v-slot:[`item.event`]="{ item }">
                <span v-if="item.event">
               <v-tooltip right content-class="map-tooltip-wrapper">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                        <v-icon size="40" @click.stop="showEventView(item.event)" :color="eventCategory(item.event.eventCategoryId).color">{{  eventCategory(item.event.eventCategoryId).icon }}</v-icon>
                        </div>
                    </template>
                    <tooltip-content :tooltip="tooltip(item.event)"></tooltip-content>
                </v-tooltip>
                </span>
            </template>
            <template v-slot:[`item.icon`]="{ item }">
                <event-job-icon v-bind:resource="item" v-bind:job="item.eventJob ? item.eventJob : null"
                                class="my-2"></event-job-icon>
            </template>
            <template v-slot:[`item.baseId`]="{ item }">
                {{ item.baseName }}
            </template>
            <template v-slot:[`item.resourceTypeId`]="{ item }">
                {{ resourceTypeById(item.resourceTypeId).name }}
            </template>
        </v-data-table>
    </v-card>
</template>

<script>

import {mapGetters} from "vuex";
import {values} from "lodash";
import api from "@/api/api";
import EventJobIcon from "@/components/panel/sidebar/event/JobIcon";
import TooltipContent from "@/components/ui/TooltipContent.vue";
import {appBus} from "@/main";
import {SHOW_EVENT_VIEW} from "@/events";

export default {
    name: 'sidebar-event-contact-form',
    components: {TooltipContent, EventJobIcon},
    data() {
        return {
            filter: null,
            search: '',
            loading: true,
            hints: true,
            tableHeight: '',
            resources: [],
            headers: [
                {text: 'stav', align: 'left', sortable: true, value: 'event'},
                {text: '', align: 'left', sortable: true, value: 'icon'},
                {text: 'název', align: 'left', sortable: true, value: 'name'},
                {text: 'kód (RZ)', align: 'left', sortable: true, value: 'licence'},
                {text: 'jednotka', align: 'left', sortable: true, value: 'baseName'},
                {text: 'typ', align: 'left', sortable: true, value: 'resourceTypeId'},
            ],
            sortBy: ['resourceTypeId', 'name'],
            sortDesc: [false, false],
        }
    },
    created() {
        this.load();
    },
    mounted() {
        window.addEventListener('resize', this.setHeight);
        this.setHeight();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setHeight);
    },
    computed: {
        filteredResources() {
            return this.resourcesArray.filter((item) => {
                const licenceNoSpaces = item.licence?.replaceAll(/\s+/g, '').toLowerCase();
                const searchCheck = item.name.toString().toLowerCase().includes(this.search.toLowerCase())
                    || licenceNoSpaces?.toString().toLowerCase().includes(this.search.toLowerCase())
                    || item.baseName?.toString().toLowerCase().includes(this.search.toLowerCase())

                const filterCheck = this.filter ? item.resourceTypeId === this.filter : true;

                return searchCheck && filterCheck;
            })
        },
        resourcesArray() {
            return values(this.resources);
        },
        eventCategory() {
            return id => this.getEventCategoryById(id);
        },
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
        }),
        ...mapGetters('bases', {
            baseById: 'byId',
        }),
        ...mapGetters('resourceTypes', {
            resourceTypeById: 'byId',
        }),
        ...mapGetters('events', {
            tooltip: 'targetTooltip',
        }),
    },
    methods: {
        load() {
            this.loading = true;
            api.get('/v2/resources')
                .then((response) => {
                    this.loading = false;
                    this.resources = response;
                })
                .catch(() => {
                })
        },
        select(row) {
            this.$emit('onSelect', row);
        },
        showEventView(event) {
            if (event)
                appBus.$emit(SHOW_EVENT_VIEW, event);  
        },
        close() {
            this.$emit('onClose');
        },
        setHeight() {
            this.tableHeight = window.innerHeight * 0.9 - 200;
        }
    }
}
</script>
