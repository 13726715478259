<template>
    <div>
        <vl-layer-vector :zIndex="120" :opacity="screenShotActive ? 0 : 1" >
            <vl-source-vector ref="event-poises" ident="eventPoises">
                <vl-feature v-for="poi in eventActiveExisting" :key="poi.uuid"
                            :properties="{type: 'eventObject', sequence: poi.sequence, tooltip: tooltip(poi),  uuid: poi.uuid,active: isObjectActive(poi.uuid), draggable: false}">
                    <vl-geom-point :coordinates="[poi.longitude, poi.latitude]"></vl-geom-point>
                </vl-feature>
                <vl-style-func :function="styleFunc()"/>
            </vl-source-vector>
        </vl-layer-vector>
        <vl-layer-vector :zIndex="121" :opacity="screenShotActive ? 0 : 1">
            <vl-source-vector ident="newEventObject" :features.sync="features">
                <vl-feature v-if="poi && !poi.id" :key="poi.uuid"
                            :properties="{type: 'eventObject', sequence: poi.sequence, uuid: poi.uuid, active: true, draggable: true}"
                >
                    <vl-geom-point :coordinates="[poi.longitude, poi.latitude]"></vl-geom-point>
                </vl-feature>
                <vl-style-func :function="styleFunc()"/>
            </vl-source-vector>
        </vl-layer-vector>
        <vl-interaction-translate
                v-if="features !== undefined && features.length > 0"
                source="newEventObject"
                @translating="updateCoord"
                @translateend="updateCoord"
        >
        </vl-interaction-translate>
        <vl-overlay
                v-if="isActive"
                :position="[poi.longitude, poi.latitude]"
                style="position: absolute"
        >
            <v-card raised min-width="300" dark>
                <v-card-text>
                    <v-textarea
                            ref="objectDescription"
                            auto-grow
                            text
                            outline
                            hide-details
                            v-model="content"
                            rows="2"
                            :readonly=readOnly
                            :disabled="processed"
                            label="popis bodu"
                    ></v-textarea>
                </v-card-text>
                <v-toolbar
                        dark
                        dense
                >
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn v-if="!poi.id" text small :disabled="processed" @click="save" title="Uložit">
                            <v-icon left small>$vuetify.icons.save</v-icon>
                            Uložit
                        </v-btn>
                        <v-btn text small @click="remove" :disabled="processed" title="Odebrat">
                            <v-icon left small>$vuetify.icons.trash</v-icon>
                            Odebrat
                        </v-btn>
                        <v-btn text small @click="close" :disabled="processed" title="Zpět">
                            <v-icon left small>$vuetify.icons.cancel</v-icon>
                            Zpět
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
            </v-card>
        </vl-overlay>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Style from 'ol/style/Style'
import Circle from 'ol/style/Circle'
import Fill from 'ol/style/Fill'
import Text from 'ol/style/Text'
import Stroke from 'ol/style/Stroke'

export default {
    name: 'layer-event-poises',
    props: {
        event: Object,
        screenShotActive: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            content: '',
            processed: false,
            features: undefined,
        }
    },
    computed: {
        ...mapState({
            isActive: state => state.eventPoises.status,
        }),
        ...mapGetters('eventPoises', {
            poi: 'activePoi',
            poises: 'eventPoises',
        }),
        readOnly() {
            return this.poi.id > 0;
        },
        eventActiveExisting() {
            return this.poises(this.event.uuid).filter(function (item) {
                return item.id;
            });
        },
    },
    methods: {
        ...mapActions('eventPoises', {
            close: 'close',
        }),
        updateCoord(event) {
            this.poi.longitude = event.coordinate[0];
            this.poi.latitude = event.coordinate[1];
        },
        tooltip(poi) {
            return {
                title: 'Poznámka č.: ' + poi.sequence,
                content: poi.content,
            }
        },
        remove() {
            this.processed = true;
            this.$store.dispatch('eventPoises/remove').finally(() => {
                this.processed = false;
            });
        },
        save() {
            this.processed = true;
            this.poi.content = this.content;
            let payload = this.poi;
            this.$store.dispatch('eventPoises/save', payload).finally(() => {
                this.processed = false;
            });
        },
        isObjectActive(uuid) {
            return this.isActive && this.poi && this.poi.id && this.poi.uuid === uuid;
        },
        styleFunc() {
            return (feature) => {

                let scale = 0.7;
                let active = feature.get('active');
                let sequence = feature.get('sequence');

                if (!sequence)
                    sequence = '-';

                return [
                    new Style({
                        image: new Circle({
                            fill: new Fill({
                                color: active ? 'yellow' : 'red',
                            }),
                            stroke: new Stroke({color: "black", width: 1}),
                            radius: 12,
                        }),
                        text: new Text({
                            text: '' + sequence,
                            placement: 'point',
                            textBaseline: 'middle',
                            textAlign: 'center',
                            scale: scale * 2,
                            fill: new Fill({
                                color: active ? 'black' : 'white',
                            })
                        }),
                    }),
                ];
            }
        },
    },
    watch: {
        isActive: function (active) {
            if (active) {
                this.content = this.poi.content;
                this.$nextTick(() => {
                    let input = this.$refs.objectDescription;
                    setTimeout(function () {
                        input.focus();
                    }, 200);
                });
            }
        },
    },
}
</script>
