const sphere = require('ol/sphere')

module.exports = {
    formatDistance: (value) => {

        let distance = '';
        if (value >= 1) {
            distance = 'km';
        } else {
            value = value * 1000;
            distance = 'm';
        }
        return Math.round(value) + distance;
    },
    formatLineLength: (line) => {
        const length = sphere.getLength(line);
        let output;
        if (length > 1000) {
            output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
        } else {
            output = Math.round(length * 10) / 10 + ' ' + 'm';
        }
        return output;
    },
    formatPolygonArea: (polygon) => {
        const area = sphere.getArea(polygon);
        let output;
        if (area > 100000) {
            output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km2';
        } else {
            output = Math.round(area * 100) / 100 + ' ' + 'm2';
        }
        return output;
    },
}
