import axios from 'axios';
import store from "../store";

let apiUrl = process.env.VUE_APP_STOPA_API_URL;

export default {
    getHeaders() {
        return {
            timeout: 10000
        };
    },
    getData(cb) {
        this.loadData()
            .then(
                data => {
                    cb(data);
                }
            ).catch(function (error) {
            // eslint-disable-next-line
            console.error('error 2', error);
        });
    },
    get(endpoint) {
        return axios.get(apiUrl + endpoint)
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    post(endpoint, data) {
        return axios.post(apiUrl + endpoint, data, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(
                response => response.data
            ).catch(function (error) {
                throw new Error(error);
            });
    },
    loadData() {
        return new Promise(function (resolve) {
            resolve({
                "types": {
                    "T": "1",
                    "T05": "9",
                    "ST2": "3",
                    "ST5": "4",
                    "ST6": "5",
                    "SZ30": "6",
                    "SZ40": "7",
                    "ST1": "8",
                    "X03": "9",
                    "X02": "10",
                    "SZ01": "25",
                    "SZ02": "26",
                    "SZ10": "27",
                    "SZ20": "28",
                    "SZ93": "29",
                    "SZ94": "30",
                    "X04": "35",
                    "T00": "37",
                    "X11": "36",
                    "X01": "11",
                    "X21": "1",
                    "X30": "37",
                    "zprava": "zprava",
                    "dispecink": "dispecink"
                }
            });
        });
    },
    sendToQueue(uuid) {
        return axios.get(apiUrl + '/isv/export?uuid=' + uuid + '&user='+store.state.auth.user.id+'&serial=' + store.state.auth.device.serial)
            .then(
                response => {
                    return response.data;
                }
            ).catch(function (error) {
                throw new Error(error.response);
            });
    }
}
