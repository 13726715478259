<template>
    <div>
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                <tr>
                    <th>stav</th>
                    <th></th>
                    <th>název</th>
                    <th>kód (RZ)</th>
                    <th>jednotka</th>
                    <th>typ</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                       <span v-if="resourceEvent">
                            <v-tooltip right content-class="map-tooltip-wrapper">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <v-icon @click.stop="showEventView()" size="40"
                                                :color="eventCategory(resourceEvent.eventCategoryId).color">{{
                                                eventCategory(resourceEvent.eventCategoryId).icon
                                            }}</v-icon>
                                    </div>
                                </template>
                                <tooltip-content :tooltip="tooltip(resourceEvent)"></tooltip-content>
                            </v-tooltip>
                        </span>
                    </td>
                    <td class="py-2">
                        <event-job-icon v-bind:resource="resource" v-bind:job="job"></event-job-icon>
                    </td>
                    <td>{{ resource.name }}</td>
                    <td>{{ resource.licence }}</td>
                    <td>{{ base.name }}</td>
                    <td>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                        <v-btn color="secondary" v-if="showUrl" @click="openUrl" icon v-on="on">
                            <v-icon>videocam</v-icon>
                        </v-btn>
                            </template>
                            {{ resource.urlName }}
                        </v-tooltip>
                        {{ resourceTypeById(resource.resourceTypeId).name }}
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import EventJobIcon from "@/components/panel/sidebar/event/JobIcon";
import TooltipContent from "@/components/ui/TooltipContent.vue";
import {appBus} from "@/main";
import {ON_OPEN_IFRAME, SHOW_EVENT_VIEW} from "@/events";

export default {
    name: 'resource',
    components: {TooltipContent, EventJobIcon},
    props: {
        resource: {
            type: Object,
            require: true,
        },
        job: {
            type: Object,
            require: true,
        },
        resourceEvent: {
            type: Object,
            require: false,
        },
        currentEvent: {
            type: Object,
        }
    },
    computed: {
        base() {
            return this.baseById(this.resource.baseId);
        },
        ...mapGetters('bases', {
            baseById: 'byId',
        }),
        eventCategory() {
            return id => this.getEventCategoryById(id);
        },
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
        }),
        ...mapGetters('events', {
            tooltip: 'targetTooltip',
        }),
        ...mapGetters('resourceTypes', {
            resourceTypeById: 'byId',
        }),
        showUrl() {
            return this.isSameEvent && this.resource.url;
        },
        isSameEvent() {
            return this.currentEvent.id === this.resourceEvent?.id;
        }
    },
    methods: {
        openUrl() {
            appBus.$emit(ON_OPEN_IFRAME, this.resource.url, this.resource.urlName);
        },
        showEventView() {
            if (this.resourceEvent)
                appBus.$emit(SHOW_EVENT_VIEW, this.resourceEvent);
        },
    }
}
</script>
