<template>
    <v-col align-items-center class="panel-alerts error fill-height mr-2 text-center font-weight-bold">
        <span class="text-caption">PORUCHA PŘIPOJENÍ!</span><br />
        <span class="text-caption">Poslední aktualizace {{ updatedAt | moment('HH:mm:ss')}}</span>
    </v-col>
</template>

<script>
    export default {
        name: 'header-main',
        props: {
            updatedAt: Number,
        },
    }
</script>
