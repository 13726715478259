<template>
    <div>
        <vl-layer-vector ref="vectorLayer" :opacity="isActiveEvent?0.5:1" id="events-layer"
                         :visible="!screenShotActive" :zIndex="103">
            <vl-source-vector :features="features">
                <vl-style-func :function="styleFunc"/>
            </vl-source-vector>
        </vl-layer-vector>
        <vl-layer-vector v-if="isActiveEvent" :visible="!screenShotActive" :zIndex="104">
            <vl-source-vector :features="activeEventFeatures">
                <vl-style-func :function="styleFunc"/>
            </vl-source-vector>
        </vl-layer-vector>
    </div>
</template>

<script>
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import Text from 'ol/style/Text'
import Fill from 'ol/style/Fill'
import {isEmpty} from "lodash";
import {mapGetters, mapState} from "vuex";

export default {
    name: 'layer-events-target',
    props: {
        screenShotActive: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        ...mapState({
            activeEventUuid: state => state.events.active,
        }),
        ...mapGetters('events', {
            events: 'getFilteredEvents',
            isActiveEvent: 'isActiveEvent',
            activeEvent: 'getActiveEvent',
        }),
        activeEventFeatures() {
            const activeEvents = {
                [this.activeEventUuid]: this.activeEvent
            }
            return this.eventToGeoJson(activeEvents)
        },
        features() {
            const geoJson = this.eventToGeoJson(this.events)
            if (isEmpty(geoJson))
                this.$refs.vectorLayer.refresh();

            return geoJson
        },
    },
    methods: {
        eventToGeoJson(events) {
            return Object.values(events)
                .filter(event => event.targetLongitude && event.targetLatitude)
                .map((event, index) => {
                    let eventCategory = this.$store.getters['eventCategories/getEventCategoryById'](event.eventCategoryId)

                    return {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [event.targetLongitude, event.targetLatitude]
                        },
                        properties: {
                            id: event.uuid,
                            type: 'eventTarget',
                            zIndex: index,
                            marker: eventCategory.marker,
                            tooltipAction: 'events/targetTooltipByKey',
                            contextAction: 'events/targetTooltipByKey',
                        }
                    }
                });
        },
        styleFunc(feature) {
            let markerHeight = 100;
            let style = [];
            const marker = feature.get('marker');
            const zIndex = feature.get('zIndex')

            const markerStyle = new Style({
                    image: new Icon({
                        src: './assets/icons/map/' + marker.shape,
                        crossOrigin: 'anonymous',
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'fraction',
                        anchor: [0.5, 1],
                        color: marker.color,
                        scale: marker.scale
                    }),
                });
            markerStyle.setZIndex(zIndex);
            style.push(markerStyle);

            const iconStyle = new Style({
                        text: new Text({
                            text: marker.iconCharCode,
                            font: '50px ' + marker.iconFontFamily,
                            offsetY: -1 * ((markerHeight / 100) * marker.iconOffset.y) * marker.scale,
                            placement: 'point',
                            textBaseline: 'middle',
                            textAlign: 'center',
                            scale: marker.scale,
                            fill: new Fill({
                                color: marker.iconColor
                            })
                        })
                    }
                );

            iconStyle.setZIndex(zIndex);
            style.push(iconStyle);

            return style;
        },
    },
}
</script>
