<template>
    <div>
        <vl-layer-vector :zIndex="102">
            <vl-source-vector :features.sync="features" ident="explorePoint">
                <vl-feature :properties="{type: 'sapTarget'}">
                    <vl-geom-point :coordinates="coordinates"></vl-geom-point>
                    <vl-style-func :function="targetStyleFunc()"/>
                </vl-feature>
            </vl-source-vector>
        </vl-layer-vector>
        <vl-interaction-translate
                v-if="features !== undefined && features.length > 0"
                source="explorePoint"
                @translatestart="beginTranslate"
                @translateend="endTranslate"
        >
        </vl-interaction-translate>
    </div>
</template>
<script>
    import {mapState} from "vuex";
    import Style from 'ol/style/Style'
    import Icon from 'ol/style/Icon'

    export default {
        name: 'sap-map-layer',
        data() {
            return {
                offsetLat: 0,
                offsetLon: 0,
                features: undefined
            }
        },
        computed: {
            ...mapState({
                items: state => state.explore.items,
                coordinates: state => state.explore.coordinates,
                status: state => state.sap.status
            }),
        },
        methods: {
            targetStyleFunc() {
                return () => {
                  return [
                    new Style({
                      image: new Icon({
                        src: './assets/icons/map/marker.png',
                        crossOrigin: 'anonymous',
                        anchor: [0.5, 1],
                      }),
                    })
                  ];
                }
            },
            beginTranslate(event) {
                this.offsetLon = this.features[0].geometry.coordinates[0] - event.coordinate[0];
                this.offsetLat = this.features[0].geometry.coordinates[1] - event.coordinate[1];
            },
            endTranslate(event) {
                const coordinates = [event.coordinate[0] + this.offsetLon, event.coordinate[1] + this.offsetLat];
                this.$store.dispatch('explore/start', {coordinates: coordinates});
            },
        },
    }
</script>
