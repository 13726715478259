import {stateMerge} from "@/store/object-merge";

const getDefaultState = () => {
    return {
        items: {},
    }
};

const state = getDefaultState();

const getters = {
    byId: state => (id) => {
        if (state.items[id] !== undefined)
            return state.items[id];
        return null;
    },
};

const actions = {
    async set({commit}, items) {
        commit("MERGE_STATE", items)
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
