import axios from 'axios';

let mapApiUrl = process.env.VUE_APP_MAP_API_URL;
let apiCancelSource = null;

export default {
    getHeaders() {
        const hostname = (new URL(mapApiUrl)).hostname;
        return {
            cancelToken: apiCancelSource ? apiCancelSource.token : null,
            headers: {
                'Access-Control-Allow-Origin': hostname,
            }
        };
    },
    getUrl() {
        return mapApiUrl;
    },
    get(endpoint) {
        this.cancel();
        apiCancelSource = axios.CancelToken.source();

        return axios.get(mapApiUrl + endpoint + '/', this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                window.console.warn(error);
            });
    },
    post(endpoint, data) {
        this.cancel();
        apiCancelSource = axios.CancelToken.source();

        return axios.post(mapApiUrl + endpoint + '/', data, this.getHeaders())
            .then(
                response => response.data
            ).catch(function (error) {
                window.console.warn(error);
            });
    },
    cancel() {
        if (apiCancelSource)
            apiCancelSource.cancel('Cancel previous request');
    }
}
