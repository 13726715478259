<template>
  <v-card v-if="visible" class="rail-image-wrapper">
    <v-toolbar
        color="default"
        dark
        dense
    >
      <v-toolbar-title>
        <small>
                    <span v-show="status===1">
                    Načítám... |
                </span>
          Náhled na trať
        </small>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="pl-3">
        <div v-show="status===5">
          <v-btn fab x-small @click="toggleSize">
            <v-icon>{{ resizeIcon }}</v-icon>
          </v-btn>
            <capture-screen-button v-if="$can('manage', 'Event')" :el="$refs.print" :message-type="messageType" @onSend="close"></capture-screen-button>
        </div>
        <v-btn fab x-small color="error" @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
      <v-card v-if="message">
      <v-card-text>
          {{ message }}
      </v-card-text>
      </v-card>
    <v-card v-show="maximized" ref="print">
      <v-card-text v-if="image">
        <div>
          Pozice: {{ coordinates }} | Datum pořízení: {{ capturedAt }} | Odchylka: {{ deviation }}m
        </div>
        <div class="images" v-viewer="">
          <img :src="'data:image/jpeg;base64,'+image"/>
        </div>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import railApi from "../../../api/rail";
import {transform} from 'ol/proj'

const regex = /\w'(.+)'/gm;
const subst = `$1`;

export default {
  name: 'map-rail-images',
    components: {
        CaptureScreenButton: () => import("@/components/ui/CaptureScreenButton"),
    },
  data() {
    return {
      status: 0,
      maximized: true,
      cancelSource: null,
      content: null,
      message: null,
      image: null,
      capturedAt: null,
      deviation: null,
      messageType: 'X24',
    }
  },
  computed: {
    ...mapState({
      userCoordinates: state => state.railImages.userCoordinates,
      coordinates: state => state.railImages.coordinates,
    }),
    ...mapGetters('railImages', [
      'visible',
    ]),
    resizeIcon() {
      return this.maximized ? 'minimize' : 'maximize';
    },
    coordinates: {
      get() {
        return this.$store.state.railImages.coordinates;
      },
      set(value) {
        this.$store.commit('railImages/setCoordinates', value)
      }
    },
  },
  methods: {
    ...mapActions('railImages', {
      closeRailImages: 'close',
    }),
    toggleSize() {
      this.maximized = !this.maximized;
    },
    close() {
      this.status = 0;
      this.closeRailImages();
    },
    getData(coordinates) {
      this.status = 1;
      this.maximized = false;
      this.message = null;

      return railApi.getData(coordinates).then((response) => {
        if (this.visible) {
          if (response.status !== undefined && response.status === 'OK') {
            this.coordinates = transform([response.longitude, response.latitude], 'EPSG:102067', this.$store.state.map.dataProjection);
            let result = response.image.replace(regex, subst);
            this.image = result;
            this.capturedAt = response.capturedAt;
            this.deviation = response.deviation;
            this.maximized = true;
            this.status = 5;
          } else {
              this.maximized = false;
              if (response.message) {
                  this.message = "Služba náhled na trať vrátila chybu: " + response.message;
              } else {
                  this.message = "Je nám líto, ale došlo k chybě při načítání náhledu na trať.";    
              }
          }
        }
      });
    },
  },
  watch: {
    userCoordinates: function (coordinates) {
      this.getData(coordinates);
    },
  },
}
</script>
