import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import './../icons/styles.css'

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import DatetimePicker from 'vuetify-datetime-picker'

const MY_ICONS = {
    'events': 'fas fa-list-alt',
    'calendar': 'fas fa-calendar-alt',
    'newEvent': 'fas fa-plus-circle',
    'messages': 'fas fa-comment',
    'refresh': 'fas fa-sync-alt',
    'edited': 'fas fa-pen',
    'reload': 'fas fa-redo-alt',
    'tools': 'fas fa-tools',
    'save': 'save',
    'clear': 'clear',
    'trash': 'delete',
    'delete': 'cancel',
    'cancel': 'cancel',
    'back': 'chevron_left',
    'close': 'fas fa-times',
    'inverColors': 'mdi mdi-invert-colors',

    // chat
    'chatUser': 'fas fa-user-astronaut',

    'confirm': 'fas fa-check',
    'attachment': 'attachment',
    'sendMessage': 'send',
    'visited': 'fas fa-eye',

    'eventMessages': 'chat_bubble',
    'eventMessagesTab': 'forum',
    'sendMessageByEnter': 'check_box',
    'sendMessageBySubmit': 'check_box_outline_blank',

    'guide': 'fas fa-info',
    'send': 'fas fa-check',
    'mu': 'fas fa-car-crash',
    'zoc': 'fas fa-tools',
    'mon': 'fas fa-eye',

    'eventInfo': 'info',
    'eventResources': 'fas fa-car-alt',
    'eventContacts': 'fax fa-phone',
    'eventLocation': 'fas fa-search-location',
    'eventCalls': 'fas fa-history',

    // map
    'mapOrigin': 'my_location',
    'mapPin': 'control_point',
    'drawPolygon': 'fas fa-draw-polygon',
    'drawCircle': 'fas fa-circle-notch',
    'searchLocation': 'fas fa-search',
    'mapFocusOn': 'ter ter-fit-on',
    'mapFocusOff': 'ter ter-fit-off',
    'suggest': 'fas fa-chevronUp',
    'target': 'fas fa-map-marker-alt',
    'panorama': 'streetview',
    'screenshot': 'add_photo_alternate',

    // map vehicles
    'vehicle_big-truck': 'ter ter-big-truck',
    'vehicle_bus': 'ter ter-bus',
    'vehicle_car': 'ter ter-car',
    'vehicle_crane': 'ter ter-crane',
    'vehicle_fire-truck': 'ter ter-fire-truck',
    'vehicle_pickup': 'ter ter-pickup',
    'vehicle_platform': 'ter ter-platform',
    'vehicle_tractor': 'ter ter-tractor',
    'vehicle_truck': 'ter ter-truck',
    'vehicle_missing': 'cancel',

    // poi types
    'poi_crossings': 'fas fa-traffic-light',
    'poi_rail': 'fas fa-grip-lines',
    'poi_station': 'fas fa-school',
    'poi_street': 'fas fa-road',
    'poi_address': 'fas fa-building',
    'poi_town': 'fas fa-city',

    'poi_missing': 'fas fa-question',
    'poi_custom': 'fas fa-map-marked-alt',
};

Vue.use(Vuetify);
Vue.use(DatetimePicker);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
        values: MY_ICONS,
    },
    theme: {
        // options: { customProperties: true },
        // themes: {
        //     light: {
        //         grey: {
        //             lighten3: "#e5e5e5",
        //         },
        //     }
        // },
    }
});
