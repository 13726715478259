<template>
    <div class="tags flex-grow-1">
        <event-tag v-for="(tag) in eventTags(event)"
                     :key="tag.id"
                     v-bind:tag="tag">
        </event-tag>
        <v-chip outlined small v-if="isClosed(event)">Uzavřeno</v-chip>
        <v-chip outlined small v-if="isDeleted(event)">Smazáno</v-chip>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import EventTag from "@/components/panel/sidebar/event/EventTag.vue";

    export default {
        name: 'event-tags',
        components: {EventTag},
        props: {
            event: {
                type: Object,
            },
        },
        computed: {
            ...mapGetters('events', {
                eventTags: 'getEventTags',
                isActive: 'isActive',
                isClosed: 'isClosed',
                isDeleted: 'isDeleted',
            }),
        },
        methods: {}
    }
</script>
