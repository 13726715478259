import {merge, filter, findIndex, forEach, toArray, orderBy} from "lodash";
import decode from "../../helpers/polyline";
import Vue from "vue";

const getDefaultState = () => {
    return {
        status: 0,
        items: [],
        routes: [],
        districts: [],
        datetime: null,
        coordinates: [],
    }
};

const state = getDefaultState();

const getters = {
    isEnabled: state => {
        return state.status > 0;
    },
    visible: state => {
        return state.status > 0;
    },
    jobs: (state, getters, rootState, rootGetters) => {
        let jobs = filter(state.items, {type: "vehicle"});

        let result = [];
        forEach(jobs, function (item) {
            if (item.lat > 0 && item.lon > 0) {
                let base = rootGetters['bases/byId'](item.baseId);

                if (base === undefined)
                    window.console.log('Cannot find base UID: ', item, item.baseId);

                result.push({
                    id: item.id,
                    baseId: base.id,
                    code: 1111,
                    eventJobCategoryId: 12,
                    eventJobStatusId: item.status,
                    eventUid: null,
                    vehicle: null,
                    _vehicle: {
                        id: item.id,
                        baseId: base.id,
                        code: item.name,
                        contactId: null,
                        destination: null,
                        icon: item.icon,
                        licence: item.licence,
                        name: item.name,
                        resourceTypeId: 1,
                        pnt: {
                            sourceName: '',
                            latitude: item.lat,
                            longitude: item.lon,
                            updatedAt: null,
                            route: item.geometry,
                        },
                    },
                    resources: {},
                });
            }
        });
        return result;

    },
    lines: (state) => {
        let result = [];
        forEach(state.items, function (item) {
            result.push({
                line: [
                    [
                        parseFloat(item.lon),
                        parseFloat(item.lat)
                    ],
                    [
                        state.coordinates[0],
                        state.coordinates[1],
                    ]
                ]
            });
        });
        return result;
    },
};

const actions = {
    start: ({commit}, coords) => {
        commit('resetState');
        commit('map/setOpacity', 0, {root: true});
        commit('map/setAutoFocus', 0, {root: true});
        commit('map/setJpoAreas', true, {root: true});
        commit('setCoordinates', coords);
        commit('setStatus', 1);
    },
    end: ({commit}) => {
        commit('resetState');
        commit('setStatus', false);
        commit('map/setOpacity', 1, {root: true});
        commit('map/setAutoFocus', 1, {root: true});
        commit('map/setJpoAreas', false, {root: true});
    },
    setInitialData: ({commit}, payload) => { // state, dispatch
        commit('setInitialData', payload);
    },
    resetState({commit}) {
        commit('resetState');
    },
};

const mutations = {
    setCoordinates: (state, coordinates) => {
        state.coordinates = coordinates;
    },
    setInitialData: (state, payload) => {
        state.districts = payload.districts;
        state.datetime = payload.date;
        state.items = payload.data;
        state.status = 4;
    },
    setExtendedData: (state, payload) => {
        let data = toArray(payload.data);
        let routes = [];
        forEach(data, function (item) {
            let index = findIndex(state.items, {'id': item.id});
            if (state.items[index] !== undefined) {
                state.items[index] = merge(state.items[index], item);

                if (item.route) {
                    routes.push({
                        type: 1,
                        label: item.durationText + ' / ' + item.distanceText,
                        coordinates: decode(item.route),
                    });
                }
            }
        });
        state.status = 5;
        state.items = orderBy(state.items, ['duration'], ['asc']);
        
        Vue.set(state, 'routes', routes);
    },
    setStatus: (state, status) => {
        state.status = status;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
